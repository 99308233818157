import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class DeregisterInstanceModel implements GraphQLData{
  
    lastEvaluatedKey: string = '';
    instanceId: string = '';

  public static create(instanceId: string) : DeregisterInstanceModel {
    const t = new DeregisterInstanceModel();
    t.instanceId = instanceId;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    if (data && data.data && data.data.GetApp && data.data.GetApp.DeregisterManagedInstance) {
      // this.instanceId = data.data.GetApp.managedInstance.instanceId;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetApp or data.data.GetApp.managedInstances.instanceId');
    }
  }
}