import React from 'react';
import { ITenant } from 'interfaces/tenant';
import { IEchoNode } from 'interfaces/echo-node';
import { IEchoEdge } from 'interfaces/echo-edge';
import EdgeMove from 'components/edge/EdgeMove';
import { IGraphEdgeData, IGraphNodeData } from 'components/graph/graph-interfaces';

export enum MoveDialogType {
  None,
  Move
}

interface Props {
  onCancel: () => void,
  tenant: ITenant,
  showDialog: MoveDialogType,
  newSource: IGraphNodeData | undefined,
  newTarget: IGraphNodeData | undefined,
  edge: IEchoEdge | undefined,
  onMoved: () => void,
  onEdgeMoved: (edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => void
}

export const MoveDialogs: React.FC<Props> = (props) => {
  if (props.showDialog === MoveDialogType.None) {
    return <></>;
  }

  return (
    <div>
      {props.showDialog === MoveDialogType.Move &&
        <EdgeMove
          edge={props.edge}
          tenant={props.tenant}
          onMoved={props.onMoved}
          onCancel={props.onCancel}
          newSource={props.newSource}
          newTarget={props.newTarget}
          onEdgeMoved={props.onEdgeMoved}
        />
      }
    </div>
  );
}