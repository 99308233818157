import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class StringArrayModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  result: Array<string> = new Array<string>();

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    if (data && data.data && data.data.GetSupportedRegions) {
      this.result = data.data.GetSupportedRegions;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetSupportedRegions');
    }
  }
}
