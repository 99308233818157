import React, { useEffect, useState } from "react";
import { makeStyles, Tab, Tabs, TextField, withStyles } from "@material-ui/core";
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import { IMessageType } from "interfaces/message-type";
import { ManagedNodeTypeModel } from "models/managed/managed-node-type";
import { CodeType } from "interfaces/code-type";
import EditorTemplate from "components/messageTypes/EditorTemplate";

interface Props {
  managedNodeType: ManagedNodeTypeModel,
  currentUserRole: UserRole | undefined,
  tenant: ITenant,
  onManagedNodeTypeChange: (code: string, copeType: CodeType, description: string, imageUri: string) => void
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#0EBCB7",
  },
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: "5px",
    marginRight: "5px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EditManagedNodeType: React.FC<Props> = (props) => {
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(0);
  const [description, setDescription] = useState('')
  const [imageUri, setImageUri] = useState('');
  const [readme, setReadme] = useState('');

  const classes = useStyles();

  useEffect(() => {
    setDescription(props.managedNodeType.description);
    setImageUri(props.managedNodeType.imageUri);
    setReadme(props.managedNodeType.readme);
  }, [props.managedNodeType])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const clearState = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpen(!hideDialog);
  }

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
    props.onManagedNodeTypeChange(readme, CodeType.readme, event.target.value, imageUri);
  }

  const onImageUriChange = (event: any) => {
    setImageUri(event.target.value);
    props.onManagedNodeTypeChange(readme, CodeType.readme, description, event.target.value);
  }
  
  const onReadmeChange = (code: string, codeType: CodeType, requirements: Array<string>) => {
    setReadme(code);
    props.onManagedNodeTypeChange(code, codeType, description, imageUri);
  }

  return (
    <div style={{ height: '100%' }}>
      <Tabs
        value={value}
        onChange={handleChange}
      >
        <Tab label="Description"  {...a11yProps(0)} />
        <Tab label="Image Uri"  {...a11yProps(1)} />
        <Tab label="ReadMe"  {...a11yProps(2)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TextField
          variant="outlined"
          value={props.managedNodeType.description}
          margin="dense"
          multiline
          rows={5}
          id="description"
          label="Description"
          onChange={onDescriptionChange}
          fullWidth
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TextField
          variant="outlined"
          value={props.managedNodeType.imageUri}
          margin="dense"
          id="imageUri"
          label="imageUri"
          onChange={onImageUriChange}
          fullWidth
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <EditorTemplate 
          codeType={CodeType.readme} 
          onChange={onReadmeChange} 
          tenant={props.tenant} 
          code={props.managedNodeType?.readme} 
          title="ReadMe"
          functionTester={true}
        />
      </TabPanel>
      
    </div>
  );
};

export default EditManagedNodeType;
