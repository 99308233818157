import React, { useEffect, useState } from 'react';
import { PortRequirement } from 'models/managed/port-requirement';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Protocol } from 'enumerations/protocol';
import { Port } from 'models/managed/port';
import ManagedNodePort from './ManagedNodePort';

interface Props {
  portRequirements: Array<PortRequirement> | undefined,
  ports: Array<Port> | undefined,
  onPortsChanged: (ports: Array<Port>) => void,
  isAddingManagedNode?: boolean
}

const PortView: React.FC<Props> = (props) => {
  const [portHasChanged, setPortHasChange] = useState(false);
  const [editedValue, setEditedValue] = useState(new Port(0, '', Protocol.tcp, '', 0));
  const [addedPortValue, setAddedPortValue] = useState(new Port(0, '', Protocol.tcp, '', 0));
  const [isEditing, setIsEditing] = useState(false);
  const [portBeforeEdit, setPortBeforeEdit] = useState(new Port(0, '', Protocol.tcp, '', 0));
  const [addingPort, setAddingPort] = useState(false);
  const [ports, setPorts] = useState<Array<Port>>(new Array<Port>());


  //NEED TO SET HOST PORT TO LOCAL PORT? OR FINE AS IS?
  useEffect(() => {
    if (props.portRequirements) {
      const prts = props.portRequirements.map(o => {
        return {
          containerPort: o.containerPort,
          description: o.description,
          protocol: o.protocol,
          hostAddress: '0.0.0.0',
          hostPort: o.containerPort
        } as Port
      });
      props.onPortsChanged(prts);
      setPorts(prts);
    } else if (props.ports) {
      setPorts(props.ports);
    }
  }, [props.portRequirements, props.ports]);

  const editPort = (port: Port) => {
    setIsEditing(true);
    //setEditedPort(port);
    setEditedValue({ ...port })
  }

  const updatePort = (oldPort: Port, newPort: Port) => {
    // delete the edited port
    const prts = ports.filter(o => o !== oldPort);
    // add the new one
    const tt = prts.concat(newPort);
    setPorts(tt);
    props.onPortsChanged(tt);
    cancelEdit();
  }

  const handleValueChange = (event: any) => {
    //setEditedKey(event.target.value);
  }

  const handleContainerPortChange = (event: any) => {
    setEditedValue(new Port(event.target.value, editedValue.description, editedValue.protocol, editedValue.hostAddress, editedValue.hostPort));
  }

  const cancelEdit = () => {
    setEditedValue(new Port(0, '', Protocol.tcp, '', 0));
    setIsEditing(false);
  }

  const savePort = () => {

  }

  const resetPorts = () => {

  }

  return (
    <>
      <div style={{ overflow: 'auto', marginTop: '10px' }}>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Ports </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Container Port</TableCell>
                  <TableCell align="left">Protocol</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Host Address</TableCell>
                  <TableCell align="left">Host Port</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ports && ports.map((o, index) => {
                  if (!isEditing) {
                    return (
                      <TableRow>
                        <ManagedNodePort 
                          key={index}
                          port={o}
                          updatePort={updatePort}
                          cancelEdit={cancelEdit}
                          setEditedValue={setEditedValue}
                          editedValue={editedValue}
                          setPortBeforeEdit={setPortBeforeEdit}
                          />
                      </TableRow>)
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </fieldset>
      </div>
    </>
  );
}

export default PortView;