import React from 'react';
import { IAtBy } from 'interfaces/at-by';
import { Utilities } from 'utilities/utilities';

interface Props {
  atby: IAtBy | undefined,
  label: string
}

const AtByView: React.FC<Props> = (props) => {
  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
        <legend style={{ fontSize: '12px' }}>{props.label}</legend>
        At '{Utilities.toLocaleString(props.atby?.at)}' by '{props.atby?.by}'
      </fieldset>
    </div>
  );
}

export default AtByView;