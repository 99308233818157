import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FormControl, FormControlLabel, IconButton, InputLabel, Select } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import EchoDialog from './EchoDialog';
import { IApp } from 'interfaces/app';
import { ManagedNodeTypeModel } from 'models/managed/managed-node-type';
import { PortRequirement } from 'models/managed/port-requirement';
import PortView from './managedNodeTypes/PortView';
import { Port } from 'models/managed/port';
import { ManagedNodeModel } from 'models/managed/managed-node';
import JSONEditor from 'components/JSONEditor';
import MountView from './managedNodeTypes/MountView';
import { MountRequirement } from 'models/managed/mount-requirement';
import { Mount } from 'models/managed/mount';
import { MountInput, PortInput } from '../models/managed/InputTypes';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  managedNodeTypes: Array<ManagedNodeTypeModel>,
  tenant: ITenant,
  app: IApp,
  isAddingManagedNode?: boolean
}

const ManagedNodeAdd: React.FC<Props> = (props) => {

  const [nodeName, setNodeName] = useState('');
  const [description, setDescription] = useState('');
  const [nodeNameValid, setNodeNameValid] = useState(false);
  const [managedNodeType, setManagedNodeType] = useState('');
  const [ports, setPorts] = useState<Array<Port>>(new Array<Port>());
  const [mounts, setMounts] = useState<Array<Mount>>(new Array<Mount>());
  const [config, setConfig] = useState('{}');
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [portRequirements, setPortRequirements] = useState<Array<PortRequirement>>(new Array<PortRequirement>());
  const [mountRequirements, setMountRequirements] = useState<Array<MountRequirement>>(new Array<MountRequirement>());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.managedNodeTypes) {
      // default to the first one
      changeSelectedManagedNodeType(props.managedNodeTypes[0].name);
    }
  }, [props.managedNodeTypes]);

  const handleAddNode = async () => {
    try {
      setIsLoading(true);
      setInfoMessage('');
      setErrorMessage('');
      setSuccessMessage('');
      const mountInputs = mounts.map(o => {
        return {
          source: o.source,
          target: o.target
        } as MountInput
      });
      const portInputs = ports.map(o => {
        return {
          containerPort: o.containerPort,
          hostAddress: o.hostAddress,
          hostPort: o.hostPort,
          protocol: o.protocol
        } as PortInput
      });
      const params = {
        app: props.app.name,
        managedNodeType: managedNodeType,
        tenant: props.tenant.name,
        name: nodeName,
        description: description,
        ports: portInputs,
        mounts: mountInputs,
        config: config
      };
      const p = await GraphQLHelper.execute<ManagedNodeModel>(MutationsStatic.createManagedNode, params, ManagedNodeModel);
      if (!p.error) {
        setSuccessMessage(`Managed Node ${nodeName} created.`);
      } else {
        setErrorMessage(p.errorMessage);
      }
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log(JSON.stringify(err));
      console.log('Can\'t create node', err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNodeNameChange = (event: any) => {
    setNodeName(event.target.value);
    setNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-]{3,63}$/)  ? true : false);

  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const handleConfigChange = (code: string) => {
    setConfig(code);
  }

  const changeSelectedManagedNodeType = (nodeType: string) => {
    setManagedNodeType(nodeType);
    setPortRequirements(new Array<PortRequirement>());
    const found = props.managedNodeTypes.find(o => o.name === nodeType);
    if (found && found.portRequirements.length > 0) {
      setPortRequirements(found.portRequirements);
    }
    if (found && found.mountRequirements.length > 0) {
      setMountRequirements(found.mountRequirements);
    }
  };

  const managedNodeTypeChange = (event: any) => {
    setPorts(new Array<Port>());
    setMounts(new Array<Mount>());
    changeSelectedManagedNodeType(event.target.value);
  };

  const onShowHelp = (url: string) => {
    window.open(url, '_blank');
  }

  const portsChanged = (prts: Array<Port>) => {
    setPorts(prts);
  }

  const mountsChanged = (mounts: Array<Mount>) => {
    setMounts(mounts);
  }

  const title = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>{`Add Managed Node to '${props.app?.name}'`}</div>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={() => { onShowHelp('https://docs.echo.stream/docs/managed-node'); }}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
      </div>)
  }

  const contentText = () => {
    return `Managed Nodes are nodes that exist on the boundary of your EchoStream network. They are Docker images that are managed by EchoStream inside of a Managed Application virtualization environment (VMWare, KVM, Hyper-V or VirtualBox). They are designed to run on your or your customer's premise.`;
  }

  const isValid = (): boolean => {
    const string = nodeName;
    return managedNodeType && nodeName && string.match(/^[A-Za-z0-9\-]{3,63}$/)  ? true : false;
  }

  return (
    <EchoDialog
      onCancel={props.onCancel}
      onOk={props.onAdd}
      onSave={async () => { await handleAddNode(); }}
      isValid={isValid()}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      successMessage={successMessage}
      title={title()}
      contentText={contentText()}
      open={true}
      fullWidth={true}
      size={'lg'}
      spinner={isLoading}
    >
      <div>
        <TextField
          error={!nodeNameValid}
          helperText="Name must be a minimum of 3 characters and can use the special character '-' ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="transNodeName"
          label="Node name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="transDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Config </legend>
          <JSONEditor code={config} onCodeChange={!isLoading ? handleConfigChange : null} />
        </fieldset>
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}>
            <InputLabel htmlFor="filled-age-native-simple">Managed Node Type</InputLabel>
            <Select
              native
              value={managedNodeType}
              onChange={managedNodeTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
              disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
            >
              {props.managedNodeTypes && props.managedNodeTypes.map((m: ManagedNodeTypeModel, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        {managedNodeType && portRequirements.length > 0 &&
          <PortView
            portRequirements={portRequirements}
            onPortsChanged={portsChanged}
            ports={undefined}
            isAddingManagedNode={props.isAddingManagedNode}
          />
        }
        {managedNodeType && mountRequirements.length > 0 &&
          <MountView
            mountRequirements={mountRequirements}
            onMountsChanged={mountsChanged}
            mounts={undefined}
            isAddingManagedNode={props.isAddingManagedNode}
          />
        }
      </div>
    </EchoDialog>
  );
}

export default ManagedNodeAdd;