import React, { useState, useEffect } from "react";
import { ConfigurableEntity } from "enumerations/configurable-entity";
import { ConfigView } from "components/ConfigView";
import { ITenant } from "interfaces/tenant";
import CredentialsView from "components/CredentialsView";
import { CredentialEntity } from "enumerations/credential-entity";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { ExternalAppModel } from "models/app/external-app";
import { UserRole } from "enumerations/user-role";

interface Props {
  app: ExternalAppModel,
  tenant: ITenant,
  userRole: UserRole,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  tableAccess: boolean,
  setTableAccess: React.Dispatch<React.SetStateAction<boolean>>,
  onTableAccessChange: (tableAccess: boolean) => void,
  description: string,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  onDescriptionChange: (e: any) => void
}

const ExternalApp: React.FC<Props> = (props) => {
  
  const [allowTableAccess, setAllowTableAccess] = useState(false);

  useEffect(() => {
    console.log("ExternalApp", props.app);
    // setAllowTableAccess(props.app.tableAccess);
    
    if (props.app.description) {
      props.setDescription(props.app.description);
    }
  }, [props.app]);

  return (
    <div>
      {!props.isEditing ? 
        <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
          <legend style={{ fontSize: '12px' }}>Description</legend>
            {props.description ? props.description : '<no description>'}
        </fieldset>
       :
       <TextField
        onChange={(e: any) => {
          props.onDescriptionChange(e);
        }}
        variant="outlined"
        value={props.description}
        margin="dense"
        id="description"
        label="Description"
        fullWidth
     />
      }
      <div style={{ clear: 'both' }}>
        <ConfigView 
          tenant={props.tenant} 
          configurableEntity={ConfigurableEntity.ExternalApp} 
          entityKey={props.app.name} 
          parentKey={''} 
          userRole={props.userRole}
        />
      </div>
      <CredentialsView 
        tenant={props.tenant} 
        credentialEntity={CredentialEntity.ExternalApp} 
        entityKey={props.app.name} 
        appsyncEndpoint={props.app.appsyncEndpoint}
      />
      {/* <AWSCredentialsView tenant={props.tenant} entityKey={props.app.name} /> */}
      <FormControlLabel
          control={
            <Checkbox
              checked={props.tableAccess}
              onChange={(): void => { setAllowTableAccess(!allowTableAccess); props.onTableAccessChange(!allowTableAccess); props.setTableAccess(!props.tableAccess)}}
              name="checkedB"
              color="primary"
            />
          }
          label="Allow Table Access"
        />
    </div>
  )
}

export default ExternalApp