import React from 'react';
import { PortRequirement } from 'models/managed/port-requirement';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

interface Props {
  portRequirements: Array<PortRequirement> | undefined,
  label: string
}

const PortRequirementsView: React.FC<Props> = (props) => {

  return (
    //NOT MY CODE, DIDN'T WANT TO JUST DELETE IT. 
    // <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '700px' }}>
    //   <legend style={{ fontSize: '12px' }}>{props.label} {(!props.portRequirements || props.portRequirements.length === 0) && ' - (not configured)'}</legend>
    //   {
    //     props.portRequirements?.map((o, index) =>
    //       <div>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Port</legend>
    //           {o.containerPort}
    //         </fieldset>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Description</legend>
    //           {o.description}
    //         </fieldset>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Protocol</legend>
    //           {o.protocol}
    //         </fieldset>
    //       </div>
    //     )
    //   }
    // </fieldset>

    <div style={{ overflow: 'auto', marginTop: '10px' }}>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Port Requirements </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Container Port</TableCell>
                  <TableCell align="left">Protocol</TableCell>
                  <TableCell align="left">Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.portRequirements?.map((o, index) => 
                  <>
                    <TableRow key={`${o.containerPort}-${index}`}>
                      <TableCell style={{ width: '200px' }}>{o.containerPort}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.protocol}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.description}</TableCell>
                    </TableRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </fieldset>
      </div>
  )
}

export default PortRequirementsView;