import { GraphItemType } from "components/graph/graph-interfaces";
import { IApp } from "interfaces/app";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { TenantModel } from "models/tenant/tenant-model";

export class ExternalAppModel implements IApp, GraphQLData {
  hasTableAccess: boolean = true;
  graphItemType: GraphItemType = GraphItemType.app;
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  tableAccess: boolean = false;
  name: string = '';
  tenant: ITenant = new TenantModel();
  typeName: string = '';

  deleted: boolean = false;
  lastEvaluatedKey: string = '';
  appsyncEndpoint: string = '';

  public static create(name: string, tenant: ITenant, typeName: string, tableAccess: boolean, appsyncEndpoint: string, description: string): ExternalAppModel {
    const t = new ExternalAppModel();
    t.name = name;
    t.tenant = tenant;
    t.typeName = typeName;
    t.tableAccess = tableAccess;
    t.appsyncEndpoint = appsyncEndpoint;
    t.description = description;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}