import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, Tooltip } from "@material-ui/core";
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import { Box } from '@mui/material';
import EchoDialog from "components/EchoDialog";
import EditIcon from '@material-ui/icons/Edit';
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import EditManagedNodeType from "./EditManagedNodeType";
import { ManagedNodeTypeModel } from "models/managed/managed-node-type";
import MountRequirementsView from "./MountRequirementsView";
import PortRequirementsView from "./PortRequirementsView";
import AtByView from "components/AtBy";
import { CodeType } from "interfaces/code-type";
import MarkdownView from "components/MarkdownView";
import JSONEditor from "components/JSONEditor";
import { MutationsStatic } from "graphql/mutations-static";
import { GraphQLHelper } from "utilities/graphql-helper";

interface Props {
  managedNodeType: ManagedNodeTypeModel | undefined,
  currentUserRole: UserRole | undefined,
  onDelete: () => void,
  onSave: () => void,
  onCancel: () => void,
  tenant: ITenant,
  onManagedNodeTypeChange: (code: string, codeType: CodeType, description: string, imageUri: string) => void,
}

const ManagedNodeTypeDetails: React.FC<Props> = (props) => {
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [managedNodeTypeHasChange, setManagedNodeTypeHasChange] = useState(false);
  const [prevMNT, setPrevMNT] = useState<ManagedNodeTypeModel>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPrevMNT(props.managedNodeType)
  }, []);

  useEffect(() => {
    setManagedNodeTypeHasChange(false);
    setEditing(false);
  }, [props.managedNodeType]);

  const clearState = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpen(!hideDialog);
  }

  const onDeleteManagedNodeType = async () => {
    if(props.managedNodeType){
    const params = {
      name: props.managedNodeType?.name,
      tenant: props.tenant?.name
    }
    console.log(params);
    
    const p = await GraphQLHelper.execute<ManagedNodeTypeModel>(MutationsStatic.deleteManagedNodeType, params, ManagedNodeTypeModel);
    if (!p.error) {
      console.log(p);
      setSuccessMessage('Managed Node Type Deleted');
      props.onDelete();  
    } else {
      setErrorMessage(JSON.stringify(p.errorMessage));
    }
  }
}

  const onMNTChange = (code: string, codeType: CodeType, description: string, imageUri: string) => {
    setManagedNodeTypeHasChange(true);
    props.onManagedNodeTypeChange(code, codeType, description, imageUri);
    }
    
  const cancelEdit = () => {
    props.onCancel();
    setEditing(false);
    setManagedNodeTypeHasChange(false);
  }

  const sys = props.managedNodeType?.system ? 'System' : 'Tenant';
  //const requirements = props.messageType?.requirements?.join().replaceAll(',', '\n');
  const title = <div><div style={{ float: 'left' }}>{props.managedNodeType?.name}</div><div style={{ float: 'right' }}>{`${sys}`}</div></div>;
  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={open}
        title={successMessage ? 'Message Type deleted' : `Delete message type: ${props.managedNodeType?.name}?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearState(true)}
        onOk={() => clearState(true)}
        onSave={() => onDeleteManagedNodeType()}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      {props.managedNodeType &&
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '0px', marginTop: '30px' }}
            title={title ? title : ''}
          />
          <div style={{ overflow: 'auto', height: '100%' }}>
            <CardContent style={{ height: '90%', flex: 'auto' }}>
              {editing &&
                <div style={{ height: '100%' }}>
                  <EditManagedNodeType 
                    onManagedNodeTypeChange={onMNTChange} 
                    managedNodeType={props.managedNodeType} 
                    tenant={props.tenant} 
                    currentUserRole={props.currentUserRole} 
                    />
                  <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                    <Grid container justifyContent="flex-end">
                      {!props.managedNodeType?.system &&
                        <Tooltip title="Edit" aria-label="edit">
                          <span>
                            <IconButton disabled={!managedNodeTypeHasChange} onClick={() => { props.onSave(); setManagedNodeTypeHasChange(false); setEditing(false); }} aria-label="share">
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                      <IconButton onClick={() => { cancelEdit(); }} aria-label="share">
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </CardActions>
                </div>
              }
              {!editing &&
                <div>
                  <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}>Description</legend>
                    {props.managedNodeType?.description ? props.managedNodeType?.description : '<no description>'}
                  </fieldset>
                  <div style={{ clear: 'both', float: 'left' }}>
                    <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                      <legend style={{ fontSize: '12px' }}>Receive Message Type</legend>
                        {props.managedNodeType?.receiveMessageType && props.managedNodeType?.receiveMessageType.name ? props.managedNodeType?.receiveMessageType.name : '<no message type>'}
                    </fieldset>
                  </div>
                  <div style={{ float: 'left', marginLeft: '5px' }}>
                    <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                      <legend style={{ fontSize: '12px' }}>Send Message Type</legend>
                        {props.managedNodeType?.sendMessageType && props.managedNodeType?.sendMessageType.name ? props.managedNodeType?.sendMessageType.name : '<no message type>'}
                    </fieldset>
                  </div>
                  <div style={{ clear: 'left' }}>
                    <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                      <legend style={{ fontSize: '12px' }}>ImageUri</legend>
                        {props.managedNodeType?.imageUri}
                    </fieldset>
                  </div>
                  <fieldset style={{ clear: 'both', marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Config Template </legend>
                    <JSONEditor code={JSON.stringify(JSON.parse(props.managedNodeType?.configTemplate || '{}'), null, 2)} />
                  </fieldset>
                  <fieldset style={{ clear: 'both', marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> ReadMe </legend>
                    <Box height="300px">
                      <MarkdownView markdown={props.managedNodeType?.readme} />
                    </Box>
                  </fieldset>
                  <div style={{ clear: 'both' }}>
                    <MountRequirementsView mountRequirements={props.managedNodeType?.mountRequirements} label='Host Mount Requirements' />
                  </div>
                  <div style={{ clear: 'both' }}>
                    <PortRequirementsView portRequirements={props.managedNodeType?.portRequirements} label='Port Requirements' />
                  </div>
                  <div style={{ clear: 'both' }}>
                    <AtByView label='Created' atby={props.managedNodeType?.created} />
                  </div>
                  <div style={{ clear: 'both' }}>
                    <AtByView label='Last Modified' atby={props.managedNodeType?.lastModified} />
                  </div>
                  {((props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) && !props.managedNodeType.system) &&
                    <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                      <Grid container justifyContent="flex-end">
                        <Tooltip title="Edit" aria-label="edit">
                          <IconButton onClick={() => { setEditing(true); }} aria-label="share">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => { setOpen(true); }} aria-label="share">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </CardActions>
                  }
                </div>
              }
            </CardContent>
          </div>
        </Card>
      }
    </div>
  );
};

export default ManagedNodeTypeDetails;
