import { IAtBy } from "interfaces/at-by";
import { AtByModel } from "models/at-by-model";

export enum ChangeAction {
  none = 'None',
  update = 'Update',
  insert = 'Insert',
  delete = 'Delete',
}

export class ChangeModel {
  newModel: string = '';
  newModelAuditor: IAtBy = new AtByModel('', '');
  oldModel: string = '';
  oldModelAuditor: IAtBy = new AtByModel('', '');
  typeName: string = '';
  action: ChangeAction = ChangeAction.none;

  constructor(oldModel: string, oldModelAuditor: IAtBy, newModel: string, newModelAuditor: IAtBy, typeName: string) {
    this.oldModel = oldModel;
    this.oldModelAuditor = oldModelAuditor;
    this.newModel = newModel;
    this.newModelAuditor = newModelAuditor;
    this.typeName = typeName;

    this.action = ChangeAction.update;

    if (newModel && oldModel === 'null') {
      this.action = ChangeAction.insert;
    } else if (newModel === 'null' && oldModel) {
      this.action = ChangeAction.delete;
    }

  }
}