import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { IEchoEdge } from "interfaces/echo-edge";

export class NotImplementedNodeModel implements IEchoNode, GraphQLData {
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = ''
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  deleted: boolean = false;
  receiveMessageType: IMessageType = new MessageTypeModel();
  sendMessageType: IMessageType = new MessageTypeModel();
  receiveEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  
  public static create(
    name: string,
    typeName: string,
    receiveMessageType: IMessageType,
    sendMessageType: IMessageType, 
    sendEdges: Array<IEchoEdge>
  ) : NotImplementedNodeModel {
    const t = new NotImplementedNodeModel();
    t.name = name;
    t.typeName = typeName;
    t.receiveMessageType = receiveMessageType;
    t.sendEdges = sendEdges;
    t.sendMessageType = sendMessageType;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}