import React, { useState, useEffect } from "react";
import { IEchoFunction } from "interfaces/echo-function";
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, List, ListItem, Tooltip, Typography } from "@material-ui/core";
import { Box, ListItemText } from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import EditIcon from '@material-ui/icons/Edit';
import CodeEditor from "components/CodeEditor";
import FunctionTester from "components/FunctionTester";
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import EditFunction from "./EditFunction";
import { ValidationType } from "enumerations/validation-type";
import { CodeType } from "interfaces/code-type";
import MarkdownView from "components/MarkdownView";
import ApiFunctionTester from "components/ApiFunctionTester";

interface Props {
  selectedFunction: IEchoFunction | undefined,
  tenant: ITenant,
  currentUserRole: UserRole | undefined,
  onSave: (func: IEchoFunction) => void,
  onDelete: (func: IEchoFunction) => void,
  editSelectedFunction: boolean,
  setEditSelectedFunction: React.Dispatch<React.SetStateAction<boolean>>
}

const TenantFunctionDetails: React.FC<Props> = (props) => {

  const [code, setCode] = useState<string>('');
  const [codeEditing, setCodeEditing] = useState(false);
  const [functionHasChanged, setFunctionHasChanged] = useState(false);
  const [description, setDescription] = useState('');
  const [editedFunction, setEditedFunction] = useState<IEchoFunction>();
  const [requirements, setRequirements] = useState<Array<string>>([]);
  

  useEffect(() => {
    if (props.selectedFunction) {
      setCode(props.selectedFunction.code);
      setEditedFunction(props.selectedFunction);
    }
  }, [props.selectedFunction]);

  const onCodeChange = (code: string) => {
    setCode(code);
    const changedFunc = { ...editedFunction, code: code } as IEchoFunction;
    setEditedFunction(changedFunc);
    setFunctionHasChanged(true);
    setCodeEditing(true);
  }

  const detailsViewCodeChange = (code: string) => {
    setCode(code);
    const changedFunc = { ...editedFunction, code: code } as IEchoFunction;
    setEditedFunction(changedFunc);
    setCodeEditing(true);
  }

  const onFunctionChange = (code: string, codeType: CodeType, requirements: Array<string>) => {
    setFunctionHasChanged(true);
    if (codeType === CodeType.requirements) {
      const changedFunc = { ...editedFunction, requirements: requirements } as IEchoFunction;
      setEditedFunction(changedFunc);
    } else if (codeType === CodeType.description) {
      const changedFunc = { ...editedFunction, description: code } as IEchoFunction;
      setEditedFunction(changedFunc);
    } else if (codeType === CodeType.readme) {
      const changedFunc = { ...editedFunction, readme: code } as IEchoFunction;
      setEditedFunction(changedFunc);
    } else if (codeType === CodeType.processor || codeType === CodeType.bitMapper) {
      const changedFunc = { ...editedFunction, code: code } as IEchoFunction;
      setEditedFunction(changedFunc);
    }
  }

  const saveFunc = () => {
    setCodeEditing(false);
    setFunctionHasChanged(false);
    if (editedFunction) {
      props.onSave(editedFunction);
    }
  }

  const sys = props.selectedFunction?.system ? 'System Function' : 'Tenant Function';
  const title = <div><div style={{ float: 'left' }}>{props.selectedFunction?.name}</div><div style={{ float: 'right' }}>{`${sys}`}</div></div>;

  return (
    <div style={{ height: '100%' }}>
      {props.selectedFunction &&
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '0px', marginTop: '30px' }}
            title={title ? title : ''}
          />
          <div style={{ overflow: 'auto' }}>
            <CardContent style={{ height: 'auto', flex: 'auto' }}>
              {!props.editSelectedFunction && <div>
                <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
                    <legend style={{ fontSize: '12px' }}> Description </legend>
                    {props.selectedFunction?.description}
                </fieldset>
                <div>
                  <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
                    <legend style={{ fontSize: '12px' }}> Type </legend>
                    {props.selectedFunction?.typeName}
                  </fieldset>
                  {props.selectedFunction?.typeName !== "ApiAuthenticatorFunction" &&
                    <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
                    <legend style={{ fontSize: '12px' }}> Argument Type </legend>
                    {props.selectedFunction?.argumentMessageType?.name}
                  </fieldset>}
                  {props.selectedFunction?.returnMessageType &&
                    <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
                      <legend style={{ fontSize: '12px' }}> Result Type </legend>
                      {props.selectedFunction?.returnMessageType.name ? props.selectedFunction?.returnMessageType.name : 'N/A'}
                    </fieldset>
                  }
                </div>
                <fieldset style={{ marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888', clear: 'both' }}>
                    <legend style={{ fontSize: '12px' }}> ReadMe </legend>
                    <Box height="150px">
                      <MarkdownView markdown={props.selectedFunction?.readme} />
                    </Box>
                  </fieldset>
                <div style={{ clear: 'both' }}>
                  <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Function Code </legend>
                    <Box height="250px" overflow="auto" >
                      {(props.selectedFunction?.system) &&
                        <CodeEditor code={code} />
                      }
                      {!props.selectedFunction?.system &&
                        <CodeEditor code={code} onCodeChange={detailsViewCodeChange} />
                      }
                    </Box>
                    {!props.selectedFunction?.system &&
                        <Tooltip title="Save" aria-label="save" >
                          <span>
                            <IconButton disabled={!codeEditing} onClick={() => { saveFunc(); }} aria-label="share">
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                  </fieldset>
                </div>
                <div style={{ marginLeft: '3px' }}>
                  {props.selectedFunction?.typeName !== 'ApiAuthenticatorFunction' ?
                    <FunctionTester
                      tenant={props.tenant}
                      code={code}
                      functionName={props.selectedFunction?.name}
                      validationType={ValidationType.Function}
                      receiveMessageType={props.selectedFunction?.argumentMessageType}
                    />
                  :
                    <ApiFunctionTester 
                      tenant={props.tenant}
                      code={code}
                      functionName={props.selectedFunction?.name}
                      validationType={ValidationType.Function}
                    />
                  }
                </div>
                <Box sx={{ border: "0.5px #888888 solid", borderRadius: 1, marginTop: 5}}>
                  <Typography style={{ padding: "8px", fontWeight: 1, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }} variant="h6">
                    Requirements
                  </Typography>
                    <List>
                      {props.selectedFunction.requirements ? props.selectedFunction.requirements.map(req => <ListItem style={{ padding: 0, paddingLeft: 8, margin:0 }}> <ListItemText primary={req}/> </ListItem> ) : '<no requirements>'}
                    </List>
                </Box>
              </div>
              }
              {props.editSelectedFunction &&
                <div style={{ height: '100%' }}>
                  <EditFunction function={props.selectedFunction} onFunctionChange={onFunctionChange} tenant={props.tenant} currentUserRole={props.currentUserRole} />
                  <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                    <Grid container justifyContent="flex-end">
                      {!props.selectedFunction?.system &&
                        <Tooltip title="Save" aria-label="save">
                          <span>
                            <IconButton disabled={!functionHasChanged} onClick={() => { saveFunc(); props.setEditSelectedFunction(false); }} aria-label="share">
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                      <IconButton onClick={() => { props.setEditSelectedFunction(false); setFunctionHasChanged(false); }} aria-label="share">
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </CardActions>
                </div>
              }
            </CardContent>
            {((props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) && !props.selectedFunction?.system && !props.editSelectedFunction) &&
              <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                <Grid container justifyContent="flex-end">
                  {functionHasChanged && <div><Tooltip title="Save" aria-label="edit">
                    <IconButton onClick={() => { props.setEditSelectedFunction(true); }} aria-label="share">
                      <SaveIcon />
                    </IconButton>
                  </Tooltip>
                    <IconButton onClick={() => { setFunctionHasChanged(false); setCode(props.selectedFunction ? props.selectedFunction.code : ''); }} aria-label="share">
                      <CancelIcon />
                    </IconButton>
                  </div>
                  }
                  {!functionHasChanged && <div><Tooltip title="Edit" aria-label="edit">
                    <IconButton onClick={() => { props.setEditSelectedFunction(true); }} aria-label="share">
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                    <IconButton onClick={() => { if (props.selectedFunction) { props.onDelete(props.selectedFunction); setFunctionHasChanged(false); } }} aria-label="share">
                      <DeleteIcon />
                    </IconButton>
                  </div>}
                </Grid>
              </CardActions>
            }
          </div>
        </Card>
      }
    </div>
  );
};

export default TenantFunctionDetails;