import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { IValidateFunction } from "interfaces/validate-function";

export class ValidateFunctionModel implements IValidateFunction, GraphQLData {
  lastEvaluatedKey: string = '';
  result: string = '';
  stdout: string = '';
  logs: string = '';
  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    if (data && data.data && data.data.GetValidation && data.data.GetValidation.Validate) {
      const rs = data.data.GetValidation.Validate;
      this.result = rs.result;
      this.stdout = rs.stdout;
      this.logs = rs.logs;
    } else if (data && data.data && data.data.GetNode && data.data.GetNode.Validate) {
      const rs = data.data.GetNode.Validate;
      this.result = rs.result;
      this.stdout = rs.stdout;
      this.logs = rs.logs;
    } else if (data && data.data && data.data.GetFunction && data.data.GetFunction.Validate) {
      const rs = data.data.GetFunction.Validate;
      this.result = rs.result;
      this.stdout = rs.stdout;
      this.logs = rs.logs;
    } else {  
      throw new Error('data structured passed in is incorrect');
    }
  }
}
