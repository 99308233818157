import React from 'react';
import { ITenant } from 'interfaces/tenant';
import { IEchoNode } from 'interfaces/echo-node';
import EdgeDelete from 'components/edge/EdgeDelete';
import { IEchoEdge } from 'interfaces/echo-edge';
import NodeDelete from 'components/node/NodeDelete';
import { IApp } from 'interfaces/app';
import AppDelete from 'components/apps/AppDelete';

export enum DeleteDialogType {
  None,
  Node,
  App,
  Edge
}

interface Props {
  onCancel: () => void,
  tenant: ITenant,
  showDialog: DeleteDialogType,
  fromNode: IEchoNode | undefined,
  toNode: IEchoNode | undefined,
  node: IEchoNode | undefined,
  edge: IEchoEdge | undefined,
  app: IApp | undefined,
  onDelete: () => void,
  onCloseDialog: () => void
}

export const DeleteDialogs: React.FC<Props> = (props) => {
  if (props.showDialog === DeleteDialogType.None) {
    return <></>;
  }

  return (
    <div>
      {props.showDialog === DeleteDialogType.Edge &&
        <EdgeDelete
          edge={props.edge}
          tenant={props.tenant}
          onDeleted={props.onDelete}
          onCancel={props.onCancel}
        />
      }
      {props.showDialog === DeleteDialogType.Node &&
        <NodeDelete
          node={props.node}
          tenant={props.tenant}
          onDeleted={props.onDelete}
          onCancel={props.onCancel}
          onCloseDialog={props.onCloseDialog}
        />
      }
      {props.showDialog === DeleteDialogType.App &&
        <AppDelete
          app={props.app}
          tenant={props.tenant}
          onDeleted={props.onDelete}
          onCancel={props.onCancel}
        />
      }
    </div>
  );
}