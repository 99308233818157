import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import EchoPane from "components/EchoPane";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EchoDialog from 'components/EchoDialog';
import TenantAPIUserDetails from './TenantAPIUserDetails';
import { ITenant } from 'interfaces/tenant';
import { UserRole } from 'enumerations/user-role';
import { IApiUser } from 'interfaces/api-user';
import { ApiUserRole } from 'enumerations/api-user-role';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ApiUsersModel } from 'models/api-user/api-users-model';
import { QueriesStatic } from 'graphql/queries-static';
import { ApiUserModel } from "models/api-user/api-user-model";
import { MutationsStatic } from "graphql/mutations-static";
import { DeleteApiUserModel } from "models/api-user/delete-api-user";

interface Props {
  tenant: ITenant;
  currentUserRole: UserRole | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantAPIUsers: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [apiUserList, setApiUserList] = useState<Array<IApiUser>>([]);
  const [selectedAPIUser, setSelectedAPIUser] = useState<IApiUser>();
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddAPIUserDialog, setShowAddAPIUserDialog] = useState(false);
  const [userRole, setUserRole] = useState<ApiUserRole>();
  const [userRoleHasValue, setUserRoleHasValue] = useState(false);
  const [description, setDescription] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getAPIUsers = async (tnt: ITenant) => {
    setIsRefreshing(true);
    const params = {
      tenant: tnt.name,
    }
    const p = await GraphQLHelper.execute<ApiUsersModel>(QueriesStatic.listApiUsers, params, ApiUsersModel);
    if (!p.error) {
      const apiUsers = p.result as ApiUsersModel;
      console.log(apiUsers);
      setApiUserList(apiUsers.apiUsers);
    }
    setIsRefreshing(false);
  }

  useEffect(() => {
    getAPIUsers(props.tenant);
  }, [props.tenant]);

  const onRefresh = () => {
    getAPIUsers(props.tenant);
  }

  const showDetails = (mt: IApiUser) => {
    setSelectedAPIUser(mt);
  }

  let nodeIndex = 1000;

  const apiUsers = Object.keys(ApiUserRole).map((t: string) => {
    // get users with that role
    const ur = apiUserList.filter(o => o.role === t).map((t2: IApiUser) => {
      nodeIndex += 1;
      return <TreeItem label={t2.username} nodeId={'' + nodeIndex} key={'' + nodeIndex} onClick={() => showDetails(t2)} />
    })
    nodeIndex += 1;
    return (
      <TreeItem label={t} nodeId={'' + nodeIndex} key={'' + nodeIndex}>
        {ur}
      </TreeItem>)
  });

  const addAPIUser = async () => {
    try {
      setIsLoading(true);
      setIsRefreshing(true);
      clearState(false);
      const params = {
        tenant: props.tenant.name,
        role: userRole,
        description: description
      }
      const p = await GraphQLHelper.execute<ApiUserModel>(MutationsStatic.createApiUser, params, ApiUserModel);
      if (!p.error) {
        const apiUser = p.result as ApiUserModel;
        console.log(apiUser);
        setSuccessMessage(`API User Added`);
      } else {
        setErrorMessage(p.errorMessage);
      }
      await getAPIUsers(props.tenant);
    } catch (err) {
      console.log(JSON.stringify(err));
      console.log(err);
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsRefreshing(false);
      setIsLoading(false);
    }
  }

  const clearState = (hideDialog: boolean) => {
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
    if (hideDialog) {
      setDescription('');
      setUserRoleHasValue(false);
    }
    setShowAddAPIUserDialog(!hideDialog);
  }

  const isUserValid = (): boolean => {
    return userRoleHasValue;
  }

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const handleRoleChange = (event: any) => {
    setUserRoleHasValue(false);
    if (event.target.value) {
      setUserRoleHasValue(true);
    }
    setUserRole(event.target.value as ApiUserRole);
  }

  const onDeleteAPIUser = async () => {
    try {
      if (!selectedAPIUser) {
        return;
      }
      setIsRefreshing(true);
      const params = {
        tenant: props.tenant.name,
        username: selectedAPIUser?.username
      }
      const p = await GraphQLHelper.execute<DeleteApiUserModel>(MutationsStatic.deleteApiUser, params, DeleteApiUserModel);
      if (!p.error) {
        const apiUser = p.result as ApiUserModel;
        console.log(apiUser);
        setSuccessMessage(`API User ${params.username} deleted`);
      } else {
        setErrorMessage(p.errorMessage);
      }
      await getAPIUsers(props.tenant);
    } catch (err) {
      console.log(JSON.stringify(err));
      console.log(err);
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsRefreshing(false);
      setSelectedAPIUser(undefined);
    }
  }

  const treeView =
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}>
      {apiUsers}
    </TreeView>

  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        onCancel={() => { clearState(true); }}
        onOk={() => { clearState(true); }}
        onSave={async () => { await addAPIUser(); }}
        isValid={isUserValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Add API User"
        contentText=""
        open={showAddAPIUserDialog}
        spinner={isLoading}
      >
        <TextField
          variant="outlined"
          autoFocus
          margin="dense"
          id="descriptionFunc"
          label="Description"
          fullWidth
          onChange={onDescriptionChange}
          disabled={isLoading}
        />
        <FormControl variant="filled">
          <InputLabel htmlFor="filled-age-native-simple">Role</InputLabel>
          <Select
            value={userRole}
            style={{ width: '250px' }}
            onChange={handleRoleChange}
            inputProps={{
              name: "tenant",
              id: "filled-age-native-simple"
            }}
            disabled={isLoading}
          >
            {Object.keys(ApiUserRole).map((t: string, index: number) =>
              <MenuItem key={index} value={t}>{t}</MenuItem>
            )}
          </Select>
        </FormControl>
      </EchoDialog>
      <EchoPane
        itemDetails={
          <TenantAPIUserDetails 
            tenant={props.tenant} 
            onDelete={() => onDeleteAPIUser()} 
            selectedAPIUser={selectedAPIUser} 
            currentUserRole={props.currentUserRole} 
            setSelectedAPIUser={(user: any) => setSelectedAPIUser(user)}
          />}
        menuList={treeView}
        onRefresh={() => onRefresh()}
        showBack={false}
        onBack={() => { }}
        showAdd={props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner}
        onAdd={() => setShowAddAPIUserDialog(true)}
        width={'400px'}
        isRefreshing={isRefreshing}
      />
    </div>
  );
};

export default TenantAPIUsers;
