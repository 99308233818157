import React, { useState, useEffect } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { Box } from '@mui/material';
import { ITenant } from "interfaces/tenant";
import { LogLevel, ValidateStatic } from "graphql/validate-static";
import { ValidateFunctionModel } from "models/validate-function-model";
import { GraphQLHelper } from "utilities/graphql-helper";
import { ValidationType } from "enumerations/validation-type";
import { IMessageType } from "interfaces/message-type";

interface Props {
  code: string,
  tenant: ITenant,
  functionName: string,
  validationType: ValidationType,
  receiveMessageType: IMessageType | undefined | null
}

const FunctionTester: React.FC<Props> = (props) => {
  const [messageArg, setMessageArg] = useState<string>('');
  const [executionOutput, setExecutionOutput] = useState<string>('');
  const [executionError, setExecutionError] = useState(false);
  const [executionStdout, setExecutionStdout] = useState('');
  const [executionLogs, setExecutionLogs] = useState('');
  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => { setMessageArg('') }, [props.functionName])

  const onTestFunction = async (cd: string) => {
    if (cd) {
      try {
        setIsExecuting(true);
        setExecutionError(true);
        setExecutionStdout('Executing...');
        setExecutionOutput('');
        setExecutionLogs('');

        if (props.validationType === ValidationType.Node){
        const query = ValidateStatic.validateNodeFunction(messageArg, props.code, LogLevel.DEBUG);
        const params = {
          name: props.functionName,
          tenant: props.tenant.name
        }
        const p = await GraphQLHelper.execute<ValidateFunctionModel>(query, params, ValidateFunctionModel);
        if (p.error) {
          console.log(p.errorMessage);
          setExecutionError(true);
          setExecutionOutput(p.errorMessage);
          setExecutionStdout('Failed...');
          setExecutionLogs('Failed...')
        } else {
          const result = p.result as ValidateFunctionModel;
          setExecutionStdout(JSON.stringify(result.stdout).replace(/(\\n)/g, "\n").replace(/"/g, ""));
          setExecutionOutput(JSON.stringify(JSON.parse(result.result), null, 2));
          setExecutionLogs(JSON.stringify(result.logs).replace(/(\\n)/g, "\n").replace(/"/g, ""));
        }
        } else if (props.validationType === ValidationType.Function){
          const query = ValidateStatic.validateFunction(messageArg, props.code, LogLevel.DEBUG);
          const params = {
          name: props.functionName,
          tenant: props.tenant.name
        }
        const p = await GraphQLHelper.execute<ValidateFunctionModel>(query, params, ValidateFunctionModel);
        if (p.error) {
          console.log(p.errorMessage);
          setExecutionError(true);
          setExecutionOutput(p.errorMessage);
          setExecutionStdout('Failed...');
          setExecutionLogs('Failed...')
        } else {
          const result = p.result as ValidateFunctionModel;
          setExecutionStdout(JSON.stringify(result.stdout).replace(/(\\n)/g, "\n").replace(/"/g, ""));
          setExecutionOutput(JSON.stringify(JSON.parse(result.result), null, 2));
          setExecutionLogs(JSON.stringify(result.logs).replace(/(\\n)/g, "\n").replace(/"/g, ""));
        }
        }
      } catch (err) {
        setExecutionError(true);
        setExecutionOutput(JSON.stringify(err));
        setExecutionLogs(JSON.stringify(err))
        console.log('Can\'t validate function', err);
        console.log(JSON.stringify(err));
      } finally {
        setIsExecuting(false);
      }
    } else {
      // TODO: show a dialog that the code or message type is not set
    }
  }

  const handleOnMessageChange = (event: any) => {
    setMessageArg(event.target.value);
  }

  return (
    <Box>
      <div style={{ marginTop: '5px' }}>
        <Button style={{ marginRight: '5px ' }} variant="outlined" onClick={() => setMessageArg(props.receiveMessageType ? props.receiveMessageType.sampleMessage : '')}>sample message</Button>
      </div>
      <TextField
        error={!messageArg || messageArg.length < 1}
        variant="outlined"
        required
        multiline
        autoFocus
        rows={7}
        value={messageArg}
        margin="dense"
        id="messsageArg"
        label="Message (input)"
        fullWidth
        onChange={handleOnMessageChange}
        disabled={isExecuting}
      />
      <TextField
        disabled={true}
        error={executionError}
        variant="outlined"
        multiline
        rows={5}
        value={executionOutput}
        margin="dense"
        id="messsageArg"
        label="Returns"
        fullWidth
      />
      <TextField
        disabled={true}
        variant="outlined"
        multiline
        rows={5}
        value={executionStdout}
        margin="dense"
        id="messsageArg"
        label="Stdout"
        fullWidth
      />
      <TextField
        disabled={true}
        variant="outlined"
        multiline
        rows={5}
        value={executionLogs}
        margin="dense"
        id="logs"
        label="Logs"
        fullWidth
      />
      <Button
        variant="contained"
        onClick={() => { onTestFunction(props.code); }}
        color="primary">
          Test { (isExecuting) && <CircularProgress size={24} style={{ color: '#F5F5F5', position: 'absolute', top: '50%', left: '50%', marginLeft: '-12px', marginTop: '-12px' }} />}
      </Button>
    </Box>
  );
};

export default FunctionTester;
