import { IGraphLayout } from "interfaces/graph-layout";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { GraphLayoutModel } from "./graph-layout-model";

export class GraphLayoutListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  graphs: Array<IGraphLayout> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.GetTenantUser && data.data.GetTenantUser) {
      const t = data.data.GetTenantUser.graphLayouts;
      if (t) {
        this.graphs = t.map((o: { layout: string; name: string; }) => GraphLayoutModel.create(o.layout, o.name));
      }
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenantUser, data.data.GetFunction.SaveGraphLayout');
    }
  }
}