import { ITenant } from 'interfaces/tenant';
import { QueriesStatic } from 'graphql/queries-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IDictionary } from 'interfaces/dictionary';
import { IMessageType } from 'interfaces/message-type';
import { MessageTypeListModel } from 'models/message-type/messagetype-list-model';

let instance: MessageTypeCache | null = null;

export class MessageTypeCache {
  private messageTypes: IDictionary<Array<IMessageType>> = {};

  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  public clearCache(tenant: ITenant) {
    this.messageTypes[tenant.name] = new Array<IMessageType>();
  }

  public async getMessageTypes(tenant: ITenant) : Promise<Array<IMessageType>> {
    if (!this.messageTypes[tenant.name] || this.messageTypes[tenant.name].length === 0) {
      let ret = [] as Array<IMessageType>;
      let lastKey = {};
      let iterations = 0;
      while(true && iterations < 10) {
        const params = {
          tenant: tenant.name,
          exclusiveStartKey: JSON.stringify(lastKey)
        }
        const p = await GraphQLHelper.execute<MessageTypeListModel>(QueriesStatic.listMessageTypes, params, MessageTypeListModel);
        if (p.error) {
          console.log(p.errorMessage);
          break;
        } else {
          const fns = p.result as MessageTypeListModel;
          ret.push(...fns.messageTypes);
          if (fns.lastEvaluatedKey) {
            // run the query again and aggergate result
            lastKey = JSON.parse(fns.lastEvaluatedKey);
          } else {
            break;
          }
        }
      }
      this.messageTypes[tenant.name] = new Array<IMessageType>();
      this.messageTypes[tenant.name].push(...ret);
    }

    return this.messageTypes[tenant.name]
  }
}
