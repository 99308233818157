import React, { useEffect, useState } from "react";
import DisplayField from "components/node/DisplayField";
import { Box, Button, Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { ManagedAppModel } from "models/managed/managed-app";
import { GraphQLHelper } from "utilities/graphql-helper";
import { QueriesStatic } from "graphql/queries-static";
import { ITenant } from "interfaces/tenant";
import { ConfigView } from "components/ConfigView";
import { ConfigurableEntity } from "enumerations/configurable-entity";
import { UserRole } from "enumerations/user-role";

interface Props {
  app: ManagedAppModel,
  tenant: ITenant,
  userRole: UserRole;
  onTableAccessChange: (tableAccess: boolean) => void,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  tableAccess: boolean,
  setTableAccess: React.Dispatch<React.SetStateAction<boolean>>,
  description: string,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  onDescriptionChange: (e: any) => void
}

const ManagedApp: React.FC<Props> = (props) => {
  //const config = JSON.parse(props.app.iso);
  // iso = base64 gzipped
  useEffect(() => {
    console.log(props.app);
    setAllowTableAccess(props.app.tableAccess);
  }, [props.app]);

  const [allowTableAccess, setAllowTableAccess] = useState(false);

  const downloadISO = async () => {
    // ..

    const params = {
      name: props.app.name,
      tenant: props.tenant.name,
    }
    const p = await GraphQLHelper.execute<ManagedAppModel>(QueriesStatic.getManagedApp, params, ManagedAppModel);
    if (!p.error) {
      const managedApp = p.result as ManagedAppModel;
      console.log(managedApp);
      const binary = atob(managedApp.iso);
      const array = new Uint8Array(binary.length);
      for (var i = 0; i < binary.length; i++) { array[i] = binary.charCodeAt(i) }
      //new Blob([array])

      const element = document.createElement("a");
      const file = new Blob([array], { type: 'application/zip' });
      element.href = URL.createObjectURL(file);
      element.download = "iso.zip";
      document.body.appendChild(element);
      element.click();
    }

    return;
  }

  const downloadUserdata = async () => {
    
    const params = {
      name: props.app.name,
      tenant: props.tenant.name
    }
    const p = await GraphQLHelper.execute<ManagedAppModel>(QueriesStatic.getManagedAppUserdata, params, ManagedAppModel);
    if(!p.error) {
      const managedApp = p.result as ManagedAppModel;
      console.log(managedApp);
      const userdata = managedApp.userdata;
      const element = document.createElement("a");
      const file = new Blob([userdata], {type: 'text'});
      element.href = URL.createObjectURL(file);
      element.download = "userdata";
      document.body.appendChild(element);
      element.click();
    }

    return;
  }

  return (
    <div>
      <TextField
        onChange={(e: any) => {
          props.onDescriptionChange(e);
        }}
        variant="outlined"
        value={props.description}
        margin="dense"
        id="description"
        label="Description"
        fullWidth
        InputProps={{
          readOnly: !props.isEditing,
        }}
      />
      <Button style={{ marginTop: '15px', marginLeft: '5px' }} variant='contained' color='primary' onClick={downloadISO}>Download ISO</Button>
      <Button style={{ marginTop: '15px', marginLeft: '5px' }} variant='contained' color='primary' onClick={downloadUserdata}>Download UserData</Button>
      <ConfigView 
        configurableEntity={ConfigurableEntity.ManagedApp} 
        entityKey={props.app.name} 
        tenant={props.tenant} 
        parentKey={''} 
        userRole={props.userRole}/>
      {/* <AWSCredentialsView tenant={props.tenant} entityKey={props.app.name} /> */}
      <FormControlLabel
        control={
          <Checkbox
            checked={allowTableAccess}
            onChange={(): void => { setAllowTableAccess(!allowTableAccess); props.onTableAccessChange(!allowTableAccess); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Allow Table Access"
      />

    </div>
  )
}

export default ManagedApp