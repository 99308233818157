import React, { useState, useEffect } from 'react';
import { TextField } from '@material-ui/core';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { IEchoEdge } from 'interfaces/echo-edge';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IEchoNode } from 'interfaces/echo-node';
import { BitmapRouterNodeModel } from 'models/node/bitmap-router-node';
import { IRouteTableEntry } from 'interfaces/route-table-entry';

interface Props {
  edge: IEchoEdge | undefined,
  tenant: ITenant,
  nodes: Array<IEchoNode> | undefined,
  onUpdate: () => void,
  onCancel: () => void
}

const RouteEditView: React.FC<Props> = (props) => {

  const [errorUpdating, setErrorUpdating] = useState('');
  const [infoUpdating, setInfoUpdating] = useState('');
  const [successUpdating, setSuccessUpdating] = useState('');
  const [route, setRoute] = useState('');
  const [isValidRoute, setIsValidRoute] = useState(false);
  const [bitmapRouterNode, setBitmapRouterNode] = useState<BitmapRouterNodeModel>(new BitmapRouterNodeModel());
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setRoute('0x');
    if (props.edge && props.nodes) {
      const source = props.edge.source;
      const target = props.edge.target;

      // find the router node
      const sourceNode = props.nodes.find(o => o.name === source) as BitmapRouterNodeModel;
      if (sourceNode) {
        setBitmapRouterNode(sourceNode);
        if (sourceNode.routeTable) {
          let rt = '';
          // find the existing route
          sourceNode.routeTable.forEach(o => {
            const anyRoute = o.toNodes.find(k => k === target);
            if (anyRoute) {
              const routeHex = o.routeNumber;
              rt = routeHex;
            }
          })
          if (rt.length > 0) {
            const routeString = rt;
            setRoute(routeString);
            if (routeString) {
              setIsValidRoute(routeString?.match(/0x[0-9a-fA-F]+(,0x[0-9a-fA-F]+)*$/) ? true : false);
            }
          }
        }
      }
    }
  }, [props.edge, props.nodes]);

  const clearState = () => {
    setErrorUpdating('');
    setInfoUpdating('');
    setSuccessUpdating('');
  };

  const onUpdateRoute = async () => {
    try{
    setIsLoading(true);
    if(isValidRoute){
      clearState();
    console.log('update route');
    const newRoutes = calculateRoutes(route);

    const params = {
      name: bitmapRouterNode.name,
      tenant: props.tenant.name,
    };
    
    const query = MutationsStatic.updateBitmapRouterNodeRouteTable(newRoutes);
    const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(query, params, BitmapRouterNodeModel);
    if (!p.error) {
      setSuccessUpdating(`Route table updated.`);
    } else {
      setErrorUpdating(p.errorMessage);
    }
    } else {
      setErrorUpdating("Input hexidecimal code. Ex: 0x01, 0xF0, 0xFFFF, 0xFAD1, etc.");
    }
  } catch (err) {
    console.log(err);
  } finally {
    setIsLoading(false);
  }
  };

  const calculateRoutes = (route: string) : Array<IRouteTableEntry> => {
    const newRoutes = Array<IRouteTableEntry>();
    if (!props.edge || !props.edge.target) {
      return newRoutes;
    }

    // ...
    const currentRoutes = bitmapRouterNode.routeTable;
    // ... we know the toNode from the edge ...
    const toNode = props.edge.target;

    // ... remove all instances of this node. if routes are empty (with no toNodes, remove them)
    currentRoutes.forEach(o => {
      const filtered = o.toNodes.filter(k => k !== toNode);
      if (filtered && filtered.length > 0) {
        const newrt = {
          routeNumber: o.routeNumber,
          toNodes: filtered
        } as IRouteTableEntry;
        newRoutes.push(newrt);
      }
    });

    if (route) {
      const routeList = route.split(',');
      // ... add this node back to all matching routes
      routeList.forEach(t => {
        if (t) {
          const foundRoute = newRoutes.find(o => o.routeNumber.toString() === t);
          if (foundRoute) {
            // make sure we don't add duplicates
            if(!foundRoute.toNodes.find(p => p === toNode)) {
              foundRoute.toNodes.push(toNode);
            }
          } else {
            // .. add it it didn't exist
            const newrt = {
              routeNumber: t,
              toNodes: [toNode]
            } as IRouteTableEntry;
            newRoutes.push(newrt);
          }  
        }
      });
    }
    return newRoutes;
  }

  const onRouteChange = (event: any) => {
    if (event.target.value === '') {
      setRoute('');
      calculateRoutes('');
    } else {
      setIsValidRoute(event.target.value.match(/0x[0-9a-fA-F]+(,0x[0-9a-fA-F]+)*$/));
      if (event.target.value) {
        setRoute(event.target.value);
      }
    }
  }

  return (
    <EchoDialog
      open={true}
      title={`Route from "${props.edge?.source}" to "${props.edge?.target}"`}
      errorMessage={errorUpdating}
      infoMessage={infoUpdating}
      successMessage={successUpdating}
      confirmDialog={true}
      onCancel={props.onCancel}
      onOk={props.onUpdate}
      onSave={() => onUpdateRoute()}
      contentText=""
      isValid={isValidRoute}
      spinner={isLoading}
    >        
      <TextField
        helperText="Input hexidecimal code. Ex: 0x01, 0xF0, 0xFFFF, 0xFAD1, etc."
        variant="outlined"
        required
        autoFocus
        margin="dense"
        id="route"
        value={route}
        fullWidth
        onChange={onRouteChange}
        error={!isValidRoute}
      />
    </EchoDialog>
  )
}

export default RouteEditView;