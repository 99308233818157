import React, { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import EchoDialog from 'components/EchoDialog';
import { IconButton, TextField } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { IEchoNode } from 'interfaces/echo-node';
import { EdgeModel } from 'models/edge/edge-model';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant,
  show: boolean,
  fromNode: IEchoNode,
  toNode: IEchoNode
}

const TenantAddEdgeDialog: React.FC<Props> = (props) => {
  const { fromNode, toNode } = props;
  const [edgeDescription, setEdgeDescription] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [maxReceiveCount, setMaxReceiveCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const addEdge = async () => {
    if (isEdgeValid()) {
      try {
        setIsLoading(true);
        clearState(false);
        const edgeToSave = {
          tenant: props.tenant.name,
          description: edgeDescription,
          source: fromNode?.name,
          target: toNode?.name,
          maxReceiveCount: maxReceiveCount
        }
        console.log(edgeToSave);
        const p = await GraphQLHelper.execute<EdgeModel>(MutationsStatic.createEdge, edgeToSave, EdgeModel);
        console.log(p);
        if (!p.error) {
          console.log('Created edge: ', p.result);
          setSuccessMessage(`Edge created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log('Can\'t create edge', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const clearState = (hideDialog: boolean) => {
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
  }

  const isEdgeValid = (): boolean => {
    if (fromNode && toNode && fromNode.name !== toNode.name) {
      return true;
    }
    return false;
  }

  const onDescriptionChange = (event: any) => {
    setEdgeDescription(event.target.value);
  }
  const onMaxCountChange = (event: any) => {
    const parsedValue = parseInt(event.target.value);
    setMaxReceiveCount(parsedValue);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/edges', '_blank');
  }

  return (
    <EchoDialog
      onCancel={props.onCancel}
      onOk={props.onAdd}
      onSave={async () => { await addEdge(); }}
      isValid={isEdgeValid()}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      successMessage={successMessage}
      title={<div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>Add Edge</div>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={() => { onShowHelp(); }}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
      </div>}
      contentText="An edge creates a FIFO queue between two nodes"
      open={true}
      spinner={isLoading}
    >
      <p style={{ color: '#AAA' }}>Create edge between</p>
      <p style={{ color: 'white', textIndent: '50px' }}>{`[${fromNode.name}] and [${toNode.name}]`}</p>
      <p style={{ color: '#AAA' }}>Using message type</p>
      <p style={{ color: 'white', textIndent: '50px' }}>{`[${fromNode.sendMessageType?.name}] to [${toNode.receiveMessageType?.name}]`}</p>
      <TextField
        variant="outlined"
        autoFocus
        margin="dense"
        id="edgeDescriptionId"
        label="Description"
        fullWidth
        onChange={onDescriptionChange}
        disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
      />
      <TextField 
        type="number"
        InputProps={{ inputProps: { min: 0 }}}
        variant="outlined"
        margin="dense"
        id="edgeMaxReceiveCount"
        label="Max Receive Count"
        fullWidth
        onChange={onMaxCountChange}
        disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
      />
    </EchoDialog>
  )
}

export default TenantAddEdgeDialog;