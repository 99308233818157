import React, { useState, useEffect, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';
import TenantListContext from 'contexts/TenantListContext';
import { ITenant } from 'interfaces/tenant';
import { SortTenants } from 'models/tenant/tenant-list-model';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';
import { DeleteTenantModel } from 'models/tenant/delete-tenant-model';

interface Props {
  show: boolean;
  closed: () => void;
  tenantToDelete?: ITenant;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantDeleteDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(props.show);
  const [tenantName, setTenantName] = useState('');
  const [tenantValid, setTenantValid] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState('');
  const [infoDeleting, setInfoDeleting] = useState('');
  const [successDeleting, setSuccessDeleting] = useState('');
  const {tenantList, setTenantList } = useContext(TenantListContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => setOpen(props.show), [props.show])
  useEffect(() => { setErrorDeleting(''); setInfoDeleting(''); setSuccessDeleting('') }, [props.show])

  const handleClose = () => {
    setOpen(false);
    props.closed();
    setTenantName('');
    setTenantValid(false);
  };

  const handleDeleteTenant = async () => {
    if (!tenantValid) {
      return;
    }
    try {
      setIsLoading(true);
      setErrorDeleting('');
      setInfoDeleting('');
      setSuccessDeleting('');
      console.log('Delete tentant');
      console.log(tenantName);
      setIsLoading(true);

      const p = await GraphQLHelper.execute<DeleteTenantModel>(MutationsStatic.deleteTenant, { tenant: tenantName }, DeleteTenantModel);
      setIsLoading(false);
      if (!p.error) {
        let tntList = tenantList.filter(o => o.name !== tenantName);
        tntList = tntList.sort(SortTenants);
        setTenantList(tntList);
        handleClose();
      } else {
        setErrorDeleting(JSON.stringify(p.errorMessage));
        console.log('Can\'t delete tenant', p.errorMessage);
      }
    } catch (err) {
      setErrorDeleting(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  const handleOnChange = (event: any) => {
    setTenantName(event.target.value);
    setTenantValid((props.tenantToDelete?.name === event.target.value) ? true : false);
  }

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">Delete Tenant: {props.tenantToDelete?.name}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Alert severity="warning">Deleting a tenant is permanent. All resources associated with the tenant will also be deleted.</Alert>
        </DialogContentText>
        <TextField
          error={!tenantValid}
          helperText="Re-enter the name of the tenant you wish to delete"
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="tentantName"
          label="Tenant name"
          fullWidth
          onChange={handleOnChange}
          disabled={((successDeleting && setSuccessDeleting.length > 0) ? true : false) || isLoading}
        />
        {(errorDeleting && errorDeleting.length > 0) &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorDeleting}
          </Alert>
        }
        {(infoDeleting && infoDeleting.length > 0) &&
          <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            {infoDeleting}
          </Alert>
        }
        {(successDeleting && setSuccessDeleting.length > 0) &&
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successDeleting}
          </Alert>
        }
      </DialogContent>
      {(!successDeleting || successDeleting.length === 0) &&
        <DialogActions>
          <Button disabled={!tenantValid || isLoading } variant="contained" onClick={handleDeleteTenant} color="primary">
            Delete { isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
        </Button>
          <Button disabled={isLoading} variant="contained" onClick={handleClose} color="primary">
            Cancel
        </Button>
        </DialogActions>
      }
      {(successDeleting && successDeleting.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default TenantDeleteDialog;