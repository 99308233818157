import { GraphItemType } from "components/graph/graph-interfaces";
import { IApp } from "interfaces/app";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";

export class CrossTenantSendingAppModel implements IApp, GraphQLData {
  
  tableAccess: boolean = false;
  hasTableAccess: boolean = false;
  graphItemType: GraphItemType = GraphItemType.app;
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  messageType: IMessageType = new MessageTypeModel();
  name: string = ''
  source: string = '';
  target: string = '';
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  queue: string = '';
  receivingApp: string = '';
  receivingTenant: string = '';

  deleted: boolean = false;
  lastEvaluatedKey: string = '';

  public static create(name: string, description: string, tenant: ITenant, typeName: string, receivingApp: string, receivingTenant: string): CrossTenantSendingAppModel {
    const t = new CrossTenantSendingAppModel();
    t.name = name;
    t.description = description;
    t.tenant = tenant;
    t.typeName = typeName;
    t.receivingApp = receivingApp;
    t.receivingTenant = receivingTenant;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}