import React, { useState, useEffect } from "react";
import { loadingService } from 'services/common';
import { FunctionCache } from 'cache/FunctionCache';
import { MessageTypeCache } from 'cache/MessageTypeCache';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import EchoPane from "components/EchoPane";
import TenantFunctionDetails from './TenantFunctionDetails';
import EchoDialog from 'components/EchoDialog';
import { UserRole } from 'enumerations/user-role';
import { IEchoFunction, EchoFunctionType } from 'interfaces/echo-function';
import { ITenant } from 'interfaces/tenant';
import { IMessageType } from 'interfaces/message-type';
import { FunctionModel } from 'models/function/function-model';
import { DeleteFunctionModel } from 'models/function/delete-function';
import { TreeItem, TreeView } from "@material-ui/lab";
import { grey } from "@material-ui/core/colors";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { FormControl, InputLabel, makeStyles, MenuItem, Select, TextField } from "@material-ui/core";
import { BitmapperFunctionModel } from "models/function/bitmapper-function-model";
import { ProcessorFunctionModel } from "models/function/processor-function-model";
import { ApiAuthenticatorFunctionModel } from "models/function/api-authenticator-function-model";

interface Props {
  tenant: ITenant,
  currentUserRole: UserRole | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantFunctions: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [functionsList, setFunctionsList] = useState<Array<IEchoFunction>>([]);
  const [selectedFunction, setSeletedFunction] = useState<IEchoFunction>();
  const [editSelectedFunction, setEditSelectedFunction] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [messageTypes, setMessageTypes] = useState<Array<IMessageType>>([]);
  const [functionName, setFunctionName] = useState('');
  const [validFunctionName, setValidFunctionName] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddFunctionDialog, setShowAddFunctionDialog] = useState(false);
  const [argumentMessageType, setArgumentMessageType] = useState<IMessageType>();
  const [resultMessageType, setResultMessageType] = useState<IMessageType>();
  const [functionType, setFunctionType] = useState<EchoFunctionType>(EchoFunctionType.processor);
  const [description, setDescription] = useState('');
  const [showDeleteFunctionDialog, setShowDeleteFunctionDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiAuthType, setApiAuthType] = useState("");

  const getFunctions = async (tnt: ITenant) => {
    try {
      setIsRefreshing(true);
      const funcs = await new FunctionCache().getFunctions(tnt);
      // if a function is selected refresh it...
      if (selectedFunction) {
        setSeletedFunction(funcs.find(o => o.name === selectedFunction.name));
      }
      setFunctionsList(funcs);
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  }

  useEffect(() => {
    getMts(props.tenant);
    onRefresh();
  }, [props.tenant]);

  const getMts = async (tnt: ITenant) => {
    const mt = await new MessageTypeCache().getMessageTypes(tnt);
    setMessageTypes(mt ? mt : new Array<IMessageType>());
  };

  const onRefresh = async () => {
    setIsRefreshing(true);
    await getFunctions(props.tenant);
    setIsRefreshing(false);
  }

  const showDetails = (mt: IEchoFunction) => {
    setSeletedFunction(mt);
  }

  let nodeIndex = 1001;
  const systemFunctionGroups = Object.keys(EchoFunctionType).map((t: string) => {
    const ur = functionsList.filter(o => o.system && o.type === t).map((mt: IEchoFunction, index: number) => {
      nodeIndex += 1;
      return <TreeItem label={mt.name} nodeId={'' + nodeIndex} key={'' + nodeIndex} onClick={() => {showDetails(mt); setEditSelectedFunction(false); }} />
    })
    nodeIndex += 1;
    return (
      <TreeItem label={t} nodeId={'' + nodeIndex} key={'' + nodeIndex}>
        {ur}
      </TreeItem>)
  });

  const tenantFunctionGroups = Object.keys(EchoFunctionType).map((t: string) => {
    const ur = functionsList.filter(o => !o.system && o.type === t).map((mt: IEchoFunction, index: number) => {
      nodeIndex += 1;
      return <TreeItem label={mt.name} nodeId={'' + nodeIndex} key={'' + nodeIndex} onClick={() => {showDetails(mt); setEditSelectedFunction(false); }} />
    })
    nodeIndex += 1;
    return (
      <TreeItem label={t} nodeId={'' + nodeIndex} key={'' + nodeIndex}>
        {ur}
      </TreeItem>)
  });

  const treeView = 
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="5000" label="System">
        {systemFunctionGroups}
      </TreeItem>
      <TreeItem nodeId="10000" label="Tenant">
        {tenantFunctionGroups}
      </TreeItem>
    </TreeView>

  const clearState = (hideDialog: boolean) => {
    setFunctionName('');
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
    setShowAddFunctionDialog(!hideDialog);
    setFunctionType(EchoFunctionType.processor);
    setArgumentMessageType(undefined);
    setResultMessageType(undefined);
    loadingService.clearLoading();
  }

  const onFunctionNameChange = (event: any) => {
    setFunctionName(event.target.value);
    setValidFunctionName(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) && functionsList.filter(o => o.name === functionName).length === 0);
  }

  const onFunctionTypeChange = (event: any) => {
    setFunctionType(event.target.value as EchoFunctionType);
  }

  const onApiAuthTypeChange = (event: any) => {
    setApiAuthType(event.target.value);
  }

  const handleArgumentMessageTypeChange = (event: any) => {
    setArgumentMessageType(messageTypes.find(o => o.name === event.target.value) as IMessageType);
  }

  const handleResultMessageTypeChange = (event: any) => {
    setResultMessageType(messageTypes.find(o => o.name === event.target.value) as IMessageType);
  }

  const isFunctionValid = (): boolean => {
    return validFunctionName && functionType && description.length > 4;
  }

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const onSaveFunctionCallback = (func: IEchoFunction) => {
    updateFunction(func);
  }

  const onDeleteFunctionCallback = (func: IEchoFunction) => {
    clearState(true);
    setShowDeleteFunctionDialog(true);
  }

  const processorTemplate = 'def processor(*, context, message, source, **kwargs):\n\n    # TODO - Perform any transformations to the message here.\n    return message';
  const webhookauthenticatorTemplate = 'def authenticator(*, context, request, **kwargs):\n\tfrom typing import cast\n\n\tfrom fastapi import Request\n\tfrom starlette.authentication import AuthCredentials, UnauthenticatedUser\n\n\t# Optional: cast to a Request for type hinting\n\trequest = cast(Request, request)\n\n\t# Return None or a tuple as below. You may\n\t# return subclasses of AuthCredentials and BaseUser.\n\t#\n\t# Returning None is equivalent to the return below.\n\t#\n\t# If the BaseUser subclass returned returns True from\n\t# is_authenticated, then the webhook will be accepted.\n\treturn AuthCredentials(), UnauthenticatedUser()'
  const webSubHubAuthenticatorTemplate = "def authenticator(*, context, request, **kwargs):\n\timport re\n\tfrom typing import TYPE_CHECKING, cast\n\n\tfrom starlette.authentication import AuthCredentials, BaseUser\n\n\tif TYPE_CHECKING:\n\t\tfrom fastapi import Request\n\n\t\trequest = cast(Request, request)\n\telse:\n\t\tRequest = object\n\n\t# WebSubHubNodes will use the supplied user to determine both the\n\t# identity of the subscriber and whether the subscriber is\n\t# authenticated. The identity is used to specify the subscriber in \n\t# WebSubSubscriptionNodes. Note that the startlette.authentication.SimpleUser\n\t# does not implement the identity property, so you must create your own\n\t# class.\n\t#\n\t# This HostUser simply gets the IP address of the subscriber from the\n\t# request and uses that as the identity. This is a security risk and\n\t# you should replace this with a more specific identity and authentication.\n\tclass HostUser(BaseUser):\n\t\tdef __init__(self, request: Request) -> None:\n\t\t\tself.host = request.client.host\n\n\t\t@property\n\t\tdef display_name(self) -> str:\n\t\t\treturn self.host\n\n\t\t@property\n\t\tdef identity(self) -> str:\n\t\t\treturn self.host\n\n\t\t@property\n\t\tdef is_authenticated(self) -> bool:\n\t\t\treturn True\n\n\t# Creates an AuthCredentials that allows access to any topic URL.\n\t# WebSubHubNodes will use the supplied list of scopes as regular\n\t# expressions to match against the topic URL. Only topic URLs that\n\t# match one of the supplied regular expressions will be allowed.\n\t#\n\t# This is a security risk and you should replace this with a more\n\t# specific regular expression or list of regular expressions that\n\t# match your published topics.\n\tauth_credentials = AuthCredentials([r'http[s]?://.*'])\n\n\treturn auth_credentials, HostUser(request)"

  const deleteFunc = async (func: IEchoFunction) => {
    try {
      setSuccessMessage('');
      setErrorMessage('');
      setInfoMessage('');
      const params = {
        tenant: props.tenant.name,
        name: func.name,
      }
      console.log(params);
      loadingService.sendLoadingStatus(true);
      const p = await GraphQLHelper.execute<DeleteFunctionModel>(MutationsStatic.deleteFunction, params, DeleteFunctionModel);
      if (p.error) {
        setErrorMessage(p.errorMessage);
        console.log('Can\'t create function', p.errorMessage);
      } else {
        console.log('Deleted Function: ', func.name);
        setSuccessMessage(`Function ${func.name} deleted.`);
        // clear function cache
        new FunctionCache().clearCache(props.tenant);
        onRefresh();
      }
    } catch (err) {
      loadingService.clearLoading();
      setErrorMessage(JSON.stringify(err));
      console.log('Can\'t create function', JSON.stringify(err));
    } finally {
      loadingService.clearLoading();
    }
  }

  const updateFunction = async (func: IEchoFunction) => {
    try {
      setSuccessMessage('');
      setErrorMessage('');
      setInfoMessage('');
      const query = MutationsStatic.updateFunction(func.code, func.description, func.readme, func.requirements);
      const params = {
        name: func.name,
        tenant: props.tenant.name
      }
      console.log(query);
      loadingService.sendLoadingStatus(true);
      const p = await GraphQLHelper.execute<FunctionModel>(query, params, FunctionModel);
      if (p.error) {
        setErrorMessage(p.errorMessage);
        console.log('Can\'t update function', p.errorMessage);
      } else {
        console.log('Updated Function: ', func.name);
        setSuccessMessage(`Function ${func.name} updated.`);
        new FunctionCache().clearCache(props.tenant);
        onRefresh();
      }
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log('Can\'t create function', JSON.stringify(err));
    } finally {
      loadingService.clearLoading();
    }
  }

  const addFunction = async () => {
    if (isFunctionValid()) {
      try {
        setIsLoading(true);
        const params = {
          tenant: props.tenant.name,
          name: functionName,
          code: processorTemplate,
          description: description,
          argumentMessageType: argumentMessageType?.name,
          readme: '',
          requirements: []
        };

        const apiauthParams = {
          tenant: props.tenant.name,
          name: functionName,
          code: apiAuthType === "Webhook" ? webhookauthenticatorTemplate : webSubHubAuthenticatorTemplate,
          description: description,
          readme: '',
          requirements: []  
        }; 

        new FunctionCache().clearCache(props.tenant);

        if (functionType === EchoFunctionType.bitmapper) {
          const p = await GraphQLHelper.execute<BitmapperFunctionModel>(MutationsStatic.createBitmapperFunction, params, BitmapperFunctionModel);
          if (!p.error) {
            const msgTypeModel = p.result as BitmapperFunctionModel;
            console.log(msgTypeModel);
            setSuccessMessage(`Function ${functionName} created.`);
            onRefresh();
          } else {
            setErrorMessage(p.errorMessage);
          }
        } else if (functionType === EchoFunctionType.processor) {
          const pp = { ...params, returnMessageType: resultMessageType?.name }
          const p = await GraphQLHelper.execute<ProcessorFunctionModel>(MutationsStatic.createProcessorFunction, pp, ProcessorFunctionModel);
          if (!p.error) {
            const msgTypeModel = p.result as ProcessorFunctionModel;
            console.log(msgTypeModel);
            setSuccessMessage(`Function ${functionName} created.`);
            onRefresh();
          } else {
            setErrorMessage(p.errorMessage);
          }
        } else if (functionType === EchoFunctionType.apiauthenticator) {
          const p = await GraphQLHelper.execute<ApiAuthenticatorFunctionModel>(MutationsStatic.createApiauthenticatorFunction, apiauthParams, ApiAuthenticatorFunctionModel);
          if (!p.error) {
            const apifunc = p.result as ApiAuthenticatorFunctionModel;
            console.log("apiauth create", apifunc);
            setSuccessMessage(`Function ${functionName} created.`);
            onRefresh();
          } else {
            setErrorMessage(p.errorMessage);
          }
        }
        loadingService.sendLoadingStatus(true);
        loadingService.clearLoading();
      } catch (err) {
        loadingService.clearLoading();
        setErrorMessage(JSON.stringify(err));
        console.log('Can\'t create function', JSON.stringify(err));
      } finally {
        setIsLoading(false);
        setShowAddFunctionDialog(false);
      }
    }
  }

  const confirmDelete = async () => {
    if (selectedFunction) {
      await deleteFunc(selectedFunction);
      setSeletedFunction(undefined);
    }
  }

  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        onCancel={() => { setShowDeleteFunctionDialog(false); }}
        onOk={() => { setShowDeleteFunctionDialog(false); }}
        onSave={confirmDelete}
        isValid={true}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Function"
        confirmDialog
        contentText={`Delete Function '${selectedFunction?.name}'?`}
        open={showDeleteFunctionDialog}
        spinner={isLoading}
      />
      <EchoDialog
        onCancel={() => { clearState(true); }}
        onOk={() => { clearState(true); }}
        onSave={async () => { await addFunction() }}
        isValid={isFunctionValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Function"
        contentText="Add Function"
        open={showAddFunctionDialog}
        spinner={isLoading}
      >
        <TextField
          error={!validFunctionName}
          helperText="Name must be atleast 8 characters in length and may contain special characters (- _ . or :) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="functionName"
          label="Function Name"
          fullWidth
          onChange={onFunctionNameChange}
          disabled={isLoading}
        />
        <TextField
          error={description.length < 5}
          helperText={"Description must have a value of atleast 5 characters"}
          variant="outlined"
          required
          margin="dense"
          id="descriptionFunc"
          label="Description"
          fullWidth
          onChange={onDescriptionChange}
          disabled={isLoading}
        />
        <div style={{ marginTop: '5px' }}>
          <FormControl variant="filled">
            <InputLabel htmlFor="filled-argument-messageType">Function Type</InputLabel>
            <Select
              value={functionType}
              style={{ width: '250px' }}
              onChange={onFunctionTypeChange}
              inputProps={{
                name: "functionType",
                id: "filled-argument-messageType"
              }}
              disabled={isLoading}
            >
              {Object.keys(EchoFunctionType).map((t: string, index: number) =>
                <MenuItem key={index} value={t}>{t}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        {functionType === EchoFunctionType.apiauthenticator &&
          <div style={{ marginTop: '5px' }}>
          <FormControl variant="filled">
            <InputLabel htmlFor="filled-argument-messageType">API Authenticator Type</InputLabel>
            <Select
              value={apiAuthType}
              style={{ width: '250px' }}
              onChange={onApiAuthTypeChange}
              inputProps={{
                name: "functionType",
                id: "filled-argument-messageType"
              }}
              disabled={isLoading}
            >
              <MenuItem value={"Webhook"}>{"Webhook"}</MenuItem>
              <MenuItem value={"Websub"}>{"Websub"}</MenuItem>
            </Select>
          </FormControl>
        </div>
        }
        {functionType !== EchoFunctionType.apiauthenticator &&
          <div style={{ marginTop: '5px' }}>
          <FormControl variant="filled">
            <InputLabel htmlFor="filled-argument-messageType">Argument Message Type</InputLabel>
            <Select
              value={argumentMessageType}
              style={{ width: '250px' }}
              onChange={handleArgumentMessageTypeChange}
              inputProps={{
                name: "argumentMessageType",
                id: "filled-argument-messageType"
              }}
              disabled={isLoading}
            >
              {messageTypes.map((t: IMessageType, index: number) =>
                <MenuItem key={index} value={t.name}>{t.name}</MenuItem>
              )}
            </Select>
          </FormControl>
        </div>}
        {functionType === EchoFunctionType.processor &&
          <div style={{ marginTop: '5px' }}>
            <FormControl variant="filled">
              <InputLabel htmlFor="filled-result-messageType">Result Message Type</InputLabel>
              <Select
                value={resultMessageType}
                style={{ width: '250px', maxHeight: '200px' }}
                rowsMax={10}
                onChange={handleResultMessageTypeChange}
                inputProps={{
                  name: "resultMessageType",
                  id: "filled-result-messageType"
                }}
                disabled={isLoading}
              >
                {messageTypes.map((t: IMessageType, index: number) =>
                  <MenuItem key={index} value={t.name}>{t.name}</MenuItem>
                )}
              </Select>
            </FormControl>
          </div>
        }
      </EchoDialog>
      <EchoPane
        itemDetails={
          <TenantFunctionDetails
            onDelete={onDeleteFunctionCallback}
            onSave={onSaveFunctionCallback}
            tenant={props.tenant}
            selectedFunction={selectedFunction}
            currentUserRole={props.currentUserRole} 
            editSelectedFunction={editSelectedFunction}
            setEditSelectedFunction={setEditSelectedFunction}
          />
        }
        menuList={treeView}
        onRefresh={() => onRefresh()}
        showBack={false}
        onBack={() => { }}
        showAdd={true}
        onAdd={() => { clearState(true); setShowAddFunctionDialog(true); }}
        isRefreshing={isRefreshing}
      />
    </div>
  );
};
export default TenantFunctions;
