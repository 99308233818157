import React, { useEffect, useState } from "react";
import { ITenantUser } from "interfaces/tenant-user";
import { Card, CardActions, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Box } from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import EchoDialog from "components/EchoDialog";
import { ITenant } from "interfaces/tenant";
import AtByView from "components/AtBy";
import { Utilities } from "utilities/utilities";
import { GraphQLHelper } from "utilities/graphql-helper";
import { DeleteTenantUserModel } from "models/tenant-user/delete-tenant-user-model";
import { MutationsStatic } from "graphql/mutations-static";
import { UserStatus } from "enumerations/user-status";
import { UserRole } from "enumerations/user-role";
import { TenantUserModel } from "models/tenant-user/tenant-user-model";

interface Props {
  selectedUser: ITenantUser | undefined,
  currentUserRole: UserRole | undefined,
  onDelete: () => void,
  onRefresh: () => void,
  tenant: ITenant | undefined,
  users: Array<ITenantUser>
}

const TenantUserDetails: React.FC<Props> = (props) => {
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [userStatus, setUserStatus] = useState<UserStatus>(UserStatus.inactive);
  const [userRole, setUserRole] = useState<UserRole>(UserRole.read_only);
  const [userChanged, setUserChanged] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (props.selectedUser) {
      setUserRole(props.selectedUser.role);
      setUserStatus(props.selectedUser.status);
    }
  }, [props.selectedUser]);

  const clearState = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpen(!hideDialog);
  }

  const onDeleteUser = async () => {
    try {
      setIsLoading(true);
      const params = {
        tenant: props.tenant?.name,
        email: props.selectedUser?.email
      }
      const p = await GraphQLHelper.execute<DeleteTenantUserModel>(MutationsStatic.deleteTenantUser, params, DeleteTenantUserModel);
      if (!p.error) {
        setSuccessMessage('User Deleted');
        props.onDelete();
      } else {
        setErrorMessage(p.errorMessage);
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      console.log(err);
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  const onRoleChange = (event: any) => {
    const changedTo = event.target.value as UserRole;
    if (props.selectedUser) {
      // make sure there is atleast one owner user...
      if (props.selectedUser.role === UserRole.owner) {
        const anyOtherOwners = props.users.find(o => o.role === UserRole.owner && o.email !== props.selectedUser?.email);
        if (anyOtherOwners) {
          setUserRole(changedTo);
        }
      } else {
        setUserRole(changedTo);
      }      
      setUserChanged(changedTo !== props.selectedUser.role);
    }
  }

  const onStatusChange = (event: any) => {
    const changedTo = event.target.value as UserStatus;

    // cannot inactive owners
    if (props.selectedUser && props.selectedUser.role !== UserRole.owner) {
      // make sure there is atleast one active user...
      const anyActive = props.users.find(o => o.status === UserStatus.active && o.email !== props.selectedUser?.email);
      if (anyActive) {
        if (props.selectedUser.status === UserStatus.active || props.selectedUser.status === UserStatus.inactive) {
          setUserStatus(changedTo);
          setUserChanged(changedTo !== props.selectedUser.status);
        }
      }
    }
  }

  const updateUser = async () => {
    try {
      setIsLoading(true);
      const params = {
        tenant: props.tenant?.name,
        email: props.selectedUser?.email
      }
      const query = MutationsStatic.updateTenantUser(userRole, userStatus);
      console.log(query);
      const p = await GraphQLHelper.execute<TenantUserModel>(MutationsStatic.updateTenantUser(userRole, userStatus), params, TenantUserModel);
      setUserChanged(false);
      if (!p.error) {
        setSuccessMessage('User Updated');
        props.onRefresh();
      } else {
        setErrorMessage(p.errorMessage);
        if (props.selectedUser) {
          setUserRole(props.selectedUser.role);
          setUserStatus(props.selectedUser.status);
        }
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      console.log(err);
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }

  }


  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={open}
        title={successMessage ? 'User deleted' : `Delete user: ${props.selectedUser?.email}?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearState(true)}
        onOk={() => clearState(true)}
        onSave={() => onDeleteUser()}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      {props.selectedUser &&
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '30px', marginTop: '30px' }}
            title={props.selectedUser?.email}
          />
          <CardContent style={{ height: 'auto', flex: 'auto' }}>
            <Box>
              <TextField
                variant="outlined"
                value={props.selectedUser?.firstName ? props.selectedUser?.firstName : ''}
                margin="dense"
                id="firstName"
                label="First name"
                fullWidth
                autoComplete='new-password'
              />
              <TextField
                variant="outlined"
                value={props.selectedUser?.lastName ? props.selectedUser?.lastName : ''}
                margin="dense"
                id="lastName"
                label="Last name"
                fullWidth
                autoComplete='new-password'
              />
              <FormControl variant="filled">
                <InputLabel htmlFor="filled-age-native-simple">Status</InputLabel>
                <Select
                  value={userStatus}
                  style={{ width: '150px' }}
                  onChange={onStatusChange}
                  disabled={(props.currentUserRole !== UserRole.admin && props.currentUserRole !== UserRole.owner) || props.selectedUser.role === UserRole.owner}
                  inputProps={{
                    name: "tenant",
                    id: "filled-age-native-simple"
                  }}
                >
                  {Object.keys(UserStatus).map((t: string, index: number) =>
                    <MenuItem key={index} value={t}>{t}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <br />
              <br />
              <FormControl variant="filled">
                <InputLabel htmlFor="filled-age-native-simple">Role</InputLabel>
                <Select
                  value={userRole}
                  style={{ width: '150px' }}
                  onChange={onRoleChange}
                  disabled={props.currentUserRole !== UserRole.admin && props.currentUserRole !== UserRole.owner}
                  inputProps={{
                    name: "tenant",
                    id: "filled-age-native-simple"
                  }}
                >
                  {Object.keys(UserRole).map((t: string, index: number) =>
                    <MenuItem key={index} value={t}>{t}</MenuItem>
                  )}
                </Select>
              </FormControl>
              <TextField
                variant="outlined"
                value={props.selectedUser?.invited.at || ''}
                margin="dense"
                id="invitationDate"
                label="Invitation Date"
                fullWidth
                autoComplete='new-password'
              />
              <AtByView label='Created' atby={props.selectedUser?.created} />
              <AtByView label='Last Modified' atby={props.selectedUser?.lastModified} />
              {(props.selectedUser?.status === 'invited') &&
                <TextField
                  variant="outlined"
                  value={props.selectedUser?.invited.at ? props.selectedUser?.invited.at : "Not invited yet"}
                  margin="dense"
                  id="invitationDate"
                  label="Invitation Date"
                  fullWidth
                  autoComplete='new-password'
                />
              }
              <TextField
                variant="outlined"
                value={Utilities.toLocaleString(props.selectedUser.activeAt)}
                margin="dense"
                id="activeDate"
                label="Active Date"
                fullWidth
                autoComplete='new-password'
              />
            </Box>
          </CardContent>
          {(props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) &&
            <CardActions disableSpacing style={{ flex: 'none', height: '50px' }}>
              <Grid container justifyContent="flex-end">
                <IconButton disabled={!userChanged} onClick={() => { updateUser(); }} aria-label="Save">
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={() => { setOpen(true); }} aria-label="share">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </CardActions>
          }
        </Card>
      }
    </div>
  );
};

export default TenantUserDetails;
