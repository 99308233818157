export enum ConfigurableEntity {
  CrossAccountApp = 'CrossAccountApp',
  CrossTenantSendingNode = 'CrossTenantSendingNode',
  ExternalApp = 'ExternalApp',
  ExternalNode = 'ExternalNode',
  ManagedApp = 'ManagedApp',
  ManagedNode = 'ManagedNode',
  BitmapRouterNode = 'BitmapRouterNode',
  Tenant = 'Tenant',
  ProcessorNode = 'ProcessorNode',
  WebhookNode = 'WebhookNode'
}