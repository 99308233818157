import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import { Port } from 'models/managed/port';
import ManagedNodePortForm from './ManagedNodePortForm'

interface Props {
    port: Port,
    updatePort: (oldPort: Port, newPort: Port) => void,
    cancelEdit: () => void,
    setEditedValue: React.Dispatch<React.SetStateAction<Port>>,
    setPortBeforeEdit: React.Dispatch<React.SetStateAction<Port>>,
    editedValue: Port
}

const ManagedNodePort: React.FC<Props> = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [port, setPort] = useState(new Port(props.port.containerPort, props.port.description, props.port.protocol, props.port.hostAddress, props.port.hostPort));

    const editPort = () => {
        props.setEditedValue(props.port);
        props.setPortBeforeEdit(props.port);
        setIsEditing(true);
    }

    return (
        <>
        {!isEditing && props.port ? 
        <>
            <TableCell style={{ width: '150px' }} >{props.port.containerPort}</TableCell>
            <TableCell style={{ width: '200px' }} >{props.port.protocol}</TableCell>
            <TableCell style={{ width: '200px' }} >{props.port.description}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editPort()}>{props.port.hostAddress}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editPort()}>{props.port.hostPort}</TableCell>
        </>
        :
            <ManagedNodePortForm 
                port={props.port}
                cancelEdit={props.cancelEdit}
                updatePort={props.updatePort}
                toggleEdit={setIsEditing}
                setEditedValue={props.setEditedValue}
                isEditing={isEditing}
                editedValue={props.editedValue}
            />
          
        }
    </>

    );
}
export default ManagedNodePort;