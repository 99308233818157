import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { Box } from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import EchoDialog from "components/EchoDialog";
import { UserRole } from "enumerations/user-role";
import { IApiUser } from "interfaces/api-user";
import AtByView from "components/AtBy";
import CredentialsView from "components/CredentialsView";
import { CredentialEntity } from "enumerations/credential-entity";
import { ITenant } from "interfaces/tenant";
import { MutationsStatic } from "graphql/mutations-static";
import { ApiUserModel } from "models/api-user/api-user-model";
import { ApiUserRole } from "enumerations/api-user-role";
import { GraphQLHelper } from "utilities/graphql-helper";

interface Props {
  selectedAPIUser: IApiUser | undefined,
  setSelectedAPIUser: (user: any) => void,
  currentUserRole: UserRole | undefined,
  tenant: ITenant,
  onDelete: () => void,
}

const TenantAPIUserDetails: React.FC<Props> = (props) => {

  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState('');
  const [apiRole, setApiRole] = useState<ApiUserRole>(ApiUserRole.read_only);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    if (props.selectedAPIUser){
      setDescription(props.selectedAPIUser.description);
      setApiRole(props.selectedAPIUser.role);
    }
    //set role
  }, [props.selectedAPIUser])

  let config = '';
  if (props.selectedAPIUser) {
  }

  const onRoleChange = (event: any) => {
    const newRole = event.target.value as ApiUserRole;
    setApiRole(newRole);
  }

  const updateAPIUser = async () => {
    console.log("updateAPIUser");
    try {
      setIsLoading(true);
      const params = {
        tenant: props.tenant.name,
        username: props.selectedAPIUser?.username
      }
      const query = MutationsStatic.updateAPIUser(description, apiRole);
      const p = await GraphQLHelper.execute<ApiUserModel>(query, params, ApiUserModel);
      if(!p.error) {
        const apiUser = p.result as ApiUserModel;
        console.log(apiUser);
        setSuccessMessage("Api User Updated");
      } else {
        setErrorMessage(p.errorMessage);
      }
    } catch (err) {
      console.log(JSON.stringify(err));
      setErrorMessage(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  const cancelEdit = () => {
    setDescription(props.selectedAPIUser?.description ? props.selectedAPIUser?.description : '');
    setIsEditing(false);
  }

  const clearState = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpen(!hideDialog);
    setOpenSaveDialog(false);
    setIsEditing(false);
  }

  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={open}
        title={successMessage ? 'API User deleted' : `Delete API user: ${props.selectedAPIUser?.username}?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearState(true)}
        onOk={() => clearState(true)}
        onSave={() => { props.onDelete(); clearState(true); }}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      <EchoDialog
        open={openSaveDialog}
        title={successMessage ? 'API User updated' : `Update API user: ${props.selectedAPIUser?.username}?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => setOpenSaveDialog(false)}
        onOk={() => clearState(true)}
        onSave={() => { updateAPIUser(); }}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      {props.selectedAPIUser &&
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '30px', marginTop: '30px' }}
            title={props.selectedAPIUser?.username}
          />
          <CardContent style={{ height: 'auto', flex: 'auto' }}>
            <Box>
              {!isEditing ?
                <>
                <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}>Role</legend>
                    {apiRole}
                </fieldset>
                <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                  <legend style={{ fontSize: '12px' }}>Description</legend>
                  {description}
                </fieldset>
                </>
              :
              <>
                <FormControl variant="filled">
                  <InputLabel htmlFor="filled-age-native-simple">Role</InputLabel>
                  <Select
                    value={apiRole}
                    style={{ width: '150px' }}
                    onChange={onRoleChange}
                    inputProps={{
                      name: "tenant",
                      id: "filled-age-native-simple"
                    }}
                  >
                    {Object.keys(ApiUserRole).map((t: string, index: number) =>
                      <MenuItem key={index} value={t}>{t}</MenuItem>
                    )}
                  </Select>
              </FormControl>
                <TextField
                  variant="outlined"
                  value={description}
                  margin="dense"
                  id="descriptionFunc"
                  label="Description"
                  fullWidth
                  autoComplete='new-password'
                  onChange={(event: any) => setDescription(event.target.value)}
                />
              </>
              }
              <AtByView label='Created' atby={props.selectedAPIUser?.created} />
              <AtByView label='Last Modified' atby={props.selectedAPIUser?.lastModified} />
            </Box>
            <CredentialsView tenant={props.tenant} credentialEntity={CredentialEntity.ApiUser} entityKey={props.selectedAPIUser.username} appsyncEndpoint={props.selectedAPIUser.appsyncEndpoint} />
          </CardContent>
          {(props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) &&
            <CardActions disableSpacing style={{ flex: 'none', height: '50px' }}>
              <Grid container justifyContent="flex-end">
                {!isEditing ? 
                  <IconButton onClick={() => { setIsEditing(true); }}>
                    <EditIcon />
                  </IconButton>
                :
                <>
                  <IconButton onClick={() => { cancelEdit(); }}>
                    <CancelIcon />
                  </IconButton>
                  <IconButton onClick={() => { setOpenSaveDialog(true); }}>
                    <SaveIcon />
                  </IconButton>
                </>
                }
                <IconButton onClick={() => { setOpen(true); }} aria-label="share">
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </CardActions>
          }
        </Card>
      }
    </div>
  );
};

export default TenantAPIUserDetails;
