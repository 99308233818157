import React, { useState, useEffect } from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import EchoPane from "components/EchoPane";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import MessageTypeDetails from "./MessageTypeDetails";
import EchoDialog from "components/EchoDialog";
import { loadingService } from 'services/common';
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import { MutationsStatic } from "graphql/mutations-static";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { GraphQLHelper } from "utilities/graphql-helper";
import { IMessageType } from "interfaces/message-type";
import { MessageTypeCache } from "cache/MessageTypeCache";
import { CodeType } from "interfaces/code-type";

interface Props {
  tenant: ITenant,
  currentUserRole: UserRole | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantMessageTypes: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [messageTypes, setMessageTypes] = useState<Array<IMessageType>>([]);
  const [selectedMessageType, setSelectedMessageType] = useState<IMessageType | undefined>();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddMessageTypeDialog, setShowAddMessageTypeDialog] = useState(false);
  const [messageTypeName, setMessageTypeName] = useState('');
  const [auditor, setAuditor] = useState('def auditor(*, message, **kwargs):\n    return {}');
  const [bitmapperTemplate, setBitmapperTemplate] = useState('def bitmapper(*, context, message, source, **kwargs):\n\n    bitmap = 0\n    # TODO - Perform conditional bitmapping here\n    return bitmap');
  const [processorTemplate, setProcessorTemplate] = useState('def processor(*, context, message, source, **kwargs):\n\n    # TODO - Perform any transformations to the message here.\n    return message');
  const [requirements, setRequirements] = useState<Array<string>>([]);
  const [selectedNodeId, setSelectedNodeId] = useState('');
  const [messageTypeChanged, setMessageTypeChanged] = useState<IMessageType | undefined>();
  const [navigateToMessageType, setNavigateToMessageType] = useState<IMessageType | undefined>();
  const [showAlertChanged, setShowAlertChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const deleteMessageType = (mt: string) => { };

  useEffect(() => {
    getMts(props.tenant);
  }, [props.tenant]);
  
  const getMts = async (tnt: ITenant) => {
    setIsRefreshing(true);
    const mt = await new MessageTypeCache().getMessageTypes(tnt);
    setMessageTypes(mt ? mt : new Array<IMessageType>());
    setIsRefreshing(false);
  };

  const onRefresh = () => {
    getMts(props.tenant);
  }

  const showDetails = (messageType: IMessageType) => {
    if (messageTypeChanged) {
      clearState(true);
      setShowAlertChanged(true);
      setNavigateToMessageType(messageType);
    } else {
      setSelectedMessageType(messageType);
    }
  }

  const clearState = (hideDialog: boolean) => {
    setShowAddMessageTypeDialog(!hideDialog);
    loadingService.clearLoading();
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
  }

  let nodeIndex = 5001;
  const systemMessageTypes = messageTypes &&
    messageTypes.filter(o => o.system).map((mt: IMessageType, index: number) => {
      return (<TreeItem label={mt.name} key={'' + index + nodeIndex} nodeId={'' + index + nodeIndex} onClick={() => showDetails(mt)} />)
    });

  nodeIndex = 10001
  const tenantMessageTypes = messageTypes &&
    messageTypes.filter(o => !o.system).map((mt: IMessageType, index: number) => {
      return (<TreeItem label={mt.name} key={'' + index + nodeIndex} nodeId={'' + (index + nodeIndex)} onClick={() => showDetails(mt)} />)
    });

  const treeView =
    <TreeView
      selected={selectedNodeId}
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="5000" label="System">
        {systemMessageTypes}
      </TreeItem>
      <TreeItem nodeId="10000" label="Tenant">
        {tenantMessageTypes}
      </TreeItem>
    </TreeView>

  const isMessageTypeValid = (): boolean => {
    return true;
  }

  const addMessageType = async (update: boolean) => {
    if (isMessageTypeValid() || update) {
      try {
        setIsLoading(true);
        if (!update) { 
          clearState(false);
        }
        loadingService.sendLoadingStatus(true);
        if (!update) {
          const messageTypeToSave = {
            auditor: auditor,
            bitmapperTemplate: bitmapperTemplate,
            name: messageTypeName,
            tenant: props.tenant.name,
            processorTemplate: processorTemplate,
            description: ' ',
            readme:'#Empty',
            requirements: requirements,
            sampleMessage: 'Update me'
          }
          console.log(messageTypeToSave);
          const p = await GraphQLHelper.execute<MessageTypeModel>(MutationsStatic.createMessageType, messageTypeToSave, MessageTypeModel);
          if (!p.error) {
            const msgTypeModel = p.result as MessageTypeModel;
            console.log(msgTypeModel);
            setSuccessMessage(`MessageType ${messageTypeName} created.`);
            new MessageTypeCache().clearCache(props.tenant);
          } else {
            setErrorMessage(p.errorMessage);
          }
        } else {
          if (selectedMessageType) {
            const params = {
              name: selectedMessageType.name,
              tenant: props.tenant.name
            }       
            const query = MutationsStatic.updateMessageType(
              selectedMessageType.auditor,
              selectedMessageType.bitmapperTemplate,
              selectedMessageType.description,
              selectedMessageType.readme,
              selectedMessageType.requirements,
              selectedMessageType.processorTemplate,
              selectedMessageType.sampleMessage
            );
            console.log(query);
            const p = await GraphQLHelper.execute<MessageTypeModel>(
              query,
              params,
              MessageTypeModel);
            if (!p.error) {
              const msgTypeModel = p.result as MessageTypeModel;
              console.log(msgTypeModel);
              setSuccessMessage(`MessageType ${messageTypeName} updated.`);
              new MessageTypeCache().clearCache(props.tenant);
            } else {
              setErrorMessage(p.errorMessage);
            }
          }
        }
      } catch (err) {
        console.log('Can\'t create message type', err);
        setErrorMessage(JSON.stringify(err));
      } finally {
        loadingService.clearLoading();
        setIsLoading(false);
      }
    }
  }

  const isMessageTypeNameValid = (): boolean => {
    return messageTypeName.match(/^[A-Za-z][A-Za-z0-9\-_\.]{3,24}$/) && messageTypes.filter(o => o.name === messageTypeName).length === 0 ? true : false;
  }

  const onMessageTypeNameChange = (event: any) => {
    setMessageTypeName(event.target.value);
  }

  const selectSavedMessageType = async () => {
    setShowAddMessageTypeDialog(false);
    await getMts(props.tenant);  
  }

  const onDeleteMessageType = () => {
    console.log('on delete mt');
    setSelectedMessageType(undefined);
    getMts(props.tenant);
  }

  const onMessageTypeChange = (code: string, codeType: CodeType, requirements: Array<string>) => {
    if (selectedMessageType) {
      if (codeType === CodeType.bitMapper) {
        selectedMessageType.bitmapperTemplate = code;
      } else if (codeType === CodeType.processor) {
        selectedMessageType.processorTemplate = code;
      } else if (codeType === CodeType.auditor) {
        selectedMessageType.auditor = code;
      } else if (codeType === CodeType.requirements) {
        selectedMessageType.requirements = requirements;
      } else if (codeType === CodeType.description) {
        selectedMessageType.description = code;
      } else if (codeType === CodeType.readme) {
        selectedMessageType.readme = code;
      } else if (codeType === CodeType.sampleMessage) {
        selectedMessageType.sampleMessage = code;
      }
    }
    setMessageTypeChanged(selectedMessageType);
  }

  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={showAlertChanged}
        title={`'${messageTypeChanged?.name}' has changed, save changes?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => { setShowAlertChanged(false); setMessageTypeChanged(undefined); setSelectedMessageType(navigateToMessageType); }}
        onOk={() => { setShowAlertChanged(false); setMessageTypeChanged(undefined); setSelectedMessageType(navigateToMessageType); }}
        onSave={async () => { await addMessageType(true); }}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      <EchoDialog
        onCancel={() => { setShowAddMessageTypeDialog(false); }}
        onOk={async () => { await selectSavedMessageType(); }}
        onSave={async () => { await addMessageType(false); }}
        isValid={isMessageTypeNameValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Add Message Type"
        contentText=""
        open={showAddMessageTypeDialog}
        spinner={isLoading}
      >
        {!successMessage && 
        <TextField
          error={!isMessageTypeNameValid()}
          helperText={"Name must be 3-24 characters in length and must be unique (no other message types can have this name). Can use special characters (- _ or .). "}
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="messageTypeName"
          label="Message Type name"
          fullWidth
          onChange={onMessageTypeNameChange}
          disabled={isLoading}
        />}
      </EchoDialog>
      <EchoPane
        itemDetails={<MessageTypeDetails onSave={() => { setShowAlertChanged(false); setMessageTypeChanged(undefined); addMessageType(true); }} onMessageTypeChange={onMessageTypeChange} messageType={selectedMessageType} tenant={props.tenant} onDelete={() => onDeleteMessageType()} currentUserRole={props.currentUserRole}/>}
        menuList={treeView}
        onRefresh={() => onRefresh()}
        showBack={false}
        onBack={() => { }}
        showAdd={true}
        onAdd={() => { clearState(false); setShowAddMessageTypeDialog(true); }}
        isRefreshing={isRefreshing}
      />
    </div>
  );
};
export default TenantMessageTypes;
