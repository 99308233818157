import { IAtBy } from "interfaces/at-by";
import { IEchoEdge } from "interfaces/echo-edge";
import { IEchoFunction } from "interfaces/echo-function";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { ApiAuthenticatorFunctionModel } from "models/function/api-authenticator-function-model";
import { LogLevel } from "enumerations/log-level";
import { SignatureAlgorithm } from "enumerations/signature-algorithm";
import { SubscriptionSecurity } from "enumerations/subscription-security";

export class WebSubHubNodeModel implements IEchoNode, GraphQLData {
  config: string = '';
  created: IAtBy = new AtByModel('', '');
  defaultLeaseSeconds: number = 0;
  deliveryRetries: number = 0;
  description: string = '';
  endpoint: string = '';
  inlineApiAuthenticator: string = '';
  lastModified: IAtBy = new AtByModel('', '');
//   loggingLevel: LogLevel = LogLevel.DEBUG;
  managedApiAuthenticator: IEchoFunction = new ApiAuthenticatorFunctionModel();
  maxLeaseSeconds: number = 0;
  name: string = '';
  receiveMessageType: IMessageType = new MessageTypeModel();
  requirements: Array<string> = new Array<string>();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendMessageType: IMessageType = new MessageTypeModel();
  signatureAlgorithm: SignatureAlgorithm = SignatureAlgorithm.sha1;
  stopped: boolean = false;
  subscriptionSecurity: SubscriptionSecurity = SubscriptionSecurity.none;
  tenant: ITenant = new TenantModel();
  deleted: boolean = false;
  typeName: string = '';
  lastEvaluatedKey: string = '';

  public static create(
    name: string,
    typeName: string,
    sendEdges: Array<IEchoEdge>,
    inlineApiAuthenticator: string,
    managedApiAuthenticator: IEchoFunction,
    requirements: Array<string>,
    description: string,
    sendMessageType: IMessageType,
    receiveMessageType: IMessageType,
    endpoint: string,
    stopped: boolean,
    defaultLeaseSeconds: number,
    deliveryRetries: number,
    maxLeaseSeconds: number,
    signatureAlgorithm: SignatureAlgorithm,
    subscriptionSecurity: SubscriptionSecurity) : WebSubHubNodeModel {
    const t = new WebSubHubNodeModel();
    t.name = name;
    t.sendEdges = sendEdges;
    t.typeName = typeName;
    t.inlineApiAuthenticator = inlineApiAuthenticator;
    t.managedApiAuthenticator = managedApiAuthenticator;
    t.requirements = requirements;
    t.description = description;
    t.sendMessageType = sendMessageType;
    t.receiveMessageType = receiveMessageType;
    t.endpoint = endpoint;
    t.stopped = stopped;
    t.defaultLeaseSeconds = defaultLeaseSeconds;
    t.deliveryRetries = deliveryRetries;
    t.maxLeaseSeconds = maxLeaseSeconds;
    t.signatureAlgorithm = signatureAlgorithm;
    t.subscriptionSecurity = subscriptionSecurity;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  }
}