import React, { useEffect, useState } from 'react';
import { DataGrid, GridRowsProp, GridColDef, GridToolbar, GridSelectionModel } from '@mui/x-data-grid';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { Button } from '@material-ui/core';
import ShieldIcon from '@mui/icons-material/Shield';
import { ITenant } from 'interfaces/tenant';
import { IEchoNode } from 'interfaces/echo-node';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { QueriesStatic } from 'graphql/queries-static';
import { WebSubSubscriptionNodeListModel } from 'models/node/websub-subscription-node-list';
import { WebSubSubscriptionNodeModel } from 'models/node/websub-subscription-node';
import { NodeTypeName } from 'enumerations/nodetype-name';
import { MutationsStatic } from 'graphql/mutations-static';
import { DeleteNodeModel } from 'models/node/delete-node-model';
import EchoDialog from 'components/EchoDialog';

interface SubHubProps {
    node: IEchoNode;
    tenant: ITenant;
    startNode: (name: string | undefined, typeName: string | undefined) => void;
    stopNode: (name: string | undefined, typeName: string | undefined) => void;
}

const SubHub: React.FC<SubHubProps> = (props) => {

    const [subs, setSubs] = useState<WebSubSubscriptionNodeModel[]>();
    const [rows, setRows] = useState<GridRowsProp>([]);
    const [subscriber, setSubscriber] = useState("");
    const [topic, setTopic] = useState("");
    const [callback, setCallback] = useState("");
    const [esk, setEsk] = useState("");
    const [page, setPage] = useState(0);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
    const [open, setOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (props.node && props.tenant) {
            getSubscriptions(esk);
        }
    }, [])

    
    //query to get list of subscriptions
    const getSubscriptions = async (startKey: string) => {
        try{
            const params = {
                name: props.node.name,
                tenant: props.tenant.name
            }
            const query = QueriesStatic.listSubscriptions(callback.trim(), subscriber.trim(), topic.trim(), startKey)
            const p = await GraphQLHelper.execute<WebSubSubscriptionNodeListModel>(query, params, WebSubSubscriptionNodeListModel);
            if (!p.error) {
                console.log("p", p);
                const subs = p.result as WebSubSubscriptionNodeListModel;
                console.log("subs", subs);
                fillRows(subs.subs)
                setEsk(subs.lastEvaluatedKey)
            } else {
                //build some sort of display to show on the table if there is an error retrieving the subscriptions
                console.log("ERROR", p.errorMessage);
            }
        } catch (err) {
            console.log(err)
        } 
    }

    useEffect(() => {
        if (esk !== null && esk !== ""){
            getSubscriptions(esk)
        }
    }, [esk])

    const fillRows = (data: any) => {
        let gridRows: any[] = [];
        data.forEach((item: any) => {
            gridRows.push({
                id: item.name,
                stopped: item.stopped,
                secured: item.secured,
                subscriber: item.subscriber,
                topic: item.topic,
                expiration: item.expiration.toString(),
                callback: item.callback
            })
        })
        if (gridRows) {
            console.log("gridrows", gridRows)
            setRows(rows.concat(gridRows))
        }
    }

    const onDeleteSubscription = async (name: string) => {
        try {
            setIsLoading(true);
            const params = {
            name: name,
            tenant: props.tenant.name,
            };
            const query = MutationsStatic.deleteWebSubNode();
            const p = await GraphQLHelper.execute<DeleteNodeModel>(
            query,
            params,
            DeleteNodeModel
            );
            if (!p.error) {
                setSuccessMessage("Successfully deleted nodes.")
            } else {
                console.log("Can't delete node", p.errorMessage);
                setErrorMessage(p.errorMessage);
            }
        } catch(err) {
            console.log(err);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteButton = () => {
        console.log("delete button clicked", selectionModel);
        const updatedRows: any = [];
        rows.forEach(row => {
            let likeObjectIndex = -1;
            selectionModel.forEach((name, index2) => {
                if (row.id === name) {
                    likeObjectIndex = index2;
                    onDeleteSubscription(row.id)
                }
            });
            if (likeObjectIndex === -1) {
                updatedRows.push(row);
            } else {
                selectionModel.splice(likeObjectIndex, 1);
            }
        });
        setRows(updatedRows);
        setSelectionModel(selectionModel);
    }

    const handleStartStopButton = () => {
        rows.forEach(row => {
            selectionModel.forEach(name => {
                if (row.id === name && row.stopped) {
                    startNode(row.id);
                } else if (row.id === name && !row.stopped) {
                    stopNode(row.id);
                }
            })
        })
    }

    const startNode = (name: string) => {
        props.startNode(name, NodeTypeName.WebSubSubscriptionNode);
        // update stopped at state level
        setRows(prevArray => {
            const updatedArray = [...prevArray];
            const objectIndex = updatedArray.findIndex(row => row.id === name);
            updatedArray[objectIndex].stopped = false;
            return updatedArray;
        });

    }

    const stopNode = (name: string) => {
        props.stopNode(name, NodeTypeName.WebSubSubscriptionNode);
        // update stopped at state level
        setRows(prevArray => {
            const updatedArray = [...prevArray];
            const objectIndex = updatedArray.findIndex(row => row.id === name);
            updatedArray[objectIndex].stopped = true;
            return updatedArray;
        });
    }

    const columns: GridColDef[] = [
        { field: 'stopped', headerName: '', width: 50, sortable: false, renderCell: ({ row }) => (
            !row.stopped
            ? <img height={24} width={24} src="Icon_ArrowLink.svg" alt="node-running" style={{padding: "5px", cursor: "pointer" }} onClick={() => {stopNode(row.id)}} />
            : <img height={24} width={24} src="Sign-Stop.svg" alt="node-stopped" style={{padding: "5px", cursor: "pointer" }} onClick={() => {startNode(row.id)}} />
        )},
        { field: 'secured', headerName: '', width: 50, sortable: false, renderCell: ({ row }) => (
            row.secured
            ? <ShieldIcon style={{ color: "#07bc0c"}}/>
            : <ShieldIcon style={{ color: "#e0e0e0"}}/>
        ) },
        { field: 'subscriber', headerName: 'Subscriber', width: 175 },
        { field: 'topic', headerName: 'Topic', width: 350, renderCell: ({ row }) => (
            <a href={row.topic} target='_blank' rel="noreferrer">{row.topic}</a>
        ) },
        { field: 'expiration', headerName: 'Expiration', width: 150, sortable: false },
        { field: 'callback', headerName: 'Callback', width: 350 },
    ];
   
  return (
    <>
    <div style={{ height: 650, width: '100%', display: "flex", flexDirection: "column",  }}>
        <div style={{ display: 'flex', gap: 5, marginBottom: 10, justifyContent: "flex-end" }}>
            <Button variant='contained' color='primary' onClick={() => {handleStartStopButton(); }}>Start/Stop</Button>
            <Button variant='contained' color='primary' onClick={() => {setOpen(true); }}>Delete</Button>
        </div>
      <DataGrid 
        sx={{ backgroundColor: '#fff', height: 600 }}
        rows={rows as GridRowsProp} 
        columns={columns}
        checkboxSelection 
        density='compact'
        autoPageSize
        disableSelectionOnClick
        disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        components={{ 
            Toolbar: GridToolbar,
         }}
        componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
              csvOptions: { disableToolbarButton: true },
              printOptions: { disableToolbarButton: true },
            },
        }}
        pagination
        onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          selectionModel={selectionModel}
        />
    </div>
    {open && (
        <EchoDialog
        open={true}
        title={`Delete selected nodes?`}
        errorMessage={errorMessage}
        infoMessage={""}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        onSave={() => handleDeleteButton()}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      )}
</>
  )
}

export default SubHub;