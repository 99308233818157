import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ManagedAppModel } from 'models/managed/managed-app';
import EchoDialog from './EchoDialog';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant
}

const ManagedAppAdd: React.FC<Props> = (props) => {

  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [appValid, setAppValid] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allowTableAccess, setAllowTableAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddApp = async () => {
    if (appName && appName.length >= 4) {
      try {
        setIsLoading(true);
        setInfoMessage('');
        setErrorMessage('');
        setSuccessMessage('');
        const params = {
          tenant: props.tenant.name,
          name: appName,
          description: appDescription,
          tableAccess: allowTableAccess
        }
        console.log(params);
        const p = await GraphQLHelper.execute<ManagedAppModel>(MutationsStatic.createManagedApp, params, ManagedAppModel);
        if (!p.error) {
          setSuccessMessage(`Managed app ${appName} created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create managed app', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleAppNameChange = (event: any) => {
    setAppName(event.target.value);
    setAppValid(event.target.value.match(/^[A-Za-z0-9_\-]{3,80}$/) ? true : false);
  }

  const handleDescriptionChange = (event: any) => {
    setAppDescription(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/managed-app', '_blank');
  }

  return (
    <EchoDialog
      onCancel={props.onCancel}
      onOk={props.onAdd}
      onSave={async () => { await handleAddApp(); }}
      isValid={appValid}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      successMessage={successMessage}
      title={<div style={{ display: 'flex', flexDirection: 'row' }}>
        <div>Add Managed Application</div>
        <div style={{ marginLeft: 'auto' }}>
          <IconButton onClick={() => { onShowHelp(); }}>
            <HelpOutlineIcon />
          </IconButton>
        </div>
      </div>}
      contentText="Managed Applications contain Managed Nodes. They exist to group those Nodes and to extend your EchoStream network into your or your customer's premise."
      open={true}
      spinner={isLoading}
    >
      <TextField
        error={!appValid}
        helperText="Name must be a minimum of 3 character and may include special characters (- or _)."
        variant="outlined"
        required
        autoFocus
        margin="dense"
        id="appName"
        label="Managed application name"
        fullWidth
        onChange={handleAppNameChange}
        disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
      />
      <TextField
        variant="outlined"
        margin="dense"
        id="routerDescriptionName"
        label="Description"
        fullWidth
        onChange={handleDescriptionChange}
        disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={allowTableAccess}
            onChange={(): void => { setAllowTableAccess(!allowTableAccess); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Allow Table Access"
        disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
      />
    </EchoDialog>
  );
}

export default ManagedAppAdd;