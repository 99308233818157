import React from 'react';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { TextField, IconButton } from '@material-ui/core';
import copy from 'clipboard-copy';
import { toast } from 'react-toastify';

interface Props {
  label: string,
  value: string,
}

const TextFieldCopy: React.FC<Props> = (props) => {
  return (
    <div style={{display: 'block'}}>
      <TextField
        variant="outlined"
        value={props.value}
        margin="dense"
        id="descriptionFunc"
        label={props.label}
        autoComplete='new-password'
        style={{width: 'calc(100% - 30px)'}}
      />
      <IconButton size="small" onClick={() => { copy(props.value); toast(`Value copied to clipboard.`, { autoClose: 2000 }) }} style={{position: 'relative', top: '15px', left: '-35px'}}>
        <FileCopyIcon />
      </IconButton>
    </div>
  );
}

export default TextFieldCopy;