import React, { useState } from 'react';
import { PortRequirement } from 'models/managed/port-requirement';
import { Button, FormControl, IconButton, InputLabel, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Protocol } from 'enumerations/protocol';
import { Port } from 'models/managed/port';
import PortReq from './PortReq';
import PortReqForm from './PortReqForm';

// NEED TO CHANGE NAMING OF PORTS TO PORT REQS

interface Props {
  portRequirements: Array<PortRequirement> | undefined,
  ports: Array<Port> | undefined,
  addPortRequirements: (portReqs: Array<PortRequirement>) => void,
  isAddingManagedNode?: boolean
}

const AddMNTPorts: React.FC<Props> = (props) => {

  const [portHasChanged, setPortHasChanged] = useState(false);
  const [addingPort, setAddingPort] = useState(false);
  const [addedPortValue, setAddedPortValue] = useState(new PortRequirement(0, '', Protocol.tcp));
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(new PortRequirement(0, '', Protocol.tcp));
  const [portsToAdd, setPortsToAdd] = useState<Array<PortRequirement>>(new Array<PortRequirement>());
  const [selectedPortReq, setSelectedPortReq] = useState(new PortRequirement(0, '', Protocol.tcp));

  const editPort = (portReq: PortRequirement) => {
    setIsEditing(true);
    setEditedValue({ ...portReq });
  }

  const cancelEdit = () => {
    setEditedValue(new PortRequirement(0, '', Protocol.tcp));
    setIsEditing(false);
  }

  const updatePortToAdd = (portReq: PortRequirement) => {
    // delete the edited port
    const ports = portsToAdd?.filter( o => o !== selectedPortReq);
    // add the new one
   const tt = ports.concat([portReq]);
    setPortsToAdd(tt);
    cancelAddPort();
    setIsEditing(false);
    props.addPortRequirements(tt);
  }

  const cancelAddPort = () => {
    setAddedPortValue(new PortRequirement(0, '', Protocol.tcp));
    setAddingPort(false);
  }

  const addPort = (newPortReq: PortRequirement) => {
    const newReq = portsToAdd.concat(newPortReq);
    setPortsToAdd(newReq);
    setAddingPort(false);
    props.addPortRequirements(newReq);
    cancelAddPort();
  }

  const deletePort = (portReq: PortRequirement) => {
    const prts = portsToAdd.filter(o => o !== portReq);
    // const prts = portsToAdd.filter(o => o !== selectedPortReq);
    setPortsToAdd(prts);
    props.addPortRequirements(prts);
  }

  return(
    <>
    <div style={{ overflow: 'auto', marginTop: '10px' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Port Requirements </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Container Port</TableCell>
                  <TableCell align="left">Protocol</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {addingPort &&
                    <PortReqForm
                      portReq={addedPortValue}
                      updatePortReq={updatePortToAdd}
                      cancelEdit={cancelEdit} 
                      toggleEdit={setIsEditing} 
                      setEdits={setEditedValue}
                      addPortReq={addPort}
                    />
                  }
                </TableRow>
                {portsToAdd && portsToAdd.map((o, index) => {
                  if (!isEditing) {
                    return(
                      <TableRow>
                        <PortReq 
                          key={index}
                          portReq={o}
                          updatePortReq={updatePortToAdd}
                          deletePortReq={deletePort}
                          addPortReq={addPort}
                          cancelEdit={cancelEdit}
                          setEdits={setEditedValue}
                          setSelectedPortReq={setSelectedPortReq}
                        />
                      </TableRow>)
                  }})}
                <div style={{ marginTop: '5px' }}>
                  <Button variant="contained" color="primary" onClick={() => { setAddingPort(true); }}>
                    Add Port Requirements
                  </Button>
                </div>
              </TableBody>
            </Table> 
          </TableContainer>
        </fieldset>
      </div>
    </>
  );
}

export default AddMNTPorts;