import React, { useState } from "react";
import { makeStyles, IconButton, Tooltip, CircularProgress } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';

interface Props {
  onRefresh: () => void,
  menuList: any,
  itemDetails: any,
  showBack: boolean,
  onBack: () => void,
  showAdd: boolean,
  onAdd: () => void,
  width?: string,
  isRefreshing: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5"
  },
}));

const EchoPane: React.FC<Props> = (props) => {

  const [collapsed, setCollapsed] = useState(false);
  const [menuWidth, setMenuWidth] = useState(props.width ? props.width : '300px');
  const [display, setDisplay] = useState(props.width ? props.width : '300px');

  const classes = useStyles();

  const onMenuCollapseClick = () => {
    setCollapsed(!collapsed);
    if (!collapsed) {
      setMenuWidth('50px');
      setDisplay('50px');
    } else {
      setMenuWidth(props.width ? props.width : '300px');
      setDisplay('');
    }
  }

  const onBack = () => {

  }

  return (
    <div style={{height: '96%'}}>
      <div style={{ float: 'left', width: '100%', height: '100%' }}>
        <div style={{ marginLeft: menuWidth, height: '100%' }}>
          <div style={{ marginLeft: '5px', marginRight: '5px', height: '100%', backgroundColor: '#111', border: '1px solid #555', borderRadius: '7px' }}>
            {props.itemDetails}
          </div>
        </div>
      </div>
      <div id="echo-pane-scroll" style={{ height: '100%', border: '1px solid #555', borderRadius: '7px', display: display, float: 'left', width: menuWidth, marginLeft: '-100%', backgroundColor: '#222' }}>
        <div>
          {props.showBack && 
            <Tooltip title="Go back" aria-label="go back">
              <IconButton onClick={() => onBack()}><ArrowBackIosIcon /></IconButton>
            </Tooltip>
          }
          <Tooltip title={!collapsed ? "Collapse menu" : "Expand menu"} aria-label="collapse menu">
            <IconButton onClick={() => onMenuCollapseClick()}>{!collapsed ? <FirstPageIcon /> : <LastPageIcon />}</IconButton>
          </Tooltip>
          {props.showAdd && 
            <Tooltip title="Add" aria-label="add">
              <IconButton onClick={() => props.onAdd()}><AddIcon /></IconButton>
            </Tooltip>
          }
          <Tooltip title="Refresh" aria-label="refresh">
            <span>
              <IconButton disabled={props.isRefreshing} onClick={() => { props.onRefresh(); }}>
                {!props.isRefreshing ? <RefreshIcon /> : <CircularProgress size={18} className={classes.buttonProgress} />}
              </IconButton>
            </span>
          </Tooltip>
        </div>
          {!collapsed && props.menuList ? props.menuList : null}
      </div>
    </div>
  );
};

export default EchoPane;
