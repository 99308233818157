import { Subject } from 'rxjs';

const subject = new Subject();
const isLoading = new Subject();


export const authService = {
  // @ts-ignore
  sendData: authData => subject.next(authData),
  // @ts-ignore
  clearData: () => subject.next(),
  getData: () => subject.asObservable(),
};

export const loadingService = {
  // @ts-ignore
  sendLoadingStatus: (loading:boolean) => isLoading.next(loading),
  clearLoading:() => isLoading.next(false),
  getLoading:()=> isLoading.asObservable()
}

export const tenantService = {
	regions: [
		'us_east_1',
		'us_east_2',
		'us_west_1',
		'us_west_2',
		'af_south_1',
		'ap_east_1',
		'ap_south_1',
		'ap_northeast_1',
		'ap_northeast_2',
		'ap_northeast_3',
		'ap_southeast_1',
		'ap_southeast_2',
		'ca_central_1',
		'eu_central_1',
		'eu_west_1',
		'eu_west_2',
		'eu_south_1',
		'eu_west_3',
		'eu_north_1',
		'me_south_1',
		'sa_east_1',
		'us_gov_east_1',
		'us_gov_west_1'
	]
}