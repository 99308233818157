import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { FunctionModel } from "models/function/function-model";
import { IEchoFunction } from "interfaces/echo-function";
import { IEchoEdge } from "interfaces/echo-edge";
import { IRouteTableEntry } from "interfaces/route-table-entry";

export class BitmapRouterNodeModel implements IEchoNode, GraphQLData {
  config: string = '';
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  inlineBitmapper: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  managedBitmapper: IEchoFunction = new FunctionModel();
  name: string = '';
  receiveEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  receiveMessageType: IMessageType = new MessageTypeModel();
  requirements: Array<string> = new Array<string>();
  routeTable: Array<IRouteTableEntry> = new Array<IRouteTableEntry>();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendMessageType: IMessageType = new MessageTypeModel();
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  deleted: boolean = false;
  stopped: boolean = false;

  public static create
  (name: string,
    typeName: string,
    receiveMessageType: IMessageType,
    sendMessageType: IMessageType,
    sendEdges: Array<IEchoEdge>,
    inlineBitmapper: string,
    managedBitmapper: IEchoFunction,
    routeTable: string,
    requirements: Array<string>,
    description: string,
    stopped: boolean
  ) : BitmapRouterNodeModel {
    const t = new BitmapRouterNodeModel();
    t.name = name;
    t.typeName = typeName;
    t.receiveMessageType = receiveMessageType;
    t.sendMessageType = sendMessageType;
    t.sendEdges = sendEdges;
    t.inlineBitmapper = inlineBitmapper;
    t.managedBitmapper = managedBitmapper;
    t.requirements = requirements;
    t.description = description;
    t.stopped = stopped;

    if (routeTable) {
      const rt = JSON.parse(routeTable);
      Object.keys(rt).forEach(o => {
        const routeNumber = o;
        let route = t.routeTable.find(k => k.routeNumber === routeNumber);
        if (!route) {
          route = {
            routeNumber: routeNumber,
            toNodes: []
          } as IRouteTableEntry
         }
        route.toNodes = rt[o];
        t.routeTable.push(route);
      })
    }
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}