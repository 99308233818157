import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { MountRequirement } from 'models/managed/mount-requirement';
import MountReqForm from './MountReqForm';

interface Props {
    mountReq: MountRequirement,
    updateMountReq: (newMountReq: MountRequirement) => void,
    deleteMountReq: (mountReq: MountRequirement) => void,
    cancelEdit: () => void,
    setEdits: React.Dispatch<React.SetStateAction<MountRequirement>>,
    addMountReq: (newMountReq: MountRequirement) => void,
    setSelectedMountReq: React.Dispatch<React.SetStateAction<MountRequirement>>

}

const MountReq: React.FC<Props> = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [mountRequirement, setMountRequirement] = useState(new MountRequirement(props.mountReq.description, props.mountReq.source, props.mountReq.target));

    const editMountReq = () => {
        props.setSelectedMountReq(props.mountReq)
        setIsEditing(true);
    }

    const handleDelete = () => {
        // props.setSelectedMountReq(props.mountReq);
        props.deleteMountReq(props.mountReq);
    }

    return (
        <>
        {!isEditing && props.mountReq ? 
        <>
            <TableCell style={{ width: '150px' }} onClick={() => editMountReq()}>{props.mountReq.target ? props.mountReq.target : "<change me>"}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editMountReq()}>{props.mountReq.description ? props.mountReq.description : "<change me>"}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editMountReq()}>{props.mountReq.source ? props.mountReq.source : ""}</TableCell>
            <TableCell style={{ width: '100px' }}>
            <IconButton size="small" onClick={handleDelete}>
                <DeleteIcon />
            </IconButton>
            </TableCell>
        </>
        :
            <MountReqForm 
                mountReq={mountRequirement}
                cancelEdit={props.cancelEdit}
                updateMountReq={props.updateMountReq}
                toggleEdit={setIsEditing}
                setEdits={props.setEdits}
                isEditing={isEditing}
                addMountReq={props.addMountReq}
            />
          
        }
    </>

    );
}
export default MountReq;