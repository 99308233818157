import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { IEchoEdge } from "interfaces/echo-edge";
import { IApp } from "interfaces/app";
import { CrossTenantSendingAppModel } from "models/app/cross-tenant-sending-app";
import { IEchoFunction } from "interfaces/echo-function";
import { FunctionModel } from "models/function/function-model";

/*
type ManagedNodeType @aws_cognito_user_pools {
  healthcheck: Healthcheck
  hostMountRequirements: [HostMountRequirement!]
  portMappingRequirements: [PortMappingRequirement!]
  readme: String
}*/
export class CrossTenantSendingNodeModel implements IEchoNode, GraphQLData {
  created: IAtBy = new AtByModel('', '');
  deleted: boolean = false;
  description: string = '';
  inlineProcessor: string = '';
  lastEvaluatedKey: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  managedProcessor: IEchoFunction = new FunctionModel();
  name: string = '';
  parent: IApp = new CrossTenantSendingAppModel();
  receiveEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  receiveMessageType: IMessageType = new MessageTypeModel();
  requirements: Array<string> = new Array<string>();
  scannedCount: number = 0;
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendMessageType: IMessageType = new MessageTypeModel();
  sequentialProcessing: boolean = false;
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  stopped: boolean = false;

  public static create(
    name: string,
    typeName: string,
    parent: IApp,
    receiveMessageType: IMessageType,
    receiveEdges: Array<IEchoEdge>,
    sendMessageType: IMessageType,
    sendEdges: Array<IEchoEdge>,
    inlineProcessor: string,
    sequentialProcessing: boolean,
    managedProcessor: IEchoFunction,
    requirements: Array<string> = new Array<string>(),
    stopped: boolean
    ): CrossTenantSendingNodeModel {
    const t = new CrossTenantSendingNodeModel();
    t.inlineProcessor = inlineProcessor;
    t.managedProcessor = managedProcessor;
    t.name = name;
    t.parent = parent;
    t.receiveEdges = receiveEdges;
    t.receiveMessageType = receiveMessageType;
    t.requirements = requirements;
    t.sendEdges = sendEdges;
    t.sendMessageType = sendMessageType;
    t.sequentialProcessing = sequentialProcessing;
    t.typeName = typeName;
    t.stopped = stopped;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}