import React, { useState, useContext, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import TenantContext from 'contexts/TenantContext';
import { TenantUserEmailContext, GetTenantUser } from 'contexts/TenantUserFunctions';
import TenantListContext from 'contexts/TenantListContext';
import { ManagedNodeTypeCache } from 'cache/ManagedNodeTypeCache';
import { FunctionCache } from 'cache/FunctionCache';
import { MessageTypeCache } from 'cache/MessageTypeCache';
import EchoGraph from 'components/graph/EchoGraph';
import ComponentInspector from 'components/ComponentInspector';
import ListChangesView from 'components/ListChanges';
import { MoveDialogs, MoveDialogType } from './MoveDialogs';
import { AddDialogs, AddDialogType } from './AddDialogs';
import { DeleteDialogs, DeleteDialogType } from './DeleteDialogs';
import ListLogEventsView from 'components/ListLogEvents';
import RouteEditView from './RouteEditView';
import { QueriesStatic } from 'graphql/queries-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphItemType, IGraphData, IGraphEdgeData, IGraphElement, IGraphNodeData } from 'components/graph/graph-interfaces';
import { ITenant } from 'interfaces/tenant';
import { NodeListModel } from 'models/node/node-list-model';
import { IEchoFunction } from 'interfaces/echo-function';
import { IMessageType } from 'interfaces/message-type';
import { BitmapRouterNodeModel } from 'models/node/bitmap-router-node';
import { ProcessorNodeModel } from 'models/node/processor-node';
import { NotImplementedNodeModel } from 'models/node/not-implemented-node';
import { NodeTypeName } from 'enumerations/nodetype-name';
import { IEchoEdge } from 'interfaces/echo-edge';
import { ITenantUser } from 'interfaces/tenant-user';
import { TenantUserModel } from 'models/tenant-user/tenant-user-model';
import { TimerNodeModel } from 'models/node/timer-node';
import { AppListModel } from 'models/app/app-list-model';
import { IApp } from 'interfaces/app';
import { IEchoNode } from 'interfaces/echo-node';
import { AppTypeName } from 'enumerations/apptype-name';
import { CrossTenantSendingNodeModel } from 'models/node/cross-tenant-sending-node';
import { CrossTenantReceivingNodeModel } from 'models/node/cross-tenant-receiving-node';
import { ExternalNodeModel } from 'models/node/external-node';
import { AppChangeReceiverNodeModel } from 'models/node/managed-app-change-receiver-node';
import { ManagedNodeTypeModel } from 'models/managed/managed-node-type';
import { ManagedNodeModel } from 'models/managed/managed-node';
import { MoveEdgeModel } from 'models/edge/move-edge-model';
import { Checkbox, CircularProgress, FormGroup, IconButton, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import SettingsIcon from '@material-ui/icons/Settings';
import AccessTime from '@material-ui/icons/AccessTime';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import RefreshIcon from '@material-ui/icons/Refresh';
import StorageIcon from '@material-ui/icons/Storage';
import PublicIcon from '@material-ui/icons/Public';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { FilesDotComWebhookNodeModel } from 'models/node/filesdotcom-webhook-node';
import { WebhookNodeModel } from 'models/node/webhook-node';
import { FormControlLabel } from '@mui/material';
import { LoadBalancerNodeModel } from 'models/node/load-balancer-node';
import PurgeEdge from 'components/PurgeEdge';
import { WebSubHubNodeModel } from 'models/node/websub-hub-node';
import { WebSubSubscriptionNodeModel } from 'models/node/websub-subscription-node';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex'
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: '5px',
    marginRight: '5px',
    textAlign: 'center',
    height: '90%',
    color: theme.palette.text.secondary,
  },
  paperGraph: {
    padding: theme.spacing(5),
    marginLeft: '5px',
    marginRight: '5px',
    textAlign: 'center',
    height: '90%',
    color: theme.palette.text.secondary,
  },
  buttonProgress: {
    color: '#F5F5F5',
  }
}));

const TenantDetails = (props: any) => {

  const { tenant, setTenant } = useContext(TenantContext);
  const { tenantList } = useContext(TenantListContext);
  const userEmail = useContext(TenantUserEmailContext);

  const [tenantUser, setTenantUser] = useState<ITenantUser>(new TenantUserModel());
  const [nodes, setNodes] = useState<Array<IEchoNode>>();
  const [edges, setEdges] = useState<Array<IEchoEdge>>();
  const [apps, setApps] = useState<Array<IApp>>([]);
  const [messageTypes, setMessageTypes] = useState<Array<IMessageType>>([]);
  const [functionList, setFunctionList] = useState<Array<IEchoFunction>>([]);
  const [managedNodeTypes, setManagedNodeTypes] = useState<Array<ManagedNodeTypeModel>>([]);
  const [selectedNode, setSelectedNode] = useState<IEchoNode | undefined>(undefined);
  const [selectedFromNode, setSelectedFromNode] = useState<IEchoNode | undefined>();
  const [selectedToNode, setSelectedToNode] = useState<IEchoNode | undefined>();
  const [selectedEdge, setSelectedEdge] = useState<IEchoEdge | undefined>(undefined);
  const [refreshGraph, setRefreshGraph] = useState(false);
  const [showComponentInspector, setShowComponentInspector] = useState(false);
  const [selectedApp, setSelectedApp] = useState<IApp | undefined>(undefined);
  const [addNodeType, setAddNodeType] = useState<NodeTypeName>(NodeTypeName.Unknown);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [isAddingManagedNode, setIsAddingManagedNode] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const [showRouteEdit, setShowRouteEdit] = useState(false);
  const [menuWidth, setMenuWidth] = useState('200px');
  const [display, setDisplay] = useState('200px');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [graphData, setGraphData] = useState<IGraphData>();
  const [showDelete, setShowDelete] = useState(false);
  const [showMove, setShowMove] = useState(false);
  const [showAddDialogType, setShowAddDialogType] = useState<AddDialogType>(AddDialogType.None);
  const [showDeleteDialogType, setShowDeleteDialogType] = useState<DeleteDialogType>(DeleteDialogType.None);
  const [showMoveDialogType, setShowMoveDialogType] = useState<MoveDialogType>(MoveDialogType.None);
  const [showListChanges, setShowListChanges] = useState(false);
  const [showLogEvents, setShowLogEvents] = useState(false);
  const [listChangesName, setListChangesName] = useState('');
  const [listChangesTypeName, setListChangesTypeName] = useState('');
  const [listChangesItemType, setListChangesItemName] = useState<GraphItemType>(GraphItemType.none);
  const [newSource, setNewSource] = useState<IGraphNodeData>();
  const [newTarget, setNewTarget] = useState<IGraphNodeData>();
  const [showSystemNodesAndEdges, setShowSystemNodesAndEdges] = useState(false);
  const [purging, setPurging] = useState(false);
  const [saveStatus, setSaveStatus] = useState("");

  const classes = useStyles();
  const history = useHistory();
  let { search } = useLocation();

  useEffect(() => {
    onInitialLoad();
  }, [search, setTenant, tenantList])

  const onInitialLoad = async () => {
    const query = new URLSearchParams(search);
    const pew = query.get('t')?.replaceAll('\'', '');
    const foundTenant = tenantList.find(o => o.name === pew);
    
    if (foundTenant) {
      setTenant(foundTenant);

      // get the current user
      const user = await GetTenantUser(foundTenant, userEmail);
      setTenantUser(user);

      setGraphData({
        nodesSource: new Array<IGraphNodeData>(),
        edgesSource: new Array<IGraphEdgeData>(),
        apps: new Array<IApp>()
      } as IGraphData);

      await updateGraph(foundTenant);

      const mng = await new ManagedNodeTypeCache().getManagedNodeTypes(foundTenant);
      setManagedNodeTypes(mng);

    } else {
      console.log(`Couldn't find tenant: ${pew}`);
    }
  }

  const equals = (a: any, b: any): boolean => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a.prototype !== b.prototype) return false;
    let keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => equals(a[k], b[k]));
  }

  const updateGraph = async (tnt: ITenant) => {
    if (isRefreshing) {
      return;
    }

    setIsRefreshing(true);

    try {
      console.log('-> REFRESHING <-');
      await new Promise(resolve => setTimeout(resolve, 1000));

      const mt = await new MessageTypeCache().getMessageTypes(tnt);
      setMessageTypes(mt);

      const funcs = await new FunctionCache().getFunctions(tnt);
      setFunctionList(funcs);

      const nn = await getNodes(tnt);
      const aa = await getApps(tnt);
      setNodes(nn);
      setApps(aa);
      buildGraphData(nn, aa);
    } catch (error) {
      console.log('error refreshing out: ', error);
    } finally {
      setIsRefreshing(false);
    }
  }

  const getApps = async (tnt: ITenant) => {
    const params = {
      tenant: tnt.name,
    };
    const p = await GraphQLHelper.execute<AppListModel>(QueriesStatic.listApps, params, AppListModel);
    if (!p.error) {
      const apps = p.result as AppListModel;
      return apps.applications;
    }
    return new Array<IApp>();
  }

  const buildGraphData = (nodes: Array<IEchoNode>, apps: Array<IApp>) => {
    console.log("build graph data nodes", nodes);
    const edges = Array<IGraphEdgeData>();
    const dataEdges = Array<IEchoEdge>();
    const ntd = nodes.map((o, index) => {
      if (o.receiveEdges) {
        dataEdges.push(...o.receiveEdges);
      }

      if (o.sendEdges) {
        dataEdges.push(...o.sendEdges);
      }

      if (o.typeName === NodeTypeName.BitmapRouterNode) {
        const nt = o as BitmapRouterNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, routeTable: nt.routeTable, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.LoadBalancerNode) {
        const nt = o as LoadBalancerNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.ProcessorNode) {
        const nt = o as ProcessorNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.TimerNode) {
        const nt = o as TimerNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.FilesDotComWebhookNode) {
        const nt = o as FilesDotComWebhookNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.CrossTenantSendingNode) {
        const nt = o as CrossTenantSendingNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, parent: nt.parent, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.CrossTenantReceivingNode) {
        const nt = o as CrossTenantReceivingNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, sendMessageType: nt.sendMessageType.name, parent: nt.parent, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.ExternalNode) {
        const nt = o as ExternalNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, parent: nt.parent, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.AppChangeReceiverNode) {
        const nt = o as AppChangeReceiverNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, parent: nt.parent, system: true, graphItemType: GraphItemType.node } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.ManagedNode) {
        const nt = o as ManagedNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, parent: nt.parent, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.WebhookNode) {
        const nt = o as WebhookNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName,  sendMessageType: nt.sendMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData
        return nd;
      } else if (o.typeName === NodeTypeName.WebSubHubNode) {
        const nt = o as WebSubHubNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName,  receiveMessageType: nt.receiveMessageType.name, system: false, graphItemType: GraphItemType.node, stopped: nt.stopped } as IGraphNodeData;
        return nd;
      } else {
        const nt = o as NotImplementedNodeModel;
        const nd = { id: index, name: nt.name, typeName: nt.typeName, receiveMessageType: nt.receiveMessageType.name, sendMessageType: nt.sendMessageType.name, system: true, graphItemType: GraphItemType.node } as IGraphNodeData
        return nd;
      }
    });

    // after the node list have been built; find the edges and build a list of those
    ntd.forEach(o => {
      const fromId = o.id;
      const nd = nodes.find(t => t.name === o.name);
      if (nd && nd.sendEdges) {
        nd.sendEdges.forEach(k => {
          // find the TO node
          const toNode = ntd.find(q => q.name === k.target);
          const mrc = k.maxReceiveCount;
          if (toNode) {
            edges.push({ 
              fromNode: fromId, 
              toNode: toNode.id, 
              fromNodeName: { name: o.name }, 
              toNodeName: { name: toNode.name }, 
              edgeUniqueKey: k.edgeUniqueKey, 
              graphItemType: GraphItemType.edge, 
              maxReceiveCount: mrc,
              messageCounts: k.messageCounts
            } as IGraphEdgeData);
          }
        })
      }
    });

    const graphdt = {
      nodesSource: ntd,
      edgesSource: edges,
      apps: apps
    } as IGraphData;

    setGraphData(graphdt);
    setEdges(dataEdges);
  }

  const getNodes = async (tnt: ITenant) => {
    const params = {
      tenant: tnt.name,
      types: ["!WebSubSubscriptionNode"]
    };
    const p = await GraphQLHelper.execute<NodeListModel>(QueriesStatic.listNodes, params, NodeListModel, tnt);
    if (!p.error) {
      const nodes = p.result as NodeListModel;
      return nodes.nodes;
    }
    return Array<IEchoNode>();
  }

  const onBack = () => {
    history.push('/tenants');
  }

  const onMenuCollapseClick = () => {
    setCollapsed(!collapsed);
    if (!collapsed) {
      setMenuWidth('0px');
      setDisplay('none');
    } else {
      setMenuWidth('200px');
      setDisplay('');
    }
  }

  const onEdgeCreated = (edge: IGraphEdgeData) => {
    if (nodes) {
      const from = nodes?.filter(o => o.name === edge.fromNodeName.name);
      const to = nodes?.filter(o => o.name === edge.toNodeName.name);
      if (from && to) {
        setSelectedFromNode(from[0]);
        setSelectedToNode(to[0]);
      }
      setShowAddDialogType(AddDialogType.Edge);
    }
    console.log('on edge created in parent', edge);
  }

  const onEdgeDeleted = (edge: IGraphEdgeData) => {
    if (edge) {
      const edgeToDelete = edges?.find(o => o.edgeUniqueKey === edge.edgeUniqueKey);
      if (edgeToDelete) {
        setSelectedEdge(edgeToDelete);
        setShowDeleteDialogType(DeleteDialogType.Edge);
      }
    }
  }

  const onEdgeMoved = async (edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => {
    //old edge name and info
    if(edge && newSource && newTarget){
      const edgeToMove = edges?.find(o => o.edgeUniqueKey === edge.edgeUniqueKey);
      const oldSource = edgeToMove?.source;
      const oldTarget = edgeToMove?.target;
      const from = nodes?.filter(o => o.name === edge.fromNodeName.name);
      const to = nodes?.filter(o => o.name === edge.toNodeName.name);

      if (oldTarget && from && from[0].typeName === NodeTypeName.BitmapRouterNode) {
        const fromNode = from[0] as BitmapRouterNodeModel;
        const oldRT = fromNode.routeTable;
        //find old route
        const routeObj = oldRT.filter(rt => rt.toNodes.includes(oldTarget));
        const oldRouteNodes = routeObj[0].toNodes;
        // build new route object
        const newRouteNodes = oldRouteNodes.filter(r => r !== oldTarget).concat(newTarget.name);
        const newRoute = {
            routeNumber: routeObj[0].routeNumber,
            toNodes: newRouteNodes
        }
        //build new route table
        const newRT = oldRT.filter(rt => rt.routeNumber !== routeObj[0].routeNumber).concat(newRoute);
        
        const bmrParams = {
          name: oldSource,
          tenant: tenant.name
        }

        const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(MutationsStatic.updateBitmapRouterNodeRouteTable(newRT), bmrParams, BitmapRouterNodeModel);
        if (!p.error) {
          updateGraph(tenant);
        } else {
          console.log(p.errorMessage);
        }
      }

      const params = {
        source: oldSource,
        target: oldTarget,
        tenant: tenant.name
      };

      //@ts-ignore
      const query = MutationsStatic.moveEdge(newSource.name, newTarget.name);
      const p = await GraphQLHelper.execute<MoveEdgeModel>(query, params, MoveEdgeModel);
      if (!p.error) {
        const movedEdge = p.result as MoveEdgeModel;
        console.log("movedEdge", movedEdge);
        updateGraph(tenant);
      } 
    }
  }
  
  const onShowDialog = (showDelete: boolean, item: IGraphElement, showMove: boolean, newSource?: IGraphNodeData, newTarget?: IGraphNodeData) => {
    console.log("onShowDialog was called on", item)
    setSelectedEdge(undefined);
    setSelectedApp(undefined);
    setSelectedNode(undefined);
    const node = item as IGraphNodeData;
    const prevEdge = item as IGraphEdgeData;
    if (node && node.name && node.graphItemType === GraphItemType.node) {
      const foundNode = nodes?.find(o => o.name === node.name);
      if (foundNode) {
        setSelectedNode(foundNode);
        setShowComponentInspector(true);
        setShowDeleteDialogType(DeleteDialogType.None);
        if (showDelete) {
          setShowComponentInspector(false);
          setShowDeleteDialogType(DeleteDialogType.Node);
        }
      }
    } else if (node && node.name && node.graphItemType === GraphItemType.app) {
      const foundApp = apps?.find(o => o.name === node.name);
      if (foundApp) {
        setSelectedApp(foundApp);
        setShowComponentInspector(true);
        setShowDeleteDialogType(DeleteDialogType.None);
        setShowMoveDialogType(MoveDialogType.None);
        if (showDelete) {
          setShowComponentInspector(false);
          setShowDeleteDialogType(DeleteDialogType.App);
        } 
      }
    } else if (prevEdge.graphItemType === GraphItemType.edge && 
      newSource?.graphItemType === GraphItemType.node && 
      newTarget?.graphItemType === GraphItemType.node){
        const e = edges?.find(u => u.edgeUniqueKey === prevEdge.edgeUniqueKey);
        setSelectedEdge(e);
        if(showMove){
          setShowComponentInspector(false);
          setShowDeleteDialogType(DeleteDialogType.None);
          setShowMoveDialogType(MoveDialogType.Move);
        }
      } else {
      const edge = item as IGraphEdgeData;
      if (edge && edge.edgeUniqueKey) {
        const foundEdge = edges?.find(o => o.edgeUniqueKey === edge.edgeUniqueKey);
        if (foundEdge) {
          setSelectedEdge(foundEdge);
          setShowComponentInspector(true);
          setShowDeleteDialogType(DeleteDialogType.None);
          setShowMoveDialogType(MoveDialogType.None);
          if (showDelete) {
            setShowComponentInspector(false);
            setShowDeleteDialogType(DeleteDialogType.Edge);
            setShowMoveDialogType(MoveDialogType.None);
          }
        }
      }
    }
  }
  
  const onGraphInspection = (item: IGraphElement) => {
    console.log('inspect', item);
    onShowDialog(false, item, false);
  }

  const onGraphDeletion = (item: IGraphElement) => {
    console.log('deletion', item);
    onShowDialog(true, item, false);
  }

  const onGraphEdgeMove = (item: IGraphElement, newSource: IGraphNodeData, newTarget: IGraphNodeData) => {
    console.log("edge move");
    onShowDialog(false, item, true, newSource, newTarget);
  }

  const onGraphEdgePurge = (item: IGraphElement) => {
    console.log("purge edge", item);
    const edge = item as IGraphEdgeData;
    if (edge && edge.edgeUniqueKey) {
      const foundEdge = edges?.find(o => o.edgeUniqueKey === edge.edgeUniqueKey);
      if (foundEdge) {
        setSelectedEdge(foundEdge);
        setPurging(true);
      }
    }
  }

  const onRouteEdit = (item: IGraphElement) => {
    console.log("onRouteEdit", item);
    setSelectedEdge(undefined);
    setShowRouteEdit(false);
    const edge = item as IGraphEdgeData;
    if (edge && edge.edgeUniqueKey) {
      const foundEdge = edges?.find(o => o.edgeUniqueKey === edge.edgeUniqueKey);
      if (foundEdge) {
        setSelectedEdge(foundEdge);
        setShowRouteEdit(true);
      }
    }
  }

  const onGraphListChanges = (item: IGraphElement) => {
    console.log('list changes', item);
    const node = item as IGraphNodeData;
    if (node.graphItemType === GraphItemType.app || node.graphItemType === GraphItemType.node) {
      setListChangesName(node.name);
      setListChangesTypeName(node.typeName);
      setListChangesItemName(node.graphItemType);
      setShowListChanges(true);
    } else if (node.graphItemType === GraphItemType.edge) {
      const edge = item as IGraphEdgeData;
      setShowListChanges(true);
      setListChangesName(edge.edgeUniqueKey);
      setListChangesTypeName('edge');
      setListChangesItemName(edge.graphItemType);
    }
  }

  const onGraphListLogEvents = (item: IGraphElement) => {
    console.log('list log events', item);
    const node = item as IGraphNodeData;
    if (node.graphItemType === GraphItemType.node) {
      const foundNode = nodes?.find(o => o.name === node.name);
      if (foundNode) {
        setSelectedNode(foundNode);
        setShowLogEvents(true);
      }
    }
  }

  const onGraphSelection = (item: IGraphElement | null) => {
    setSelectedApp(undefined);
    setSelectedNode(undefined);
    setSelectedEdge(undefined);
    const app = item as IApp;
    if (app && app.name) {
      setSelectedApp(app);
    }
    if (!item) {
      return;
    }
  }

  const addDialogClosed = async () => {
    setShowAddDialogType(AddDialogType.None);
    await updateGraph(tenant);
  }

  const handleCheck = (event: any) => {
    setShowSystemNodesAndEdges(event.target.checked);
  }

  const startNode = async (name: string | undefined, typeName: string | undefined) => {
    const params = {
      tenant: tenant.name,
      name: name
    }
    const mutation = MutationsStatic.startNode;
    if (typeName === NodeTypeName.BitmapRouterNode) {
      const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(mutation, params, BitmapRouterNodeModel);
      if (!p.error) {
        const startedNode = p.result as BitmapRouterNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.CrossTenantSendingNode) {
      const p = await GraphQLHelper.execute<CrossTenantSendingNodeModel>(mutation, params, CrossTenantSendingNodeModel);
      if (!p.error) {
        const startedNode = p.result as CrossTenantSendingNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      } 
    } else if (typeName === NodeTypeName.CrossTenantReceivingNode) {
      const p = await GraphQLHelper.execute<CrossTenantReceivingNodeModel>(mutation, params, CrossTenantReceivingNodeModel);
      if (!p.error) {
        const startedNode = p.result as CrossTenantReceivingNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ExternalNode) {
      const p = await GraphQLHelper.execute<ExternalNodeModel>(mutation, params, ExternalNodeModel);
      if (!p.error) {
        const startedNode = p.result as ExternalNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.FilesDotComWebhookNode) {
      const p = await GraphQLHelper.execute<FilesDotComWebhookNodeModel>(mutation, params, FilesDotComWebhookNodeModel);
      if (!p.error) {
        const startedNode = p.result as FilesDotComWebhookNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.LoadBalancerNode) {
      const p = await GraphQLHelper.execute<LoadBalancerNodeModel>(mutation, params, LoadBalancerNodeModel);
      if (!p.error) {
        const startedNode = p.result as LoadBalancerNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ManagedNode) {
      const p = await GraphQLHelper.execute<ManagedNodeModel>(mutation, params, ManagedNodeModel);
      if (!p.error) {
        const startedNode = p.result as ManagedNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ProcessorNode) {
      const p = await GraphQLHelper.execute<ProcessorNodeModel>(mutation, params, ProcessorNodeModel);
      if (!p.error) {
        const startedNode = p.result as ProcessorNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.TimerNode) {
      const p = await GraphQLHelper.execute<TimerNodeModel>(mutation, params, TimerNodeModel);
      if (!p.error) {
        const startedNode = p.result as TimerNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebhookNode) {
      const p = await GraphQLHelper.execute<WebhookNodeModel>(mutation, params, WebhookNodeModel);
      if (!p.error) {
        const startedNode = p.result as WebhookNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebSubHubNode) {
      const p = await GraphQLHelper.execute<WebSubHubNodeModel>(mutation, params, WebSubHubNodeModel);
      if (!p.error) {
        const startedNode = p.result as WebSubHubNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebSubSubscriptionNode) {
      const p = await GraphQLHelper.execute<WebSubSubscriptionNodeModel>(mutation, params, WebSubSubscriptionNodeModel);
      if (!p.error) {
        const startedNode = p.result as WebSubSubscriptionNodeModel;
        console.log(startedNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    }
  }

  const stopNode = async (name: string | undefined, typeName: string | undefined) => {
    const params = {
      tenant: tenant.name,
      name: name
    }
    const mutation = MutationsStatic.stopNode;
    if (typeName === NodeTypeName.BitmapRouterNode) {
      const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(mutation, params, BitmapRouterNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as BitmapRouterNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.CrossTenantSendingNode) {
      const p = await GraphQLHelper.execute<CrossTenantSendingNodeModel>(mutation, params, CrossTenantSendingNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as CrossTenantSendingNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      } 
    } else if (typeName === NodeTypeName.CrossTenantReceivingNode) {
      const p = await GraphQLHelper.execute<CrossTenantReceivingNodeModel>(mutation, params, CrossTenantReceivingNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as CrossTenantReceivingNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ExternalNode) {
      const p = await GraphQLHelper.execute<ExternalNodeModel>(mutation, params, ExternalNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as ExternalNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.FilesDotComWebhookNode) {
      const p = await GraphQLHelper.execute<FilesDotComWebhookNodeModel>(mutation, params, FilesDotComWebhookNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as FilesDotComWebhookNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.LoadBalancerNode) {
      const p = await GraphQLHelper.execute<LoadBalancerNodeModel>(mutation, params, LoadBalancerNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as LoadBalancerNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ManagedNode) {
      const p = await GraphQLHelper.execute<ManagedNodeModel>(mutation, params, ManagedNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as ManagedNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.ProcessorNode) {
      const p = await GraphQLHelper.execute<ProcessorNodeModel>(mutation, params, ProcessorNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as ProcessorNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.TimerNode) {
      const p = await GraphQLHelper.execute<TimerNodeModel>(mutation, params, TimerNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as TimerNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebhookNode) {
      const p = await GraphQLHelper.execute<WebhookNodeModel>(mutation, params, WebhookNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as WebhookNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebSubHubNode) {
      const p = await GraphQLHelper.execute<WebSubHubNodeModel>(mutation, params, WebSubHubNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as WebSubHubNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    } else if (typeName === NodeTypeName.WebSubSubscriptionNode) {
      const p = await GraphQLHelper.execute<WebSubSubscriptionNodeModel>(mutation, params, WebSubSubscriptionNodeModel);
      if (!p.error) {
        const stoppeddNode = p.result as WebSubSubscriptionNodeModel;
        console.log(stoppeddNode);
        onInitialLoad();
      } else {
        console.log(JSON.stringify(p.errorMessage))
      }
    }
  }

  return (
    <>
      <AddDialogs
        onCancel={() => { setShowAddDialogType(AddDialogType.None); setRefreshCounter(refreshCounter + 1); }}
        onAdd={() => { addDialogClosed(); }}
        addNodeType={addNodeType}
        messageTypes={messageTypes}
        app={selectedApp}
        tenant={tenant}
        showDialog={showAddDialogType}
        fromNode={selectedFromNode}
        toNode={selectedToNode}
        functionList={functionList}
        managedNodeTypes={managedNodeTypes}
        isAddingManagedNode={isAddingManagedNode}
      />
      <DeleteDialogs
        onDelete={() => { setShowDeleteDialogType(DeleteDialogType.None); updateGraph(tenant); }}
        onCancel={() => { setShowDeleteDialogType(DeleteDialogType.None); setRefreshCounter(refreshCounter + 1); }}
        onCloseDialog={() => { setShowComponentInspector(false); }}
        tenant={tenant}
        showDialog={showDeleteDialogType}
        fromNode={selectedFromNode}
        toNode={selectedToNode}
        node={selectedNode}
        edge={selectedEdge}
        app={selectedApp}
      />
      <MoveDialogs
        onMoved={() => { updateGraph(tenant); }}
        onEdgeMoved={(edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => onEdgeMoved(edge, newSource, newTarget)} 
        onCancel={() => { setShowMoveDialogType(MoveDialogType.None); setRefreshCounter(refreshCounter + 1); }}
        tenant={tenant}
        showDialog={showMoveDialogType}
        newSource={newSource}
        newTarget={newTarget}
        edge={selectedEdge}
      />
      <PurgeEdge 
        edge={selectedEdge}
        tenant={tenant}
        onCancel={() => { setPurging(false); setRefreshCounter(refreshCounter + 1); }}
        onCloseDialog={() => { setPurging(false); }}
        isPurging={purging}
      />
      <div style={{ float: 'left', width: '100%', height: '100%' }}>
        <div style={{ marginLeft: menuWidth, height: '100%' }}>
          <div style={{ width: '100%', height: '100%', backgroundColor: '#999' }}>
            {tenantUser && tenantUser.email &&
              <EchoGraph
                graphData={graphData}
                onResetData={() => { }}
                onEdgeCreated={(edge: IGraphEdgeData) => { onEdgeCreated(edge); }}
                onEdgeDeleted={(edge: IGraphEdgeData) => { onEdgeDeleted(edge); }}
                onEdgeMoved={(edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => { onEdgeMoved(edge, newSource, newTarget); }}
                onMoved={(edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => { onGraphEdgeMove(edge, newSource, newTarget); }}
                onInspection={(item: IGraphElement) => { onGraphInspection(item); }}
                onListChanges={(item: IGraphElement) => { onGraphListChanges(item); }}
                onListLogEvents={(item: IGraphElement) => { onGraphListLogEvents(item); }}
                onDeletion={(item: IGraphElement) => { onGraphDeletion(item); }}
                onRoute={(item: IGraphElement) => { onRouteEdit(item); }}
                onSelection={(item: IGraphElement | null) => { onGraphSelection(item); }}
                onPurgeEdge={(item: IGraphElement) => { onGraphEdgePurge(item); }}
                menuWidth={menuWidth}
                tenant={tenant}
                tenantUser={tenantUser}
                refresh={refreshCounter}
                showSystemNodesAndEdges={showSystemNodesAndEdges}
                startNode={(name: string | undefined, typeName: string | undefined) => {startNode(name, typeName)}}
                stopNode={(name: string | undefined,  typeName: string | undefined) => {stopNode(name, typeName)}}
                isRefreshing={isRefreshing}
                setIsRefreshing={setIsRefreshing}
                saveStatus={saveStatus}
                setSaveStatus={setSaveStatus}
              />
            }
          </div>
        </div>
      </div>
      <div style={{ height: '100%', boxShadow: '5px 5px 5px #555', display: display, float: 'left', width: menuWidth, marginLeft: '-100%', backgroundColor: '#666' }}>
        <div style={{ marginTop: '45px', marginLeft: '5px' }}>
          <FormGroup>
            <FormControlLabel 
              label="Display System Nodes & Edges"
              sx={{ color: "#fff", marginLeft: '5px' }}
              control={
                <Checkbox
                  color="primary"
                  checked={showSystemNodesAndEdges}
                  onChange={handleCheck}
                />
              }
            />
          </FormGroup>
          <h5 style={{ color: '#FFF' }}>Internal</h5>
          <Tooltip title="Bitmap Router Node" aria-label="bitmap router node">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.BitmapRouterNode)}>
              <CallSplitIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Processor Node" aria-label="processor node">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setAddNodeType(NodeTypeName.ProcessorNode); setShowAddDialogType(AddDialogType.ProcessorNode) }}>
              <SettingsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Timer Node" aria-label="timer node">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setShowAddDialogType(AddDialogType.TimerNode) }}>
              <AccessTime />
            </IconButton>
          </Tooltip>
          <Tooltip title='FilesDotComWebhookNode' aria-label='filesdotcom webhook node'>
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setShowAddDialogType(AddDialogType.FilesDotComWebhookNode); }}>
              <img height='24' width='24' src='pinwheel-Files-dot-com.svg' alt='filesdotcom' />
            </IconButton>
          </Tooltip>
          <Tooltip title='WebhookNode' aria-label='webhook node'>
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setShowAddDialogType(AddDialogType.WebhookNode); }}>
              <img height='24' width='24' src='webhooks-logo-svg-vector.svg' alt='webhook' />
            </IconButton>
          </Tooltip>
          <Tooltip title='LoadBalancerNode' aria-label='loadbalancer node'>
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setShowAddDialogType(AddDialogType.LoadBalancerNode); }}>
              <img height='24' width='24' src='load-balancer-icon-1.svg' alt='loadbalancer' />
            </IconButton>
          </Tooltip>
          <Tooltip title='WebSubHubNode' aria-label='webSubHub node'>
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setShowAddDialogType(AddDialogType.WebSubHubNode); }}>
              <img height='24' width='24' src='websub.svg' alt='websub' />
            </IconButton>
          </Tooltip>
          <h5 style={{ color: '#FFF' }}>External</h5>
          <Tooltip title="External Application" aria-label="external application">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.ExteralApp)}>
              <StorageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Cross Account Application" aria-label="cross account application">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.CrossAccountApp)}>
              <ExitToAppIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={(!selectedApp || selectedApp?.typeName !== AppTypeName.ExternalApp) ? 'You must select an external application to create an external node for' : 'External Node'} aria-label="external node">
            <span>
              <IconButton disabled={(!selectedApp || (selectedApp?.typeName !== AppTypeName.ExternalApp && selectedApp?.typeName !== AppTypeName.CrossAccountApp))} style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setAddNodeType(NodeTypeName.ExternalNode); setShowAddDialogType(AddDialogType.ExternalNode); }}>
                <SettingsIcon />
              </IconButton>
            </span>
          </Tooltip>
          <h5 style={{ color: '#FFF' }}>Managed</h5>
          <Tooltip title="Managed Application" aria-label="managed application">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.ManagedApp)}>
              <StorageIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={(!selectedApp || selectedApp?.typeName !== AppTypeName.ManagedApp) ? 'You must select a managed application to create a managed node for' : 'Managed Node'} aria-label="managed node">
            <span>
              <IconButton disabled={(!selectedApp || selectedApp?.typeName !== AppTypeName.ManagedApp)} style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setAddNodeType(NodeTypeName.ManagedNode); setShowAddDialogType(AddDialogType.ManagedNode); setIsAddingManagedNode(true); }}>
                <SettingsIcon />
              </IconButton>
            </span>
          </Tooltip>
          <h5 style={{ color: '#FFF' }}>Cross-tenant</h5>
          <Tooltip title="Cross Tenant Sending Application" aria-label="cross tenant sending application">
            <span>
              <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.XTenantSendingApp)}>
                <StorageIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cross Tenant Sending Node" aria-label="cross tenant sending node">
            <span>
              <IconButton disabled={(!selectedApp || selectedApp?.typeName !== AppTypeName.CrossTenantSendingApp)} style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => { setAddNodeType(NodeTypeName.CrossTenantSendingNode); setShowAddDialogType(AddDialogType.XTenantNode); }}>
                <SettingsIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Cross Tenant Receiving Application" aria-label="cross tenant receiving application">
            <IconButton style={{ backgroundColor: '#555', margin: '5px' }} onClick={() => setShowAddDialogType(AddDialogType.XTenantReceivingApp)}>
              <PublicIcon />
            </IconButton>
          </Tooltip>
        </div>
      </div>
      <div style={{ position: 'absolute' }}>
        <Tooltip title="Go back" aria-label="go back">
          <IconButton onClick={() => onBack()}><ArrowBackIosIcon /></IconButton>
        </Tooltip>
        <Tooltip title={!collapsed ? "Collapse menu" : "Expand menu"} aria-label="collapse menu">
          <IconButton onClick={() => onMenuCollapseClick()}>
            {!collapsed ? <FirstPageIcon /> : <LastPageIcon />}
          </IconButton>
        </Tooltip>
        <Tooltip title="Refresh" aria-label="refresh">
          <span>
          <IconButton disabled={isRefreshing} onClick={() => { onInitialLoad(); setRefreshGraph(!refreshGraph); }}>
              {!isRefreshing ? <RefreshIcon /> : <CircularProgress size={18} className={classes.buttonProgress} />}
            </IconButton>
          </span>
        </Tooltip>
      </div>
      <ListChangesView 
        open={showListChanges} 
        onCloseDialog={() => { setShowListChanges(false); }} 
        name={listChangesName} 
        typeName={listChangesTypeName} 
        itemType={listChangesItemType} 
        tenant={tenant} 
      />
      { showLogEvents && selectedNode &&
        <ListLogEventsView 
          open={showLogEvents} 
          onCloseDialog={() => { setShowLogEvents(false); }} 
          name={selectedNode?.name} 
          tenant={tenant} 
        />
      }
      <ComponentInspector
        nodes={nodes}
        open={showComponentInspector}
        onCloseDialog={() => { setShowComponentInspector(false); updateGraph(tenant); setShowDelete(false); setShowMove(false); }}
        edge={selectedEdge}
        node={selectedNode}
        app={selectedApp}
        tenant={tenant}
        userRole={tenantUser.role}
        functionList={functionList}
        onUpdateGraph={ async () => { await updateGraph(tenant); setRefreshCounter(refreshCounter + 1);}}
        onDeleteClicked={(e) => setShowDeleteDialogType(e)}
        startNode={(name: string | undefined, typeName: string | undefined) => {startNode(name, typeName)}}
        stopNode={(name: string | undefined,  typeName: string | undefined) => {stopNode(name, typeName)}}
      />
      { showRouteEdit &&
        <RouteEditView 
          edge={selectedEdge}
          nodes={nodes}
          tenant={tenant}
          onCancel={() => { setShowRouteEdit(false); setRefreshCounter(refreshCounter + 1); }}
          onUpdate={async () => { setShowRouteEdit(false); await updateGraph(tenant); setRefreshCounter(refreshCounter + 1);}}
        />
      }
    </>
  )
}

export default TenantDetails;


