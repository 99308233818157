import { IApp } from "interfaces/app";
import { IMessageCounts } from "interfaces/message-counts";
import { IRouteTableEntry } from "interfaces/route-table-entry";

export enum GraphItemType {
  none = 'None',
  app = 'App',
  node = 'Node',
  edge = 'Edge'
}

export interface IGraphElement {
  graphItemType: GraphItemType
}

export interface IGraphNodeData extends IGraphElement {
  id: number
  name: string
  parent: IApp
  receiveMessageType: string
  sendMessageType: string
  system: boolean
  typeName: string
  routeTable: Array<IRouteTableEntry>
  stopped?: boolean
}

export interface IGraphEdgeData extends IGraphElement  {
  fromNode: number
  toNode: number
  fromNodeName: {
    name: string
    typeName: string
  }
  toNodeName:  {
    name: string,
    typeName: string
  }
  edgeUniqueKey: string
  maxReceiveCount: number
  messageCounts: IMessageCounts
}

export interface IGraphData {
  nodesSource: IGraphNodeData[]
  edgesSource: IGraphEdgeData[]
  apps: IApp[]
}

export interface IAppState {
  graphData: IGraphData
}