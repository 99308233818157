import React, { useEffect, useState } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Mount } from 'models/managed/mount';
import { MountRequirement } from 'models/managed/mount-requirement';
import ManagedNodeMount from './ManagedNodeMount';

interface Props {
  mounts: Array<Mount> | undefined,
  onMountsChanged: (mounts: Array<Mount>) => void,
  isAddingManagedNode?: boolean,
  mountRequirements: Array<MountRequirement> | undefined
}

const MountView: React.FC<Props> = (props) => {
  const [editedValue, setEditedValue] = useState(new Mount('', '', ''));
  const [isEditing, setIsEditing] = useState(false);
  const [mounts, setMounts] = useState<Array<Mount>>(new Array<Mount>());
  const [selectedMount, setSelectedMount] = useState(new Mount ('', '', ''))


  useEffect(() => {
    if (props.mountRequirements) {
      const mnts = props.mountRequirements.map(o => {
        return {
          description: o.description,
          source: o.source,
          target: o.target
        } as Mount
      });
      props.onMountsChanged(mnts);
      setMounts(mnts);
    } else if (props.mounts) {
      setMounts(props.mounts);
    }
  }, [props.mountRequirements, props.mounts]);

  const editMount = (mount: Mount) => {
    setIsEditing(true);
    //setEditedPort(port);
    setEditedValue({ ...mount })
  }

  const updateMount = (mount: Mount) => {
    // delete the edited port
    const mnts = mounts.filter(o => o !== selectedMount);
    // add the new one
    const tt = mnts.concat([mount]);
    setMounts(tt);
    props.onMountsChanged(tt);
    cancelEdit();
  }

  const handleValueChange = (event: any) => {
    //setEditedKey(event.target.value);
  }

  const handleContainerPortChange = (event: any) => {
    //setEditedValue(new Port(event.target.value, editedValue.description, editedValue.protocol, editedValue.hostAddress, editedValue.hostPort));
  }

  const cancelEdit = () => {
    setEditedValue(new Mount('', '', ''));
    setSelectedMount(new Mount('', '', ''));
    setIsEditing(false);
  }

  return (
    <>
      <div style={{ overflow: 'auto', marginTop: '10px' }}>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Mounts </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Target</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Source</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mounts && mounts.map((o, index) => {
                  if (!isEditing) {
                    return (
                      <TableRow>
                        <ManagedNodeMount 
                          key={index}
                          mount={o} 
                          updateMount={updateMount} 
                          cancelEdit={cancelEdit} 
                          setEdits={setEditedValue} 
                          setSelectedMount={setSelectedMount}                        
                        />
                      </TableRow>)
                  }
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </fieldset>
      </div>
    </>
  );
}

export default MountView;