import { GraphItemType } from "components/graph/graph-interfaces";
import { IApp } from "interfaces/app";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { TenantModel } from "models/tenant/tenant-model";

export class CrossTenantReceivingAppModel implements IApp, GraphQLData {
  
  tableAccess: boolean = false;
  hasTableAccess: boolean = false;
  graphItemType: GraphItemType = GraphItemType.app;
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = ''
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  sendingApp: string = '';
  sendingTenant: string = '';

  deleted: boolean = false;
  lastEvaluatedKey: string = '';

  public static create(name: string, tenant: ITenant, typeName: string, sendingApp: string, sendingTenant: string): CrossTenantReceivingAppModel {
    const t = new CrossTenantReceivingAppModel();
    t.name = name;
    t.tenant = tenant;
    t.typeName = typeName;
    t.sendingApp = sendingApp;
    t.sendingTenant = sendingTenant;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}