import {
  DefaultPortCandidate,
  FreeNodePortLocationModel,
  GraphObject,
  IEnumerable,
  IInputModeContext,
  IListEnumerable,
  INode,
  IPortCandidate,
  IPortCandidateProvider,
  List,
  PortCandidateProviderBase,
  PortCandidateValidity
} from 'yfiles'

/**
 * This port candidate provider only allows connections from green nodes.
 * To achieve this, this class returns different port candidates for source
 * and target ports.
 */
export default class MessageTypePortCandidateProvider extends PortCandidateProviderBase {
  private readonly node: INode

  /**
   * Creates a new instance of <code>GreenPortCandidateProvider</code>.
   * @param node The given node.
   */
  constructor(node: INode) {
    super()
    this.node = node
  }

  /**
   * Returns a central port candidate if the owner node of the source
   * candidate is green, and an empty list otherwise.
   * @param context The context for which the candidates should be provided
   * @param source The opposite port candidate
   * @see Overrides {@link PortCandidateProviderBase#getTargetPortCandidates}
   * @see Specified by {@link IPortCandidateProvider#getTargetPortCandidates}.
   */
  getTargetPortCandidates(
    context: IInputModeContext,
    source: IPortCandidate
  ): IEnumerable<IPortCandidate> {

    const graph = context.graph;
    const result = new List<IPortCandidate>();
    if (graph) {
      graph.nodes.forEach(node => {
        if (node !== source.owner && node.tag.receiveMessageType === source.owner.tag.sendMessageType) {
          // see if there is already an edge between these two ports...
          const found = graph.edges.find(o => o.targetNode?.tag === node.tag && o.sourceNode?.tag === source.owner.tag);
          if (!found) {
            const provider = node.lookup(IPortCandidateProvider.$class)
            // If available, use the candidates from the provider. Otherwise, add a default candidate.
            if (provider instanceof IPortCandidateProvider) {
              result.addRange(provider.getAllSourcePortCandidates(context))
            } else {
              result.add(new DefaultPortCandidate(node, FreeNodePortLocationModel.NODE_CENTER_ANCHORED))
            }  
          }
        }
      });
    }
    return result;
  }

  /**
   * Returns a list that contains a port candidate for each of the node's
   * ports. Each candidate has the same location as the port. If a port
   * already has a connected edge, its port candidate is marked as invalid.
   * Note that the variants of getPortCandidates for target ports are all
   * implemented by this class. Therefore, this method is only used for
   * source ports.
   * @param context The context for which the candidates should be provided
   * @see Overrides {@link PortCandidateProviderBase#getPortCandidates}
   */
  getPortCandidates(context: IInputModeContext): IEnumerable<IPortCandidate> {
    const candidates = new List<IPortCandidate>()
    let hasValid = false
    const graph = context.graph

    if (graph) {
      // Create a port candidate for each free port on the node
      this.node.ports.forEach(port => {
        const portCandidate = new DefaultPortCandidate(port)
        const valid = graph.degree(port) === 0
        hasValid = hasValid || valid
        // All ports are valid, they are controlled by loading of nodes and their message types
        portCandidate.validity = PortCandidateValidity.VALID;// valid ? PortCandidateValidity.VALID : PortCandidateValidity.INVALID
        candidates.add(portCandidate)
      })
    }

    // If no valid candidates have been created so far, use the ShapeGeometryPortCandidateProvider as fallback.
    // This provides a candidate in the middle of each of the four sides of the node.
    if (!hasValid) {
      // DO NOT ADD DEFAULT candidate PORTS
      //candidates.addRange(
      //  IPortCandidateProvider.fromShapeGeometry(this.node, 0.5).getAllSourcePortCandidates(context)
      //)
    }

    return candidates
  }
}