import { IGraphLayout } from "interfaces/graph-layout";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class GraphLayoutModel implements IGraphLayout, GraphQLData {
  layout: string = '';
  name: string = '';

  lastEvaluatedKey: string = '';

  public static create(layout: string, name: string) {
    const t = new GraphLayoutModel();
    t.layout = layout;
    t.name = name;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {

    if (data && data.data && data.data.GetTenantUser && data.data.GetTenantUser.SaveGraphLayout) {
      const t = data.data.GetTenantUser.SaveGraphLayout;
      this.layout = t.layout;
      this.name = t.name;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenantUser, data.data.GetFunction.SaveGraphLayout');
    }
  }
}