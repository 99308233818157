import React, { useEffect, useState } from 'react';
import { ITenant } from 'interfaces/tenant';
import { IconButton, Tab, Tabs, withStyles } from '@material-ui/core';
import { ConfigurableEntity } from 'enumerations/configurable-entity';
import { IKeyValue } from 'interfaces/key-value';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import VisabilityIcon from '@material-ui/icons/Visibility';
import { QueriesStatic } from 'graphql/queries-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ConfigModel } from 'models/config-model';
import { MutationsStatic } from 'graphql/mutations-static';
import { toast } from 'react-toastify';
import JSONEditor from './JSONEditor';
import { UserRole } from 'enumerations/user-role';

interface Props {
  tenant: ITenant,
  userRole: UserRole,
  configurableEntity: ConfigurableEntity,
  entityKey: string,
  parentKey: string
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#0EBCB7",
  },
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

export const ConfigView: React.FC<Props> = (props) => {

  const [config, setConfig] = useState();
  const [hiddenConfig, setHiddenConfig] = useState('');
  const [tenantConfig, setTenantConfig] = useState();
  const [hiddenTenantConfig, setHiddenTenantConfig] = useState('');
  const [appConfig, setAppConfig] = useState();
  const [hiddenAppConfig, setHiddenAppConfig] = useState('');
  const [configHasChanged, setConfigHasChanged] = useState(false);
  const [editedConfig, setEditedConfig] = useState('');
  const [editedKey, setEditedKey] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [value, setValue] = useState(0);
  const [isConfigValid, setIsConfigValid] = useState(false);
  const [masked, setMasked] = useState(true);

  const readConfig = async () => {
    if (props.configurableEntity !== ConfigurableEntity.Tenant) {
      const tenantParams = {
        name: props.tenant.name,
        tenant: props.tenant.name
      }

      // get tenant config
      const queryTenant = QueriesStatic.getConfig(ConfigurableEntity.Tenant);
      const configTenant = await GraphQLHelper.execute<ConfigModel>(queryTenant, tenantParams, ConfigModel);
      if (!configTenant.error) {
        const cfg = configTenant.result as ConfigModel;
        setTenantConfig((configTenant.result as ConfigModel).configJson);
        const hiddenTenantConfig = hideData(cfg).configJson;
        setHiddenTenantConfig(JSON.stringify(hiddenTenantConfig, null, 2));
      }
    }

    // get app config
    if (props.configurableEntity === ConfigurableEntity.ExternalNode) {
      const appParams = {
        name: props.parentKey,
        tenant: props.tenant.name
      }
      // get external app config
      const queryApp = QueriesStatic.getConfig(ConfigurableEntity.ExternalApp);
      const configApp = await GraphQLHelper.execute<ConfigModel>(queryApp, appParams, ConfigModel);
      if (!configApp.error) {
        const cfg = configApp.result as ConfigModel;
        setAppConfig((configApp.result as ConfigModel).configJson);
        const hiddenAppConfig = hideData(cfg).configJson;
        setHiddenAppConfig(JSON.stringify(hiddenAppConfig, null, 2));
      }
    } else if (props.configurableEntity === ConfigurableEntity.CrossTenantSendingNode) {

    } else if (props.configurableEntity === ConfigurableEntity.ManagedNode) {
      const appParams = {
        name: props.parentKey,
        tenant: props.tenant.name
      }
      // get external app config
      const queryApp = QueriesStatic.getConfig(ConfigurableEntity.ManagedApp);
      const configApp = await GraphQLHelper.execute<ConfigModel>(queryApp, appParams, ConfigModel);
      if (!configApp.error) {
        const cfg = configApp.result as ConfigModel;
        setAppConfig((configApp.result as ConfigModel).configJson);
        const hiddenExtAppConfig = hideData(cfg).configJson;
        setHiddenAppConfig(JSON.stringify(hiddenExtAppConfig, null, 2));
      }
    } 

    // get node config
    const params = {
      name: props.entityKey,
      tenant: props.tenant.name
    }
    const query = QueriesStatic.getConfig(props.configurableEntity);
    const p = await GraphQLHelper.execute<ConfigModel>(query, params, ConfigModel);
    if (!p.error) {
      const cfg = p.result as ConfigModel;
      setEditedConfig(JSON.stringify(cfg.configJson, null, 2));
      setConfig(cfg.configJson);
      const hideConfig = hideData(cfg).configJson;
      setHiddenConfig(JSON.stringify(hideConfig, null, 2));
    }
  }

  useEffect(() => {
    if (props.tenant && props.tenant.name !== 'DEFAULT_TENANT') {
      readConfig();
    }
  }, [props.tenant, props.configurableEntity, props.entityKey, props.parentKey]);

  const sortConfigs = (t1: IKeyValue, t2: IKeyValue) => {
    if (t1.key < t2.key) return -1;
    if (t1.key > t2.key) return 1;
    return 0;
  }

  const saveConfig = async () => {
    const params = {
      name: props.entityKey,
      tenant: props.tenant.name
    }
    const query = MutationsStatic.updateConfig(props.configurableEntity, editedConfig);
    const p = await GraphQLHelper.execute<ConfigModel>(query, params, ConfigModel);
    if (!p.error) {
      toast('Configuration updated.');
      setConfigHasChanged(false);
      readConfig();
    }
  }

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const onCodeChange = (code: string) => {
    //...
    setIsConfigValid(false);
    setConfigHasChanged(true);
    try {
      JSON.parse(code);
      const t = RegExp(/^{.*}$/s).exec(code);
      if (t && t.length > 0) {
        setIsConfigValid(true);
      }
    } catch (err) {
    }

    if (code === JSON.stringify(config, null, 2)) {
      setConfigHasChanged(false);
    }
    setEditedConfig(code);
  }

  const tabs = Array<any>();
  const panels = Array<any>();

  let idx = 0;
  tabs.push(<Tab label="Config"  {...a11yProps(idx)} />);
  panels.push(
    <TabPanel value={value} index={idx}>
      <JSONEditor code={(masked && !configHasChanged) ? hiddenConfig : editedConfig} onCodeChange={(props.userRole === UserRole.admin || props.userRole === UserRole.owner) ? onCodeChange : null} />
    </TabPanel>);

  if (appConfig) {
    idx += 1;
    tabs.push(<Tab label="From Application"  {...a11yProps(idx)} />);
    panels.push(
      <TabPanel value={value} index={idx}>
        <JSONEditor code={masked ? hiddenAppConfig : JSON.stringify(appConfig || '{}', null, 2)} />
      </TabPanel>);
  }

  if (tenantConfig) {
    idx += 1;
    tabs.push(<Tab label="From Tenant"  {...a11yProps(idx)} />);
    panels.push(
      <TabPanel value={value} index={idx}>
        <JSONEditor code={!masked ? (JSON.stringify(tenantConfig || '{}', null, 2)) : (hiddenTenantConfig || '{}')} />
      </TabPanel>);
  }

  if ((tenantConfig && appConfig) || (tenantConfig && config)) {
    idx += 1;
    tabs.push(<Tab label="Effective Config"  {...a11yProps(idx)} />);
    panels.push(
      <TabPanel value={value} index={idx}>
        <JSONEditor code={masked ? hiddenTenantConfig + hiddenConfig + hiddenAppConfig : JSON.stringify(Object.assign({}, tenantConfig, appConfig, config), null, 2)} />
      </TabPanel>);
  }

  function hideData(jsonData: any) {
    // if no config return
    if (!jsonData) {
      return jsonData;
    }

    // Clone the JSON object to avoid modifying the original
    const clonedData = JSON.parse(JSON.stringify(jsonData));
  
    // Replace all values in the cloned object with "****"
    const traverse = (obj: any) => {
      for (const key in obj) {
        if (typeof obj[key] === 'object') {
          traverse(obj[key]); // Recursively traverse nested objects
        } else {
          obj[key] = '****'; // Replace the value with "****"
        }
      }
    };
  
    traverse(clonedData);
  
    return clonedData;
  }

  return (
    <div style={{ overflow: 'auto', marginTop: '10px' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}> Configuration </legend>
        <Tabs
          value={value}
          onChange={handleChange}
        >
          {tabs}
        </Tabs>
        {panels}
        <div style={{ marginTop: '5px' }} hidden={value !== 0}>
          <div style={{ float: 'right' }}>
            <IconButton disabled={props.userRole === UserRole.read_only || props.userRole === UserRole.user} onClick={() => {setMasked(!masked);}}>
              <VisabilityIcon />
            </IconButton>
            <IconButton disabled={!(isConfigValid && configHasChanged)} size="small" onClick={() => { saveConfig(); }}>
              <SaveIcon />
            </IconButton>
            <IconButton disabled={!configHasChanged} size="small" onClick={() => { setConfigHasChanged(false); readConfig(); }}>
              <CancelIcon />
            </IconButton>
          </div>
        </div>
        <div style={{ marginTop: '5px', height: '30px' }} hidden={value === 0} />
      </fieldset>
    </div>
  )
}