import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel, Grid, IconButton, makeStyles,
  TextField,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import { Autocomplete, Box } from "@mui/material";
import CodeEditor from "components/CodeEditor";
import EchoDialog from "components/EchoDialog";
import { ConfigurableEntity } from "enumerations/configurable-entity";
import { NodeTypeName } from "enumerations/nodetype-name";
import { ValidationType } from "enumerations/validation-type";
import { MutationsStatic } from "graphql/mutations-static";
import { IEchoFunction } from "interfaces/echo-function";
import { IEchoNode } from "interfaces/echo-node";
import { IRouteTableEntry } from "interfaces/route-table-entry";
import { ITenant } from "interfaces/tenant";
import { BitmapRouterNodeModel } from "models/node/bitmap-router-node";
import { CrossTenantSendingNodeModel } from "models/node/cross-tenant-sending-node";
import { DeleteNodeModel } from "models/node/delete-node-model";
import { ExternalNodeModel } from "models/node/external-node";
import { FilesDotComWebhookNodeModel } from "models/node/filesdotcom-webhook-node";
import { WebhookNodeModel } from "models/node/webhook-node";
import { ProcessorNodeModel } from "models/node/processor-node";
import { TimerNodeModel } from "models/node/timer-node";
import React, { useEffect, useState } from "react";
import { GraphQLHelper } from "utilities/graphql-helper";
import { ConfigView } from "./ConfigView";
import FunctionTester from "./FunctionTester";
import DisplayField from "./node/DisplayField";
import ManagedFunctionHeader from "./node/ManagedFunctionHeader";
import NodeDelete from "./node/NodeDelete";
import RouteTable from "./RouteTable";
import ApiFunctionTester from "./ApiFunctionTester";
import { LoadBalancerNodeModel } from "models/node/load-balancer-node";
import { WebSubHubNodeModel } from "models/node/websub-hub-node";
import { SignatureAlgorithm } from "enumerations/signature-algorithm";
import { SubscriptionSecurity } from "enumerations/subscription-security";
import { UserRole } from "enumerations/user-role";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
}));

interface Props {
  node: IEchoNode | undefined;
  nodes: Array<IEchoNode> | undefined;
  tenant: ITenant;
  userRole: UserRole;
  onUpdateGraph: () => void;
  onCloseDialog: () => void;
  functionList: Array<IEchoFunction>;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  startNode: (name: string | undefined, typeName: string | undefined) => void;
  stopNode: (name: string | undefined, typeName: string | undefined) => void;
}

const NodeDetail: React.FC<Props> = (props) => {

  const [open, setOpen] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState("");
  const [infoDeleting, setInfoDeleting] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [nodeCode, setNodeCode] = useState<string>("");
  const [codeChanged, setCodeChanged] = useState<boolean>(false);
  const [routeTable, setRouteTable] = useState<Array<IRouteTableEntry>>();
  const [canEditCode, setCanEditCode] = useState(false);
  const [newManagedFunctionUsed, setNewManagedFunctionUsed] = useState("");
  const [noFunction, setNoFunction] = useState(false);
  const [sequentialProcessing, setSequentialProcessing] = useState(false);
  const [requirements, setRequirements] = useState<Array<string>>([]);
  const [description, setDescription] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [stopped, setStopped] = useState(false);
  const [functionChanged, setFunctionChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sigAlgo, setSigAlgo] = useState<SignatureAlgorithm>(SignatureAlgorithm.sha1);
  const [subSec, setSubSec] = useState<SubscriptionSecurity>(SubscriptionSecurity.none);
  const [maxLS, setMaxLS] = useState(0);
  const [defaultLS, setDefaultLS] = useState(0);
  const [deliveryRetries, setDeliveryRetries] = useState(0);

  const classes = useStyles();

  useEffect(() => {
    let code = "";
    // copy the code from the send message type of the processor is empty
    if (props.node?.typeName === NodeTypeName.BitmapRouterNode) {
      const nd = props.node as BitmapRouterNodeModel;
      setRequirements(nd.requirements);
      if (nd.inlineBitmapper) {
        code = nd.inlineBitmapper;
        setCanEditCode(false);
      } else if (nd.managedBitmapper && nd.managedBitmapper.code && nd.managedBitmapper.name) {
        code = nd.managedBitmapper.code;
      } else {
        setNoFunction(true);
      }
      setRouteTable(nd.routeTable);
    }
    if (props.node?.typeName === NodeTypeName.ProcessorNode) {
      const nd = props.node as ProcessorNodeModel;
      setSequentialProcessing(nd.sequentialProcessing);
      setRequirements(nd.requirements);
      if (nd.inlineProcessor) {
        code = nd.inlineProcessor;
        setCanEditCode(false);
      } else if (nd.managedProcessor && nd.managedProcessor.code && nd.managedProcessor.name) {
        code = nd.managedProcessor.code;
      } else {
        setNoFunction(true);
      }
    }
    if (props.node?.typeName === NodeTypeName.CrossTenantSendingNode) {
      const nd = props.node as CrossTenantSendingNodeModel;
      setSequentialProcessing(nd.sequentialProcessing);
      setRequirements(nd.requirements);
      if (nd.inlineProcessor) {
        code = nd.inlineProcessor;
        setCanEditCode(false);
      } else if (nd.managedProcessor && nd.managedProcessor.code && nd.managedProcessor.name) {
        code = nd.managedProcessor.code;
      } else {
        setNoFunction(true);
      }
    }
    if (props.node?.typeName === NodeTypeName.WebhookNode) {
      const nd = props.node as WebhookNodeModel;
      setRequirements(nd.requirements);
      if (nd.inlineApiAuthenticator) {
        code = nd.inlineApiAuthenticator;
        setCanEditCode(false);
      } else if (nd.managedApiAuthenticator && nd.managedApiAuthenticator.code && nd.managedApiAuthenticator.name) {
          code = nd.managedApiAuthenticator.code;
      } else {
        setNoFunction(true);
      }
    }
    if (props.node?.typeName === NodeTypeName.WebSubHubNode) {
      const nd = props.node as WebSubHubNodeModel;
      setRequirements(nd.requirements);
      setSigAlgo(nd.signatureAlgorithm);
      setSubSec(nd.subscriptionSecurity);
      setDefaultLS(nd.defaultLeaseSeconds);
      setMaxLS(nd.maxLeaseSeconds);
      setDeliveryRetries(nd.deliveryRetries);
      if (nd.inlineApiAuthenticator) {
        code = nd.inlineApiAuthenticator;
        setCanEditCode(false)
      } else if (nd.managedApiAuthenticator && nd.managedApiAuthenticator.code && nd.managedApiAuthenticator.name) {
        code = nd.managedApiAuthenticator.code;
    } else {
      setNoFunction(true);
    }
    }
    if (!code) {
      //setNodeCode(props.node?.receiveMessageType.processorTemplate || '');
    } else {
      setNodeCode(code);
    }
    if (props.node?.description) {
      setDescription(props.node.description);
    }
    if (props.node?.stopped) {
      setStopped(true);
    } else {
      setStopped(false);
    }
  }, [props.node]);

  const clearState = (hideDialog: boolean) => {
    setErrorDeleting("");
    setInfoDeleting("");
    setSuccessMessage("");
    setOpen(!hideDialog);
  };

  const clearStateSaveDialog = (hideDialog: boolean) => {
    setErrorDeleting("");
    setInfoDeleting("");
    setSuccessMessage("");
    setOpenSaveDialog(!hideDialog);
  };

  const onDeleteNode = async () => {
    clearState(false);
    const params = {
      name: props.node?.name,
      tenant: props.tenant.name,
    };
    const query = MutationsStatic.deleteNode(false);
    const p = await GraphQLHelper.execute<DeleteNodeModel>(
      query,
      params,
      DeleteNodeModel
    );
    if (!p.error) {
      setSuccessMessage(`Node ${props.node?.name} deleted.`);
      props.onUpdateGraph();
    } else {
      setErrorDeleting(JSON.stringify(p.errorMessage));
      console.log("Can't delete node", p.errorMessage);
    }
  };

  const onUpdateNode = async () => {
    try {
      clearStateSaveDialog(false);
      setIsLoading(true);
      const params = {
        tenant: props.tenant.name,
        name: props.node?.name,
      };

      // let filteredReqs = new Array<string>();
      // if (requirements) {
      //   const reqs = requirements.split(",") as Array<string>;
      //   // remove empty
      //   filteredReqs = reqs.filter(o => o);
      // }
      let newp = {};
      let updateFunc = "";
      if (props.node?.typeName === NodeTypeName.BitmapRouterNode) {
        newp = {
          ...params,
        };

        const nt = props.node as BitmapRouterNodeModel;
        if (newManagedFunctionUsed) {
          // set the managed function name only, to clear out the processor...
          newp = { ...newp, managedBitmapper: newManagedFunctionUsed };
          updateFunc = MutationsStatic.updateBitmapRouterNode(
            description,
            "",
            newManagedFunctionUsed,
            requirements,
            routeTable ? routeTable : new Array<IRouteTableEntry>()
          );
        } else if (
          nt.managedBitmapper &&
          nt.managedBitmapper.code &&
          nt.managedBitmapper.name &&
          canEditCode
        ) {
          // changed to use a custom func, move the managed func to an inline processor...
          newp = { ...newp, inlineBitmapper: nodeCode };
          updateFunc = MutationsStatic.updateBitmapRouterNode(
            description,
            nodeCode,
            "",
            requirements,
            routeTable ? routeTable : new Array<IRouteTableEntry>()
          );
        } else if (nt.inlineBitmapper || nodeCode) {
          // they just edited the existing inline processor..
          newp = { ...newp, inlineBitmapper: nodeCode };
          updateFunc = MutationsStatic.updateBitmapRouterNode(
            description,
            nodeCode,
            "",
            requirements,
            routeTable ? routeTable : new Array<IRouteTableEntry>()
          );
        } else if (noFunction) {
          //change to <none> function
          newp = { ...newp };
          updateFunc = MutationsStatic.updateBitmapRouterNode (
            description,
            nodeCode,
            "",
            requirements,
            routeTable ? routeTable : new Array<IRouteTableEntry>()
          );
        }
        const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(
          updateFunc,
          params,
          BitmapRouterNodeModel
        );
        console.log(p);
        if (p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.ProcessorNode) {
        newp = {
          ...params,
          receiveMessageType: props.node?.receiveMessageType
            ? props.node?.receiveMessageType.name
            : "",
          sendMessageType: props.node?.sendMessageType
            ? props.node?.sendMessageType.name
            : "",
        };
        const nt = props.node as ProcessorNodeModel;
        if (newManagedFunctionUsed) {
          // set the managed function name only, to clear out the processor...
          newp = { ...newp, managedProcessor: newManagedFunctionUsed };
          updateFunc = MutationsStatic.updateNode(
            description,
            "",
            newManagedFunctionUsed,
            requirements,
            sequentialProcessing
          );
        } else if (
          nt.managedProcessor &&
          nt.managedProcessor.code &&
          nt.managedProcessor.name &&
          canEditCode
        ) {
          // changed to use a custom func, move the managed func to an inline processor...
          newp = { ...newp, inlineProcessor: nodeCode };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        } else if (nt.inlineProcessor || nodeCode) {
          // they just edited the existing inline processor..
          newp = { ...newp, inlineProcessor: nodeCode };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        } else if (noFunction) {
          //change to <none> function
          newp = { ...newp };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        }
        const p = await GraphQLHelper.execute<ProcessorNodeModel>(
          updateFunc,
          params,
          ProcessorNodeModel
        );
        console.log(p);
        if (p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.WebhookNode) {
        newp = {
          ...params,
        };
        const nt = props.node as WebhookNodeModel;
        if (newManagedFunctionUsed) {
          // set the managed function name only, to clear out the processor...
          newp = { ...newp, managedApiAuthenticator: newManagedFunctionUsed };
          updateFunc = MutationsStatic.updateWebhookNode(
            description,
            "",
            newManagedFunctionUsed,
            requirements,
          );
        } else if (
          nt.managedApiAuthenticator &&
          nt.managedApiAuthenticator.code &&
          nt.managedApiAuthenticator.name &&
          canEditCode
        ) {
          // changed to use a custom func, move the managed func to an inline processor...
          newp = { ...newp, inlineApiAuthenticator: nodeCode };
          updateFunc = MutationsStatic.updateWebhookNode(
            description,
            nodeCode,
            "",
            requirements,
          );
        } else if (nt.inlineApiAuthenticator || nodeCode) {
          // they just edited the existing inline processor..
          newp = { ...newp, inlineApiAuthenticator: nodeCode };
          updateFunc = MutationsStatic.updateWebhookNode(
            description,
            nodeCode,
            "",
            requirements,
          );
        } else if (noFunction) {
          //change to <none> function
          newp = { ...newp };
          updateFunc = MutationsStatic.updateWebhookNode(
            description,
            nodeCode,
            "",
            requirements
          );
        }
        const p = await GraphQLHelper.execute<WebhookNodeModel>(
          updateFunc,
          params,
          WebhookNodeModel
        );
        console.log(p);
        if (p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.WebSubHubNode) {
        newp = {
          ...params,
        };
        const nt = props.node as WebSubHubNodeModel;
        if (newManagedFunctionUsed) {
          // set the managed function name only, to clear out the processor...
          newp = { ...newp, managedApiAuthenticator: newManagedFunctionUsed };
          updateFunc = MutationsStatic.updateWebSubHubNode(
            description,
            nodeCode,
            "",
            requirements,
            defaultLS, 
            deliveryRetries,
            maxLS,
            sigAlgo,
            subSec
          );
        } else if (
          nt.managedApiAuthenticator &&
          nt.managedApiAuthenticator.code &&
          nt.managedApiAuthenticator.name &&
          canEditCode
        ) {
          // changed to use a custom func, move the managed func to an inline processor...
          newp = { ...newp, inlineApiAuthenticator: nodeCode };
          updateFunc = MutationsStatic.updateWebSubHubNode(
            description,
            nodeCode,
            "",
            requirements,
            defaultLS, 
            deliveryRetries,
            maxLS,
            sigAlgo,
            subSec
          );
        } else if (nt.inlineApiAuthenticator || nodeCode) {
          // they just edited the existing inline processor..
          newp = { ...newp, inlineApiAuthenticator: nodeCode };
          updateFunc = MutationsStatic.updateWebSubHubNode(
            description,
            nodeCode,
            "",
            requirements,
            defaultLS, 
            deliveryRetries,
            maxLS,
            sigAlgo,
            subSec
          );
        } else if (noFunction) {
          //change to <none> function
          newp = { ...newp };
          updateFunc = MutationsStatic.updateWebSubHubNode(
            description,
            nodeCode,
            "",
            requirements,
            defaultLS, 
            deliveryRetries,
            maxLS,
            sigAlgo,
            subSec
          );
        }
        const p = await GraphQLHelper.execute<WebSubHubNodeModel>(
          updateFunc,
          params,
          WebSubHubNodeModel
        );
        console.log(p);
        if (p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.CrossTenantSendingNode) {
        newp = {
          ...params,
          receiveMessageType: props.node?.receiveMessageType
            ? props.node?.receiveMessageType.name
            : "",
          sendMessageType: props.node?.sendMessageType
            ? props.node?.sendMessageType.name
            : "",
        };
        const nt = props.node as CrossTenantSendingNodeModel;
        if (newManagedFunctionUsed) {
          // set the managed function name only, to clear out the processor...
          newp = { ...newp, managedProcessor: newManagedFunctionUsed };
          updateFunc = MutationsStatic.updateNode(
            nt.description,
            "",
            newManagedFunctionUsed,
            requirements,
            sequentialProcessing
          );
        } else if (
          nt.managedProcessor &&
          nt.managedProcessor.code &&
          nt.managedProcessor.name &&
          canEditCode
        ) {
          // changed to use a custom func, move the managed func to an inline processor...
          newp = { ...newp, inlineProcessor: nodeCode };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        } else if (nt.inlineProcessor || nodeCode) {
          // they just edited the existing inline processor..
          newp = { ...newp, inlineProcessor: nodeCode };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        } else if (noFunction) {
          //change to <none> function
          newp = { ...newp };
          updateFunc = MutationsStatic.updateNode(
            description,
            nodeCode,
            "",
            requirements,
            sequentialProcessing
          );
        }
        const p = await GraphQLHelper.execute<CrossTenantSendingNodeModel>(
          updateFunc,
          params,
          CrossTenantSendingNodeModel
        );
        console.log(p);
        if (p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.TimerNode) {
        updateFunc = MutationsStatic.updateTimerNode(description);
        const p = await GraphQLHelper.execute<TimerNodeModel>(updateFunc, params, TimerNodeModel);
        console.log(p);
        if (p.error){
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.ExternalNode) {
        updateFunc = MutationsStatic.updateExternalNode(description);
        const p = await GraphQLHelper.execute<ExternalNodeModel>(updateFunc, params, ExternalNodeModel);
        console.log(p);
        if(p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.FilesDotComWebhookNode) {
        updateFunc = MutationsStatic.updateFilesDotComWebhookNode(description, apiKey);
        const p = await GraphQLHelper.execute<FilesDotComWebhookNodeModel>(updateFunc, params, FilesDotComWebhookNodeModel);
        console.log(p);
        if(p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node Updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
      if (props.node?.typeName === NodeTypeName.LoadBalancerNode) {
        updateFunc = MutationsStatic.updateLoadBalancerNode(description);
        const p = await GraphQLHelper.execute<LoadBalancerNodeModel>(updateFunc, params, LoadBalancerNodeModel);
        console.log(p);
        if(p.error) {
          setErrorDeleting(p.errorMessage);
        } else {
          setSuccessMessage("Node Updated");
          setFunctionChanged(false);
          props.onUpdateGraph();
        }
      }
    } catch (err) {
      setErrorDeleting(JSON.stringify(err));
      console.log("Can't update node", err);
    } finally {
      setIsLoading(false);
    }
  };

  const onCodeChange = (code: string) => {
    setNodeCode(code);
    setCodeChanged(true);
  };

  const routesChanged = (routes: Array<IRouteTableEntry>) => {
    console.log(routes);
    // enable save button
    setCodeChanged(true); 
    setRouteTable(routes);
  };

  let subTitle = "";

  if (props.node) {
    if (props.node?.typeName === NodeTypeName.BitmapRouterNode) {
      subTitle = "Bitmap Router Node";
    } else if (props.node?.typeName === NodeTypeName.ProcessorNode) {
      subTitle = "Processor Node";
    } else if (props.node?.typeName === NodeTypeName.TimerNode) {
      subTitle = "Timer Node";
    } else {
      subTitle = props.node.typeName;
    }
  }

  const onCustomFunctionSelected = () => {
    // enable code editor...
    setNodeCode("# add your custom function here");
    setNewManagedFunctionUsed("");
    setCodeChanged(true);
    setFunctionChanged(true);
  };

  const onNoFunctionSelected = () => {
    // enable code editor...
    setNodeCode("");
    setNewManagedFunctionUsed("");
    setCodeChanged(true);
    setNoFunction(true);
    setFunctionChanged(true);
  };

  const onManagedFunctionSelected = (funcName: string) => {
    setNewManagedFunctionUsed(funcName)
    // look up func; replace values...
    setFunctionChanged(true);
    const func = props.functionList.find((o) => o.name === funcName);
    if (func) {
      setNodeCode(func?.code);
    }
  };

  const handleChangeSequentialProcessing = () => {
    setSequentialProcessing(!sequentialProcessing);
    setCodeChanged(true);
  };

  const onRequirementsChange = (event: any, value: any) => {
    const trimmedReqs = value.map((req: any) => req.trim())
    setRequirements((state) => trimmedReqs);
    setCodeChanged(true);
  }

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
    setCodeChanged(true);
  };

  const onApiKeyChange = (event: any) => {
    setApiKey(event.target.value);
    setCodeChanged(true);
  }

  const cancelEdit = () => {
    if (props.node?.typeName === NodeTypeName.BitmapRouterNode) {
      const nd = props.node as BitmapRouterNodeModel;
      const reqStr = nd.requirements;
      if (reqStr === null || reqStr === undefined) {
        setRequirements([]);
      } else {
        setRequirements(nd.requirements);
      }
    }
    setCodeChanged(false);
    setCanEditCode(false);
    props.setIsEditing(false);
    if (props.node?.description) {
      setDescription(props.node?.description);
    }
    if (props.node?.typeName === NodeTypeName.WebSubHubNode) {
      const nd = props.node as WebSubHubNodeModel;
      setSigAlgo(nd.signatureAlgorithm);
      setDefaultLS(nd.defaultLeaseSeconds);
      setMaxLS(nd.maxLeaseSeconds);
      setSubSec(nd.subscriptionSecurity);
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
      {open && (
        <NodeDelete
          node={props.node}
          tenant={props.tenant}
          onDeleted={() => {
            onDeleteNode();
          }}
          onCancel={() => {
            clearState(true);
          }}
          onCloseDialog={() => {
            props.onCloseDialog();
          }}
        />
      )}
      <EchoDialog
        open={openSaveDialog}
        title={`Save node '${props.node?.name}' changes?`}
        errorMessage={errorDeleting}
        infoMessage={infoDeleting}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearStateSaveDialog(true)}
        onOk={() => clearStateSaveDialog(true)}
        onSave={() => {
          onUpdateNode();
          props.setIsEditing(false);
        }}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      <Card
        style={{ height: "100%", display: "flex", flexDirection: "column" }}
        elevation={0}
      >
        <CardHeader
          style={{ flex: "none", height: "10px" }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              T
            </Avatar>
          }
          title={props.node?.name}
          subheader={subTitle}
        />
        <CardContent style={{ height: "auto", flex: "auto" }}>
          {stopped ? 
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "5px" }}>
            <Button onClick={() => {props.startNode(props.node?.name, props.node?.typeName); setStopped(!stopped); }}>
              <img height={24} width={24} src="Sign-Stop.svg" alt="node-stopped" style={{padding: "5px" }}/>
              <Typography style={{ color: "#ff1800" }}>STOPPED</Typography>
            </Button>
          </div>
          :
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "5px"}}>
            <Button onClick={() => {props.stopNode(props.node?.name, props.node?.typeName); setStopped(!stopped); }}>
              <img height={24} width={24} src="Icon_ArrowLink.svg" alt="node-running" style={{padding: "5px" }}/>
              <Typography style={{ color: "#07bc0c" }}>RUNNING</Typography>
            </Button>
          </div>
          }
          {props.node && (
            <div>
              <TextField
                onChange={(e: any) => {
                  onDescriptionChange(e);
                }}
                variant="outlined"
                value={description}
                margin="dense"
                id="description"
                label="Description"
                fullWidth
                InputProps={{
                  readOnly: !props.isEditing,
                }}
              />
              {props.node.typeName !== NodeTypeName.TimerNode && (
                <DisplayField
                  title="Receive Type"
                  description={
                    props.node.receiveMessageType?.name ||
                    "<no receive message type>"
                  }
                />
              )}
              <DisplayField
                title="Send Type"
                description={
                  props.node.sendMessageType?.name || "<no send message type>"
                }
              />
              {(props.node.typeName === NodeTypeName.FilesDotComWebhookNode ||
              props.node.typeName === NodeTypeName.WebhookNode ||
              props.node.typeName === NodeTypeName.WebSubHubNode) &&
              <DisplayField 
                title="Endpoint"
                description={(props.node as FilesDotComWebhookNodeModel).endpoint || (props.node as WebhookNodeModel).endpoint || (props.node as WebSubHubNodeModel).endpoint}
              />
              }
             {props.node.typeName === NodeTypeName.FilesDotComWebhookNode &&
             <>
                <DisplayField title="Token" description={(props.node as FilesDotComWebhookNodeModel).token} />
                <TextField 
                  onChange={(e: any) => {
                    onApiKeyChange(e);
                  }}
                  variant="outlined"
                  value={!props.isEditing ? "********************" : apiKey}
                  margin="dense"
                  id="apiKey"
                  label="Api Key"
                  fullWidth
                  InputProps={{
                    readOnly: !props.isEditing,
                  }}
                />
              </>
              }
              {props.node?.typeName === NodeTypeName.TimerNode && (
                <DisplayField
                  title="Scheduled Expression"
                  description={
                    (props.node as TimerNodeModel).scheduleExpression
                  }
                />
              )}
              {props.node?.typeName === NodeTypeName.WebSubHubNode &&
              <div style={{ width: '100%', display: 'flex', gap: 5, padding: '5px 0' }}>
                <TextField 
                  onChange={(e: any) => {
                    setDeliveryRetries(e.target.value);
                    setCodeChanged(true);
                  }}
                  variant="outlined"
                  value={deliveryRetries}
                  margin="dense"
                  id="deliveryRetries"
                  label="Delivery Retries"
                  type='number'
                  InputProps={{
                    readOnly: !props.isEditing,
                  }}
                />
                <FormControl
                  variant='filled'
                  style={{ width: "200px" }}>
                    <InputLabel htmlFor="filled-age-native-simple">Signature Algorithm</InputLabel>
                    <Select
                      native
                      value={sigAlgo}
                      onChange={(e: any) => {
                        setSigAlgo(e.target.value as SignatureAlgorithm);
                        setCodeChanged(true);
                      }}
                      inputProps={{
                        name: "tenant",
                        id: "filled-age-native-simple"
                      }}
                      disabled={!props.isEditing}>
                        {Object.keys(SignatureAlgorithm).map((key: string, index: number) => (
                          <option key={index} value={key as SignatureAlgorithm}>{key as SignatureAlgorithm}</option>
                        ))}
                    </Select>
                </FormControl>
                 <FormControl
                  variant='filled'
                  style={{ width: "200px" }}>
                    <InputLabel htmlFor="filled-age-native-simple">Subscription Security</InputLabel>
                    <Select
                      native
                      value={subSec}
                      onChange={(e: any) => {
                        setSubSec(e.target.value as SubscriptionSecurity)
                        setCodeChanged(true);
                      }}
                      inputProps={{
                        name: "tenant",
                        id: "filled-age-native-simple"
                      }}
                      disabled={!props.isEditing}>
                        {Object.keys(SubscriptionSecurity).map((ss: string, index: number) => 
                          <option key={index} value={ss as SubscriptionSecurity}>{ss as SubscriptionSecurity}</option>
                        )}
                    </Select>
                </FormControl>
                <TextField 
                  onChange={(e: any) => {
                    setDefaultLS(e.target.value);
                    setCodeChanged(true);
                  }}
                  variant="outlined"
                  value={defaultLS}
                  margin="dense"
                  id="defaultLeaseSeconds"
                  label="Default Lease Seconds"
                  type='number'
                  InputProps={{
                    readOnly: !props.isEditing,
                  }}
                />
                <TextField 
                  onChange={(e: any) => {
                    setMaxLS(e.target.value);
                    setCodeChanged(true);
                  }}
                  variant="outlined"
                  value={maxLS}
                  margin="dense"
                  id="maxLeaseSeconds"
                  label="Max Lease Seconds"
                  type='number'
                  InputProps={{
                    readOnly: !props.isEditing,
                  }}
                />
              </div>
              }
              <div>
                {(props.node?.typeName === NodeTypeName.ProcessorNode ||
                  props.node?.typeName === NodeTypeName.BitmapRouterNode ||
                  props.node?.typeName === NodeTypeName.CrossTenantSendingNode ||
                  props.node?.typeName === NodeTypeName.WebhookNode ||
                  props.node?.typeName === NodeTypeName.WebSubHubNode) && (
                    <ManagedFunctionHeader
                      node={props.node}
                      funcs={props.functionList}
                      onCustomSelected={onCustomFunctionSelected}
                      onNoFunctionSelected={onNoFunctionSelected}
                      onManagedFunctionSelected={onManagedFunctionSelected}
                      isEditing={props.isEditing}
                      codeChanged={codeChanged}
                    />
                  )}
              </div>
            </div>
          )}
          <div style={{ marginTop: "5px", clear: "both" }}>
            {nodeCode && 
              <div>
                <Box height="300px">
                  {canEditCode && (
                    <CodeEditor code={nodeCode} onCodeChange={onCodeChange} />
                  )}
                  {!canEditCode && <CodeEditor code={nodeCode} />}
                </Box>
                {props.node && (props.node.typeName !== NodeTypeName.WebhookNode && props.node.typeName !== NodeTypeName.WebSubHubNode) &&
                  <FunctionTester
                    tenant={props.tenant}
                    code={nodeCode}
                    functionName={props.node.name}
                    validationType={ValidationType.Node}
                    receiveMessageType={props.node.receiveMessageType}
                  />}
                  {props.node && (props.node.typeName === NodeTypeName.WebhookNode || props.node.typeName === NodeTypeName.WebSubHubNode) &&
                  <ApiFunctionTester 
                    tenant={props.tenant}
                    code={nodeCode}
                    node={props.node}
                    functionName={props.node.name}
                    validationType={ValidationType.Node}
                  /> 
                  }
              </div>
            }
            <Box>
              {(props.node?.typeName === NodeTypeName.BitmapRouterNode ||
                props.node?.typeName === NodeTypeName.ProcessorNode ||
                props.node?.typeName === NodeTypeName.AppChangeReceiverNode ||
                props.node?.typeName === NodeTypeName.CrossTenantSendingNode ||
                props.node?.typeName === NodeTypeName.CrossTenantReceivingNode ||
                props.node?.typeName === NodeTypeName.WebhookNode ||
                props.node?.typeName === NodeTypeName.WebSubHubNode) && (
                  !props.isEditing ? 
                  <Box sx={{ border: "0.5px #888888 solid", borderRadius: 1, marginTop: 5}}>
                  <Typography style={{ padding: "8px", fontWeight: 1, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }} variant="h6">
                    Requirements
                  </Typography>
                    <List>
                      {requirements ? requirements.map(req => <ListItem style={{ padding: 0, paddingLeft: 8, margin:0 }}> <ListItemText primary={req}/> </ListItem> ) : '<no requirements>'}
                    </List>
                </Box>
                  :
                  <Autocomplete
                    multiple
                    id="tags-filled"
                    options={[]}
                    defaultValue={requirements ? requirements : []}
                    freeSolo
                    onChange={onRequirementsChange}
                    style={{ paddingTop: "20px", paddingBottom: "20px" }}
                    renderTags={(
                      value: any[],
                      getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
                    ) =>
                    <div style={{ display: "flex", flexDirection:"column" }}>
                      {value.map((option: any, index: any) => {
                        return (
                          <Chip
                            key={index}
                            variant="outlined"
                            label={option}
                            {...getTagProps({ index })}
                          />
                        );}
                      )
                    }
                    </div>}
                    renderInput={(params: any) => (
                      <div>
                        <TextField
                          {...params}
                          fullWidth
                          label="Requirements"
                          placeholder="Add a requirement by pressing enter"
                    />
                    </div>
                  )}
                />
        
                )}
            </Box>
            <Box>
              {(props.node?.typeName === NodeTypeName.ProcessorNode ||
                props.node?.typeName === NodeTypeName.CrossTenantSendingNode ||
                props.node?.typeName === NodeTypeName.WebhookNode || 
                props.node?.typeName === NodeTypeName.WebSubHubNode) && (
                  <>
                    <ConfigView
                      configurableEntity={props.node?.typeName === NodeTypeName.WebhookNode ? ConfigurableEntity.WebhookNode : ConfigurableEntity.ProcessorNode}
                      entityKey={props.node.name}
                      tenant={props.tenant}
                      parentKey={props.node?.typeName === NodeTypeName.CrossTenantSendingNode ? (props.node as CrossTenantSendingNodeModel).parent.name : ""}
                      userRole={props.userRole}
                    />
                    {props.node?.typeName !== NodeTypeName.WebhookNode && 
                      <FormControlLabel
                      control={
                        <Checkbox
                          checked={sequentialProcessing}
                          onChange={handleChangeSequentialProcessing}
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label="Sequential Processing"
                    />}
                  </>
                )}
            </Box>
            <Box>
              {props.node?.typeName === NodeTypeName.BitmapRouterNode && (
                <>
                  <ConfigView
                    configurableEntity={ConfigurableEntity.BitmapRouterNode}
                    entityKey={props.node.name}
                    tenant={props.tenant}
                    parentKey={""}
                    userRole={props.userRole}
                  />
                  <RouteTable
                    routesChanged={routesChanged}
                    node={props.node as BitmapRouterNodeModel}
                    routes={routeTable}
                  />
                </>
              )}
            </Box>
            <Box>
              {props.node?.typeName === NodeTypeName.ExternalNode && (
                <>
                  <ConfigView
                    configurableEntity={ConfigurableEntity.ExternalNode}
                    entityKey={props.node.name}
                    tenant={props.tenant}
                    parentKey={(props.node as ExternalNodeModel).parent.name}
                    userRole={props.userRole}
                  />
                </>
              )}
            </Box>
          </div>
        </CardContent>
        <CardActions disableSpacing style={{ flex: "none", height: "50px" }}>
          <Grid container justifyContent="flex-end">
            {!props.isEditing ? (
              <IconButton
                onClick={() => {
                  props.setIsEditing(true);
                  setCanEditCode(true);
                }}
                aria-label="Save"
              >
                <EditIcon />
              </IconButton>
            ) : (
              <>
                <IconButton
                  onClick={() => {
                    cancelEdit();
                  }}
                >
                  <CancelIcon />
                </IconButton>
                <IconButton
                  disabled={!codeChanged && !functionChanged}
                  onClick={() => {
                    setOpenSaveDialog(true);
                    setCanEditCode(false);
                  }}
                  aria-label="Save"
                >
                  <SaveIcon />
                </IconButton>
              </>
            )}
            <IconButton
              onClick={() => {
                setOpen(true);
                props.setIsEditing(false);
              }}
              aria-label="share"
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </div>
  );
};

export default NodeDetail;
