import { ICredentials } from "interfaces/credentials";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class CredentialsModel implements ICredentials, GraphQLData {
  lastEvaluatedKey: string = '';
  clientId: string = '';
  password: string = '';
  username: string = '';
  userPoolId: string = '';

  public static create(clientId: string, password: string, username: string, userPoolId: string): CredentialsModel {
    const t = new CredentialsModel();
    t.clientId = clientId;
    t.password = password;
    t.username = username;
    t.userPoolId = userPoolId;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.GetCredentials && data.data.GetCredentials.credentials) {
      const dt = data.data.GetCredentials.credentials;
      this.clientId = dt.clientId;
      this.password = dt.password;
      this.userPoolId = dt.userPoolId;
      this.username = dt.username;

    } else {
      throw new Error('data structured passed in does not contain data, data.data.GetCredentials or data.data.GetCredentials.credentials');
    }
  }
}