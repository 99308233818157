import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class IAMPolicyModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  policy: string = '';

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.GetApp && data.data.GetApp.iamPolicy) {
      this.policy = data.data.GetApp.iamPolicy;
    } else {
      throw new Error('data && data.data && data.data.GetApp && data.data.GetApp.iamPolicy');
    }
  }
}