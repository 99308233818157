import { ValidationType } from "enumerations/validation-type";

export enum LogLevel {
	DEBUG = 'DEBUG',
	ERROR = 'ERROR',
	INFO = 'INFO',
	WARNING = 'WARNING'
}

export class ValidateStatic {

  public static validateFields(message: string, code: string, loggingLevel: LogLevel) { 
    return `
      Validate(
        message: ${JSON.stringify(message)},
        code: ${JSON.stringify(code)},
        loggingLevel: ${loggingLevel}
      ) {
      result
      stdout
      logs
      }
  `
  }

  public static typeSpread(type: ValidationType, message: string, code: string, loggingLevel: LogLevel) {
    if (type === ValidationType.Node) {
      return `
        ... on ProcessorNode { ${this.validateFields(message, code, loggingLevel)} }
        ... on CrossTenantSendingNode { ${this.validateFields(message, code, loggingLevel)} }
        ... on BitmapRouterNode { ${this.validateFields(message, code, loggingLevel)} }
      `
    } else if (type === ValidationType.Function || type === ValidationType.MessageType) {
      return `
        ${this.validateFields(message, code, loggingLevel)}
      `
    }
  }


  // public static validateFunction(type: ValidationType, message: string, code: string, loggingLevel: LogLevel) {
  //     return `
  //     query Get${type}($name: String!, $tenant: String!) {
  //       Get${type}(name: $name, tenant: $tenant) {
  //         ... on BitmapperFunction { ${this.typeSpread(type, message, code, loggingLevel)} }
  //         ... on ProcessorFunction { ${this.typeSpread(type, message, code, loggingLevel)} }
  //       }
  //     }
  //   `
  //   }

  public static validateNodeFunction(message: string, code: string, loggingLevel: LogLevel) {
    return `
    query GetNode($name: String!, $tenant: String!) {
      GetNode(name: $name, tenant: $tenant) {
        ...on ProcessorNode {
          Validate (
            message: ${JSON.stringify(message)},
            code: ${JSON.stringify(code)},
            loggingLevel: ${loggingLevel}
          ) {
            result
            stdout
            logs
          }
        }
        ... on BitmapRouterNode {
          Validate (
            message: ${JSON.stringify(message)},
            code: ${JSON.stringify(code)},
            loggingLevel: ${loggingLevel}
          ) {
            result
            stdout
            logs
          }
        }
        ... on CrossTenantSendingNode {
          Validate (
            message: ${JSON.stringify(message)},
            code: ${JSON.stringify(code)},
            loggingLevel: ${loggingLevel}
          ) {
            result
            stdout
            logs
          }
        }
      } 
    }
    `
  }
  public static validateFunction(message: string, code: string, loggingLevel: LogLevel) {
    return `
    query GetFunction($name: String!, $tenant: String!) {
      GetFunction(name: $name, tenant: $tenant) {
        ...on ProcessorFunction {
          Validate (
            message: ${JSON.stringify(message)},
            code: ${JSON.stringify(code)},
            loggingLevel: ${loggingLevel}
          ) {
            result
            stdout
            logs
          }
        }
        ... on BitmapperFunction {
          Validate (
            message: ${JSON.stringify(message)},
            code: ${JSON.stringify(code)},
            loggingLevel: ${loggingLevel}
          ) {
            result
            stdout
            logs
          }
        }
      } 
    }
    `
  }

  public static validateNodeApiAuthenticatorFunction(code: string, request: string, loggingLevel: LogLevel) {
    return `
      query GetNode($name: String!, $tenant: String!) {
        GetNode(name: $name, tenant: $tenant) {
          ... on WebhookNode {
            Validate(
              code: ${JSON.stringify(code ? code : null)},
              request: ${JSON.stringify(request)},
              loggingLevel: ${loggingLevel}
            ) {
              result
              stdout
              logs
            }
          }
        }
      }
    `
  }

  public static validateApiAuthenticatorFunction(code: string, request: string, loggingLevel: LogLevel) {
    return `
      query GetFunction($name: String!, $tenant: String!) {
        GetFunction(name: $name, tenant: $tenant) {
          ... on ApiAuthenticatorFunction {
            Validate(
              code: ${JSON.stringify(code ? code : null)},
              request: ${JSON.stringify(request)},
              loggingLevel: ${loggingLevel}
            ) {
              result
              stdout
              logs
            }
          }
        }
      }
    `
  }



  /*public static validateNode(message: string, code: string, loggingLevel: LogLevel) {
    return `
      query GetNode($name: String!, $tenant: String) {
        GetNode(name: $name, tenant: $tenant) {
          Validate(
              message: ${JSON.stringify(message)},
              code: ${JSON.stringify(code)},
              loggingLevel: ${loggingLevel}
            ) {
            result
            stdout
          }
        }
      }
    `
  }

  public static validateMessageType(message: string, code: string, loggingLevel: LogLevel) {
    return `
      query GetMessageType($name: String!, $tenant: String) {
        GetMessageType(name: $name, tenant: $tenant) {
          Validate(
              message: ${JSON.stringify(message)},
              code: ${JSON.stringify(code)},
              loggingLevel: ${loggingLevel}
            ) {
            result
            stdout
          }
        }
      }
    `
  }*/
}
