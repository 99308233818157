import { IDeleteEntity } from "interfaces/delete-entity";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class DeleteNodeModel implements IDeleteEntity, GraphQLData {
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  deleted: boolean = false;

  public static create(
    deleted: boolean) : DeleteNodeModel {
    const t = new DeleteNodeModel();
    t.deleted = deleted;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    // if (data && data.data && data.data.GetNode && data.data.GetNode.Delete) {
    //   this.deleted = data.data.GetNode.Delete;
    // } else {
    //   throw new Error('data structured passed in does not contain data, data.data, data.data.GetNode or data.data.GetNode.Delete');
    // }
  }
}