import { IAtBy } from "interfaces/at-by";
import { IEchoEdge } from "interfaces/echo-edge";
import { IEchoNode } from "interfaces/echo-node";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { EdgeModel } from "models/edge/edge-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { WebSubHubNodeModel } from "./websub-hub-node";

export class WebSubSubscriptionNodeModel implements GraphQLData {
    lastEvaluatedKey: string = '';

    callback: string = '';
    created: IAtBy = new AtByModel('', '');
    description: string = '';
    subscriber: string = '';
    expiration: Date = new Date();
    hub: IEchoNode = new WebSubHubNodeModel();
    lastModified: IAtBy = new AtByModel('', '');
    name: string = '';
    // receiveEdge: IEchoEdge = new EdgeModel();
    // receiveMessageType: IMessageType = new MessageTypeModel();
    secured: boolean = false;
    stopped: boolean = false;
    topic: string = '';
    tenant: ITenant = new TenantModel();
    deleted: boolean = false;
    __typeName: string = '';

    public static create(
        name: string,
        topic: string,
        subscriber: string,
        description: string,
        typeName: string,
        expiration: Date,
        hub: IEchoNode,
        // receiveMessageType: IMessageType,
        // receiveEdge: IEchoEdge,
        secured: boolean,
        stopped: boolean,
        callback: string,
    ): WebSubSubscriptionNodeModel {
        const t = new WebSubSubscriptionNodeModel();
        t.name = name;
        t.topic = topic;
        t.subscriber = subscriber;
        t.description = description;
        t.__typeName = typeName;
        t.expiration = expiration;
        t.hub = hub;
        // t.receiveMessageType = receiveMessageType;
        // t.receiveEdge = receiveEdge;
        t.secured = secured;
        t.stopped = stopped;
        t.callback = callback;
        return t;
    }

    async hydrate(data: any, tenant: ITenant): Promise<void> {
        console.log(data);
    }
}