import { PingStatus } from "enumerations/ping-status";

export class ManagedInstanceModel {
    activationId: string;
    hostname: string;
    instanceId: string;
    // ipAddress: AWSIPAddress,
    ipAddress: string;
    // lastPingDateTime: AWSDateTime,
    lastPingDateTime: string;
    pingStatus: PingStatus;
    // registrationDate: AWSDateTime
    registrationDate: Date;

    constructor (activationId: string, hostname: string, instanceId: string, ipAddress: string, lastPingDateTime: string, pingStatus: PingStatus, registrationDate: Date) {
        this.activationId = activationId;
        this.hostname = hostname;
        this.instanceId = instanceId;
        // this.ipAddress = ipAddress;
        this.ipAddress = ipAddress;
        // this.lastPingDateTime = lastPingDateTime;
        this.lastPingDateTime = lastPingDateTime;
        this.pingStatus = pingStatus;
        // this.registrationDate = registrationDate;
        this.registrationDate = registrationDate;
    }
}
