import { Protocol } from "enumerations/protocol";

export class PortRequirement {
  containerPort: number;
  description: string;
  protocol: Protocol;

  constructor(containerPort: number, description: string, protocol: Protocol) {
    this.containerPort = containerPort;
    this.description = description;
    this.protocol = protocol;
  }
}