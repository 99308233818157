import { NodeTypeName } from "enumerations/nodetype-name";
import { IAtBy } from "interfaces/at-by";
import { IEchoNode } from "interfaces/echo-node";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { TenantModel } from "models/tenant/tenant-model";
import { WebSubHubNodeModel } from "./websub-hub-node";
import { WebSubSubscriptionNodeModel } from "./websub-subscription-node";

export class WebSubSubscriptionNodeListModel implements GraphQLData {

    subs: Array<IEchoNode> = [];    
    lastEvaluatedKey: string = '';

    async hydrate(data: any, tenant: ITenant): Promise<void> {
        console.log("data", data);
        if (data && data.data && data.data.GetNode && data.data.GetNode.ListSubscriptions) {
            const dt = data.data.GetNode.ListSubscriptions;
            // const messageTypes = await new MessageTypeCache().getMessageTypes(tenant);
            this.lastEvaluatedKey = dt.lastEvaluatedKey;
            this.subs = dt.echos.map(
                (o: {
                    callback: string;
                    created: IAtBy;
                    description: string;
                    subscriber: string;
                    expiration: Date;
                    hub: WebSubHubNodeModel;
                    lastModified: IAtBy;
                    name: string;
                    // receiveEdge:{
                    //     description: string;
                    //     source: { name: string };
                    //     target: { name: string };
                    //     __typename: string;            
                    // };
                    // receiveMessageType: { name: string };
                    secured: boolean;
                    stopped: boolean;
                    topic: string;
                    tenant:TenantModel;
                    deleted: boolean;
                    __typeName: string;
                }) => {
                    // let receiveMessageType = new MessageTypeModel();

                    // const receiveEdge = new EdgeModel();

                    // if (o.receiveMessageType) {
                    //     receiveMessageType = messageTypes.find(
                    //       (t) => t.name === o.receiveMessageType.name
                    //     ) as MessageTypeModel;
                    //   }

                    return WebSubSubscriptionNodeModel.create (
                        o.name,
                        o.topic,
                        o.subscriber,
                        o.description,
                        o.__typeName,
                        o.expiration,
                        o.hub,
                        // receiveMessageType,
                        // receiveEdge,
                        o.secured,
                        o.stopped,
                        o.callback,
                    )
                }
            )
        }
    }
}