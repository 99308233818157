import React, { useState, useEffect } from "react";
import { Button, CircularProgress, TextField } from "@material-ui/core";
import { Box } from '@mui/material';
import { ITenant } from "interfaces/tenant";
import { LogLevel, ValidateStatic } from "graphql/validate-static";
import { ValidateFunctionModel } from "models/validate-function-model";
import { GraphQLHelper } from "utilities/graphql-helper";
import { ValidationType } from "enumerations/validation-type";
import JSONEditor from "./JSONEditor";
import { IEchoNode } from "interfaces/echo-node";


interface Props {
  code: string,
  tenant: ITenant,
  node?: IEchoNode | undefined,
  functionName: string,
  validationType: ValidationType,
}

const FunctionTester: React.FC<Props> = (props) => {
  const [request, setRequest] = useState("");
  const [executionOutput, setExecutionOutput] = useState<string>('');
  const [executionError, setExecutionError] = useState(false);
  const [executionStdout, setExecutionStdout] = useState('');
  const [executionLogs, setExecutionLogs] = useState('');
  const [isExecuting, setIsExecuting] = useState(false);

  useEffect(() => {
    const webhookMessage = {
        "path": "/",
        "headers": {
            "Authorization": "Bearer 12345",
            "Cookie": "yummy_cookie=choco; tasty_cookie=strawberry"
        },
        "http_version": "1.1",
        "method": "GET",
        "client": ["127.0.0.1", 443],
        "body": "",
        "query_string": {
            "param1": "foo",
            "param2": "bar"
        }
    }
    setRequest(JSON.stringify(webhookMessage, null, 2));
}, [props.functionName, props.code])

  const onTestFunction = async (cd: string) => {
    if (cd) {
      try {
        setIsExecuting(true);
        setExecutionError(true);
        setExecutionStdout('Executing...');
        setExecutionOutput('');
        setExecutionLogs('');
        if (props.node) {
          const query = ValidateStatic.validateNodeApiAuthenticatorFunction(props.code, request, LogLevel.INFO);
          const params = {
            name: props.node?.name,
            tenant: props.tenant.name
          }
          const p = await GraphQLHelper.execute<ValidateFunctionModel>(
            query,
            params,
            ValidateFunctionModel);
          if (p.error) {
            console.log(p.errorMessage);
            setExecutionError(true);
            setExecutionOutput(p.errorMessage);
            setExecutionStdout('Failed...');
            setExecutionLogs('Failed...')
          } else {
            const result = p.result as ValidateFunctionModel;
            setExecutionStdout(JSON.stringify(result.stdout).replace(/(\\n)/g, "\n").replace(/"/g, ""));
            setExecutionOutput(JSON.stringify(JSON.parse(result.result), null, 2));
            setExecutionLogs(JSON.stringify(result.logs).replace(/(\\n)/g, "\n").replace(/"/g, ""));
          }
        } else {
          const query = ValidateStatic.validateApiAuthenticatorFunction(props.code, request, LogLevel.INFO);
          const params = {
            name: props.functionName,
            tenant: props.tenant.name
          }
          const p = await GraphQLHelper.execute<ValidateFunctionModel>(
            query,
            params,
            ValidateFunctionModel);
          if (p.error) {
            console.log(p.errorMessage);
            setExecutionError(true);
            setExecutionOutput(p.errorMessage);
            setExecutionStdout('Failed...');
            setExecutionLogs('Failed...')
          } else {
            const result = p.result as ValidateFunctionModel;
            setExecutionStdout(JSON.stringify(result.stdout).replace(/(\\n)/g, "\n").replace(/"/g, ""));
            setExecutionOutput(JSON.stringify(JSON.parse(result.result), null, 2));
            setExecutionLogs(JSON.stringify(result.logs).replace(/(\\n)/g, "\n").replace(/"/g, ""));
          }
        }
      } catch (err) {
        setExecutionError(true);
        setExecutionOutput(JSON.stringify(err));
        setExecutionLogs(JSON.stringify(err))
        console.log('Can\'t validate function', err);
        console.log(JSON.stringify(err));
      } finally {
        setIsExecuting(false);
      }
    }
  }

  const onCodeChange = (code: string) => {
    setRequest(code);
  }

  return (
    <Box>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}> Request </legend>
        <div style={{ marginTop: '5px' }}>
          <JSONEditor code={request} onCodeChange={onCodeChange} isExecuting={isExecuting}/>
        </div>
        </fieldset>
      <TextField
        disabled={true}
        error={executionError}
        variant="outlined"
        multiline
        rows={5}
        value={executionOutput}
        margin="dense"
        id="messsageArg"
        label="Returns"
        fullWidth
      />
      <TextField
        disabled={true}
        variant="outlined"
        multiline
        rows={5}
        value={executionStdout}
        margin="dense"
        id="messsageArg"
        label="Stdout"
        fullWidth
      />
      <TextField
        disabled={true}
        variant="outlined"
        multiline
        rows={5}
        value={executionLogs}
        margin="dense"
        id="logs"
        label="Logs"
        fullWidth
      />
      <Button
        variant="contained"
        onClick={() => { onTestFunction(props.code); }}
        color="primary">
          Test { (isExecuting) && <CircularProgress size={24} style={{ color: '#F5F5F5', position: 'absolute', top: '50%', left: '50%', marginLeft: '-12px', marginTop: '-12px' }} />}
      </Button>
    </Box>
  );
};

export default FunctionTester;
