import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { PortRequirement } from 'models/managed/port-requirement';
import PortReqForm from './PortReqForm';

interface Props {
    portReq: PortRequirement,
    updatePortReq: (newPortReq: PortRequirement) => void,
    deletePortReq: (portReq: PortRequirement) => void,
    cancelEdit: () => void,
    setEdits: React.Dispatch<React.SetStateAction<PortRequirement>>,
    addPortReq: (newPortReq: PortRequirement) => void,
    setSelectedPortReq: React.Dispatch<React.SetStateAction<PortRequirement>>
}

const PortReq: React.FC<Props> = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [portRequirement, setPortRequirement] = useState(new PortRequirement(props.portReq.containerPort, props.portReq.description, props.portReq.protocol));

    const editPortReq = () => {
        props.setSelectedPortReq(props.portReq);
        setIsEditing(true);
    }

    const handleDelete = () => {
        // props.setSelectedPortReq(props.portReq);
        props.deletePortReq(props.portReq);
    }

    return (
        <>
        {!isEditing && props.portReq ? 
        <>
            <TableCell style={{ width: '150px' }} onClick={() => editPortReq()}>{props.portReq.containerPort ? props.portReq.containerPort : "<change me>"}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editPortReq()}>{props.portReq.protocol}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editPortReq()}>{props.portReq.description ? props.portReq.description : "<change me>"}</TableCell>
            <TableCell style={{ width: '100px' }}>
            <IconButton size="small" onClick={handleDelete}>
                <DeleteIcon />
            </IconButton>
            </TableCell>
        </>
        :
            <PortReqForm 
                portReq={portRequirement}
                cancelEdit={props.cancelEdit}
                updatePortReq={props.updatePortReq}
                toggleEdit={setIsEditing}
                setEdits={props.setEdits}
                isEditing={isEditing}
                addPortReq={props.addPortReq}
            />
          
        }
    </>

    );
}
export default PortReq;