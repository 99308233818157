import { Protocol } from "enumerations/protocol";

export class Port {
  containerPort: number;
  description: string;
  protocol: Protocol;
	hostAddress: string;
	hostPort: number;

  constructor(containerPort: number, description: string, protocol: Protocol, hostAddress: string, hostPort: number) {
    this.containerPort = containerPort;
    this.description = description;
    this.protocol = protocol;
    this.hostAddress = hostAddress;
    this.hostPort = hostPort;
  }
}