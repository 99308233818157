import React from "react";

interface Props {
  title: string,
  description: string
}

const DisplayField: React.FC<Props> = (props) => {
  return (
    <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
      <legend style={{ fontSize: '12px' }}> {props.title} </legend>
      {props.description}
    </fieldset>
  )
}

export default DisplayField