import React, { useState } from 'react';
import { FormControl, IconButton, InputLabel, Select, TableCell, TextField } from '@material-ui/core';
import { Protocol } from 'enumerations/protocol';
import { Port } from 'models/managed/port';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import { prototype } from 'stream';


interface Props {
    port: Port,
    updatePort: (oldPort: Port, newPort: Port) => void,
    cancelEdit: () => void,
    toggleEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setEditedValue: React.Dispatch<React.SetStateAction<Port>>,
    isEditing?: boolean,
    editedValue: Port
}

const ManagedNodePortForm: React.FC<Props> = (props) => {

    const [inputs, setInputs] = useState(new Port(props.editedValue.containerPort, props.editedValue.description, props.editedValue.protocol, props.editedValue.hostAddress, props.editedValue.hostPort));
    
    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setInputs(prevInputs => ({...prevInputs, [name]: value}));
    }

    const handleSubmit = () => {
        if(props.isEditing){
        props.updatePort(props.port, props.editedValue);
        setInputs(new Port(0, '', Protocol.tcp, '', 0));
        props?.toggleEdit(false); 
        }
    }

    const onCancel = () => {
        if(props.isEditing){
            props.cancelEdit();
            props.toggleEdit(false);
        } 
    }
    
    return(
        <>
            <TableCell style={{ width: '150px' }} >{props.port.containerPort}</TableCell>
            <TableCell style={{ width: '200px' }} >{props.port.protocol}</TableCell>
            <TableCell style={{ width: '200px' }} >{props.port.description}</TableCell>

        <TableCell style={{ width: '150px' }}>
            <TextField
                required
                autoFocus={false}
                name="hostAddress"
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                fullWidth
                value={inputs.hostAddress}
                margin="dense"
                id="messsageArg"
                onChange={(event: any) => {props.setEditedValue(new Port(props.editedValue.containerPort, props.editedValue.description, props.editedValue.protocol, event.target.value, props.editedValue.hostPort)); handleChange(event); }}

            />
        </TableCell>
        <TableCell style={{ width: '200px' }}>
            <TextField
                required
                name="hostPort"
                autoFocus={false}
                fullWidth
                value={inputs.hostPort}
                margin="dense"
                id="messsageArg"
                onChange={(event: any) => {props.setEditedValue(new Port(props.editedValue.containerPort, props.editedValue.description, props.editedValue.protocol, props.editedValue.hostAddress, parseInt(event.target.value))); handleChange(event); }}
            />
        </TableCell>
        <TableCell style={{ width: '100px' }}>
            <IconButton size="small" onClick={() => handleSubmit()}>
                <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => { onCancel(); }}>
                <CancelIcon />
            </IconButton>
        </TableCell>
</>
    );
}

export default ManagedNodePortForm;