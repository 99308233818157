import { IAtBy } from "interfaces/at-by";
import { IEchoEdge } from "interfaces/echo-edge";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageCounts } from "interfaces/message-counts";
import { IMessageType } from "interfaces/message-type";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";

export class MessageCountModel implements IMessageCounts {
  approximateNumberOfMessages: number = 0;
  approximateNumberOfMessagesDelayed: number = 0;
  approximateNumberOfMessagesNotVisible: number = 0;

  constructor(approximateNumberOfMessages: number, approximateNumberOfMessagesDelayed: number, approximateNumberOfMessagesNotVisible: number) {
    this.approximateNumberOfMessages = approximateNumberOfMessages;
    this.approximateNumberOfMessagesDelayed = approximateNumberOfMessagesDelayed;
    this.approximateNumberOfMessagesNotVisible = approximateNumberOfMessagesNotVisible;
  }
}

export class EdgeModel implements IEchoEdge, GraphQLData {
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  edgeUniqueKey: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  maxReceiveCount: number = 0;
  messageCounts: IMessageCounts = new MessageCountModel(0, 0, 0);
  messageType: IMessageType = new MessageTypeModel();
  name: string = ''
  source: string = '';
  target: string = '';
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  queue: string = '';

  deleted: boolean = false;
  lastEvaluatedKey: string = '';

  public static create(source: string, target: string, typeName: string, description: string, maxReceiveCount: number, queue: string, messageCounts: IMessageCounts): EdgeModel {
    const t = new EdgeModel();
    t.source = source;
    t.target = target;
    t.typeName = typeName;
    t.description = description;
    t.edgeUniqueKey = `${source}~${target}`;
    t.maxReceiveCount = maxReceiveCount;
    t.queue = queue;
    t.messageCounts = messageCounts;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log("Edge Model", data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}