import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { MountRequirement } from "./mount-requirement";
import { PortRequirement } from "./port-requirement";
import { IEchoEdge } from "interfaces/echo-edge";

/*
type ManagedNodeType @aws_cognito_user_pools {
  healthcheck: Healthcheck
  hostMountRequirements: [HostMountRequirement!]
  portMappingRequirements: [PortMappingRequirement!]
  readme: String
}*/
export class ManagedNodeTypeModel implements IEchoNode, GraphQLData {
  receiveEdges?: IEchoEdge[] | undefined;
  sendEdges: IEchoEdge[] = new Array<IEchoEdge>();
  configTemplate: string = '';
  created: IAtBy = new AtByModel('', '');
  deleted: boolean = false;
  description: string = '';
  mountRequirements: Array<MountRequirement> = new Array<MountRequirement>();
  imageUri: string = '';
  lastEvaluatedKey: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = '';
  portRequirements: Array<PortRequirement> = new Array<PortRequirement>();
  readme: string = '';
  system: boolean = false;
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  receiveMessageType: IMessageType | null = new MessageTypeModel();
  sendMessageType: IMessageType | null = new MessageTypeModel();

  public static create(
    configTemplate: string,
    created: IAtBy,
    description: string,
    mountRequirements: Array<MountRequirement>,
    imageUri: string,
    lastModified: IAtBy,
    name: string,
    portRequirements: Array<PortRequirement>,
    readme: string,
    tenant: ITenant,
    system: boolean,
    typeName: string,
    receiveMessageType: IMessageType | null,
    sendMessageType: IMessageType | null,
    ): ManagedNodeTypeModel {
    const t = new ManagedNodeTypeModel();
    t.configTemplate = configTemplate;
    t.created = created;
    t.description = description;
    t.mountRequirements = mountRequirements;
    t.imageUri = imageUri;
    t.lastModified = lastModified;
    t.name = name;
    t.portRequirements = portRequirements;
    t.readme = readme ? readme : '';
    t.tenant = tenant;
    t.system = system;
    t.typeName = typeName;
    t.receiveMessageType = receiveMessageType;
    t.sendMessageType = sendMessageType;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  //   let md = null;
  //   if (data && data.data && data.data.CreateManagedNodeType) {
  //     md = data.data.CreateManagedNodeType
  //   } else if (data && data.data && data.data.GetManagedNodeType && data.data.GetManagedNodeType.Update) {
  //     md = data.data.GetManagedNodeType.Update
  //   } else if (data && data.data && data.data.GetManagedNodeType && data.data.GetManagedNodeType) {
  //     md = data.data.GetManagedNodeType
  //   } else {
  //     throw new Error('data, data.data or data.data.CreateManagedNodeType, GetManagedNodeType, GetManagedNodeType.Update empty');
  //   }

  //   if (md) {
  //     this.configTemplate = md.configTemplate;
  //     this.created = new AtByModel(md.created.at, md.created.by);
  //     this.description = md.description;
  //     this.mountRequirements = md.mountRequirements;
  //     this.imageUri = md.imageUri;
  //     this.lastModified = new AtByModel(md.lastModified.at, md.lastModified.by);
  //     this.name = md.name;
  //     this.portRequirements = md.portRequirements;
  //     this.readme = md.readme;
  //     this.receiveMessageType = md.receiveMessageType;
  //     this.sendMessageType = md.sendMessageType;
  //     this.system = md.system;
  //     this.typeName = md.typeName;
  //     this.tenant = TenantModel.create(md.tenant.name, md.tenant.description, md.tenant.region);
  //   }
   }
}