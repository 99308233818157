import { API, graphqlOperation } from "aws-amplify";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { toast } from 'react-toastify';
import { loadingService } from 'services/common';

export class GraphQLResult {
  error: boolean = false;
  errorMessage: string = '';
  result: GraphQLData;

  constructor(result: GraphQLData, error: boolean, errorMessage: string ) {
    this.error = error;
    this.errorMessage = errorMessage;
    this.result = result;
 }
}

export class GraphQLHelper {
  public static async execute<T extends GraphQLData>(query: string, params: any, TCreator: { new (): T; }, tenant?: ITenant) : Promise<GraphQLResult> {
    const p = new Promise<GraphQLResult>((resolve, reject) => {
      async function runQuery() {
        try {
          loadingService.sendLoadingStatus(true);
          const result = await API.graphql(graphqlOperation(query, params));
          const newInstance = new TCreator();
          await newInstance.hydrate(result, tenant);
          const newGrahpQLResult = new GraphQLResult(newInstance, false, '');
          resolve(newGrahpQLResult);
        } catch (err: any) {
          console.log(`failed with error: ${err} at query: ${query}`);
          if (err && err.errors) {
            const ss = err.errors.map((o: { errorType: any, message: any; }) => `${o.errorType}\r\n${o.message}`).join('\r\n');
            console.log(err)
            toast.error(ss);
            const pew = new GraphQLResult(new TCreator(), true, ss);
            // using resolve so we don't have to try / catch and cast the error whereever it's used
            resolve(pew);
          } else {
            const pew = new GraphQLResult(new TCreator(), true, err);
            console.log("graphql helper error", err)
            toast.error(`Javascript error:\r\n${err}`);
            // using resolve so we don't have to try / catch and cast the error whereever it's used
            resolve(pew);
          }
        } finally {
          loadingService.clearLoading();
        }
      }
      runQuery();
    });
    return p;
  }
}

