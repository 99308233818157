import React, {useEffect, useState} from 'react';
import { FormControl, IconButton, InputLabel, Select, TableCell, TextField } from '@material-ui/core';
import { Protocol } from 'enumerations/protocol';
import { PortRequirement } from 'models/managed/port-requirement';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';


interface Props {
    portReq: PortRequirement,
    updatePortReq: (newPortReq: PortRequirement) => void,
    cancelEdit: () => void,
    toggleEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setEdits: React.Dispatch<React.SetStateAction<PortRequirement>>,
    isEditing?: boolean,
    addPortReq: (newPortReq: PortRequirement) => void
}

const PortReqForm: React.FC<Props> = (props) => {

    const [inputs, setInputs] = useState(new PortRequirement(props.portReq.containerPort || 0, props.portReq.description || "", props.portReq.protocol || Protocol.tcp));

    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setInputs(prevInputs => ({...prevInputs, [name]: value}));
    }

    const handleSubmit = () => {
        if(props.isEditing){
        props.setEdits(inputs);
        props.updatePortReq(inputs);
        setInputs(new PortRequirement(0, '', Protocol.tcp));
        props?.toggleEdit(false); 
        } else {
            props.addPortReq(inputs);
        }
    }

    const onCancel = () => {
        if(props.isEditing){
            props.toggleEdit(false);
        } 
    }
    
    return(
        <>
        <TableCell style={{ width: '150px' }}>
            <TextField
                required
                autoFocus={false}
                name="containerPort"
                fullWidth
                value={inputs.containerPort}
                margin="dense"
                id="messsageArg"
                onChange={handleChange}
                // onChange={(event: any) => { setEditedValue(new PortRequirement(parseInt(event.target.value), inputs.description, editedValue.protocol)); }}
            />
        </TableCell>
        <TableCell>
            <FormControl variant="filled" style={{ width: '300px' }}>
                <InputLabel htmlFor="filled-age-native-simple">Protocol</InputLabel>
                <Select
                    native
                    name="protocol"
                    value={inputs.protocol}
                    onChange={handleChange}
                    // onChange={(event: any) => {setEditedValue(new PortRequirement(editedValue.containerPort, editedValue.description, event.target.value ))}}
                    inputProps={{
                        id: "filled-age-native-simple"
                    }}
                    >
                    <option aria-label="None" value="" />
                    <option value={Protocol.tcp}>{Protocol.tcp}</option>
                    <option value={Protocol.sctp}>{Protocol.sctp}</option>
                    <option value={Protocol.udp}>{Protocol.udp}</option>
                </Select>
            </FormControl>
        </TableCell>
        <TableCell style={{ width: '200px' }}>
            <TextField
                required
                name="description"
                autoFocus={false}
                fullWidth
                value={inputs.description}
                margin="dense"
                id="messsageArg"
                onChange={handleChange}
                // onChange={(event: any) => { setEditedValue(new PortRequirement(editedValue.containerPort, event.target.value, editedValue.protocol)); }}
            />
        </TableCell>
        <TableCell style={{ width: '100px' }}>
            <IconButton size="small" onClick={() => handleSubmit()}>
                <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => { onCancel(); }}>
                <CancelIcon />
            </IconButton>
        </TableCell>
        </>
    );
}

export default PortReqForm;