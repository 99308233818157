import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, Select } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { IMessageType } from 'interfaces/message-type';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { EchoFunctionType, IEchoFunction } from 'interfaces/echo-function';
import EchoDialog from 'components/EchoDialog';
import JSONEditor from 'components/JSONEditor';
import { ManagedNodeTypeModel } from 'models/managed/managed-node-type';
import { PortRequirement } from 'models/managed/port-requirement';
import { MountRequirement } from 'models/managed/mount-requirement';
import AddMNTPorts from './AddMNTPorts';
import AddMNTMounts from './AddMNTMounts';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  messageTypes: Array<IMessageType>,
  tenant: ITenant,
  functionList: Array<IEchoFunction>,
}

const AddManagedNodeType: React.FC<Props> = (props) => {

  const defaultFunctionType = '<built-in system function>';
  const [nodeTypeName, setNodeTypeName] = useState('');
  const [description, setDescription] = useState('');
  const [imageUri, setImageUri] = useState('');
  const [nodeTypeNameValid, setNodeTypeNameValid] = useState(false);
  const [receiveMessageType, setReceiveMessageType] = useState(null);
  const [sendMessageType, setSendMessageType] = useState(null);
  const [config, setConfig] = useState('{}');
  const [portRequirements, setPortRequirements] = useState<Array<PortRequirement>>(new Array<PortRequirement>());
  const [mountRequirements, setMountRequirements] = useState<Array<MountRequirement>>(new Array<MountRequirement>());
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddManagedNodeType = async () => {
    try {
      setIsLoading(true);      
      setInfoMessage('');
      setErrorMessage('');
      setSuccessMessage('');
      let createdNode = {};
      const params = {
        description: description,
        name: nodeTypeName,
        imageUri: imageUri,
        tenant: props.tenant.name,
        configTemplate: config,
        receiveMessageType: receiveMessageType,
        sendMessageType: sendMessageType,
        portRequirements: portRequirements,
        mountRequirements: mountRequirements
      };
      // public.ecr.aws/micahhausler/alpine:3.14.0
      const p = await GraphQLHelper.execute<ManagedNodeTypeModel>(MutationsStatic.createManagedNodeType, params, ManagedNodeTypeModel);
      if (!p.error) {
        setSuccessMessage(`Managed Node Type ${nodeTypeName} created.`);
      } else {
        setErrorMessage(p.errorMessage);
      }
      console.log(createdNode);
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log(JSON.stringify(err));
      console.log('Can\'t create node', err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNameChange = (event: any) => {
    setNodeTypeName(event.target.value);
    setNodeTypeNameValid(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false);
  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const handleURIChange = (event: any) => {
    setImageUri(event.target.value);
  }

  const handleConfigChange = (code: string) => {
    setConfig(code);
  }

  const receiveMessageTypeChange = (event: any) => {
    setReceiveMessageType(event.target.value);
  };

  const sendMessageTypeChange = (event: any) => {
    setSendMessageType(event.target.value);
  };

  const onShowHelp = (url: string) => {
    window.open(url, '_blank');
  }

  const contentText = () => {
    return '';
  }

  const isValid = (): boolean => {
    return nodeTypeName.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false;
    // return (receiveMessageType || sendMessageType) &&  nodeTypeName.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false;
  }

  const getFunctionsFiltered = () => {
    if (props.functionList) {
      const filtered = props.functionList.filter(o => (o.type === EchoFunctionType.processor && o.argumentMessageType?.name === receiveMessageType && o.returnMessageType?.name === sendMessageType));
      return filtered
    }
    return [] as Array<IEchoFunction>;
  }

  const addPortRequirements = (portReqs: Array<PortRequirement>) => {
    setPortRequirements(portReqs);
  }

  const addMountRequirements = (mountReqs: Array<MountRequirement>) => {
    setMountRequirements(mountReqs);
  }

  return (
    <>
      <EchoDialog
        onCancel={props.onCancel}
        size="xl"
        onOk={props.onAdd}
        onSave={async () => { await handleAddManagedNodeType(); }}
        isValid={isValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Add Managed Node Type"
        contentText={contentText()}
        open={true}
        spinner={isLoading}
      >
        <TextField
          error={!nodeTypeNameValid}
          helperText="Name must be 3 characters in length and can contain special characters (- _ . or :). "
          variant="outlined"
          required
          autoFocus={false}
          margin="dense"
          // trans Node is processor Node
          id="transNodeName"
          label="Managed Node Type Name"
          fullWidth
          onChange={handleNameChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          required
          autoFocus={false}
          // trans Node is processor Node
          id="transDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          required
          autoFocus={false}
          // trans Node is processor Node
          id="transDescriptionName"
          label="Image URI"
          fullWidth
          onChange={handleURIChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', width: '520px' }}>
          <legend style={{ fontSize: '12px' }}> Config Template </legend>
          <JSONEditor code={config} onCodeChange={handleConfigChange} ixExecuting={(successMessage && successMessage.length > 0 ? true : false) || isLoading} />
        </fieldset>
        <AddMNTPorts 
          portRequirements={undefined}
          ports={undefined}
          addPortRequirements={addPortRequirements}
        />
        <AddMNTMounts 
          mounts={undefined} 
          addMountRequirements={addMountRequirements}
          mountRequirements={undefined}        
        />
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}>
            <InputLabel htmlFor="filled-age-native-simple">Receive Message Type</InputLabel>
            <Select
              native
              value={receiveMessageType}
              onChange={receiveMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}>
            <InputLabel htmlFor="filled-age-native-simple">Send Message Type</InputLabel>
            <Select
              native
              value={sendMessageType}
              onChange={sendMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
      </EchoDialog>
    </>
  );
}

export default AddManagedNodeType;