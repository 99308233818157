import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import LockIcon from '@material-ui/icons/Lock';

import { Auth } from 'aws-amplify';

import routes from 'variables/routes';
import { IMenuRoute } from 'interfaces/menu-route';
import { authService } from 'services/common';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

interface Props {
  show: boolean;
  closed: () => void;
  onMenuItemClicked(value: IMenuRoute): void;
}

const EchoMenu: React.FC<Props> = (prop) => {
  const classes = useStyles();
  const anchor = 'left';
  const [state, setState] = useState({
    top: false,
    left: prop.show,
    bottom: false,
    right: false,
  });

  const filteredRoutes = routes.filter(o => o.display);

  const signOut = async () => {
      try {
          await Auth.signOut(/*{ global: true, }*/);
          console.log('signout');
          authService.clearData();
      } catch (error) {
          console.log('error signing out: ', error);
      }
  }

  useEffect(() => {
    setState({ ...state, left: prop.show });
  }, [prop.show]);

  const toggleDrawer = (anchor: any, open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    console.log(event);
    prop.closed();
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: any) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {filteredRoutes.map((route, index) => (
          <ListItem button key={route.name} onClick={() => {prop.onMenuItemClicked(route);}}>
            <ListItemIcon><InboxIcon /></ListItemIcon>
            <ListItemText primary={route.name} />
          </ListItem>
        ))}
      </List>
      <Divider />
      <List>
        {['Logout'].map((text, index) => (
          <ListItem button key={text} onClick={async () => { await signOut(); }}>
            <ListItemIcon><LockIcon /></ListItemIcon>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <div>
      <React.Fragment key={anchor}>
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}

export default EchoMenu;