import React from 'react';
import { ITenant } from 'interfaces/tenant';
import DisplayField from 'components/node/DisplayField';
import { CrossTenantSendingAppModel } from 'models/app/cross-tenant-sending-app';
import { TextField } from '@material-ui/core';

interface Props {
  app: CrossTenantSendingAppModel,
  tenant: ITenant,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  description: string,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  onDescriptionChange: (e: any) => void
}

const CrossTenantSendingApp: React.FC<Props> = (props) => {
    
  return (
    <div>
      <TextField
        onChange={(e: any) => {
          props.onDescriptionChange(e);
        }}
        variant="outlined"
        value={props.description}
        margin="dense"
        id="description"
        label="Description"
        fullWidth
        InputProps={{
          readOnly: !props.isEditing,
        }}
      />
      <DisplayField title="Receiving App" description={props.app?.receivingApp ? props.app.receivingApp : '<no receiving app>'} />
      <DisplayField title="Receiving Tenant" description={props.app?.receivingTenant ? props.app.receivingTenant : '<no receiving tenant>'} />
    </div>
  )
}

export default CrossTenantSendingApp;