import { ITenant } from 'interfaces/tenant';
import { QueriesStatic } from 'graphql/queries-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IDictionary } from 'interfaces/dictionary';
import { ManagedNodeTypeModel } from 'models/managed/managed-node-type';
import { ManagedNodeTypeListModel } from 'models/managed/managed-node-type-list';

let instance: ManagedNodeTypeCache | null = null;

export class ManagedNodeTypeCache {
  private managedNodeTypes: IDictionary<Array<ManagedNodeTypeModel>> = {};

  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  public clearCache(tenant: ITenant) {
    this.managedNodeTypes[tenant.name] = new Array<ManagedNodeTypeModel>();
  }

  public async getManagedNodeTypes(tenant: ITenant) : Promise<Array<ManagedNodeTypeModel>> {
    if (!this.managedNodeTypes[tenant.name] || this.managedNodeTypes[tenant.name].length === 0) {
      let ret = [] as Array<ManagedNodeTypeModel>;
      let lastKey = {};
      let iterations = 0;
      while(true && iterations < 10) {
        const params = {
          tenant: tenant.name,
          exclusiveStartKey: JSON.stringify(lastKey)
        }
        const p = await GraphQLHelper.execute<ManagedNodeTypeListModel>(QueriesStatic.listManagedNodeType, params, ManagedNodeTypeListModel, tenant);
        if (p.error) {
          console.log(p.errorMessage);
          break;
        } else {
          const fns = p.result as ManagedNodeTypeListModel;
          ret.push(...fns.managedNodes);
          if (fns.lastEvaluatedKey) {
            // run the query again and aggergate result
            lastKey = JSON.parse(fns.lastEvaluatedKey);
          } else {
            break;
          }
        }
      }
      this.managedNodeTypes[tenant.name] = new Array<ManagedNodeTypeModel>();
      this.managedNodeTypes[tenant.name].push(...ret);
    }

    return this.managedNodeTypes[tenant.name]
  }
}
