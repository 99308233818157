import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { EdgeModel } from 'models/edge/edge-model';
import { IEchoEdge } from 'interfaces/echo-edge';

interface Props {
  edge: IEchoEdge | undefined,
  tenant: ITenant,
  onCancel: () => void,
  onCloseDialog: () => void,
  isPurging: boolean
}

const PurgeEdge: React.FC<Props> = (props) => {

  const [errorPurging, setErrorPurging] = useState('');
  const [infoPurging, setInfoPurging] = useState('');
  const [successPurging, setSuccessPurging] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const clearState = () => {
    setErrorPurging('');
    setInfoPurging('');
    setSuccessPurging('');
    setConfirmed(false);
  }

  const onPurgeEdge = async () => {
    console.log("purge Edges was called");
    try {
      setIsLoading(true);
      const params = {
        source: props.edge?.source,
        target: props.edge?.target,
        tenant: props.tenant.name,
      }
      const query = MutationsStatic.purgeEdge;
      const p = await GraphQLHelper.execute<EdgeModel>(query, params, EdgeModel);
      if (!p.error) {
        const purgedEdge = p.result as EdgeModel;
        console.log(purgedEdge);
        setSuccessPurging(`Edge Purged.`);
      } else {
        setErrorPurging(p.errorMessage);
      }
    } catch (err) {
      setErrorPurging(JSON.stringify(err));
      console.log(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EchoDialog
      open={props.isPurging}
      title={`This will permanently remove all messages currently on Edge from ${props.edge?.source} to ${props.edge?.target}. Are you sure?`}
      errorMessage={errorPurging}
      infoMessage={infoPurging}
      successMessage={successPurging}
      confirmDialog={false}
      onCancel={() => {props.onCancel(); clearState(); }}
      onOk={() => {props.onCloseDialog(); clearState(); }}
      onSave={() => onPurgeEdge()}
      contentText=""
      isValid={confirmed}
      purgeDialog={true}
      spinner={isLoading}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={confirmed}
            onChange={(): void => { setConfirmed(!confirmed); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Yes"
        disabled={((successPurging && successPurging.length > 0) ? true : false) || isLoading}
      />
    </EchoDialog>
  )
}

export default PurgeEdge;