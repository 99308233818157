import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import EchoDialog from './EchoDialog';
import { CrossAccountAppModel } from 'models/app/cross-account-app';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant
}

const CrossAccountAppAdd: React.FC<Props> = (props) => {

  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [appNameValid, setAppNameValid] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allowTableAccess, setAllowTableAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddApp = async () => {
    if (isAppValid()) {
      try {
        setIsLoading(true);
        setInfoMessage('');
        setErrorMessage('');
        setSuccessMessage('');

        const params = {
          tenant: props.tenant.name,
          name: appName,
          description: appDescription,
          tableAccess: allowTableAccess,
          account: accountNumber
        }
        const p = await GraphQLHelper.execute<CrossAccountAppModel>(MutationsStatic.createCrossAccountApp, params, CrossAccountAppModel);
        if (!p.error) {
          setSuccessMessage(`Cross Account app "${appName}" created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create external app', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleAppNameChange = (event: any) => {
    setAppName(event.target.value);
    setAppNameValid(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false);
  }

  const handleDescriptionChange = (event: any) => {
    setAppDescription(event.target.value);
  }

  const handleAccountNumberChange = (event: any) => {
    setAccountNumber(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/cross-account-app', '_blank');
  }

  const isAppValid = (): boolean => {
    return (appName && appName.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) && accountNumber.length > 5 ? true : false);
  }
  
  return (
    <>
      <EchoDialog
        onCancel={props.onCancel}
        onOk={props.onAdd}
        onSave={async () => { await handleAddApp(); }}
        isValid={isAppValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title={<div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>Add Cross Account Application</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>}
        contentText="Cross Account Applications contain External Nodes and exist to group those nodes and provide access to EchoStream resources within your network to those Nodes."
        open={true}
        spinner={isLoading}
      >
        <TextField
          error={!appNameValid}
          helperText={"Name must be a minimum of 3 characters and may contain special characters (- _ . or :). "}
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="appName"
          label="Cross Account application name"
          fullWidth
          onChange={handleAppNameChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="routerDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          required
          margin="dense"
          id="accountNumber"
          label="Account Number"
          fullWidth
          onChange={handleAccountNumberChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allowTableAccess}
              onChange={(): void => { setAllowTableAccess(!allowTableAccess); }}
              name="checkedB"
              color="primary"
            />
          }
          label="Allow Table Access"
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
      </EchoDialog>
    </>
  );
}

export default CrossAccountAppAdd;