import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { IEchoEdge } from "interfaces/echo-edge";

export class LoadBalancerNodeModel implements IEchoNode, GraphQLData {
  config: string = '';
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = '';
  receiveMessageType: IMessageType = new MessageTypeModel();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  receiveEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendMessageType: IMessageType = new MessageTypeModel();
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  lastEvaluatedKey: string = '';
  scannedCount: number = 0;
  deleted: boolean = false;
  stopped: boolean = false;

  public static create
  (name: string,
    typeName: string,
    receiveMessageType: IMessageType,
    sendMessageType: IMessageType,
    sendEdges: Array<IEchoEdge>,
    receiveEdges: Array<IEchoEdge>,
    description: string,
    stopped: boolean
  ) : LoadBalancerNodeModel {
    const t = new LoadBalancerNodeModel();
    t.name = name;
    t.typeName = typeName;
    t.receiveMessageType = receiveMessageType;
    t.sendMessageType = sendMessageType;
    t.sendEdges = sendEdges;
    t.receiveEdges = receiveEdges;
    t.description = description;
    t.stopped = stopped;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  }
}