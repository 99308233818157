import React, { useState, useEffect } from "react";
import { makeStyles,TextField } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import EchoPane from "components/EchoPane";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EchoDialog from "components/EchoDialog";
import { loadingService } from 'services/common';
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import { IMessageType } from "interfaces/message-type";
import ManagedNodeTypeDetails from "./ManagedNodeTypeDetails";
import { ManagedNodeTypeModel } from "models/managed/managed-node-type";
import { ManagedNodeTypeCache } from "cache/ManagedNodeTypeCache";
import AddManagedNodeType from "./AddManagedNodeType";
import { MessageTypeCache } from "cache/MessageTypeCache";
import { FunctionCache } from "cache/FunctionCache";
import { IEchoFunction } from "interfaces/echo-function";
import { CodeType } from "interfaces/code-type";
import { MutationsStatic } from "graphql/mutations-static";
import { GraphQLHelper } from "utilities/graphql-helper";

interface Props {
  tenant: ITenant,
  currentUserRole: UserRole | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const ManagedNodeTypesView: React.FC<Props> = (props) => {
  const classes = useStyles();
  const [selectedManagedNodeType, setSelectedManagedNodeType] = useState<ManagedNodeTypeModel | undefined>();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddManagedNodeTypeDialog, setShowAddManagedNodeTypeDialog] = useState(false);
  const [selectedNodeId, setSelectedNodeId] = useState('');
  const [managedNodeTypeChanged, setManagedNodeTypeChanged] = useState<ManagedNodeTypeModel | undefined>();
  const [navigateToManagedNodeType, setNavigateToManagedNodeType] = useState<ManagedNodeTypeModel | undefined>();
  const [showAlertChanged, setShowAlertChanged] = useState(false);
  const [managedNodeTypes, setManagedNodeTypes] = useState<Array<ManagedNodeTypeModel>>([]);
  const [functionsList, setFunctionsList] = useState<Array<IEchoFunction>>([]);
  const [messageTypes, setMessageTypes] = useState<Array<IMessageType>>([]);
  const [isLoading, setIsLoading] = useState(false);


  const getFunctions = async (tnt: ITenant) => {
    try {
      setIsRefreshing(true);
      const funcs = await new FunctionCache().getFunctions(tnt);
      setFunctionsList(funcs);
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(error);
    } finally {
      setIsRefreshing(false);
    }
  }

  const getMts = async (tnt: ITenant) => {
    const mt = await new MessageTypeCache().getMessageTypes(tnt);
    console.log(mt);
    setMessageTypes(mt ? mt : new Array<IMessageType>());
  };

  const onRefresh = async () => {
    setIsRefreshing(true);
    await getFunctions(props.tenant);
    getManagedNodeTypes(props.tenant);
    setIsRefreshing(false);
  }

  useEffect(() => {
    getManagedNodeTypes(props.tenant);
    getMts(props.tenant);
    onRefresh();
  }, [props.tenant]);
  
  const getManagedNodeTypes = async (tnt: ITenant) => {
    setIsRefreshing(true);
    new ManagedNodeTypeCache().clearCache(tnt);
    const managedNodeTypes = await new ManagedNodeTypeCache().getManagedNodeTypes(tnt);
    setManagedNodeTypes(managedNodeTypes);
    setIsRefreshing(false);
  };

  const showDetails = (managedNodeType: ManagedNodeTypeModel) => {
    if (managedNodeTypeChanged) {
      clearState(true);
      setShowAlertChanged(true);
      setNavigateToManagedNodeType(managedNodeType);
    } else {
      setSelectedManagedNodeType(managedNodeType);
    }
  }

  const clearState = (hideDialog: boolean) => {
    setShowAddManagedNodeTypeDialog(!hideDialog);
    loadingService.clearLoading();
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
  }

  

  let nodeIndex = 5001;
  const systemNodes = managedNodeTypes &&
    managedNodeTypes.filter(o => o.system).map((mt: ManagedNodeTypeModel, index: number) => {
      return (<TreeItem label={mt.name} key={'' + index + nodeIndex} nodeId={'' + index + nodeIndex} onClick={() => showDetails(mt)} />)
    });

  nodeIndex = 10001
  const tenantNodes = managedNodeTypes &&
    managedNodeTypes.filter(o => !o.system).map((mt: ManagedNodeTypeModel, index: number) => {
      return (<TreeItem label={mt.name} key={'' + index + nodeIndex} nodeId={'' + (index + nodeIndex)} onClick={() => showDetails(mt)} />)
    });

  const treeView =
    <TreeView
      selected={selectedNodeId}
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
    >
      <TreeItem nodeId="5000" label="System">
        {systemNodes}
      </TreeItem>
      <TreeItem nodeId="10000" label="Tenant">
        {tenantNodes}
      </TreeItem>
    </TreeView>

  const isMessageTypeValid = (): boolean => {
    return true;
  }

  const addMessageType = async (update: boolean) => {
    if (isMessageTypeValid() || update) {
      try {
        if (!update) { 
          clearState(false);
        }
        loadingService.sendLoadingStatus(true);
      } catch (err) {
        console.log('Can\'t create message type', err);
        setErrorMessage(JSON.stringify(err));
      } finally {
        loadingService.clearLoading();
      }
    }
  }

  const updateManagedNodeType = async (update: boolean) => {
    if (update){
    try {
      setIsLoading(true)
      if(managedNodeTypeChanged){
      console.log(managedNodeTypeChanged);
      const params = {
        name: managedNodeTypeChanged?.name,
        tenant: props.tenant.name
      }
      const query = MutationsStatic.updateManagedNodeType(
        managedNodeTypeChanged.description,
        managedNodeTypeChanged.imageUri,
        managedNodeTypeChanged.readme
      );
      console.log(query);
      // public.ecr.aws/micahhausler/alpine:3.14.0
      const p = await GraphQLHelper.execute<ManagedNodeTypeModel>(query, params, ManagedNodeTypeModel);
      if (!p.error) {
        const MNTModel = p.result as ManagedNodeTypeModel;
        console.log(MNTModel);
        setSuccessMessage(`Managed Node Type ${managedNodeTypeChanged.name} updated.`);
        new ManagedNodeTypeCache().clearCache(props.tenant);
      } else {
        setErrorMessage(p.errorMessage);
      }}
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log(JSON.stringify(err));
      console.log('Can\'t create node', err);
    } finally {
      setIsLoading(false);
    }
    }
  }
  

  const onManagedNodeTypeChange = (code: string, codeType: CodeType, description: string, imageUri: string) => {
    if (selectedManagedNodeType) {
       if (codeType === CodeType.readme) {
        selectedManagedNodeType.readme = code;
      }
      selectedManagedNodeType.description = description;
      selectedManagedNodeType.imageUri = imageUri;
    }
    setManagedNodeTypeChanged(selectedManagedNodeType);
  }

  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={showAlertChanged}
        title={`'${managedNodeTypeChanged?.name}' has changed, save changes?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => { setShowAlertChanged(false); setManagedNodeTypeChanged(undefined); setSelectedManagedNodeType(navigateToManagedNodeType); }}
        onOk={() => { setShowAlertChanged(false); setManagedNodeTypeChanged(undefined); setSelectedManagedNodeType(navigateToManagedNodeType); }}
        onSave={async () => { await addMessageType(true); }}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      { showAddManagedNodeTypeDialog && 
      //@ts-ignore
        <AddManagedNodeType
          onAdd={async () => { setShowAddManagedNodeTypeDialog(false); await getManagedNodeTypes(props.tenant); }}
          onCancel={() => { setShowAddManagedNodeTypeDialog(false); }}
          functionList={functionsList}
          tenant={props.tenant}
          messageTypes={messageTypes}
        />
      }
      <EchoPane
        itemDetails={<ManagedNodeTypeDetails 
          onSave={() => { setShowAlertChanged(false); setManagedNodeTypeChanged(undefined); updateManagedNodeType(true); }} 
          onCancel={() => { setShowAlertChanged(false); setManagedNodeTypeChanged(undefined); }}
          onManagedNodeTypeChange={onManagedNodeTypeChange}
          managedNodeType={selectedManagedNodeType} 
          tenant={props.tenant} 
          onDelete={() => {onRefresh(); window.location.reload();}} 
          currentUserRole={props.currentUserRole}
          />}
        menuList={treeView}
        onRefresh={() => onRefresh()}
        showBack={false}
        onBack={() => { }}
        showAdd={true}
        onAdd={() => { clearState(false); setShowAddManagedNodeTypeDialog(true); }}
        isRefreshing={isRefreshing}
      />
    </div>
  );
};
export default ManagedNodeTypesView;
