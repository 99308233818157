import React from 'react';
import ReactMarkdown from 'react-markdown';

interface Props {
  markdown: string
}

const MarkdownView: React.FC<Props> = (props) => {
  return (
    <div style={{ overflow: 'auto', height: '100%' }}>
      <ReactMarkdown>
        {props.markdown}
      </ReactMarkdown>
    </div>
  );
}

export default MarkdownView;

