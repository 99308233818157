import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Typography, makeStyles, Tab, Tabs, withStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import NodeDetail from './NodeDetail';
import EdgeDetail from './edge/EdgeDetail';
import SimpleComponentDetails from './SimpleComponentDetails';
import { ITenant } from 'interfaces/tenant';
import { IEchoFunction } from 'interfaces/echo-function';
import { NodeTypeName } from 'enumerations/nodetype-name';
import { IEchoNode } from 'interfaces/echo-node';
import { IEchoEdge } from 'interfaces/echo-edge';
import { DeleteDialogType } from './tenants/DeleteDialogs';
import { IApp } from 'interfaces/app';
import ManagedNodeDetailsView from './managedNodeTypes/ManagedNodeDetailsView';
import { ManagedNodeModel } from 'models/managed/managed-node';
import ManagedInstance from './ManagedInstance';
import { ManagedAppModel } from 'models/managed/managed-app';
import SubHub from './graph/SubHub';
import { UserRole } from 'enumerations/user-role';

interface Props {
  node: IEchoNode | undefined,
  nodes: Array<IEchoNode> | undefined,
  app: IApp | undefined,
  edge: IEchoEdge | undefined,
  tenant: ITenant,
  userRole: UserRole,
  onUpdateGraph: () => void,
  onCloseDialog: () => void,
  onDeleteClicked: (dialog: DeleteDialogType) => void,
  open: boolean,
  functionList: Array<IEchoFunction>,
  startNode: (name: string | undefined, typeName: string | undefined) => void,
  stopNode: (name: string | undefined, typeName: string | undefined) => void
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#0EBCB7",
  },
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      style={{ height: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: "5px",
    marginRight: "5px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const ComponentInspector: React.FC<Props> = (props) => {
  
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  const showNodeDetail = props.node && (props.node.typeName === NodeTypeName.ProcessorNode || 
    props.node.typeName === NodeTypeName.BitmapRouterNode || 
    props.node.typeName === NodeTypeName.TimerNode || 
    props.node.typeName === NodeTypeName.CrossTenantSendingNode || 
    props.node.typeName === NodeTypeName.CrossTenantReceivingNode|| 
    props.node.typeName === NodeTypeName.ExternalNode ||
    props.node.typeName === NodeTypeName.FilesDotComWebhookNode ||
    props.node.typeName === NodeTypeName.WebhookNode ||
    props.node.typeName === NodeTypeName.LoadBalancerNode ||
    props.node.typeName === NodeTypeName.WebSubHubNode);
  const showDetails = props.app !== undefined;
  if (!showNodeDetail && !props.edge && !showDetails && !(props.node && props.node.typeName === NodeTypeName.ManagedNode)) {
    return <div />;
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  }

  return (
    <Dialog open={props.open} aria-labelledby="form-dialog-title" fullWidth maxWidth="lg" >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <DialogTitle >
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Show Details" {...a11yProps(0)} />
            {props.app?.typeName === "ManagedApp" ?
            <Tab label="Managed Instances" {...a11yProps(1)} /> 
            : null}
            {props.node?.typeName === NodeTypeName.WebSubHubNode ? 
            <Tab label="Subscriptions" {...a11yProps(2)} />
            : null}
          </Tabs>
        </DialogTitle>
        <Typography style={{ padding: 15 }} align="right">
          <IconButton onClick={() => { props.onCloseDialog(); setValue(0); setIsEditing(false) }}>
            <CloseIcon />
          </IconButton>
        </Typography>
      </div>
      <DialogContent>
      <TabPanel value={value} index={(0)} >
        {(props.node && showNodeDetail) &&
          <NodeDetail 
            nodes={props.nodes} 
            node={props.node} 
            tenant={props.tenant} 
            onUpdateGraph={props.onUpdateGraph}
            onCloseDialog={props.onCloseDialog}
            functionList={props.functionList}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            startNode={(name: string | undefined, typeName: string | undefined) => {props.startNode(name, typeName)}}
            stopNode={(name: string | undefined,  typeName: string | undefined) => {props.stopNode(name, typeName)}}
            userRole={props.userRole}
          />}
        {(props.node && props.node.typeName === NodeTypeName.ManagedNode) &&
          <ManagedNodeDetailsView 
            node={props.node as ManagedNodeModel} 
            tenant={props.tenant} 
            onUpdateGraph={props.onUpdateGraph} 
            functionList={props.functionList} 
            onCloseDialog={props.onCloseDialog}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            startNode={(name: string | undefined, typeName: string | undefined) => {props.startNode(name, typeName)}}
            stopNode={(name: string | undefined,  typeName: string | undefined) => {props.stopNode(name, typeName)}}
            userRole={props.userRole}
          />}
        {(props.edge) &&
          <EdgeDetail 
            onDeleteClicked={props.onDeleteClicked} 
            edge={props.edge} 
            tenant={props.tenant} 
            onUpdateGraph={props.onUpdateGraph} 
          />}
        {showDetails &&
          <SimpleComponentDetails
            tenant={props.tenant}
            edge={props.edge}
            node={props.node}
            app={props.app}
            onShouldCloseDialog={props.onCloseDialog}
            onUpdateGraph={props.onUpdateGraph} 
            onCloseDialog={props.onCloseDialog}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            userRole={props.userRole}
          />}
        </TabPanel>
        {(props.app && props.app.typeName === "ManagedApp") && 
        <TabPanel value={value} index={(1)}>
          <ManagedInstance 
            app={props.app as ManagedAppModel}
            tenant={props.tenant}
            closeDialog={() => {props.onCloseDialog(); }}
          />
        </TabPanel>}
        {(props.node && props.node.typeName === NodeTypeName.WebSubHubNode) && <TabPanel value={value} index={(1)}>
          <SubHub 
            node={props.node}
            tenant={props.tenant}
            startNode={(name: string | undefined, typeName: string | undefined) => {props.startNode(name, typeName)}}
            stopNode={(name: string | undefined,  typeName: string | undefined) => {props.stopNode(name, typeName)}}
          />
        </TabPanel>}
      </DialogContent>
    </Dialog>
  )
}

export default ComponentInspector;