import { IDeleteEntity } from "interfaces/delete-entity";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";

export class DeleteTenantUserModel implements IDeleteEntity, GraphQLData {
  lastEvaluatedKey: string = '';
  deleted: boolean = false;

  public static create(
    deleted: boolean) : DeleteTenantUserModel {
    const t = new DeleteTenantUserModel();
    t.deleted = deleted;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    if (data && data.data && data.data.GetTenantUser && data.data.GetTenantUser.Delete) {
      this.deleted = data.data.GetTenantUser.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenantUser or data.data.GetTenantUser.Delete');
    }
  }
}