import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
//import Amplify, { Auth } from 'aws-amplify';
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import axios from "axios";
import reportWebVitals from "./reportWebVitals";
import AuthState from "./AuthState";
import 'typeface-roboto'
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

(async function() {
  const config = await axios.get(`${window.location.protocol}//${window.location.host}/config/config.json`);
  // const config = await axios.get(`app-dev.echo.stream/config/config.json`);
  console.log("config", config);
    const hist = createBrowserHistory();

    await Amplify.configure({
      Auth: {
          region: config.data.region,
          userPoolWebClientId: config.data.clientId,
          userPoolId: config.data.userPoolId,
          //authenticationFlowType: 'USER_PASSWORD_AUTH',
      },
      API: {
        graphql_headers: async () => {
              const session = await Auth.currentSession();
              return {
                //Authorization: session.getIdToken().getJwtToken(),
                Authorization: session.getAccessToken().getJwtToken(),
              };
            },
          graphql_endpoint: config.data.graphqlEndpoint,
      }
    });

    ReactDOM.render(
      <Router history={hist}>
        <AuthState />
      </Router>
      ,
      document.getElementById("root")
    );

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();

})();