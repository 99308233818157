export enum NodeTypeName {
  ProcessorNode = 'ProcessorNode',
  BitmapRouterNode = 'BitmapRouterNode',
  TimerNode = 'TimerNode',
  ExternalNode = 'ExternalNode',
  ManagedNode = 'ManagedNode',
  Unknown = 'Unknown',
  CrossTenantSendingNode = 'CrossTenantSendingNode',
  CrossTenantReceivingNode = 'CrossTenantReceivingNode',
  AppChangeReceiverNode = 'AppChangeReceiverNode',
  AppChangeRouterNode = 'AppChangeRouterNode',
  ChangeEmitterNode = 'ChangeEmitterNode',
  AlertEmitterNode = 'AlertEmitterNode',
  AuditEmitterNode = 'AuditEmitterNode',
  LogEmitterNode = 'LogEmitterNode',
  FilesDotComWebhookNode = 'FilesDotComWebhookNode',
  WebhookNode = 'WebhookNode',
  LoadBalancerNode = 'LoadBalancerNode',
  WebSubHubNode = 'WebSubHubNode',
  WebSubSubscriptionNode = 'WebSubSubscriptionNode'
}