import { QueriesStatic } from 'graphql/queries-static';
import { FunctionListModel } from 'models/function/function-list-model';
import { StringArrayModel } from 'models/string-array-model';
import { GraphQLHelper } from 'utilities/graphql-helper';

let instance: TenantRegionCache | null = null;

export class TenantRegionCache {
  private regions: Array<string> = [];
  
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  public clearCache() {
    this.regions = new Array<string>();
  }

  public async getRegions() : Promise<Array<string>> {
    if (!this.regions || this.regions.length === 0) {
      const p = await GraphQLHelper.execute<StringArrayModel>(QueriesStatic.getSupportedRegions, '', StringArrayModel);
      if (p.error) {
        console.log(p.errorMessage);
      } else {
        const fns = p.result as StringArrayModel;
        this.regions = fns.result;
        return fns.result;
      }
    }
    return this.regions;
  }
}

