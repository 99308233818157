import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ITenant } from 'interfaces/tenant';
import { FilesDotComWebhookNodeModel } from 'models/node/filesdotcom-webhook-node';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const FilesDotComAddNode: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [nodeName, setNodeName] = useState('');
  const [description, setDescription] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [nodeNameValid, setNodeNameValid] = useState(false);
  const [nodeValid, setNodeValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNode = async () => {
    if (nodeName && nodeName.length >= 4) {
      try {
        setErrorSaving('');
        setInfoSaving('');
        setSuccessSaving('');
        const params = {
          apiKey: apiKey,
          name: nodeName,
          tenant: props.tenant.name,
          description: description,
        }
        console.log(params);
        setIsLoading(true);
        const p = await GraphQLHelper.execute<FilesDotComWebhookNodeModel>(MutationsStatic.createFilesDotComWebhookNode, params, FilesDotComWebhookNodeModel);
        if (!p.error) {
          const result = p.result as FilesDotComWebhookNodeModel;
          console.log(result);
          setSuccessSaving(`Node ${nodeName} created.`);
        } else {
          setErrorSaving(p.errorMessage);
        }
      } catch (err) {
        setErrorSaving(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create router node', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const checkIfNodeValid = () => {
    let valid = false;
    if (nodeName.length >= 4) {
      valid = true;
    }
    setNodeValid(valid);
  }

  const handleNodeNameChange = (event: any) => {
    setNodeName(event.target.value);
    setNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false);
    checkIfNodeValid();
  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const handleApiKeyChange = (event: any) => {
    setApiKey(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/filescom-webhook-node', '_blank');
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>Add FilesDotComWebhook Node</div>
          <div style={{marginLeft: 'auto'}}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Files.com Webhook Nodes are Internal Nodes that are used to receive webhooks from Files.com. They can receive both GET and POST webhooks; when the webhook is a POST, they can receive Form, JSON or XML data. Webhooks that are received are converted to the echo.files-dot-com MessageType. To enable this with Files.com, you must provide a Site-wide API Key from Files.com, and you must take the token provided by this node and place it in the Headers of your webhook using "Authorization: Bearer &lt;token&gt;" as the key.
        </DialogContentText>
        <TextField
          error={!nodeNameValid}
          helperText="Name must be atleast 3 characters in length and may contain special characters (- or _) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="filesdotcomwebhookNodeName"
          label="Name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="routerDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="apikey"
          label="Api Key"
          fullWidth
          onChange={handleApiKeyChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px'}}>
          {(errorSaving && errorSaving.length > 0) &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorSaving}
            </Alert>
          }
          {(infoSaving && infoSaving.length > 0) &&
            <Alert severity="info">
              <AlertTitle>Information</AlertTitle>
              {infoSaving}
            </Alert>
          }
          {(successSaving && successSaving.length > 0) &&
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successSaving}
            </Alert>
          }
        </div>
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={isLoading || !(apiKey && nodeName && nodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/))} variant="contained" onClick={handleAddNode} color="primary">
            Add {  isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={props.onAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default FilesDotComAddNode;