import React, { useState, useEffect } from "react";
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { ITenantUser } from "interfaces/tenant-user";
import EchoPane from "components/EchoPane";
import { TreeItem, TreeView } from "@material-ui/lab";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EchoDialog from 'components/EchoDialog';
import TenantUserDetails from './TenantUserDetails';
import { loadingService } from 'services/common';
import { ITenant, NoTenant } from 'interfaces/tenant';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { UserRole } from 'enumerations/user-role';
import { QueriesStatic } from 'graphql/queries-static';
import { TenantUsersModel } from 'models/tenant-user/tenant-users-model';
import { MutationsStatic } from 'graphql/mutations-static';
import { TenantUserModel } from "models/tenant-user/tenant-user-model";

interface Props {
  tenant: ITenant;
  currentUserRole: UserRole | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: "75px",
    expand: {
      transform: "rotate(0deg)",
      marginLeft: "auto",
      transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: "rotate(180deg)",
    },
  },
  buttonProgress: {
    color: "#F5F5F5",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TenantUsers: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [users, setUsers] = useState<Array<ITenantUser>>([]);
  const [selectedUser, setSelectedUser] = useState<ITenantUser>();
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [userRole, setUserRole] = useState<UserRole>(UserRole.read_only);
  const [userEmail, setUserEmail] = useState('');
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getUsers(props.tenant);
  }, [props.tenant]);

  const getUsers = async (tnt: ITenant) => {
    console.log('getting tenant users');
    setIsRefreshing(true);
    if (tnt && tnt.name && tnt.name !== NoTenant.name) {
      console.log(tnt.name);
      const params = {
        tenant: tnt.name
      }
      const p = await GraphQLHelper.execute<TenantUsersModel>(QueriesStatic.listTenantUsers, params, TenantUsersModel);
      if (!p.error) {
        const users = p.result as TenantUsersModel;
        console.log(users);
        setUsers(users.tenantUsers);
      }
    } else {
      console.log('no tenant');
    }
    setIsRefreshing(false);
  }

  const onRefresh = () => {
    getUsers(props.tenant);
  }

  const showDetails = (mt: ITenantUser) => {
    setSelectedUser(mt);
  }

  let nodeIndex = 1000;

  const userGroups = Object.keys(UserRole).map((t: string) => {
    // get users with that role
    const ur = users.filter(o => o.role === t).map((t2: ITenantUser) => {
      nodeIndex += 1;
      return <TreeItem label={t2.email} nodeId={'' + nodeIndex} key={'' + nodeIndex} onClick={() => showDetails(t2)} />
    })
    nodeIndex += 1;
    return (
      <TreeItem label={t} nodeId={'' + nodeIndex} key={'' + nodeIndex}>
        {ur}
      </TreeItem>)
  });

  const addUser = async () => {
    if (isUserValid()) {
      try {
        setIsLoading(true);
        clearState(false);
        loadingService.sendLoadingStatus(true);
        const params = {
          tenant: props.tenant.name
        }
        const p = await GraphQLHelper.execute<TenantUserModel>(MutationsStatic.createTenantUser(userEmail, userRole), params, TenantUserModel);
        if (!p.error) {
          setSuccessMessage(`User ${userEmail} created.`);
          console.log('Created user: ', userEmail);
          getUsers(props.tenant);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log('Can\'t create user', err);
      } finally {
        loadingService.clearLoading();
        setIsLoading(false);
      }
    }
  }
  
  const clearState = (hideDialog: boolean) => {
    setInfoMessage('');
    setErrorMessage('');
    setSuccessMessage('');
    setShowAddUserDialog(!hideDialog);
  }
  
  const handleRoleChange = (event: any) => {
    setUserRole(event.target.value as UserRole);
  }

  const isUserValid = (): boolean => {
    return validEmail(userEmail);
  }

  const onEmailChange = (event: any) => {
    setUserEmail(event.target.value);
  }

  const emailExp = RegExp(
    /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
  )

  const validEmail = (email: string): boolean => {
    return emailExp.test(email);
  }

  const treeView = 
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}>
        {userGroups}
    </TreeView>

  const onDeleteUser = async () => {
    setSelectedUser(undefined);
    getUsers(props.tenant);
  }

  return (
    <div style={{height: '100%'}}>
      <EchoDialog
        onCancel={() => { clearState(true); }}
        onOk={() => { clearState(true); }}
        onSave={async () => { await addUser(); }}
        isValid={isUserValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title="Add User"
        contentText="Invite a user"
        open={showAddUserDialog}
        spinner={isLoading}
      >
        <TextField
          error={!validEmail(userEmail)}
          helperText={!validEmail(userEmail) ? "Email must be valid" : ""}
          variant="outlined"
          required
          margin="dense"
          id="email"
          label="Email"
          fullWidth
          onChange={onEmailChange}
          disabled={isLoading}
        />
        <FormControl variant="filled">
          <InputLabel htmlFor="filled-age-native-simple">Role</InputLabel>
          <Select
            value={userRole}
            style={{ width: '150px' }}
            onChange={handleRoleChange}
            inputProps={{
              name: "tenant",
              id: "filled-age-native-simple"
            }}
            disabled={isLoading}
          >
            {Object.keys(UserRole).map((t: string, index: number) =>
              <MenuItem key={index} value={t}>{t}</MenuItem>
            )}
          </Select>
        </FormControl>
      </EchoDialog>
      <EchoPane 
        itemDetails={<TenantUserDetails users={users} selectedUser={selectedUser} tenant={props.tenant} onDelete={() => onDeleteUser()} onRefresh={() => onRefresh()} currentUserRole={props.currentUserRole}/>}
        menuList={treeView}
        onRefresh={() => onRefresh()}
        showBack={false}
        onBack={() => {}}
        showAdd={props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner}
        onAdd={() => {setShowAddUserDialog(true)}}
        width={'350px'}
        isRefreshing={isRefreshing}
      />
    </div>
  );
};
export default TenantUsers;
