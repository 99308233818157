import { GraphQLData } from "interfaces/graphql-data";
import { IKeyValue } from "interfaces/key-value";
import { ITenant } from "interfaces/tenant";

export class ConfigModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  config: Array<IKeyValue> = new Array<IKeyValue>();
  configJson: any = {};

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data) {
      if (data.data.GetConfig) {
        let dt = data.data.GetConfig;
        if (dt.config) {
          dt = dt.config;
        } else if (dt.Update) {
          dt = dt.Update.config;
        } else {
          dt = undefined;
        }

        if (dt) {
          const cfgs = JSON.parse(dt);
          this.configJson = cfgs;
          this.config = Object.keys(cfgs).map(o => {
            return {
              key: o,
              value: cfgs[o]
            } as IKeyValue;
          });
        }
      }
    } else {
      throw new Error('data structured passed in does not contain data, data.data');
    }
  }
}