//	consistency: Consistency
//	description: String!
//	labels: AWSJSON
//	noCopy: Boolean
//	options: AWSJSON
//	readOnly: Boolean
//	source: String
//	target: String!

export class MountRequirement {
  description: string;
  source: string;
  target: string;

  constructor(description: string, source: string, target: string) {
    this.description = description;
    this.source = source;
    this.target = target;
  }
}