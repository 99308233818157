import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormControl, IconButton, InputLabel, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ITenant } from 'interfaces/tenant';
import { IMessageType } from 'interfaces/message-type';
import { BitmapRouterNodeModel } from 'models/node/bitmap-router-node';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  messageTypes: Array<IMessageType>;
  tenant: ITenant;
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const RouterAddNode: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [routerNodeName, setRouterNodeName] = useState('');
  const [routerDescription, setRouterDescription] = useState('');
  const [routerNodeNameValid, setRouterNodeNameValid] = useState(false);
  const [nodeValid, setNodeValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const [receiveMessageType, setReceiveMessageType] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNode = async () => {
    if (receiveMessageType && routerNodeName && routerNodeName.length >= 4) {
      try {
        setErrorSaving('');
        setInfoSaving('');
        setSuccessSaving('');
        console.log(props.messageTypes);
        const bitmapper = props.messageTypes.find(o => o.name === receiveMessageType);
        const params = {
          tenant: props.tenant.name,
          name: routerNodeName,
          description: routerDescription,
          receiveMessageType: receiveMessageType,
          inlineBitmapper: bitmapper?.bitmapperTemplate,
          routeTable: '[]', // configure route table after creating the node
        }
        console.log(params);
        setIsLoading(true);
        const p = await GraphQLHelper.execute<BitmapRouterNodeModel>(MutationsStatic.createBitmapRouterNode, params, BitmapRouterNodeModel);
        if (!p.error) {
          const apiUser = p.result as BitmapRouterNodeModel;
          console.log(apiUser);
          setSuccessSaving(`Node ${routerNodeName} created.`);
        } else {
          setErrorSaving(p.errorMessage);
        }
      } catch (err) {
        setErrorSaving(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create router node', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const checkIfNodeValid = () => {
    let valid = false;
    if (receiveMessageType && routerNodeName && routerNodeName.length >= 4) {
      valid = true;
    }
    setNodeValid(valid);
  }

  const handleNodeNameChange = (event: any) => {
    setRouterNodeName(event.target.value);
    setRouterNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false);
    checkIfNodeValid();
  }

  const handleDescriptionChange = (event: any) => {
    setRouterDescription(event.target.value);
  }

  const receiveMessageTypeChange = (event: any) => {
    setReceiveMessageType(event.target.value);
    checkIfNodeValid();
  };

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/bitmap-router-node', '_blank');
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>Add Bitmap Router Node</div>
          <div style={{marginLeft: 'auto'}}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          A router node can direct messages to different edges based upon a custom router logic.
        </DialogContentText>
        <TextField
          error={!routerNodeNameValid}
          helperText="Name must be atleast 3 characters in length and may contain special characters (- or _) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="routerNodeName"
          label="Router name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="routerDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }}>
            <InputLabel htmlFor="filled-age-native-simple">Receive Message Type</InputLabel>
            <Select
              native
              value={receiveMessageType}
              onChange={receiveMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
              disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px'}}>
          {(errorSaving && errorSaving.length > 0) &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorSaving}
            </Alert>
          }
          {(infoSaving && infoSaving.length > 0) &&
            <Alert severity="info">
              <AlertTitle>Information</AlertTitle>
              {infoSaving}
            </Alert>
          }
          {(successSaving && successSaving.length > 0) &&
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successSaving}
            </Alert>
          }
        </div>
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={isLoading || !(receiveMessageType && routerNodeName && routerNodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/))} variant="contained" onClick={handleAddNode} color="primary">
            Add {  isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={props.onAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default RouterAddNode;