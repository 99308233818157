import { IAtBy } from "interfaces/at-by";
import { IEchoEdge } from "interfaces/echo-edge";
import { IEchoFunction } from "interfaces/echo-function";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { ApiAuthenticatorFunctionModel } from "models/function/api-authenticator-function-model";

export class WebhookNodeModel implements IEchoNode, GraphQLData {
  config: string = '';
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  inlineApiAuthenticator: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  managedApiAuthenticator: IEchoFunction = new ApiAuthenticatorFunctionModel();
  name: string = '';
  sendMessageType: IMessageType = new MessageTypeModel();
  requirements: Array<string> = new Array<string>();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  lastEvaluatedKey: string = '';
  deleted: boolean = false;
  endpoint: string = '';
  stopped: boolean = false;

  public static create(
    name: string,
    typeName: string,
    sendEdges: Array<IEchoEdge>,
    inlineApiAuthenticator: string,
    managedApiAuthenticator: IEchoFunction,
    requirements: Array<string>,
    description: string,
    sendMessageType: IMessageType,
    endpoint: string,
    stopped: boolean) : WebhookNodeModel {
    const t = new WebhookNodeModel();
    t.name = name;
    t.sendEdges = sendEdges;
    t.typeName = typeName;
    t.inlineApiAuthenticator = inlineApiAuthenticator;
    t.managedApiAuthenticator = managedApiAuthenticator;
    t.requirements = requirements;
    t.description = description;
    t.sendMessageType = sendMessageType;
    t.endpoint = endpoint;
    t.stopped = stopped;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  }
}