import { IAtBy } from "interfaces/at-by";

export class AtByModel implements IAtBy {
  at: string = '';
  by: string = '';

  constructor(at: string, by: string) {
    this.at = at;
    this.by = by;
  }
}