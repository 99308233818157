import { GraphItemType } from "components/graph/graph-interfaces";
import { IApp } from "interfaces/app";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { TenantModel } from "models/tenant/tenant-model";

export class CrossAccountAppModel implements IApp, GraphQLData {
  hasTableAccess: boolean = true;
  graphItemType: GraphItemType = GraphItemType.app;
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  tableAccess: boolean = false;
  name: string = '';
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  account: string = '';

  deleted: boolean = false;
  lastEvaluatedKey: string = '';
  appsyncEndpoint: string = '';

  public static create(name: string, description: string, tenant: ITenant, typeName: string, tableAccess: boolean, account: string, appsyncEndpoint: string): CrossAccountAppModel {
    const t = new CrossAccountAppModel();
    t.name = name;
    t.description = description;
    t.tenant = tenant;
    t.typeName = typeName;
    t.tableAccess = tableAccess;
    t.account = account;
    t.appsyncEndpoint = appsyncEndpoint;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  }
}