import { NodeTypeName } from "enumerations/nodetype-name";
import { GraphQLData } from "interfaces/graphql-data";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { NotImplementedNodeModel } from "./not-implemented-node";
import { BitmapRouterNodeModel } from "./bitmap-router-node";
import { ProcessorNodeModel } from "./processor-node";
import { IEchoEdge } from "interfaces/echo-edge";
import { EdgeModel } from "models/edge/edge-model";
import { FunctionModel } from "models/function/function-model";
import { MessageTypeCache } from "cache/MessageTypeCache";
import { FunctionCache } from "cache/FunctionCache";
import { TimerNodeModel } from "./timer-node";
import { CrossTenantSendingNodeModel } from "./cross-tenant-sending-node";
import { CrossTenantSendingAppModel } from "models/app/cross-tenant-sending-app";
import { AppTypeName } from "enumerations/apptype-name";
import { CrossTenantReceivingAppModel } from "models/app/cross-tenant-receiving-app";
import { CrossTenantReceivingNodeModel } from "./cross-tenant-receiving-node";
import { ExternalNodeModel } from "./external-node";
import { ExternalAppModel } from "models/app/external-app";
import { AppChangeReceiverNodeModel } from "./managed-app-change-receiver-node";
import { ManagedAppModel } from "models/managed/managed-app";
import { ManagedNodeModel } from "models/managed/managed-node";
import { Port } from "models/managed/port";
import { Protocol } from "enumerations/protocol";
import { Mount } from "models/managed/mount";
import { ManagedInstanceModel } from "models/managed/managed-instance";
import { FilesDotComWebhookNodeModel } from "./filesdotcom-webhook-node";
import { ApiAuthenticatorFunctionModel } from "models/function/api-authenticator-function-model";
import { WebhookNodeModel } from "./webhook-node";
import { LoadBalancerNodeModel } from "./load-balancer-node";
import { WebSubHubNodeModel } from "./websub-hub-node";
import { SignatureAlgorithm } from "enumerations/signature-algorithm";
import { SubscriptionSecurity } from "enumerations/subscription-security";
import { IMessageCounts } from "interfaces/message-counts";

export class NodeListModel implements GraphQLData {
  lastEvaluatedKey: string = "";
  nodes: Array<IEchoNode> = [];

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    if (data && data.data && data.data.ListNodes) {
      const dt = data.data.ListNodes;
      const messageTypes = await new MessageTypeCache().getMessageTypes(tenant);
      const functions = await new FunctionCache().getFunctions(tenant);
      this.nodes = dt.echos.map(
        (o: {
          __typename: string;
          name: string;
          description: string;
          maxReceiveCount: number;
          app: {
            description: string;
            name: string;
            tableAccess: boolean;
            appsyncEndpoint: string;
            managedInstances: Array<ManagedInstanceModel>;
          };
          config: string;
          sendMessageType: { name: string };
          receiveMessageType: { name: string };
          requirements: Array<string>;
          sendEdges: Array<{
            description: string;
            source: { name: string };
            target: { name: string };
            __typename: string;
          }>;
          managedProcessor: { name: string };
          managedApiAuthenticator: { name: string };
          managedBitmapper: { name: string };
          inlineProcessor: string;
          inlineBitmapper: string;
          inlineApiAuthenticator: string;
          sequentialProcessing: boolean;
          scheduleExpression: string;
          routeTable: string;
          ports: Array<{
            containerPort: number;
            description: string;
            hostAddress: string;
            hostPort: number;
            protocol: Protocol;
          }>;
          mounts: Array<{
            description: string;
            source: string;
            target: string;
          }>;
          receivingApp: string;
          receivingTenant: string;
          sendingApp: string;
          sendingTenant: string;
          endpoint: string;
          token: string;
          stopped: boolean;
          subscriptionSecurity: SubscriptionSecurity;
          signatureAlgorithm: SignatureAlgorithm;
          defaultLeaseSeconds: number;
          maxLeaseSeconds: number;
          deliveryRetries: number;
        }) => {
          let sendMessageType = new MessageTypeModel();
          let receiveMessageType = new MessageTypeModel();

          if (o.sendMessageType) {
            sendMessageType = messageTypes.find(
              (t) => t.name === o.sendMessageType.name
            ) as MessageTypeModel;
          }

          const sendEdges = new Array<IEchoEdge>();
          const receiveEdges = new Array<IEchoEdge>();

          if (o.sendEdges) {
            let edt = {};
            if (o.sendEdges) {
              edt = o.sendEdges;
            }
            // @ts-ignore
            const edges = edt.map<IEchoEdge>(
              (o: {
                source: { name: string };
                target: { name: string };
                __typename: string;
                description: string;
                maxReceiveCount: number;
                queue: string;
                messageCounts: IMessageCounts
              }) => {
                return EdgeModel.create(
                  o.source.name,
                  o.target.name,
                  o.__typename,
                  o.description,
                  o.maxReceiveCount,
                  o.queue,
                  o.messageCounts
                );
              }
            );
            sendEdges.push(...edges);
          }

          if (o.receiveMessageType) {
            receiveMessageType = messageTypes.find(
              (t) => t.name === o.receiveMessageType.name
            ) as MessageTypeModel;
          }

          let managedProcessor = new FunctionModel();
          if (o.managedProcessor) {
            managedProcessor = functions.find(
              (t) => t.name === o.managedProcessor.name
            ) as FunctionModel;
          }

          let managedBitmapper = new FunctionModel();
          if (o.managedBitmapper) {
            managedBitmapper = functions.find(
              (t) => t.name === o.managedBitmapper.name
            ) as FunctionModel;
          }

          let managedApiAuthenticator = new ApiAuthenticatorFunctionModel();
          if (o.managedApiAuthenticator) {
            managedApiAuthenticator = functions.find(
              (t) => t.name === o.managedApiAuthenticator.name
            ) as ApiAuthenticatorFunctionModel;
          }

          let ports = new Array<Port>();
          if (o.ports) {
            ports = o.ports.map(
              (k) =>
                new Port(
                  k.containerPort,
                  k.description,
                  k.protocol,
                  k.hostAddress,
                  k.hostPort
                )
            );
          }

          let mounts = new Array<Mount>();
          if (o.mounts) {
            mounts = o.mounts.map(
              (k) => new Mount(k.description, k.source, k.target)
            );
          }

          if (o.__typename === NodeTypeName.BitmapRouterNode) {
            return BitmapRouterNodeModel.create(
              o.name,
              o.__typename,
              receiveMessageType,
              sendMessageType,
              sendEdges,
              o.inlineBitmapper,
              managedBitmapper,
              o.routeTable,
              o.requirements,
              o.description,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.TimerNode) {
            return TimerNodeModel.create(
              o.name,
              o.description,
              o.__typename,
              sendMessageType,
              sendEdges,
              o.scheduleExpression,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.LoadBalancerNode) {
            return LoadBalancerNodeModel.create(
              o.name,
              o.__typename,
              receiveMessageType,
              sendMessageType,
              sendEdges,
              receiveEdges,
              o.description,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.FilesDotComWebhookNode) {
            return FilesDotComWebhookNodeModel.create (
              o.name,
              o.description,
              o.__typename,
              sendEdges,
              o.endpoint,
              sendMessageType,
              o.token,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.ProcessorNode) {
            return ProcessorNodeModel.create(
              o.name,
              o.__typename,
              receiveMessageType,
              sendMessageType,
              sendEdges,
              o.inlineProcessor,
              o.sequentialProcessing,
              managedProcessor,
              o.requirements,
              o.description,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.CrossTenantSendingNode) {
            return CrossTenantSendingNodeModel.create(
              o.name,
              o.__typename,
              CrossTenantSendingAppModel.create(
                o.app.name,
                o.app.description,
                tenant,
                AppTypeName.CrossTenantSendingApp,
                o.receivingApp,
                o.receivingTenant
              ),
              receiveMessageType,
              receiveEdges,
              sendMessageType,
              sendEdges,
              o.inlineProcessor,
              o.sequentialProcessing,
              managedProcessor,
              o.requirements,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.CrossTenantReceivingNode) {
            return CrossTenantReceivingNodeModel.create(
              o.name,
              o.__typename,
              CrossTenantReceivingAppModel.create(
                o.app.name,
                tenant,
                AppTypeName.CrossTenantReceivingApp,
                o.sendingApp,
                o.sendingTenant
              ),
              sendMessageType,
              sendEdges,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.ExternalNode) {
            return ExternalNodeModel.create(
              o.name,
              o.description,
              o.__typename,
              ExternalAppModel.create(
                o.app.name,
                tenant,
                AppTypeName.ExternalApp,
                o.app.tableAccess,
                o.app.appsyncEndpoint,
                o.description
              ),
              receiveMessageType,
              receiveEdges,
              sendMessageType,
              sendEdges,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.AppChangeReceiverNode) {
            return AppChangeReceiverNodeModel.create(
              o.name,
              o.__typename,
              ManagedAppModel.create(
                o.app.name,
                o.app.description,
                tenant,
                AppTypeName.ManagedApp,
                o.app.tableAccess,
                o.app.managedInstances
              ),
              receiveMessageType,
              receiveEdges
            );
          } else if (o.__typename === NodeTypeName.ManagedNode) {
            return ManagedNodeModel.create(
              o.name,
              o.description,
              o.__typename,
              ManagedAppModel.create(
                o.app.name,
                o.app.description,
                tenant,
                AppTypeName.ManagedApp,
                o.app.tableAccess,
                o.app.managedInstances
              ),
              o.config,
              receiveMessageType,
              receiveEdges,
              sendMessageType,
              sendEdges,
              ports,
              mounts,
              o.stopped
            );
          } else if (o.__typename === NodeTypeName.WebhookNode) {
            return WebhookNodeModel.create(
              o.name,
              o.__typename,
              sendEdges,
              o.inlineApiAuthenticator,
              managedApiAuthenticator,
              o.requirements,
              o.description,
              sendMessageType,
              o.endpoint,
              o.stopped
            )
          } else if (o.__typename === NodeTypeName.WebSubHubNode) {
            return WebSubHubNodeModel.create (
              o.name,
              o.__typename,
              sendEdges,
              o.inlineApiAuthenticator,
              managedApiAuthenticator,
              o.requirements,
              o.description,
              sendMessageType,
              receiveMessageType,
              o.endpoint,
              o.stopped,
              o.defaultLeaseSeconds,
              o.deliveryRetries,
              o.maxLeaseSeconds,
              o.signatureAlgorithm,
              o.subscriptionSecurity
            )
          }
          return NotImplementedNodeModel.create(
            o.name,
            o.__typename,
            receiveMessageType,
            sendMessageType,
            sendEdges
          );
        }
      );
    } else {
      throw new Error(
        "data structured passed in does not contain data, data.data, data.data.ListNodes"
      );
    }
  }
}
