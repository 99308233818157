import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IApp } from 'interfaces/app';
import { DeleteAppModel } from 'models/app/delete-app-model';

interface Props {
  app: IApp | undefined,
  tenant: ITenant,
  onDeleted: () => void,
  onCancel: () => void
}

const AppDelete: React.FC<Props> = (props) => {
  const [errorDeleting, setErrorDeleting] = useState('');
  const [infoDeleting, setInfoDeleting] = useState('');
  const [successDeleting, setSuccessDeleting] = useState('');
  const [drain, setDrain] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const clearState = () => {
    setErrorDeleting('');
    setInfoDeleting('');
    setSuccessDeleting('');
  }

  const onDeleteApp = async () => {
    try {
      setIsLoading(true);
      clearState();
      const params = {
        name: props.app?.name,
        tenant: props.tenant.name,
      }
      const query = MutationsStatic.deleteApp(drain);
      const p = await GraphQLHelper.execute<DeleteAppModel>(query, params, DeleteAppModel);
      if (!p.error) {
        const deletedApp = p.result as DeleteAppModel;
        console.log(deletedApp);
        props.onDeleted();
        setSuccessDeleting(`App deleted.`);
      } else {
        setErrorDeleting(p.errorMessage);
      }
    } catch (err) {
      console.log(err);
      setErrorDeleting(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EchoDialog
      open={true}
      title={`Delete app: ${props.app?.name}?`}
      errorMessage={errorDeleting}
      infoMessage={infoDeleting}
      successMessage={successDeleting}
      confirmDialog={true}
      onCancel={props.onCancel}
      onOk={props.onDeleted}
      onSave={() => onDeleteApp()}
      contentText=""
      isValid={true}
      spinner={isLoading}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={drain}
            onChange={(): void => { setDrain(!drain); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Drain Edges"
        disabled={((successDeleting && successDeleting.length > 0) ? true : false) || isLoading}
      />
    </EchoDialog>
  )
}

export default AppDelete;