import React, { useState } from "react";
import { FormControl, IconButton, InputLabel, Select, TableCell, TextField } from "@material-ui/core";
import { Mount } from "models/managed/mount";
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';


interface Props {
    mount: Mount,
    updateMount: (newMount: Mount) => void,
    cancelEdit: () => void,
    toggleEdit: React.Dispatch<React.SetStateAction<boolean>>,
    setEdits: React.Dispatch<React.SetStateAction<Mount>>,
    isEditing?: boolean,
}

const ManagedNodeMountForm: React.FC<Props> = (props) => {

    const [inputs, setInputs] = useState(new Mount(props.mount.description, props.mount.source, props.mount.target));

    const handleChange = (event: any) => {
        const {name, value} = event.target;
        setInputs(prevInputs => ({...prevInputs, [name]: value}));
    }

    const handleSubmit = () => {
        if(props.isEditing){
        props.setEdits(inputs);
        props.updateMount(inputs);
        setInputs(new Mount("", "", ""));
        props?.toggleEdit(false); 
        }
    }

    const onCancel = () => {
        if(props.isEditing){
            props.toggleEdit(false);
        } 
    }
    
    return(
        <>
        <TableCell style={{ width: '200px' }} >{props.mount.target}</TableCell>
        <TableCell style={{ width: '200px' }} >{props.mount.description}</TableCell>
        <TableCell style={{ width: '200px' }}>
            <TextField
                required
                name="source"
                autoFocus={false}
                fullWidth
                value={inputs.source}
                margin="dense"
                id="messsageArg"
                onChange={handleChange}
            />
        </TableCell>
        <TableCell style={{ width: '100px' }}>
            <IconButton size="small" onClick={() => handleSubmit()}>
                <CheckIcon />
            </IconButton>
            <IconButton size="small" onClick={() => { onCancel(); }}>
                <CancelIcon />
            </IconButton>
        </TableCell>
</>
    );
}

export default ManagedNodeMountForm;