import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { LogEventModel } from "./logEvent";

export class LogEventsListModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  logs: Array<LogEventModel> = []

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    console.log(data.data);
    
    if (data && data.data && data.data.GetNode) {
      if (data.data.GetNode.ListLogEvents && data.data.GetNode.ListLogEvents.events) {
        const dt = data.data.GetNode.ListLogEvents;
        this.lastEvaluatedKey = dt.nextToken;
        this.logs = dt.events.map((o: any) => {
          const log = new LogEventModel(o.timestamp, o.message, o.ingestionTime);
          return log;
        });
      }
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetNode');
    }
  }
}