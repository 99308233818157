import { IAtBy } from "./at-by";
import { IMessageType } from "./message-type";
import { ITenant } from "./tenant";

export enum EchoFunctionType {
  bitmapper = 'bitmapper',
  processor = 'processor',
  apiauthenticator = 'apiauthenticator'
}

export interface IEchoFunction {
	argumentMessageType?: IMessageType,
  code: string,
	created: IAtBy,
  description: string,
  name: string,
	readme: string,
  lastModified: IAtBy,
	requirements: Array<string>,
	system: boolean,
	tenant: ITenant,
	typeName: string,
	returnMessageType?: IMessageType,
	type: EchoFunctionType
}