import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormControl, FormHelperText, IconButton, InputLabel, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ITenant } from 'interfaces/tenant';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';
import { WebhookNodeModel } from 'models/node/webhook-node';
import { IMessageType } from 'interfaces/message-type';
import { EchoFunctionType, IEchoFunction } from 'interfaces/echo-function';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant,
  messageTypes: Array<IMessageType>,
  functionList: Array<IEchoFunction>
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const WebhookAddNode: React.FC<Props> = (props) => {

  const defaultFunctionType = '<built-in system function>';
  const none = '<none>';
  const classes = useStyles();
  const [nodeName, setNodeName] = useState('');
  const [description, setDescription] = useState('');
  const [nodeNameValid, setNodeNameValid] = useState(false);
  const [nodeValid, setNodeValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sendMessageType, setSendMessageType] = useState('');
  const [managedFunctionType, setManagedFunctionType] = useState(defaultFunctionType);
  const [inlineApiAuthenticator, setInlineApiAuthenticator] = useState("");
  const webhookauthenticatorTemplate = 'def authenticator(*, context, request, **kwargs):\n\tfrom typing import cast\n\n\tfrom fastapi import Request\n\tfrom starlette.authentication import AuthCredentials, UnauthenticatedUser\n\n\t# Optional: cast to a Request for type hinting\n\trequest = cast(Request, request)\n\n\t# Return None or a tuple as below. You may\n\t# return subclasses of AuthCredentials and BaseUser.\n\t#\n\t# Returning None is equivalent to the return below.\n\t#\n\t# If the BaseUser subclass returned returns True from\n\t# is_authenticated, then the webhook will be accepted.\n\treturn AuthCredentials(), UnauthenticatedUser()'


  useEffect(() => {
    if (managedFunctionType === '<built-in system function>') {
      setInlineApiAuthenticator(webhookauthenticatorTemplate);
    } else {
      setInlineApiAuthenticator("");
    }
  }, [managedFunctionType])

  const handleAddNode = async () => {
    if (nodeName && nodeName.length >= 4) {
      try {
        setErrorSaving('');
        setInfoSaving('');
        setSuccessSaving('');
        const params = {
          name: nodeName,
          tenant: props.tenant.name,
          description: description,
          inlineApiAuthenticator: inlineApiAuthenticator
        }
        console.log(params);
        setIsLoading(true);
        const p = await GraphQLHelper.execute<WebhookNodeModel>(MutationsStatic.createWebhookNode, params, WebhookNodeModel);
        if (!p.error) {
          const result = p.result as WebhookNodeModel;
          console.log(result);
          setSuccessSaving(`Node ${nodeName} created.`);
        } else {
          setErrorSaving(p.errorMessage);
        }
      } catch (err) {
        setErrorSaving(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create router node', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const checkIfNodeValid = () => {
    let valid = false;
    if (nodeName.length >= 4) {
      valid = true;
    }
    setNodeValid(valid);
  }

  const handleNodeNameChange = (event: any) => {
    setNodeName(event.target.value);
    setNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false);
    checkIfNodeValid();
  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/webhook', '_blank');
  }

  const isDisabled = (): boolean => {
    return (successSaving && successSaving.length > 0 ? true : false);
  }

  const sendMessageTypeChange = (event: any) => {
    setSendMessageType(event.target.value);
  };

  const getFunctionsFiltered = () => {
      //Will change filter as needed
    if (props.functionList) {
      const filtered = props.functionList.filter(o => (o.type === EchoFunctionType.apiauthenticator));
      return filtered
    }
    return [] as Array<IEchoFunction>;
  }

  const onManagedFunctionTypeChange = (event: any) => {
    setManagedFunctionType(event.target.value);
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>Add Webhook Node</div>
          <div style={{marginLeft: 'auto'}}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        Webhook Nodes are Internal Nodes that are used to 
        receive webhooks. They can receive both GET and POST
        webhooks; when the webhook is a POST, they can receive Form, JSON or
        XML data. Webhooks that are received are converted to JSON, so the send MessageType
        must be JSON-based. The default send MessageType is echo.json.
        Webhook Nodes accept a APIAuthenticator function which provides authentication
        for incoming hooks. Failure to specify this function will result in the Webhook Node
        accepting all incoming hooks.
        </DialogContentText>
        <TextField
          error={!nodeNameValid}
          helperText="Name must be atleast 3 characters in length and may contain special characters (- or _) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="webhookNodeName"
          label="Name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="DescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px' }}>
            <FormHelperText>Send Message Type must be a JSON based message type</FormHelperText>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={isDisabled()}>
            <InputLabel htmlFor="filled-age-native-simple">Send Message Type</InputLabel>
            <Select
              native
              value={sendMessageType}
              onChange={sendMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
              disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px' }}>
            <FormControl variant="filled" style={{ width: '300px' }} disabled={isDisabled()}>
              <InputLabel htmlFor="filled-age-native-simple">Api Authenticator Function</InputLabel>
              <Select
                native
                value={managedFunctionType}
                onChange={onManagedFunctionTypeChange}
                inputProps={{
                  name: "tenant",
                  id: "filled-age-native-simple"
                }}
                disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
              >
                <option aria-label={defaultFunctionType} value={defaultFunctionType}>{defaultFunctionType}</option>
                <option aria-label={none} value={none}>{none}</option>
                {getFunctionsFiltered().map((m: IEchoFunction, index: number) =>
                  <option key={index} value={m.name}>{m.name}</option>
                )}
              </Select>
            </FormControl>
          </div>
        <div style={{ marginTop: '10px'}}>
          {(errorSaving && errorSaving.length > 0) &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorSaving}
            </Alert>
          }
          {(infoSaving && infoSaving.length > 0) &&
            <Alert severity="info">
              <AlertTitle>Information</AlertTitle>
              {infoSaving}
            </Alert>
          }
          {(successSaving && successSaving.length > 0) &&
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successSaving}
            </Alert>
          }
        </div>
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={isLoading || !(nodeName && nodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/))} variant="contained" onClick={handleAddNode} color="primary">
            Add {  isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={props.onAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default WebhookAddNode;