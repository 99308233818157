import { createContext } from "react";
import { ITenantUser } from "interfaces/tenant-user";
import { TenantUserModel } from "models/tenant-user/tenant-user-model";
import { GraphQLHelper } from "utilities/graphql-helper";
import { QueriesStatic } from "graphql/queries-static";
import { ITenant } from "interfaces/tenant";
import { MutationsStatic } from "graphql/mutations-static";
import { GraphLayoutModel } from "models/tenant-user/graph-layout-model";
import { IGraphLayout } from "interfaces/graph-layout";
import { GraphLayoutListModel } from "models/tenant-user/graph-layout-list-model";

export const TenantUserEmailContext = createContext('');

export const GetTenantUser = async (tenant: ITenant, email: string) : Promise<ITenantUser> => {
  let user = new TenantUserModel();
  const params = {
    tenant: tenant.name,
    email: email
  }
  const p = await GraphQLHelper.execute<TenantUserModel>(QueriesStatic.getTenantUser, params, TenantUserModel);
  if (p.error) {
    console.log(p.errorMessage);
  } else {
    user = p.result as TenantUserModel;
    return user;
  }
  return user;
}

export const SaveGraphLayout = async (tenant: ITenant, email: string, layout: string, layoutName: string) : Promise<IGraphLayout> => {
  let graph = new GraphLayoutModel();
  const params = {
    tenant: tenant.name,
    email: email
  }
  const query = MutationsStatic.saveGraphLayout(layout, layoutName);
  const p = await GraphQLHelper.execute<GraphLayoutModel>(query, params, GraphLayoutModel);
  if (p.error) {
    console.log(p.errorMessage);
  } else {
    graph = p.result as GraphLayoutModel;
    return graph;
  }
  return graph;
}

export const GetGraphLayout = async (tenant: ITenant, email: string) : Promise<Array<IGraphLayout>> => {
  let graphs = new GraphLayoutListModel();
  const params = {
    tenant: tenant.name,
    email: email
  }
  const p = await GraphQLHelper.execute<GraphLayoutListModel>(QueriesStatic.getTenantUserGraphLayouts, params, GraphLayoutListModel);
  if (p.error) {
    console.log(p.errorMessage);
  } else {
    graphs = p.result as GraphLayoutListModel;
    return graphs.graphs;
  }
  return graphs.graphs;
}
