import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-json';
import 'prismjs/themes/prism-solarizedlight.css';

const JSONEditor = (props) => {
  return (
    <div style={{overflow: 'auto', height: '100%'}}>
      <Editor
        value={props.code}
        onValueChange={code => {props.onCodeChange && props.onCodeChange(code)}}
        highlight={code => highlight(code, languages.json)}
        padding={10}
        style={{
          fontFamily: '"Fira code", "Fira Mono", monospace',
          fontSize: 12,
          backgroundColor: '#303030',
          minHeight: '300px'
        }}
        disabled={props.isExecuting}
      />
    </div>
  );
}

export default JSONEditor;