import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormControl, FormHelperText, IconButton, InputLabel, Select } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ITenant } from 'interfaces/tenant';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MutationsStatic } from 'graphql/mutations-static';
import { IMessageType } from 'interfaces/message-type';
import { EchoFunctionType, IEchoFunction } from 'interfaces/echo-function';
import { WebSubHubNodeModel } from 'models/node/websub-hub-node';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant,
  messageTypes: Array<IMessageType>,
  functionList: Array<IEchoFunction>
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const WebSubHubAddNode: React.FC<Props> = (props) => {

  const defaultFunctionType = '<built-in system function>';
  const none = '<none>';
  const classes = useStyles();
  const [nodeName, setNodeName] = useState('');
  const [description, setDescription] = useState('');
  const [nodeNameValid, setNodeNameValid] = useState(false);
  const [nodeValid, setNodeValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [sendMessageType, setSendMessageType] = useState('');
  const [managedFunctionType, setManagedFunctionType] = useState(defaultFunctionType);
  const [inlineApiAuthenticator, setInlineApiAuthenticator] = useState("");
  const webSubHubAuthenticatorTemplate = "def authenticator(*, context, request, **kwargs):\n\timport re\n\tfrom typing import TYPE_CHECKING, cast\n\n\tfrom starlette.authentication import AuthCredentials, BaseUser\n\n\tif TYPE_CHECKING:\n\t\tfrom fastapi import Request\n\n\t\trequest = cast(Request, request)\n\telse:\n\t\tRequest = object\n\n\t# WebSubHubNodes will use the supplied user to determine both the\n\t# identity of the subscriber and whether the subscriber is\n\t# authenticated. The identity is used to specify the subscriber in \n\t# WebSubSubscriptionNodes. Note that the startlette.authentication.SimpleUser\n\t# does not implement the identity property, so you must create your own\n\t# class.\n\t#\n\t# This HostUser simply gets the IP address of the subscriber from the\n\t# request and uses that as the identity. This is a security risk and\n\t# you should replace this with a more specific identity and authentication.\n\tclass HostUser(BaseUser):\n\t\tdef __init__(self, request: Request) -> None:\n\t\t\tself.host = request.client.host\n\n\t\t@property\n\t\tdef display_name(self) -> str:\n\t\t\treturn self.host\n\n\t\t@property\n\t\tdef identity(self) -> str:\n\t\t\treturn self.host\n\n\t\t@property\n\t\tdef is_authenticated(self) -> bool:\n\t\t\treturn True\n\n\t# Creates an AuthCredentials that allows access to any topic URL.\n\t# WebSubHubNodes will use the supplied list of scopes as regular\n\t# expressions to match against the topic URL. Only topic URLs that\n\t# match one of the supplied regular expressions will be allowed.\n\t#\n\t# This is a security risk and you should replace this with a more\n\t# specific regular expression or list of regular expressions that\n\t# match your published topics.\n\tauth_credentials = AuthCredentials([r'http[s]?://.*'])\n\n\treturn auth_credentials, HostUser(request)"

  useEffect(() => {
    if (managedFunctionType === '<built-in system function>') {
      setInlineApiAuthenticator(webSubHubAuthenticatorTemplate);
    } else {
      setInlineApiAuthenticator("");
    }
  }, [managedFunctionType])

  const handleAddNode = async () => {
    if (nodeName && nodeName.length >= 4) {
      try {
        setErrorSaving('');
        setInfoSaving('');
        setSuccessSaving('');
        const params = {
          name: nodeName,
          tenant: props.tenant.name,
          description: description,
          inlineApiAuthenticator: inlineApiAuthenticator
        }
        console.log(params);
        setIsLoading(true);
        const p = await GraphQLHelper.execute<WebSubHubNodeModel>(MutationsStatic.createWebSubHubNode, params, WebSubHubNodeModel);
        if (!p.error) {
          const result = p.result as WebSubHubNodeModel;
          console.log(result);
          setSuccessSaving(`Node ${nodeName} created.`);
        } else {
          setErrorSaving(p.errorMessage);
        }
      } catch (err) {
        setErrorSaving(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create router node', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const checkIfNodeValid = () => {
    let valid = false;
    if (nodeName.length >= 4) {
      valid = true;
    }
    setNodeValid(valid);
  }

  const handleNodeNameChange = (event: any) => {
    setNodeName(event.target.value);
    setNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false);
    checkIfNodeValid();
  }

  const handleDescriptionChange = (event: any) => {
    setDescription(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/WebSubHub', '_blank');
  }

  const isDisabled = (): boolean => {
    return (successSaving && successSaving.length > 0 ? true : false);
  }

  const sendMessageTypeChange = (event: any) => {
    setSendMessageType(event.target.value);
  };

  const getFunctionsFiltered = () => {
      //Will change filter as needed
    if (props.functionList) {
      const filtered = props.functionList.filter(o => (o.type === EchoFunctionType.apiauthenticator));
      return filtered
    }
    return [] as Array<IEchoFunction>;
  }

  const onManagedFunctionTypeChange = (event: any) => {
    setManagedFunctionType(event.target.value);
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div>Add WebSubHub Node</div>
          <div style={{marginLeft: 'auto'}}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
        WebSubHub Nodes are internal Nodes that implement the 
        W3C WebSub standard (https://www.w3.org/TR/websub/). They allow
        you to implement content distribution without having to worry about the
        actual distribution mechanism. You may distribute any type of content that
        you wish, including binary content. WebSubHub Nodes use an ApiAuthenticator
        function to both authenticate new subscribers and authroize them for a topic
        (through the scopes returned by AuthCredentials).
        Collapse
        </DialogContentText>
        <TextField
          error={!nodeNameValid}
          helperText="Name must be atleast 3 characters in length and may contain special characters (- or _) ."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="WebSubHubNodeName"
          label="Name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="DescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px' }}>
            <FormControl variant="filled" style={{ width: '300px' }} disabled={isDisabled()}>
              <InputLabel htmlFor="filled-age-native-simple">Api Authenticator Function</InputLabel>
              <Select
                native
                value={managedFunctionType}
                onChange={onManagedFunctionTypeChange}
                inputProps={{
                  name: "tenant",
                  id: "filled-age-native-simple"
                }}
                disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
              >
                <option aria-label={defaultFunctionType} value={defaultFunctionType}>{defaultFunctionType}</option>
                <option aria-label={none} value={none}>{none}</option>
                {getFunctionsFiltered().map((m: IEchoFunction, index: number) =>
                  <option key={index} value={m.name}>{m.name}</option>
                )}
              </Select>
            </FormControl>
          </div>
        <div style={{ marginTop: '10px'}}>
          {(errorSaving && errorSaving.length > 0) &&
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              {errorSaving}
            </Alert>
          }
          {(infoSaving && infoSaving.length > 0) &&
            <Alert severity="info">
              <AlertTitle>Information</AlertTitle>
              {infoSaving}
            </Alert>
          }
          {(successSaving && successSaving.length > 0) &&
            <Alert severity="success">
              <AlertTitle>Success</AlertTitle>
              {successSaving}
            </Alert>
          }
        </div>
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={isLoading || !(nodeName && nodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/))} variant="contained" onClick={handleAddNode} color="primary">
            Add {  isLoading && <CircularProgress size={24} color="inherit" className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={props.onAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default WebSubHubAddNode;