import React from 'react';
import { ITenant } from 'interfaces/tenant';
import { CrossTenantReceivingAppModel } from 'models/app/cross-tenant-receiving-app';
import DisplayField from 'components/node/DisplayField';

interface Props {
    app: CrossTenantReceivingAppModel,
    tenant: ITenant
}

const CrossTenantReceivingApp: React.FC<Props> = (props) => {

    return (
        <div>
            <DisplayField title="Description" description={props.app?.description ? props.app.description : '<no description>'} />
            <DisplayField title="Sending App" description={props.app?.sendingApp ? props.app.sendingApp : '<no sending app>'} />
            <DisplayField title="Sending Tenant" description={props.app?.sendingTenant ? props.app.sendingTenant : '<no sending tenant>'} />
        </div>
    )
}

export default CrossTenantReceivingApp;