import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import EchoDialog from './EchoDialog';
import { ExternalAppModel } from 'models/app/external-app';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant
}

const ExternalAppAdd: React.FC<Props> = (props) => {

  const [appName, setAppName] = useState('');
  const [appDescription, setAppDescription] = useState('');
  const [appNameValid, setAppNameValid] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [allowTableAccess, setAllowTableAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddApp = async () => {
    if (isAppValid()) {
      try {
        setIsLoading(true);
        setInfoMessage('');
        setErrorMessage('');
        setSuccessMessage('');
        const params = {
          tenant: props.tenant.name,
          name: appName,
          description: appDescription,
          tableAccess: allowTableAccess
        }
        const p = await GraphQLHelper.execute<ExternalAppModel>(MutationsStatic.createExternalApp, params, ExternalAppModel);
        if (!p.error) {
          setSuccessMessage(`External app "${appName}" created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create external app', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleAppNameChange = (event: any) => {
    setAppName(event.target.value);
    setAppNameValid(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/));
  }

  const handleDescriptionChange = (event: any) => {
    setAppDescription(event.target.value);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/external-app', '_blank');
  }

  const isAppValid = (): boolean => {
    return (appName && appName.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false);
  }

  return (
    <>
      <EchoDialog
        onCancel={props.onCancel}
        onOk={props.onAdd}
        onSave={() => handleAddApp()}
        isValid={isAppValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title={<div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>Add External Application</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>}
        contentText="External Applications contain External Nodes and exist to group those nodes and provide access to EchoStream resources within your network to those Nodes."
        open={true}
        spinner={isLoading}
      >
        <TextField
          error={!appNameValid}
          helperText="Name should be a minimum of 3 characters and may contain special characters (. : - or _)."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="appName"
          label="External application name"
          fullWidth
          onChange={handleAppNameChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="routerDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={allowTableAccess}
              onChange={(): void => { setAllowTableAccess(!allowTableAccess); }}
              name="checkedB"
              color="primary"
            />
          }
          label="Allow Table Access"
          disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
        />
      </EchoDialog>
    </>
  );
}

export default ExternalAppAdd;