import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Checkbox, FormControl, FormControlLabel, IconButton, InputLabel, Select } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import { IMessageType } from 'interfaces/message-type';
import { ProcessorNodeModel } from 'models/node/processor-node';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { EchoFunctionType, IEchoFunction } from 'interfaces/echo-function';
import EchoDialog from './EchoDialog';
import { ExternalNodeModel } from 'models/node/external-node';
import { IApp } from 'interfaces/app';
import { NodeTypeName } from 'enumerations/nodetype-name';
import { CrossTenantSendingNodeModel } from 'models/node/cross-tenant-sending-node';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  messageTypes: Array<IMessageType>,
  tenant: ITenant,
  app: IApp | undefined,
  addNodeType: NodeTypeName,
  functionList: Array<IEchoFunction>
}

const AddNode: React.FC<Props> = (props) => {

  const defaultFunctionType = '<built-in system function>';
  const none = '<none>';
  const [processorNodeName, setProcessorNodeName] = useState('');
  const [processorDescription, setProcessorDescription] = useState('');
  const [processorNodeNameValid, setProcessorNodeNameValid] = useState(false);
  const [receiveMessageType, setReceiveMessageType] = useState('');
  const [sendMessageType, setSendMessageType] = useState('');
  const [isProcessor, setIsProcessor] = useState(false);
  const [sequentialProcessing, setSequentialProcessing] = useState(false);
  const [requirements, setRequiremenets] = useState<Array<string>>();
  const [managedFunctionType, setManagedFunctionType] = useState(defaultFunctionType);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNode = async () => {
    try {
      setIsLoading(true);
      setInfoMessage('');
      setErrorMessage('');
      setSuccessMessage('');
      let createdNode = {};
      if (props.addNodeType === NodeTypeName.ProcessorNode || props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
        let params = {};
        params = {
          tenant: props.tenant.name,
          name: processorNodeName,
          description: processorDescription,
          receiveMessageType,
          sendMessageType,
          sequentialProcessing,
          requirements
        };
        if (props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
          params = { ...params, app: props.app?.name }
        }
        const msg = props.messageTypes.find(o => o.name === receiveMessageType);
        let funcargs = {};
        if (isProcessor) {
          if (managedFunctionType !== defaultFunctionType) {
            funcargs = { ...params, managedProcessor: managedFunctionType }
          } else {
            funcargs = { ...params, inlineProcessor: msg?.processorTemplate }
          }
        } else {
          funcargs = params;
        }
        if (props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
          const p = await GraphQLHelper.execute<CrossTenantSendingNodeModel>(MutationsStatic.createCrossTenantSendingNode, funcargs, CrossTenantSendingNodeModel);
          if (!p.error) {
            setSuccessMessage(`Node ${processorNodeName} created.`);
          } else {
            setErrorMessage(p.errorMessage);
          }
        } else if (props.addNodeType === NodeTypeName.ProcessorNode) {
          const p = await GraphQLHelper.execute<ProcessorNodeModel>(MutationsStatic.createProcessorNode, funcargs, ProcessorNodeModel);
          if (!p.error) {
            setSuccessMessage(`Node ${processorNodeName} created.`);
          } else {
            setErrorMessage(p.errorMessage);
          }
        }
      } else if (props.addNodeType === NodeTypeName.ExternalNode) {
        const params = {
          app: props.app?.name,
          description: processorDescription,
          name: processorNodeName,
          receiveMessageType,
          sendMessageType,
          tenant: props.tenant.name
        }
        const p = await GraphQLHelper.execute<ExternalNodeModel>(MutationsStatic.createExternalNode, params, ExternalNodeModel);
        if (!p.error) {
          setSuccessMessage(`External Node ${processorNodeName} created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } else if (props.addNodeType === NodeTypeName.ManagedNode) {
        const params = {
          tenant: props.tenant.name,
          node: {
            name: processorNodeName,
            description: processorDescription
          },
          receiveMessageType,
          sendMessageType,
          app: props.app?.name
        }
      }
      console.log(createdNode);
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log(JSON.stringify(err));
      console.log('Can\'t create node', err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNodeNameChange = (event: any) => {
    setProcessorNodeName(event.target.value);
    setProcessorNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false);
  }

  const handleDescriptionChange = (event: any) => {
    setProcessorDescription(event.target.value);
  }

  const receiveMessageTypeChange = (event: any) => {
    setReceiveMessageType(event.target.value);
    if (props.addNodeType === NodeTypeName.ProcessorNode || props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
      setIsProcessor(true)
    }
  };

  const sendMessageTypeChange = (event: any) => {
    setSendMessageType(event.target.value);
    if (props.addNodeType === NodeTypeName.ProcessorNode || props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
      setIsProcessor(true)
    }
  };

  const onShowHelp = (url: string) => {
    window.open(url, '_blank');
  }

  const title = () => {
    if (props.addNodeType === NodeTypeName.ExternalNode) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{`Add External Node to '${props.app?.name}'`}</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp('https://docs.echo.stream/docs/external-nodes'); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>)
    } else if (props.addNodeType === NodeTypeName.ProcessorNode) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>Add Processor Node</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp('https://docs.echo.stream/docs/processor-node'); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>)
    } else if (props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>{`Add Cross Tenant Node to '${props.app?.name}'`}</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp('https://docs.echo.stream/docs/cross-tenant-sending-node'); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>)
    }
  }

  const contentText = () => {
    if (props.addNodeType === NodeTypeName.ProcessorNode) {
      return 'Processor Nodes are Internal Nodes that are used to transfer messages from one or more receiving Edges (fan-in) to one or more sending Edges (fan-out). While the transfer is occurring you may optionally tranform the message, either maintaining the Message Type or completely changing the Message Type.'
    } else if (props.addNodeType === NodeTypeName.ExternalNode) {
      return 'External Nodes are nodes that exist on the boundary of your EchoStream network, but where you implement the Node\'s processing code in your own environment (e.g. - AWS, GCP, Azure, Data Center, etc.). As such, they are conceptual entities within EchoStream.'
    } else if (props.addNodeType === NodeTypeName.CrossTenantSendingNode) {
      return 'Cross-Tenant Sending Nodes are Cross-Tenant Nodes that are used to transfer messages from one or more receiving Edges (fan-in) to another Tenant. While the transfer is occurring you may optionally tranform the message, either maintaining the Message Type or completely changing the Message Type.'
    }
    return '';
  }

  const isValid = (): boolean => {
    if (props.addNodeType === NodeTypeName.ProcessorNode) {
      return receiveMessageType && 
        processorNodeName && 
        processorNodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false;
    }
    if (props.addNodeType === NodeTypeName.ExternalNode) {
      return processorNodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false;
    }
    return (receiveMessageType || sendMessageType) && 
      processorNodeName && 
      processorNodeName.match(/^[A-Za-z0-9\-_ ]{3,80}$/) ? true : false;
  }

  const getFunctionsFiltered = () => {
    if (props.functionList) {
      const filtered = props.functionList.filter(o => (o.type === EchoFunctionType.processor && o.argumentMessageType?.name === receiveMessageType && o.returnMessageType?.name === sendMessageType));
      return filtered
    }
    return [] as Array<IEchoFunction>;
  }

  const handleChangeToProcessor = () => {
    setIsProcessor(!isProcessor);
    if (isProcessor) {
      setSendMessageType(receiveMessageType);
    }
  }

  const handleChangeSequentialProcessing = () => {
    setSequentialProcessing(!sequentialProcessing);
  }

  const onManagedFunctionTypeChange = (event: any) => {
    setManagedFunctionType(event.target.value);
  }

  return (
    <>
      <EchoDialog
        onCancel={props.onCancel}
        onOk={props.onAdd}
        onSave={() => { handleAddNode(); }}
        isValid={isValid()}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        title={title()}
        contentText={contentText()}
        open={true}
        spinner={isLoading}
      >
        <TextField
          error={!processorNodeNameValid}
          helperText="Name must be a minimum of 3 characters and may contain special characters (- or _)."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="transNodeName"
          label="Node name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="transDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
        />
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}>
            <InputLabel htmlFor="filled-age-native-simple">Receive Message Type</InputLabel>
            <Select
              native
              value={receiveMessageType}
              onChange={receiveMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
              disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        <div style={{ marginTop: '10px' }}>
          <FormControl variant="filled" style={{ width: '300px' }} disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}>
            <InputLabel htmlFor="filled-age-native-simple">Send Message Type</InputLabel>
            <Select
              native
              value={sendMessageType}
              onChange={sendMessageTypeChange}
              inputProps={{
                name: "tenant",
                id: "filled-age-native-simple"
              }}
              disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
            >
              <option aria-label="None" value="" />
              {props.messageTypes && props.messageTypes.map((m: IMessageType, index: number) =>
                <option key={index} value={m.name}>{m.name}</option>
              )}
            </Select>
          </FormControl>
        </div>
        {(props.addNodeType === NodeTypeName.ProcessorNode || props.addNodeType === NodeTypeName.CrossTenantSendingNode) &&
          <>
            <div style={{ display: 'none' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isProcessor}
                    onChange={handleChangeToProcessor}
                    name="checkedB"
                    color="primary"
                  />
                }
                label="Requires processor"
                disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sequentialProcessing}
                  onChange={handleChangeSequentialProcessing}
                  name="checkedB"
                  color="primary"
                />
              }
              label="Sequential Processing"
              disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
            />
          </>
        }
        {isProcessor && receiveMessageType && sendMessageType &&
          <div style={{ marginTop: '10px' }}>
            <FormControl variant="filled" style={{ width: '300px' }} disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}>
              <InputLabel htmlFor="filled-age-native-simple">Processor Function</InputLabel>
              <Select
                native
                value={managedFunctionType}
                onChange={onManagedFunctionTypeChange}
                inputProps={{
                  name: "tenant",
                  id: "filled-age-native-simple"
                }}
                disabled={((successMessage && successMessage.length > 0) ? true : false) || isLoading}
              >
                <option aria-label={defaultFunctionType} value={defaultFunctionType}>{defaultFunctionType}</option>
                <option aria-label={none} value={none}>{none}</option>
                {getFunctionsFiltered().map((m: IEchoFunction, index: number) =>
                  <option key={index} value={m.name}>{m.name}</option>
                )}
              </Select>
            </FormControl>
          </div>
        }
      </EchoDialog>
    </>
  );
}

export default AddNode;