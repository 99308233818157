import React from 'react';
import { BaseClass, DefaultPortCandidate, FreeNodePortLocationModel, IEdge, IEdgeReconnectionPortCandidateProvider, IEnumerable, IInputModeContext, IPortCandidate, IPortCandidateProvider, List } from 'yfiles';


export default class EdgeReconnectProvider extends BaseClass(IEdgeReconnectionPortCandidateProvider) {
    edge: IEdge;
    constructor(edge: IEdge){
        super()
        this.edge = edge
    }

    //returns possible source node port candidates
    getSourcePortCandidates(context: IInputModeContext): IEnumerable<IPortCandidate> {
        const result = new List<IPortCandidate>();
        //add the current one as the default
        result.add(new DefaultPortCandidate(this.edge.sourcePort!));
        const graph = context.graph;
        if(graph === null){
            return result
        }
        graph.nodes.forEach(node => {
            if (node !== this.edge.targetPort!.owner && node.tag.sendMessageType === this.edge.sourceNode?.tag.sendMessageType) {
                const provider = node.lookup(IPortCandidateProvider.$class)
                // If available, use the candidates from the provider. Otherwise, add a default candidate.
                if(provider instanceof IPortCandidateProvider) {
                    result.addRange(provider.getAllTargetPortCandidates(context))
                } else {
                    result.add(new DefaultPortCandidate(node, FreeNodePortLocationModel.NODE_CENTER_ANCHORED))
                }
            }
        })
        
    return result;
  }

    //returns possible target node port candidates
    getTargetPortCandidates(context: IInputModeContext): IEnumerable<IPortCandidate> {
        const result = new List<IPortCandidate>();
        //add the current one as the default
        result.add(new DefaultPortCandidate(this.edge.targetPort!));
        const graph = context.graph;
        if(graph === null){
            return result;
        }
        graph.nodes.forEach(node => {
            if (node !== this.edge.sourcePort!.owner && node.tag.receiveMessageType === this.edge.sourceNode?.tag.sendMessageType) {
                const provider = node.lookup(IPortCandidateProvider.$class)
            // If available, use the candidates from the provider. Otherwise, add a default candidate.
                if(provider instanceof IPortCandidateProvider) {
                    result.addRange(provider.getAllSourcePortCandidates(context))
                } else {
                    result.add(new DefaultPortCandidate(node, FreeNodePortLocationModel.NODE_CENTER_ANCHORED))
                }
            }
        })
        return result;
    }      
}
