import React, { useEffect, useState } from "react";
import { Box } from '@mui/material';
import CodeEditor from "components/CodeEditor";
import FunctionTester from "components/FunctionTester";
import { ITenant } from "interfaces/tenant";
import { ValidateStatic } from "graphql/validate-static";
import { ValidationType } from "enumerations/validation-type";
import { CodeType } from "interfaces/code-type";
interface Props {
  code: string,
  codeType: CodeType,
  tenant: ITenant,
  title: string,
  onChange: (code: string, codeType: CodeType, requirements: Array<string>) => void,
  functionTester: boolean
}

const EditorTemplate: React.FC<Props> = (props) => {
  const [code, setCode] = useState('');

  useEffect(() => {
    setCode(props.code);
  }, [props.code])

  const onCodeChange = (code: string) => {
    setCode(code);
    props.onChange(code, props.codeType, []);
  }

  return (
    <div style={{ height: '100%' }}>
      <Box height="250px">
        <CodeEditor code={code || ''} onCodeChange={onCodeChange} />
      </Box>
      {props.functionTester &&
      <>
        {(props.codeType !== CodeType.readme && props.codeType !== CodeType.sampleMessage) && 
          <FunctionTester
            tenant={props.tenant}
            code={code}
            functionName={'not sure?'}
            validationType={ValidationType.Function}
            receiveMessageType={undefined}
          />}
      </>
        
      }
      
    </div>
  );
};

export default EditorTemplate;
