import React, { useEffect, useState } from 'react';
import { ICredentials } from 'interfaces/credentials';
import { CredentialEntity } from 'enumerations/credential-entity';
import { ITenant } from 'interfaces/tenant';
import { QueriesStatic } from 'graphql/queries-static';
import { CredentialsModel } from 'models/credentials-model';
import { GraphQLHelper } from 'utilities/graphql-helper';
import axios from "axios";
import TextFieldCopy from './TextFieldCopy';

interface Props {
  tenant: ITenant,
  credentialEntity: CredentialEntity,
  entityKey: string,
  appsyncEndpoint?: string
}

const CredentialsView: React.FC<Props> = (props) => {
  const [credentials, setCredentials] = useState<ICredentials>(CredentialsModel.create('', '', '', ''));
  const [region, setRegion] = useState('');
  const [appsyncEndpoint, setAppsyncEndpoint] = useState('');

  const readCredentials = async () => {
    const params = {
      name: props.entityKey,
      tenant: props.tenant.name
    }
    const query = QueriesStatic.getCredentials(props.credentialEntity);
    const p = await GraphQLHelper.execute<CredentialsModel>(query, params, CredentialsModel);
    if (!p.error) {
      const cfg = p.result as CredentialsModel;
      setCredentials(cfg);
    }
    const config = await axios.get(`${window.location.protocol}//${window.location.host}/config/config.json`);
    setRegion(config.data.region);
    setAppsyncEndpoint(config.data.graphqlEndpoint);
  }

  useEffect(() => {
    if (props.tenant && props.tenant.name !== 'DEFAULT_TENANT') {
      readCredentials();
    }
    if (props.appsyncEndpoint) {
      setAppsyncEndpoint(props.appsyncEndpoint);
    }
  }, [props.tenant, props.credentialEntity, props.entityKey]);


  return (
    <div style={{ overflow: 'auto' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}>Credentials</legend>
        <TextFieldCopy value={region} label="Cognito User Pool Region" />
        <TextFieldCopy value={appsyncEndpoint} label="GraphQL Appsync Endpoint" />
        <TextFieldCopy value={credentials.clientId} label="Client Id" />
        <TextFieldCopy value={credentials.username} label="Username" />
        <TextFieldCopy value={credentials.password} label="Password" />
        <TextFieldCopy value={credentials.userPoolId} label="User Pool Id" />
      </fieldset>
    </div>
  );
}

export default CredentialsView;