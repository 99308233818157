import React from 'react';
import AddNode from 'components/ProcessorAddNode';
import { IEchoFunction } from 'interfaces/echo-function';
import { ITenant } from 'interfaces/tenant';
import { IMessageType } from 'interfaces/message-type';
import TimerAddNode from 'components/TimerAddNode';
import RouterAddNode from 'components/RouterAddNode';
import ExternalAppAdd from 'components/ExternalAppAdd';
import ManagedAppAdd from 'components/ManagedAppAdd';
import XTenantReceivingAppAdd from 'components/XTenantReceivingAppAdd';
import XTenantSendingAppAdd from 'components/XTenantSendingAppAdd';
import TenantAddEdgeDialog from './TenantAddEdgeDialog';
import { IEchoNode } from 'interfaces/echo-node';
import { IApp } from 'interfaces/app';
import { NodeTypeName } from 'enumerations/nodetype-name';
import ManagedNodeAdd from 'components/ManagedNodeAdd';
import { ManagedNodeTypeModel } from 'models/managed/managed-node-type';
import CrossAccountAppAdd from 'components/CrossAccountAppAdd';
import FilesDotComAddNode from 'components/FilesDotComAddNode';
import WebhookAddNode from 'components/WebhookAddNode';
import LoadBalancerAddNode from 'components/LoadBalancerAddNode';
import WebSubHubAddNode from 'components/WebSubHubAddNode';

export enum AddDialogType {
  None,
  ProcessorNode,
  TimerNode,
  FilesDotComWebhookNode,
  WebhookNode,
  LoadBalancerNode,
  BitmapRouterNode,
  ExteralApp,
  ManagedApp,
  XTenantSendingApp,
  XTenantNode,
  XTenantReceivingApp,
  Edge,
  ExternalNode,
  ManagedNode,
  CrossAccountApp,
  WebSubHubNode
}

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  addNodeType: NodeTypeName,
  functionList: IEchoFunction[],
  messageTypes: IMessageType[],
  app: IApp | undefined,
  tenant: ITenant,
  showDialog: AddDialogType,
  fromNode: IEchoNode | undefined,
  toNode: IEchoNode | undefined,
  managedNodeTypes: Array<ManagedNodeTypeModel>,
  isAddingManagedNode: boolean,
}

export const AddDialogs: React.FC<Props> = (props) => {
  if (props.showDialog === AddDialogType.None) {
    return <></>;
  }

  return (
    <div>
      {(props.showDialog === AddDialogType.ProcessorNode || props.showDialog === AddDialogType.XTenantNode || props.showDialog === AddDialogType.ExternalNode) &&
        <AddNode
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          functionList={props.functionList}
          addNodeType={props.addNodeType}
          app={props.app}
          tenant={props.tenant}
          messageTypes={props.messageTypes}
        />
      }
      {props.showDialog === AddDialogType.TimerNode &&
        <TimerAddNode
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
        />
      }
      {props.showDialog === AddDialogType.FilesDotComWebhookNode &&
        <FilesDotComAddNode 
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
        />
      }
      {props.showDialog === AddDialogType.WebhookNode &&
        <WebhookAddNode 
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          messageTypes={props.messageTypes}
          functionList={props.functionList}
        />
      }
      {props.showDialog === AddDialogType.WebSubHubNode &&
        <WebSubHubAddNode 
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          messageTypes={props.messageTypes}
          functionList={props.functionList}
        />
      }
      {props.showDialog === AddDialogType.LoadBalancerNode &&
        <LoadBalancerAddNode 
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          messageTypes={props.messageTypes}
        />
      }
      {props.showDialog === AddDialogType.BitmapRouterNode &&
        <RouterAddNode
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
          messageTypes={props.messageTypes}
        />
      }
      {props.showDialog === AddDialogType.ExteralApp &&
        <ExternalAppAdd
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
        />
      }
      {props.showDialog === AddDialogType.CrossAccountApp &&
        <CrossAccountAppAdd
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
        />
      }
      {props.showDialog === AddDialogType.ManagedApp &&
        <ManagedAppAdd
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
        />
      }
      {props.showDialog === AddDialogType.ManagedNode && props.app &&
        <ManagedNodeAdd
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
          managedNodeTypes={props.managedNodeTypes}
          app={props.app}
          isAddingManagedNode={props.isAddingManagedNode}
        />
      }
      {props.showDialog === AddDialogType.XTenantSendingApp &&
        <XTenantSendingAppAdd
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
        />
      }
      {props.showDialog === AddDialogType.XTenantReceivingApp &&
        <XTenantReceivingAppAdd
          tenant={props.tenant}
          onAdd={props.onAdd}
          onCancel={props.onCancel}
        />
      }
      {props.fromNode && props.toNode && (props.showDialog === AddDialogType.Edge) &&
        <TenantAddEdgeDialog
          onAdd={props.onAdd}
          onCancel={props.onCancel}
          tenant={props.tenant}
          show={props.showDialog === AddDialogType.Edge}
          fromNode={props.fromNode}
          toNode={props.toNode}
        />
      }
    </div>
  );
}