import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert, AlertTitle } from '@material-ui/lab';
import { loadingService } from 'services/common';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

interface Props {
  title: any,
  contentText: string,
  open: boolean,
  onOk: () => void,
  onCancel: () => void,
  onSave: () => void,
  isValid: boolean,
  errorMessage: string,
  infoMessage: string,
  successMessage: string,
  confirmDialog?: boolean,
  purgeDialog?: boolean,
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false,
  fullWidth?: boolean | false,
  spinner: boolean,
  //fields: ReactNode,
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const EchoDialog: React.FC<Props> = (props) => {
  const classes = useStyles();
  const {
    isValid,
    open,
    title,
    contentText,
    errorMessage,
    infoMessage,
    onSave,
    onCancel,
    onOk,
    confirmDialog,
    purgeDialog,
    children,
    successMessage } = props;
    const [isLoading, setLoadingStatus] = useState<any>(false);

    useEffect(() =>{
      loadingService.getLoading().subscribe((status)=>{
        setLoadingStatus(status);
      });
    });

  return (
    <Dialog open={open} aria-labelledby="form-dialog-title" fullWidth={props.fullWidth} maxWidth={props.size}>
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{contentText}</DialogContentText>
        {children}
        {(errorMessage && errorMessage.length > 0) &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorMessage}
          </Alert>
        }
        {(infoMessage && infoMessage.length > 0) &&
          <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            {infoMessage}
          </Alert>
        }
        {(successMessage && successMessage.length > 0) &&
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successMessage}
          </Alert>
        }
      </DialogContent>
      {(!confirmDialog && !purgeDialog && (!successMessage || successMessage.length === 0)) &&
        <DialogActions>
          <Button disabled={!isValid || (isLoading || props.spinner) } variant="contained" onClick={onSave} color="primary">
            Add { (isLoading || props.spinner) && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </Button>
          <Button disabled={(isLoading || props.spinner)} variant="contained" onClick={onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(!confirmDialog && successMessage && successMessage.length > 0) &&
        <DialogActions>
          <Button 
            variant="contained" 
            onClick={onOk} 
            color="primary">
            Ok
          </Button>
        </DialogActions>
      }
      {(confirmDialog && (!successMessage || successMessage.length === 0)) &&
        <DialogActions>
          <Button variant="contained" onClick={onSave} color="primary">
            Ok { (isLoading || props.spinner) && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </Button>
          <Button variant="contained" onClick={onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(purgeDialog && (!successMessage || successMessage.length === 0)) &&
        <DialogActions>
          <Button variant="contained" onClick={onSave} color="primary" disabled={!isValid || isLoading || props.spinner}>
            Purge { (isLoading || props.spinner) && <CircularProgress size={24} className={classes.buttonProgress}/>}
          </Button>
          <Button variant="contained" onClick={onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(confirmDialog && successMessage && successMessage.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={onOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  )
}

export default EchoDialog;