import React, { useEffect, useState } from 'react';
import { Button, Card, CardContent, Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CardHeader } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@material-ui/icons/Edit';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { ManagedNodeModel } from 'models/managed/managed-node';
import { IEchoFunction } from 'interfaces/echo-function';
import { ConfigurableEntity } from 'enumerations/configurable-entity';
import PortView from './PortView';
import { Port } from 'models/managed/port';
import MountView from './MountView';
import { Mount } from 'models/managed/mount';
import { ConfigView } from 'components/ConfigView';
import { MountInput, PortInput } from '../../models/managed/InputTypes';
import { DeleteNodeModel } from 'models/node/delete-node-model';
import NodeDelete from 'components/node/NodeDelete';
import { UserRole } from 'enumerations/user-role';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: '150px',
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }
}));

interface Props {
  node: ManagedNodeModel | undefined,
  tenant: ITenant,
  userRole: UserRole,
  onUpdateGraph: () => void,
  onCloseDialog: () => void,
  functionList: Array<IEchoFunction>,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  startNode: (name: string | undefined, typeName: string | undefined) => void,
  stopNode: (name: string | undefined, typeName: string | undefined) => void
}

const ManagedNodeDetailsView: React.FC<Props> = (props) => {

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [infoMessage, setInfoMessage] = useState('');
  const [changed, setChanged] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const classes = useStyles();
  const [ports, setPorts] = useState<Array<Port>>(new Array<Port>());
  const [mounts, setMounts] = useState<Array<Mount>>(new Array<Mount>());
  const [description, setDescription] = useState('');
  const [editing, setEditing] = useState(false);
  const [stopped, setStopped] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setPorts(new Array<Port>());
    setMounts(new Array<Mount>());
    setDescription('');
    setChanged(false);
    setEditing(false);
    if (props.node) {
      setPorts(props.node?.ports);
      setMounts(props.node?.mounts);
      setDescription(props.node.description);
    }
    if (props.node?.stopped) {
      setStopped(true);
    } else {
      setStopped(false);
    }
  }, [props.node]);

  const clearStateSaveDialog = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpenSaveDialog(!hideDialog);
  }

  const clearStateDeleteDialog = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpenDeleteDialog(!hideDialog);
  }

  const portsChanged = (prts: Array<Port>) => {
    setPorts(prts);
    setChanged(true);
  }

  const mountsChanged = (mts: Array<Mount>) => {
    setMounts(mts);
    setChanged(true);
  }

  const onUpdateNode = async () => {
    try {
      if (!props.node) {
        return;
      }
      clearStateSaveDialog(false);
      console.log('Update node');
      setIsLoading(true)
      const params = {
        tenant: props.tenant.name,
        name: props.node?.name
      }
      const mountInputs = mounts.map(o => {
        return {
          source: o.source,
          target: o.target
        } as MountInput;
      });
      const portInputs = ports.map(o => {
        return {
          containerPort: o.containerPort,
          hostAddress: o.hostAddress,
          hostPort: o.hostPort,
          protocol: o.protocol
        } as PortInput; 
      });
      const query = MutationsStatic.updateManagedNode(description, mountInputs, portInputs);
      const p = await GraphQLHelper.execute<ManagedNodeModel>(query, params, ManagedNodeModel);

      if (!p.error) {
        setSuccessMessage(`Managed node "${props.node.name}" updated.`);
        props.onUpdateGraph();
        props.setIsEditing(false);
      } else {
        setErrorMessage(p.errorMessage);
      }

    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log('Can\'t update node', err);
    } finally {
      setIsLoading(false)
    }
    setChanged(false);
    setEditing(false);
  }

  const onDeleteNode = async () => {
    console.log("onDeleteNode was called");
    try {
      setIsLoading(true)
      clearStateDeleteDialog(false);
      const params = {
        name: props.node?.name,
        tenant: props.tenant.name,
      }
      const query = MutationsStatic.deleteNode(false);
      const p = await GraphQLHelper.execute<DeleteNodeModel>(query, params, DeleteNodeModel);
      if (!p.error) {
        setSuccessMessage(`Node ${props.node?.name} deleted.`);
      } else {
        setErrorMessage(p.errorMessage);
      }
    } catch (err) {
      setErrorMessage(JSON.stringify(err));
      console.log('Can\'t delete node', err);
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      {openDeleteDialog && 
        <NodeDelete 
          node={props.node}
          tenant={props.tenant}
          onDeleted={() => { onDeleteNode(); }}
          onCancel={() => { clearStateDeleteDialog(true); }}
          onCloseDialog={() => { props.onCloseDialog(); }}
        />}
      <EchoDialog
        open={openSaveDialog}
        title={`Save managed node '${props.node?.name}' changes?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearStateSaveDialog(true)}
        onOk={() => clearStateSaveDialog(true)}
        onSave={() => onUpdateNode()}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      <Card style={{ height: "100%", display: 'flex', flexDirection: 'column' }} elevation={0}>
        <CardHeader style={{ flex: 'none', height: '10px' }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              T
            </Avatar>
          }
          title={props.node?.name}
          subheader={props.node?.description}
        />
        <CardContent style={{ height: "auto", flex: 'auto' }}>
        {stopped ? 
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "5px"}}>
          <Button onClick={() => {props.startNode(props.node?.name, props.node?.typeName); setStopped(!stopped); }}>
            <img height={24} width={24} src="Sign-Stop.svg" alt="node-stopped" style={{padding: "5px" }} />
            <Typography style={{ color: "#ff1800" }}>STOPPED</Typography>
          </Button>
        </div>
        :
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", gap: "5px"}}>
          <Button onClick={() => {props.stopNode(props.node?.name, props.node?.typeName); setStopped(!stopped); }}>
            <img height={24} width={24} src="Icon_ArrowLink.svg" alt="node-running" style={{padding: "5px" }} />
            <Typography style={{ color: "#07bc0c" }}>RUNNING</Typography>
          </Button>
        </div>
        }
          {props.node &&
            <> 
              <fieldset style={{ borderRadius: '4px', borderColor: '#888', width: '800px' }}>
                <legend style={{ fontSize: '12px' }}> Description </legend>
                <TextField
                  required
                  fullWidth
                  value={description}
                  margin="dense"
                  id="messsageArg"
                  onChange={(event: any) => { setChanged(true); setDescription(event.target.value); }}
                  InputProps={{
                    readOnly: !props.isEditing,
                  }}
                />
              </fieldset>
              <ConfigView 
                configurableEntity={ConfigurableEntity.ManagedNode} 
                entityKey={props.node.name} 
                tenant={props.tenant} 
                parentKey={props.node.parent.name} 
                userRole={props.userRole}
              />
              <PortView
                portRequirements={undefined}
                ports={ports}
                onPortsChanged={portsChanged}
              />
              <MountView 
                mounts={mounts} 
                onMountsChanged={mountsChanged} 
                mountRequirements={undefined}/>
            </>
          }
        </CardContent>
        <CardActions disableSpacing style={{ flex: 'none', height: '50px' }}>
          <Grid container justifyContent="flex-end">
            {!props.isEditing ? 

            <IconButton onClick={() => { props.setIsEditing(true); }} aria-label="Save">
              <EditIcon />
            </IconButton>
            :
            <IconButton onClick={() => { setOpenSaveDialog(true); }}>
              <SaveIcon />
            </IconButton>
            }
            <IconButton onClick={() => { setOpenDeleteDialog(true); }} aria-label="share">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </div>
  )
}

export default ManagedNodeDetailsView;