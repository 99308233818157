import { GraphItemType } from "components/graph/graph-interfaces";
import { IApp } from "interfaces/app";
import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { TenantModel } from "models/tenant/tenant-model";
import { ManagedInstanceModel } from "./managed-instance";

export class ManagedAppModel implements IApp, GraphQLData {
  hasTableAccess: boolean = true;
  graphItemType: GraphItemType = GraphItemType.app;
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  tableAccess: boolean = false;
  name: string = '';
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  iso: string = '';
  userdata: string = '';
  deleted: boolean = false;
  lastEvaluatedKey: string = '';
  managedInstances: Array<ManagedInstanceModel> = new Array<ManagedInstanceModel>();

  public static create(name: string, description: string, tenant: ITenant, typeName: string, tableAccess: boolean, managedInstances: Array<ManagedInstanceModel>): ManagedAppModel {
    const t = new ManagedAppModel();
    t.name = name;
    t.description = description;
    t.tenant = tenant;
    t.typeName = typeName;
    t.tableAccess = tableAccess;
    t.managedInstances = managedInstances;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    if (data && data.data && data.data.GetApp) {
      this.iso = data.data.GetApp.iso;
      this.userdata = data.data.GetApp.userdata;
    } else if (data && data.data && data.data.CreateManagedApp) {
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetApp');
    }
  }
}