import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { IEchoEdge } from 'interfaces/echo-edge';
import { DeleteEdgeModel } from 'models/edge/delete-edge-model';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { MoveEdgeModel } from 'models/edge/move-edge-model';
import { IEdge, INode } from 'yfiles/*';
import { IEchoNode } from 'interfaces/echo-node';
import { IGraphEdgeData, IGraphNodeData } from 'components/graph/graph-interfaces';

interface Props {
  edge: IEchoEdge | undefined,
  tenant: ITenant,
  newSource: IGraphNodeData | undefined,
  newTarget: IGraphNodeData | undefined,
  onMoved: () => void,
  onEdgeMoved: (edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => void,
  onCancel: () => void
}

const EdgeMove: React.FC<Props> = (props) => {
  const [errorMoving, setErrorMoving] = useState('');
  const [infoMoving, setInfoMoving] = useState('');
  const [successMoving, setSuccessMoving] = useState('');
  const [edge, setEdge] = useState<IEdge>();

  const clearState = () => {
    setErrorMoving('');
    setInfoMoving('');
    setSuccessMoving('');
  }

  // const onMoveEdge = async () => {
  //     console.log("onMoveEdge was called");
  //   clearState();
  //   //from source and from target
  //   const params = {
  //       source: props.edge?.source,
  //     target: props.edge?.target,
  //     tenant: props.tenant.name
  //     }
      
  //     //@ts-ignore
  //     const query = MutationsStatic.moveEdge(props.edge?.source, props.edge?.target, props.newSource?.name, props.newTarget?.name, props.tenant?.name);
  //     const p = await GraphQLHelper.execute<MoveEdgeModel>(query, params, MoveEdgeModel);
  //     if (!p.error) {
  //     const movedEdge = p.result as MoveEdgeModel;
  //     console.log(movedEdge);
  //     props.onMoved();
  //     setSuccessMoving(`Edge moved.`);
  //   } else {
  //     //@ts-ignore
  //     setErrorMoving(p.errorMessage.message);
  //   }
  // }

  return (
    <>
    </>
    //   Echo Dialog title needs to be conditional upon if the edge's source or target changed
    // <EchoDialog
    //   open={true}
    //   title={`Change nodes on EDGE: [${props.edge?.edgeUniqueKey}] to SOURCE: [${props.newSource?.name}] and TARGET: [${props.newTarget?.name}] ?`}
    //   errorMessage={errorMoving}
    //   infoMessage={infoMoving}
    //   successMessage={successMoving}
    //   confirmDialog={true}
    //   onCancel={props.onCancel}
    //   //@ts-ignore
    //   onOk={props.onEdgeMoved}
    //   onSave={() => onMoveEdge()}
    //   contentText=""
    //   isValid={true}
    // >
    //     {/* Box with info regarding the move, message counts, etc. */}
    // </EchoDialog>
  )
}

export default EdgeMove;