import { IconButton, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import { IRouteTableEntry } from 'interfaces/route-table-entry';
import { BitmapRouterNodeModel } from 'models/node/bitmap-router-node';
import { IEchoEdge } from 'interfaces/echo-edge';

interface Props {
  routes: Array<IRouteTableEntry> | undefined,
  node: BitmapRouterNodeModel,
  routesChanged: (routes: Array<IRouteTableEntry>) => void,
}

const RouteTable: React.FC<Props> = (props) => {

  const [routes, setRoutes] = useState<Array<IRouteTableEntry>>();
  const [editedRoute, setEditedRoute] = useState('');
  const [bitmap, setBitmap] = useState('');
  const [toNode, setToNode] = useState<string>('');

  useEffect(() => {
    setRoutes(props.routes);
  }, [props.routes])

  const cancelEdit = () => {
    setEditedRoute('');
  }

  const handleBitmapChange = (event: any) => {
    setBitmap(event.target.value);
  }

  const handleToNodeChange = (event: any) => {
    setToNode(event.target.value);
  }

  const saveRoute = (route: IRouteTableEntry, oldBitmap: string, oldToNode: string) => {
    const newRoutes = Array<IRouteTableEntry>();
    // remove old route
    let found = false;
    routes?.forEach(o => {
      if (o.routeNumber === oldBitmap.toString()) {
        const nodes = o.toNodes.filter(o => o !== oldToNode);
        o.toNodes = nodes;
        // remove the old node
        if (nodes.length > 0) {
          newRoutes.push(o);
        }
      } else {
        newRoutes.push(o);
      }
    });

    newRoutes?.forEach(o => {
      // add the new route if there is an existing route
      if ('' + o.routeNumber === '' + bitmap) {
        found = true;
        // do not add duplicates
        if (!o.toNodes.find(o => o === toNode)) {
          o.toNodes.push(toNode);
        }
      }
    });

    if (!found) {
      // add the new route
      const newrt = {
        routeNumber: bitmap,
        toNodes: [toNode]
      } as IRouteTableEntry;
      newRoutes.push(newrt);
    }

    setRoutes(newRoutes);
    setEditedRoute('');
    if (routes) {
      props.routesChanged(newRoutes);
    }
  }

  return (
    <div style={{ overflow: 'auto', marginTop: '10px' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}> Route Table </legend>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">Bitmap</TableCell>
                <TableCell align="left">to Node</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {routes && routes.map(o => {
                return o.toNodes.map(tt => {
                  if (editedRoute !== `${o.routeNumber}|${tt}`) {
                    return (
                      <TableRow key={`${o.routeNumber}|${tt}`}>
                        <TableCell style={{ width: '194px' }}>{o.routeNumber}</TableCell>
                        <TableCell style={{ width: '214px' }}>{tt}</TableCell>
                      </TableRow>)
                  } else {
                    return (
                      <TableRow key={`${o.routeNumber}-${tt}`}>
                        <TableCell style={{ width: '194px' }}>
                          <TextField
                            required
                            autoFocus
                            value={bitmap}
                            margin="dense"
                            id="messsageArg"
                            onChange={handleBitmapChange}
                          />
                        </TableCell>
                        <TableCell style={{ width: '214px' }}>
                          <div style={{ marginTop: '3px' }}>
                            <Select
                              native
                              value={toNode}
                              onChange={handleToNodeChange}
                              inputProps={{
                                name: "toNode",
                                id: "filled-age-native-simple"
                              }}
                            >
                              <option aria-label="None" value="" />
                              {props.node.sendEdges.map((m: IEchoEdge, index: number) =>
                                <option key={index} value={m.target}>{m.target}</option>
                              )}
                            </Select>
                          </div>
                        </TableCell>
                        <TableCell><IconButton size="small" onClick={() => saveRoute(o, o.routeNumber, tt)}><SaveIcon /></IconButton><IconButton size="small" onClick={() => { cancelEdit(); }}><CancelIcon /></IconButton></TableCell>
                      </TableRow>)
                  }
                })
              })
              }
            </TableBody>
          </Table>
        </TableContainer>
      </fieldset>
    </div>
  )
}

export default RouteTable;