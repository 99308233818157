import React, { useState, useEffect } from "react";
import { FormControl, InputLabel, Select } from "@material-ui/core";
import { IEchoFunction } from "interfaces/echo-function";
import { NodeTypeName } from "enumerations/nodetype-name";
import { IEchoNode } from "interfaces/echo-node";
import { BitmapRouterNodeModel } from "models/node/bitmap-router-node";
import { ProcessorNodeModel } from "models/node/processor-node";
import { CrossTenantSendingNodeModel } from "models/node/cross-tenant-sending-node";
import { WebhookNodeModel } from "models/node/webhook-node";

interface Props {
  node: IEchoNode,
  funcs: Array<IEchoFunction>,
  isEditing: boolean,
  onCustomSelected: () => void,
  onNoFunctionSelected: () => void,
  onManagedFunctionSelected: (funcName: string) => void,
  codeChanged: boolean
}

const ManagedFunctionHeader: React.FC<Props> = (props) => {
  
  const customFunctionType = '<custom>';
  const noFunction = '<none>';
  const [selectedFunc, setSelectedFunc] = useState('');

  useEffect(() => {
    if (props.node.typeName === NodeTypeName.BitmapRouterNode) {
      const nd = props.node as BitmapRouterNodeModel;
      if ( nd.managedBitmapper && nd.inlineBitmapper) {
        setSelectedFunc(customFunctionType)
      } else if (nd.managedBitmapper && nd.managedBitmapper.code && nd.managedBitmapper.name) {
        setSelectedFunc(nd.managedBitmapper.name)
      } else {
        setSelectedFunc(noFunction)
      }   
    } else if (props.node.typeName === NodeTypeName.ProcessorNode) {
      const nd = props.node as ProcessorNodeModel;
      if ( nd.managedProcessor && nd.inlineProcessor) {
        setSelectedFunc(customFunctionType)
      } else if (nd.managedProcessor && nd.managedProcessor.code && nd.managedProcessor.name) {
        setSelectedFunc(nd.managedProcessor.name)
      } else {
        setSelectedFunc(noFunction)
      }   
    } else if (props.node.typeName === NodeTypeName.CrossTenantSendingNode) {
      const nd = props.node as CrossTenantSendingNodeModel;
      if ( nd.managedProcessor && nd.inlineProcessor) {
        setSelectedFunc(customFunctionType)
      } else if (nd.managedProcessor && nd.managedProcessor.code && nd.managedProcessor.name) {
        setSelectedFunc(nd.managedProcessor.name)
      } else {
        setSelectedFunc(noFunction)
      }   
    } else if (props.node.typeName === NodeTypeName.WebhookNode) {
      const nd = props.node as WebhookNodeModel;
      if ( nd.managedApiAuthenticator && nd.inlineApiAuthenticator) {
        setSelectedFunc(customFunctionType)
      } else if (nd.managedApiAuthenticator && nd.managedApiAuthenticator.code && nd.managedApiAuthenticator.name) {
        setSelectedFunc(nd.managedApiAuthenticator.name)
      } else {
        setSelectedFunc(noFunction)
      }   
    } else if (props.node.typeName === NodeTypeName.WebSubHubNode) {
      const nd = props.node as WebhookNodeModel;
      if ( nd.managedApiAuthenticator && nd.inlineApiAuthenticator) {
        setSelectedFunc(customFunctionType)
      } else if (nd.managedApiAuthenticator && nd.managedApiAuthenticator.code && nd.managedApiAuthenticator.name) {
        setSelectedFunc(nd.managedApiAuthenticator.name)
      } else {
        setSelectedFunc(noFunction)
      }
    }
    if (props.codeChanged) {
      setSelectedFunc(customFunctionType)
    }
  }, [props.node, props.codeChanged]);

  const onFunctionChange = (event: any) => {
    setSelectedFunc(event.target.value);
    if (event.target.value === customFunctionType) {
      props.onCustomSelected();
    } else if (event.target.value === noFunction) {
      props.onNoFunctionSelected();
    } else {
      props.onManagedFunctionSelected(event.target.value);
    }
  }

  const getFiltered = () : Array<IEchoFunction> => {
    let filt = 'processor';
    let filtered = new Array<IEchoFunction>();
    if (props.node.typeName === NodeTypeName.BitmapRouterNode) {
      filt = 'bitmapper'
      //@ts-ignore
      filtered = props.funcs.filter(o => o.type === filt && o.argumentMessageType?.name === props.node.receiveMessageType?.name);
    } else if (props.node.typeName === NodeTypeName.WebhookNode) {
      filt = 'apiauthenticator'
      filtered = props.funcs.filter(o => o.type === filt);
    } else {
      filt = 'processor';
      //@ts-ignore
      filtered = props.funcs.filter(o => o.type === filt && o.argumentMessageType?.name === props.node.receiveMessageType?.name && o.returnMessageType?.name === props.node.sendMessageType?.name);      
    }
    return filtered;
  }

  return (
    <div style={{float: 'left'}}>
      <FormControl variant="filled" style={{ width: '200px' }}>
        <InputLabel htmlFor="filled-age-native-simple">Function</InputLabel>
        <Select
          native
          value={selectedFunc}
          onChange={onFunctionChange}
          inputProps={{
            name: "tenant",
            id: "filled-age-native-simple"
          }}
          disabled={!props.isEditing}
        >
          <option aria-label={noFunction} value={noFunction}>{noFunction}</option>
          <option aria-label={customFunctionType} value={customFunctionType}>{customFunctionType}</option>
          {getFiltered().map((m: IEchoFunction, index: number) =>
            <option key={index} value={m.name}>{m.name}</option>
          )}
        </Select>
      </FormControl>
    </div>
  )
}

export default ManagedFunctionHeader;