import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Alert, AlertTitle } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { TimerNodeModel } from 'models/node/timer-node';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant
}

const useStyles = makeStyles(() => ({
  buttonProgress: {
    color: '#F5F5F5',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
}));

const TimerAddNode: React.FC<Props> = (props) => {

  const classes = useStyles();
  const [timerNodeName, setTimerNodeName] = useState('');
  const [timerDescription, setTimerDescription] = useState('');
  const [scheduleExpression, setScheduleExpression] = useState('');
  const [scheduleExpressionValid, setScheduleExpressionValid] = useState(false);
  const [timerNodeNameValid, setTimerNodeNameValid] = useState(false);
  const [errorSaving, setErrorSaving] = useState('');
  const [infoSaving, setInfoSaving] = useState('');
  const [successSaving, setSuccessSaving] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddNode = async () => {
    try {
      console.log('adding node');
      setErrorSaving('');
      setInfoSaving('');
      setSuccessSaving('');
      setIsLoading(true);
      let params = {
        tenant: props.tenant.name,
        name: timerNodeName,
        description: timerDescription,
        scheduleExpression
      }
      let funcargs = {};
      console.log(funcargs);
      const p = await GraphQLHelper.execute<TimerNodeModel>(MutationsStatic.createTimerNode, params, TimerNodeModel);
      if (!p.error) {
        setSuccessSaving(`Node ${timerNodeName} created.`);
      } else {
        setErrorSaving(p.errorMessage);
      }
    } catch (err) {
      setErrorSaving(JSON.stringify(err));
      console.log(JSON.stringify(err));
      console.log('Can\'t create node', err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNodeNameChange = (event: any) => {
    setTimerNodeName(event.target.value);
    setTimerNodeNameValid(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) ? true : false);
  }

  const handleDescriptionChange = (event: any) => {
    setTimerDescription(event.target.value);
  }

  const scheduledExpressionRegEx = new RegExp(/^(\*|(?:\*|(?:[0-9]|(?:[1-5][0-9])))\/(?:[0-9]|(?:[1-5][0-9]))|(?:[0-9]|(?:[1-5][0-9]))(?:(?:\-[0-9]|\-(?:[1-5][0-9]))?|(?:\,(?:[0-9]|(?:[1-5][0-9])))*)) (\*|(?:\*|(?:\*|(?:[0-9]|1[0-9]|2[0-3])))\/(?:[0-9]|1[0-9]|2[0-3])|(?:[0-9]|1[0-9]|2[0-3])(?:(?:\-(?:[0-9]|1[0-9]|2[0-3]))?|(?:\,(?:[0-9]|1[0-9]|2[0-3]))*)) (\*|\?|L(?:W|\-(?:[1-9]|(?:[12][0-9])|3[01]))?|(?:[1-9]|(?:[12][0-9])|3[01])(?:W|\/(?:[1-9]|(?:[12][0-9])|3[01]))?|(?:[1-9]|(?:[12][0-9])|3[01])(?:(?:\-(?:[1-9]|(?:[12][0-9])|3[01]))?|(?:\,(?:[1-9]|(?:[12][0-9])|3[01]))*)) (\*|(?:[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)(?:(?:\-(?:[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))?|(?:\,(?:[1-9]|1[012]|JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC))*)) (\*|\?|[0-6](?:L|\#[1-5])?|(?:[0-6]|SUN|MON|TUE|WED|THU|FRI|SAT)(?:(?:\-(?:[0-6]|SUN|MON|TUE|WED|THU|FRI|SAT))?|(?:\,(?:[0-6]|SUN|MON|TUE|WED|THU|FRI|SAT))*)) (\*|(?:[1-9][0-9]{3})(?:(?:\-[1-9][0-9]{3})?|(?:\,[1-9][0-9]{3})*))$/);

  const handleScheduleExpressionChange = (event: any) => {
    console.log(event.target.value);
    const matches = scheduledExpressionRegEx.exec(event.target.value);
    setScheduleExpressionValid(false);
    if (matches) {
      console.log(matches.length);
    }
    if (matches && matches.length === 7) {
      console.log(matches[3]);
      console.log(matches[6]);
      console.log(scheduledExpressionRegEx.test(event.target.value));
      // can't have both group 3 and 6 be a *
      if (!(matches[3] === '*' && matches[6] === '*')) {
        setScheduleExpressionValid(scheduledExpressionRegEx.test(event.target.value));
        setScheduleExpression(event.target.value);
      }
    }
  }

  const onShowHelp = (url: string) => {
    window.open(url, '_blank');
  }

  const title = () => {
    return <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div>Add Timer Node</div>
      <div style={{ marginLeft: 'auto' }}>
        <IconButton onClick={() => { onShowHelp('https://docs.echo.stream/docs/timer-node'); }}>
          <HelpOutlineIcon />
        </IconButton>
      </div>
    </div>
  }

  const contentText = () => {
    return 'Timer nodes runs on a schedule.'
  }

  const isValid = () => {
    return timerNodeName && timerNodeName.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/) && scheduleExpressionValid;
  }

  return (
    <Dialog open={true} aria-labelledby="form-dialog-title" >
      <DialogTitle id="form-dialog-title">
        {title()}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {contentText()}
        </DialogContentText>
        <TextField
          error={!timerNodeNameValid}
          helperText="Name must be a minimum of 3 characters and may contain special characters (- _ . or :)."
          variant="outlined"
          required
          autoFocus
          margin="dense"
          id="transNodeName"
          label="Node name"
          fullWidth
          onChange={handleNodeNameChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          variant="outlined"
          margin="dense"
          id="transDescriptionName"
          label="Description"
          fullWidth
          onChange={handleDescriptionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        <TextField
          error={!scheduleExpressionValid}
          variant="outlined"
          margin="dense"
          id="scheduleExpression"
          label="Schedule Expression"
          fullWidth
          onChange={handleScheduleExpressionChange}
          disabled={((successSaving && successSaving.length > 0) ? true : false) || isLoading}
        />
        {(errorSaving && errorSaving.length > 0) &&
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {errorSaving}
          </Alert>
        }
        {(infoSaving && infoSaving.length > 0) &&
          <Alert severity="info">
            <AlertTitle>Information</AlertTitle>
            {infoSaving}
          </Alert>
        }
        {(successSaving && successSaving.length > 0) &&
          <Alert severity="success">
            <AlertTitle>Success</AlertTitle>
            {successSaving}
          </Alert>
        }
      </DialogContent>
      {(!successSaving || successSaving.length === 0) &&
        <DialogActions>
          <Button disabled={!isValid()} variant="contained" onClick={() => { handleAddNode(); }} color="primary">
            Add  {isValid() && isLoading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
          <Button disabled={isLoading} variant="contained" onClick={props.onCancel} color="primary">
            Cancel
          </Button>
        </DialogActions>
      }
      {(successSaving && successSaving.length > 0) &&
        <DialogActions>
          <Button variant="contained" onClick={props.onAdd} color="primary">
            Ok
          </Button>
        </DialogActions>
      }
    </Dialog>
  );
}

export default TimerAddNode;