import React, { useEffect, useState } from 'react'
import EchoDialog from './EchoDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import { ManagedAppModel } from 'models/managed/managed-app';
import { ManagedInstanceModel } from 'models/managed/managed-instance';
import { DeregisterInstanceModel } from 'models/managed/deregister-instance-model';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { PingStatus } from 'enumerations/ping-status';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from '@material-ui/core';
import { IManagedInstance } from 'interfaces/managed-instance';

const useStyles = makeStyles(() => ({
    connectionLost: {
        color: '#FF0000'
    }
}))

interface Props {
    app: ManagedAppModel | undefined,
    tenant: ITenant,
    closeDialog: () => void
}

const ManagedInstance: React.FC<Props> = (props) => {

    const [open, setOpen] = useState(false);
    const [instances, setInstances] = useState<Array<ManagedInstanceModel>>();
    const [managedInstance, setManagedInstance] = useState<ManagedInstanceModel>(new ManagedInstanceModel('', '', '', '', '', PingStatus.ConnectionLost, new Date()));
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        setInstances(props.app?.managedInstances);
    }, [])
    
    const deregisterInstance = async (instance: IManagedInstance) => {
        console.log("deregisterInstance", managedInstance);
        try {
            setIsLoading(true)
            const params = {
                name: props.app?.name,
                tenant: props.tenant.name
            }
            const query = MutationsStatic.deregisterManagedInstance(managedInstance.instanceId);
            const p = await GraphQLHelper.execute<DeregisterInstanceModel>(query, params, DeregisterInstanceModel);
            if (!p.error) {
                setSuccessMessage(`Managed Instance: ${managedInstance?.instanceId} was successfully deregistered`);
                setInstances(prevState => prevState?.filter(inst => inst.instanceId !== instance.instanceId));            // setInstances(props.app?.managedInstances);
                clearState();
            } else {
                setErrorMessage(p.errorMessage);
                console.log('Can\'t delete managed instance', p.errorMessage);
            }
        } catch (err) {
            console.log('Can\'t delete managed instance', err);
        } finally {
            setIsLoading(false)
        }
    }

    const clearState = () => {
        setOpen(false);
        setSuccessMessage('');
        setErrorMessage('');
        setManagedInstance(new ManagedInstanceModel('', '', '', '', '', PingStatus.ConnectionLost, new Date()));
    }
        
  return (
    <div style={{ overflow: 'auto', marginTop: '10px' }}>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
            <legend style={{ fontSize: '12px' }}> Managed Instances </legend>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Hostname</TableCell>
                            <TableCell align="left">Instance ID</TableCell>
                            <TableCell align="left">IP Address</TableCell>
                            <TableCell align="left">Last Ping Date</TableCell>
                            <TableCell align="left">Ping Status</TableCell>
                            <TableCell align="left">Registration Date</TableCell>
                            <TableCell align="left">Deregister Instance</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {instances && instances.map(instance => 
                            <TableRow key={instance.instanceId}>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.hostname}</TableCell>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.instanceId}</TableCell>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.ipAddress}</TableCell>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.lastPingDateTime}</TableCell>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.pingStatus}</TableCell>
                                <TableCell className={instance.pingStatus === "ConnectionLost" ? classes.connectionLost : ''}>{instance.registrationDate}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => { setManagedInstance(instance); setOpen(true); }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </fieldset>
    <EchoDialog 
        title={`Deregister Managed Instance: ${managedInstance?.instanceId}?`} 
        confirmDialog={true}
        contentText={''} 
        open={open} 
        onOk={() => { clearState(); }} 
        onCancel={() => { clearState(); }} 
        onSave={() => { deregisterInstance(managedInstance); }} 
        isValid={true} 
        errorMessage={errorMessage} 
        successMessage={successMessage}
        infoMessage={''} 
        spinner={isLoading}
    />
    </div>
  )
}

export default ManagedInstance;