import React from 'react';
import { MountRequirement } from 'models/managed/mount-requirement';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';

interface Props {
  mountRequirements: Array<MountRequirement> | undefined,
  label: string
}

const MountRequirementsView: React.FC<Props> = (props) => {
  return (
    //NOT MY CODE, DIDN'T WANT TO JUST DELETE IT. 
    // <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '700px' }}>
    //   <legend style={{ fontSize: '12px' }}>{props.label} {(!props.mountRequirements || props.mountRequirements.length === 0) && ' - (not configured)'}</legend>
    //   {
    //     props.mountRequirements?.map((o, index) =>
    //       <div>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Description</legend>
    //           {o.description}
    //         </fieldset>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Source</legend>
    //           {o.source ? o.source : '<no source>'}
    //         </fieldset>
    //         <fieldset id={`fieldset-container-path-${index}`} style={{ borderRadius: '4px', borderColor: '#888', float: 'left' }}>
    //           <legend style={{ fontSize: '12px' }}>Target</legend>
    //           {o.target ? o.target : '<no target>'}
    //         </fieldset>
    //       </div>
    //     )
    //   }
    // </fieldset>

    <div style={{ overflow: 'auto', marginTop: '10px' }}>
        <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
          <legend style={{ fontSize: '12px' }}> Mount Requirements </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Target</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Source</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.mountRequirements?.map((o, index) => 
                  <>
                    <TableRow key={`${o.source}~${o.target}`}>
                      <TableCell style={{ width: '200px' }}>{o.target}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.description}</TableCell>
                      <TableCell style={{ width: '200px' }}>{o.source ? o.source : 'No Source Set'}</TableCell>
                    </TableRow>
                  </>
                )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </fieldset>
      </div>



  );
}

export default MountRequirementsView;