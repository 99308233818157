import React, { useState, useEffect } from "react";
import DisplayField from "components/node/DisplayField";
import { ConfigurableEntity } from "enumerations/configurable-entity";
import { ConfigView } from "components/ConfigView";
import { ITenant } from "interfaces/tenant";
import CredentialsView from "components/CredentialsView";
import { CredentialEntity } from "enumerations/credential-entity";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import { ExternalAppModel } from "models/app/external-app";
import { CrossAccountAppModel } from "models/app/cross-account-app";
import { QueriesStatic } from "graphql/queries-static";
import { GraphQLHelper } from "utilities/graphql-helper";
import { CredentialsModel } from "models/credentials-model";
import { IAMPolicyModel } from "models/iam-policy-model";
import JSONEditor from "components/JSONEditor";
import { UserRole } from "enumerations/user-role";

interface Props {
  app: CrossAccountAppModel,
  tenant: ITenant,
  userRole: UserRole,
  onTableAccessChange: (tableAccess: boolean) => void,
  isEditing: boolean,
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>,
  tableAccess: boolean,
  setTableAccess: React.Dispatch<React.SetStateAction<boolean>>,
  description: string,
  setDescription: React.Dispatch<React.SetStateAction<string>>,
  onDescriptionChange: (e: any) => void
}

const CrossAccountApp: React.FC<Props> = (props) => {
  
  const [policy, setPolicy] = useState('');
  const [allowTableAccess, setAllowTableAccess] = useState(false);
  
  useEffect(() => {
    console.log("CrossAccountApp", props.app);
    setAllowTableAccess(props.app.tableAccess);
    readIAMPolicy();
  }, [props.app]);
  

  const readIAMPolicy = async () => {
    const params = {
      name: props.app.name,
      tenant: props.tenant.name
    }
    const p = await GraphQLHelper.execute<IAMPolicyModel>(QueriesStatic.getIAMPolicy, params, IAMPolicyModel);
    if (!p.error) {
      const cfg = p.result as IAMPolicyModel;
      setPolicy(cfg.policy);
    }
  }

  return (
    <div>
      <TextField
        onChange={(e: any) => {
          props.onDescriptionChange(e);
        }}
        variant="outlined"
        value={props.description}
        margin="dense"
        id="description"
        label="Description"
        fullWidth
        InputProps={{
          readOnly: !props.isEditing,
        }}
      />
      <DisplayField title="Account Number" description={props.app.account ? props.app.account : '<no account>'} />
      <div style={{ clear: 'both' }}>
        <ConfigView 
          tenant={props.tenant} 
          configurableEntity={ConfigurableEntity.CrossAccountApp} 
          entityKey={props.app.name} 
          parentKey={''} 
          userRole={props.userRole}
        />
      </div>
      <CredentialsView 
        tenant={props.tenant} 
        credentialEntity={CredentialEntity.CrossAccountApp}
        entityKey={props.app.name} 
        appsyncEndpoint={props.app.appsyncEndpoint}
      />
      {/* <AWSCredentialsView tenant={props.tenant} entityKey={props.app.name} /> */}
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}> IAM Policy </legend>
        <JSONEditor code={JSON.stringify(JSON.parse(policy || '{}'), null, 2)} />
      </fieldset>
      <FormControlLabel
        control={
          <Checkbox
            checked={allowTableAccess}
            onChange={(): void => { setAllowTableAccess(!allowTableAccess); props.onTableAccessChange(!allowTableAccess); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Allow Table Access"
      />
    </div>
  )
}

export default CrossAccountApp