import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { MountRequirement } from "./mount-requirement";
import { PortRequirement } from "./port-requirement";
import { IEchoEdge } from "interfaces/echo-edge";
import { ManagedAppModel } from "./managed-app";
import { IApp } from "interfaces/app";
import { Port } from "./port";
import { Mount } from "./mount";

export class ManagedNodeModel implements IEchoNode, GraphQLData {
  receiveEdges?: IEchoEdge[] | undefined;
  sendEdges: IEchoEdge[] = new Array<IEchoEdge>();
  config: string = '';
  created: IAtBy = new AtByModel('', '');
  deleted: boolean = false;
  description: string = '';
  lastEvaluatedKey: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = '';
  receiveMessageType: IMessageType = new MessageTypeModel();
  sendMessageType: IMessageType = new MessageTypeModel();
  tenant: ITenant = new TenantModel();
  parent: IApp = new ManagedAppModel();
  typeName: string = '';
  ports: Port[] = new Array<Port>();
  mounts: Mount[] = new Array<Mount>();
  stopped: boolean = false;

  public static create(
    name: string,
    description: string,
    typeName: string,
    parent: IApp,
    config: string,
    receiveMessageType: IMessageType,
    receiveEdges: Array<IEchoEdge>,
    sendMessageType: IMessageType,
    sendEdges: Array<IEchoEdge>,
    ports: Array<Port>,
    mounts: Array<Mount>,
    stopped: boolean
    ): ManagedNodeModel {
    const t = new ManagedNodeModel();
    t.config = config;
    t.name = name;
    t.description = description;
    t.receiveMessageType = receiveMessageType;
    t.receiveEdges = receiveEdges;
    t.sendMessageType = sendMessageType;
    t.sendEdges = sendEdges;
    t.parent = parent;
    t.typeName = typeName;
    t.ports = ports;
    t.mounts = mounts;
    t.stopped = stopped;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
    // let md = null;
    // if (data && data.data && data.data.CreateManagedNode) {
    //   md = data.data.CreateManagedNode
    // } else if (data && data.data && data.data.GetManagedNode && data.data.GetManagedNode.Update) {
    //   md = data.data.GetManagedNode.Update
    // } else if (data && data.data && data.data.GetManagedNode && data.data.GetManagedNode) {
    //   md = data.data.GetManagedNode
    // } else {
    //   throw new Error('data, data.data or data.data.CreateManagedNode, GetManagedNode, GetManagedNode.Update empty');
    // }

    // if (md) {
    //   this.config = md.config;
    //   this.name = md.name;
    //   this.description = md.description;
    //   this.receiveMessageType = md.receiveMessageType;
    //   this.receiveEdges = md.receiveEdges;
    //   this.sendMessageType = md.sendMessageType;
    //   this.sendEdges = md.sendEdges;
    //   this.parent = md.parent;
    //   this.typeName = md.typeName;
    //   this.ports = md.ports;
    //   this.mounts = md.mounts;
    //   this.created = new AtByModel(md.created.at, md.created.by);
    //   this.lastModified = new AtByModel(md.lastModified.at, md.lastModified.by);
    // }
  }
}
