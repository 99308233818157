import React, { useState } from 'react';
import { TableRow, TableCell, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Mount } from 'models/managed/mount';
import ManagedNodeMountForm from './ManagedNodeMountForm';

interface Props {
    mount: Mount,
    updateMount: (newMount: Mount) => void,
    cancelEdit: () => void,
    setEdits: React.Dispatch<React.SetStateAction<Mount>>,
    setSelectedMount: React.Dispatch<React.SetStateAction<Mount>>
}

const ManagedNodeMount: React.FC<Props> = (props) => {

    const [isEditing, setIsEditing] = useState(false);
    const [mount, setMount] = useState(new Mount(props.mount.description, props.mount.source, props.mount.target));

    const editMount = () => {
        props.setSelectedMount(props.mount)
        setIsEditing(true);
    }

    return (
        <>
        {!isEditing && props.mount ? 
        <>
            <TableCell style={{ width: '150px' }} >{props.mount.target}</TableCell>
            <TableCell style={{ width: '200px' }} >{props.mount.description}</TableCell>
            <TableCell style={{ width: '200px' }} onClick={() => editMount()}>{props.mount.source}</TableCell>
        </>
        :
            <ManagedNodeMountForm 
                mount={mount}
                cancelEdit={props.cancelEdit}
                updateMount={props.updateMount}
                toggleEdit={setIsEditing}
                setEdits={props.setEdits}
                isEditing={isEditing}
            />
          
        }
    </>

    );
}
export default ManagedNodeMount;