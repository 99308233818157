import React, { useEffect, useState } from "react";
import { Card, CardContent, Grid, makeStyles } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { CardHeader } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { CardActions } from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import CancelIcon from "@material-ui/icons/Cancel";
import EditIcon from "@material-ui/icons/Edit";
import EchoDialog from "components/EchoDialog";
import ExternalApp from "./apps/ExternalApp";
import ManagedApp from "./apps/ManagedApp";
import { ITenant } from "interfaces/tenant";
import { IEchoEdge } from "interfaces/echo-edge";
import { IEchoNode } from "interfaces/echo-node";
import { IApp } from "interfaces/app";
import { AppTypeName } from "enumerations/apptype-name";
import { ManagedAppModel } from "models/managed/managed-app";
import { MutationsStatic } from "graphql/mutations-static";
import { ExternalAppModel } from "models/app/external-app";
import { GraphQLHelper } from "utilities/graphql-helper";
import CrossAccountApp from "./apps/CrossAccountApp";
import { CrossAccountAppModel } from "models/app/cross-account-app";
import AppDelete from "./apps/AppDelete";
import NodeDelete from "./node/NodeDelete";
import CrossTenantSendingApp from "./apps/CrossTenantSendingApp";
import { CrossTenantSendingAppModel } from "models/app/cross-tenant-sending-app";
import CrossTenantReceivingApp from "./apps/CrossTenantReceivingApp";
import { CrossTenantReceivingAppModel } from "models/app/cross-tenant-receiving-app";
import { UserRole } from "enumerations/user-role";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	avatar: {
		backgroundColor: grey[500],
	},
	icon: {
		backgroundColor: grey[700],
	},
	card: {
		height: "150px",
		expand: {
			transform: "rotate(0deg)",
			marginLeft: "auto",
			transition: theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			}),
		},
		expandOpen: {
			transform: "rotate(180deg)",
		},
	},
}));

interface Props {
	edge: IEchoEdge | undefined;
	node: IEchoNode | undefined;
	app: IApp | undefined;
	tenant: ITenant;
	userRole: UserRole;
	onUpdateGraph: () => void;
	onShouldCloseDialog: () => void;
	onCloseDialog: () => void;
	isEditing: boolean;
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const SimpleComponentDetails: React.FC<Props> = (props) => {
	
	const [open, setOpen] = useState(false);
	const [openSaveDialog, setOpenSaveDialog] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [infoMessage, setInfoMessage] = useState("");
	const [edgeChanged, setEdgeChanged] = useState(false);
	const [successMessage, setSuccessMessage] = useState("");
	const [tableAccessChanged, setTableAccessChanged] = useState(false);
	const [tableAccess, setTableAccess] = useState(false);
	const [description, setDescription] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const classes = useStyles();

	useEffect(() => {
		if (props.app?.tableAccess) {
			setTableAccess(props.app.tableAccess);
		}
		if (props.app?.description) {
			setDescription(props.app.description);
		}
	}, [])

	const clearState = (hideDialog: boolean) => {
		setErrorMessage("");
		setInfoMessage("");
		setSuccessMessage("");
		setOpen(!hideDialog);
	};

	const clearStateSaveDialog = (hideDialog: boolean) => {
		setErrorMessage("");
		setInfoMessage("");
		setSuccessMessage("");
		setOpenSaveDialog(!hideDialog);
	};

	const onUpdateComponent = async () => {
		try {
			setIsLoading(true)
			setErrorMessage("");
			setSuccessMessage("");
			if (props.app?.hasTableAccess) {
				const params = {
					name: props.app.name,
					tenant: props.tenant.name,
				};

				const updateApp = MutationsStatic.updateExternalApp(description, tableAccess);
				const appChanged = await GraphQLHelper.execute<ExternalAppModel>(
					updateApp,
					params,
					ExternalAppModel
				);
				if (appChanged.error) {
					setErrorMessage(appChanged.errorMessage);
				} else {
					setSuccessMessage("App updated");
					setTableAccessChanged(false);
					props.setIsEditing(false);
				}
			}
			if (props.app?.typeName === AppTypeName.CrossTenantSendingApp) {
				const params = {
					name: props.app.name,
					tenant: props.tenant.name
				};

				const updateApp = MutationsStatic.updateCrossTenantSendingApp(description);
				const p = await GraphQLHelper.execute<CrossTenantSendingAppModel>(updateApp, params, CrossTenantSendingAppModel);
				if (p.error) {
					setErrorMessage(p.errorMessage);
				} else {
					setSuccessMessage("App updated");
					props.setIsEditing(false);
				}
			}
		} catch (err) {
			console.log(err)
		} finally {
			setIsLoading(false)
		}
		
	};

	let title = "";
	let subheader = "";

	if (props.node) {
		title = props.node.name;
		subheader = props.node.typeName;
	}

	if (props.app) {
		title = props.app.name;
		subheader = props.app.typeName;
	}

	const onDescriptionChange = (e: any) => {
		setDescription(e.target.value);
	}

	const onCancel = () => {
		props.setIsEditing(false);
		setTableAccessChanged(false);
		setTableAccess(false);
	}

	return (
		<div
			style={{
				height: "100%",
				display: "flex",
				flexDirection: "column",
				overflow: "auto",
			}}
		>
			{open && props.app && (
				<AppDelete
					app={props.app}
					tenant={props.tenant}
					onDeleted={() => {
						clearState(true);
						props.onShouldCloseDialog();
						props.onUpdateGraph();
					}}
					onCancel={() => clearState(true)}
				/>
			)}
			{open && props.node && (
				<NodeDelete
					node={props.node}
					tenant={props.tenant}
					onDeleted={() => {
						clearState(true);
						props.onShouldCloseDialog();
						props.onUpdateGraph();
					}}
					onCancel={() => clearState(true)}
					onCloseDialog={props.onCloseDialog}
				/>
			)}
			<EchoDialog
				open={openSaveDialog}
				title={`Save ${title} changes?`}
				errorMessage={errorMessage}
				infoMessage={infoMessage}
				successMessage={successMessage}
				confirmDialog={true}
				onCancel={() => clearStateSaveDialog(true)}
				onOk={() => clearStateSaveDialog(true)}
				onSave={() => onUpdateComponent()}
				contentText=""
				isValid={true}
				spinner={isLoading}
			/>
			<Card style={{ display: "flex", flexDirection: "column" }} elevation={3}>
				<CardHeader
					style={{ flex: "none", height: "50px" }}
					avatar={
						<Avatar aria-label="recipe" className={classes.avatar}>
							T
						</Avatar>
					}
					title={title}
					subheader={subheader}
				/>
				<CardContent style={{ flex: "auto" }}>
					{props.app && props.app.typeName === AppTypeName.ExternalApp && (
						<ExternalApp
							tenant={props.tenant}
							app={props.app as ExternalAppModel}
							onTableAccessChange={(changed) => {
								setTableAccessChanged(changed);
							}}
							tableAccess={tableAccess}
							setTableAccess={setTableAccess}
							isEditing={props.isEditing}
							setIsEditing={props.setIsEditing}
							description={description}
							setDescription={setDescription}
							onDescriptionChange={(e: any) => { onDescriptionChange(e); }}
							userRole={props.userRole}
						/>
					)}
					{props.app && props.app.typeName === AppTypeName.ManagedApp && (
						<ManagedApp
							app={props.app as ManagedAppModel}
							tenant={props.tenant}
							onTableAccessChange={(changed) => {
								setTableAccessChanged(changed);
							}}
							tableAccess={tableAccess}
							setTableAccess={setTableAccess}
							isEditing={props.isEditing}
							setIsEditing={props.setIsEditing}
							description={description}
							setDescription={setDescription}
							onDescriptionChange={(e: any) => { onDescriptionChange(e); }}
							userRole={props.userRole}
						/>
					)}
					{props.app && props.app.typeName === AppTypeName.CrossAccountApp && (
						<CrossAccountApp
							app={props.app as CrossAccountAppModel}
							tenant={props.tenant}
							onTableAccessChange={(changed) => {
								setTableAccessChanged(changed);
							}}
							tableAccess={tableAccess}
							setTableAccess={setTableAccess}
							isEditing={props.isEditing}
							setIsEditing={props.setIsEditing}
							description={description}
							setDescription={setDescription}
							onDescriptionChange={(e: any) => { onDescriptionChange(e); }}
							userRole={props.userRole}
						/>
					)}
					{props.app &&
						props.app.typeName === AppTypeName.CrossTenantSendingApp && (
							<CrossTenantSendingApp
								app={props.app as CrossTenantSendingAppModel}
								tenant={props.tenant}
								isEditing={props.isEditing}
								setIsEditing={props.setIsEditing}
								description={description}
								setDescription={setDescription}
								onDescriptionChange={(e: any) => { onDescriptionChange(e); }}	
							/>
						)}
					{props.app &&
						props.app.typeName === AppTypeName.CrossTenantReceivingApp && (
							<CrossTenantReceivingApp
								app={props.app as CrossTenantReceivingAppModel}
								tenant={props.tenant}
								
							/>
						)}
				</CardContent>
				<CardActions disableSpacing style={{ flex: "none", height: "50px" }}>
					<Grid container justifyContent="flex-end">
						{!props.isEditing && !tableAccessChanged && !edgeChanged ?
							<IconButton aria-label="Save" onClick={() => { props.setIsEditing(true); }}>
								<EditIcon />
							</IconButton>
							:
							<>
								<IconButton aria-label="Save" onClick={() => { onCancel(); }}>
									<CancelIcon />
								</IconButton>
								<IconButton aria-label="Save" onClick={() => { setOpenSaveDialog(true); }}>
									<SaveIcon />
								</IconButton>
							</>
						}
						<IconButton
							onClick={() => {
								setOpen(true);
							}}
							aria-label="share"
						>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</CardActions>
			</Card>
		</div>
	);
};

export default SimpleComponentDetails;
