import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { FormControl, IconButton } from '@material-ui/core';
import { ITenant } from 'interfaces/tenant';
import FormHelperText from '@material-ui/core/FormHelperText';
import EchoDialog from './EchoDialog';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { CrossTenantSendingAppModel } from 'models/app/cross-tenant-sending-app';

interface Props {
  onAdd: () => void,
  onCancel: () => void,
  tenant: ITenant
}

const XTenantSendingAppAdd: React.FC<Props> = (props) => {

  const [appName, setAppName] = useState('');
  const [receivingTenant, setReceivingTenant] = useState('');
  const [receivingApp, setReceivingApp] = useState('');
  const [appNameValid, setAppNameValid] = useState(false);
  const [receivingAppValid, setReceivingAppValid] = useState(false);
  const [receivingTenantValid, setReceivingTenantValid] = useState(false);
  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleAddApp = async () => {
    if (appNameValid && receivingAppValid && receivingTenantValid) {
      try {
        setIsLoading(true);
        setInfoMessage('');
        setErrorMessage('');
        setSuccessMessage('');
        const params = {
          tenant: props.tenant.name,
          name: appName,
          receivingTenant: receivingTenant,
          receivingApp: receivingApp
        }
        const p = await GraphQLHelper.execute<CrossTenantSendingAppModel>(MutationsStatic.createCrossTenantSendingApp, params, CrossTenantSendingAppModel);
        if (!p.error) {
          setSuccessMessage(`Cross-Tenant Sending app ${appName} created.`);
        } else {
          setErrorMessage(p.errorMessage);
        }
      } catch (err) {
        setErrorMessage(JSON.stringify(err));
        console.log(JSON.stringify(err));
        console.log('Can\'t create XTenant Sending app', err);
      } finally {
        setIsLoading(false);
      }
    }
  }

  const handleAppNameChange = (event: any) => {
    setAppName(event.target.value);
    setAppNameValid(event.target.value.match(/^[A-Za-z0-9\-_\.: ]{3,80}$/));
  }

  const handleReceivingTenantChange = (event: any) => {
    setReceivingTenant(event.target.value);
    setReceivingTenantValid(event.target.value.length > 4);
  }

  const handleReceivingAppChange = (event: any) => {
    setReceivingApp(event.target.value);
    setReceivingAppValid(event.target.value.length > 4);
  }

  const onShowHelp = () => {
    window.open('https://docs.echo.stream/docs/cross-tenant-app', '_blank');
  }

  return (
    <EchoDialog
      onCancel={props.onCancel}
      onOk={props.onAdd}
      onSave={async () => { await handleAddApp(); }}
      isValid={appNameValid && receivingAppValid && receivingTenantValid}
      errorMessage={errorMessage}
      infoMessage={infoMessage}
      successMessage={successMessage}
      title={
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>Add Cross-Tenant Sending Application</div>
          <div style={{ marginLeft: 'auto' }}>
            <IconButton onClick={() => { onShowHelp(); }}>
              <HelpOutlineIcon />
            </IconButton>
          </div>
        </div>}
      contentText="Add a tenant to send the messages."
      open={true}
      spinner={isLoading}
    >
      <TextField
        error={!appNameValid}
        helperText="Name should be a minimum of 3 characters and may contain special characters (. : - or _)."
        variant="outlined"
        required
        autoFocus
        margin="dense"
        id="appName"
        label="Application name"
        fullWidth
        onChange={handleAppNameChange}
        disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
      />
      <div style={{ marginTop: '10px' }}>
        <FormControl variant="filled" error={!receivingTenantValid} style={{ width: '300px' }} disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}>
          <TextField
            variant="outlined"
            margin="dense"
            id="receiving_tenant"
            label="Receiving Tenant"
            fullWidth
            onChange={handleReceivingTenantChange}
            disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
          />
          {!receivingTenantValid && <FormHelperText>Receiving Tenant Required!</FormHelperText>}
        </FormControl>
      </div>
      <div style={{ marginTop: '10px' }}>
        <FormControl variant="filled" error={!receivingAppValid} style={{ width: '300px' }} disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}>
          <TextField
            variant="outlined"
            margin="dense"
            id="receiving_app"
            label="Receiving App name"
            fullWidth
            onChange={handleReceivingAppChange}
            disabled={(successMessage && successMessage.length > 0 ? true : false) || isLoading}
          />
          {!receivingAppValid && <FormHelperText>Receiving App Required!</FormHelperText>}
        </FormControl>
      </div>
    </EchoDialog>
  );
}

export default XTenantSendingAppAdd;