import React, { useEffect, useState } from "react";
import { Card, CardActions, CardContent, CardHeader, Grid, IconButton, List, ListItem, ListItemText, Tooltip, Typography } from "@material-ui/core";
import { Box } from '@mui/material';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import CodeEditor from "components/CodeEditor";
import EchoDialog from "components/EchoDialog";
import EditIcon from '@material-ui/icons/Edit';
import EditMessageType from "./EditMessageType";
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import { DeleteMessageTypeModel } from "models/message-type/delete-messagetype";
import { GraphQLHelper } from "utilities/graphql-helper";
import { MutationsStatic } from "graphql/mutations-static";
import { IMessageType } from "interfaces/message-type";
import { CodeType } from "interfaces/code-type";
import MarkdownView from "components/MarkdownView";

interface Props {
  messageType: IMessageType | undefined,
  currentUserRole: UserRole | undefined,
  onDelete: () => void,
  onSave: () => void,
  tenant: ITenant,
  onMessageTypeChange: (code: string, copeType: CodeType, requirements: Array<string>) => void
}

const MessageTypeDetails: React.FC<Props> = (props) => {

  const [infoMessage, setInfoMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [editing, setEditing] = useState(false);
  const [messageTypeHasChanged, setMessageTypeHasChanged] = useState(false);
  const [requirements, setRequirements] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMessageTypeHasChanged(false);
    setEditing(false);
    if (props.messageType) {
      setRequirements(props.messageType?.requirements);
    }
  }, [props.messageType]);

  const clearState = (hideDialog: boolean) => {
    setErrorMessage('');
    setInfoMessage('');
    setSuccessMessage('');
    setOpen(!hideDialog);
  }

  const onDeleteMessageType = async () => {
    const params = {
      name: props.messageType?.name,
      tenant: props.tenant?.name
    }
    console.log(params);
    const result = await GraphQLHelper.execute<DeleteMessageTypeModel>(MutationsStatic.deleteMessageType, params, DeleteMessageTypeModel);
    if (!result.error) {
      console.log(result);
      setSuccessMessage('Message Type Deleted');
      props.onDelete();  
    } else {
      setErrorMessage(JSON.stringify(result.errorMessage));
    }
  }

  const onCodeChange = (code: string, codeType: CodeType, requirements: Array<string>) => {
    setMessageTypeHasChanged(true);
    if (codeType === CodeType.requirements) {
      props.onMessageTypeChange(code, codeType, requirements);
    } else {
      props.onMessageTypeChange(code, codeType, []);
    }
  }

  const sys = props.messageType?.system ? 'System' : 'Tenant';
  const title = <div><div style={{float: 'left'}}>{props.messageType?.name}</div><div style={{ float: 'right'}}>{`${sys}`}</div></div>;
  return (
    <div style={{ height: '100%' }}>
      <EchoDialog
        open={open}
        title={successMessage ? 'Message Type deleted' : `Delete message type: ${props.messageType?.name}?`}
        errorMessage={errorMessage}
        infoMessage={infoMessage}
        successMessage={successMessage}
        confirmDialog={true}
        onCancel={() => clearState(true)}
        onOk={() => clearState(true)}
        onSave={() => onDeleteMessageType()}
        contentText=""
        isValid={true}
        spinner={isLoading}
      />
      {props.messageType &&
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }} elevation={0}>
          <CardHeader style={{ flex: 'none', height: '0px', marginTop: '30px' }}
            title={title ? title : ''}
          />
          <div style={{ overflow: 'auto', height: '100%' }}>
            <CardContent style={{ height: '90%', flex: 'auto' }}>
              {editing &&
                <div style={{ height: '100%' }}>
                  <EditMessageType 
                    onMessageTypeChange={onCodeChange} 
                    messageType={props.messageType} 
                    tenant={props.tenant} 
                    currentUserRole={props.currentUserRole} 
                  />
                  <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                    <Grid container justifyContent="flex-end">
                      {!props.messageType?.system &&
                        <Tooltip title="Edit" aria-label="edit">
                          <span>
                            <IconButton disabled={!messageTypeHasChanged} onClick={() => { props.onSave(); setMessageTypeHasChanged(false); setEditing(false); }} aria-label="share">
                              <SaveIcon />
                            </IconButton>
                          </span>
                        </Tooltip>
                      }
                      <IconButton onClick={() => { setEditing(false); }} aria-label="share">
                        <CancelIcon />
                      </IconButton>
                    </Grid>
                  </CardActions>
                </div>
              }
              {!editing &&
                <div>
                  <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}>Description</legend>
                    {props.messageType?.description ? props.messageType?.description : '<no description>'}
                  </fieldset>
                  <fieldset style={{ marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> ReadMe </legend>
                    <Box height="250px">
                      <MarkdownView markdown={props.messageType?.readme} />
                    </Box>
                  </fieldset>
                  <fieldset style={{ borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Bitmapper Template </legend>
                    <Box height="250px">
                      <CodeEditor code={props.messageType?.bitmapperTemplate} />
                    </Box>
                  </fieldset>
                  <fieldset style={{ marginTop: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Processor Template </legend>
                    <Box height="250px">
                      <CodeEditor code={props.messageType?.processorTemplate} />
                    </Box>
                  </fieldset>
                  <fieldset style={{ marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Auditor </legend>
                    <Box height="250px">
                      <CodeEditor code={props.messageType?.auditor} />
                    </Box>
                  </fieldset>
                  <fieldset style={{ marginTop: '5px', marginBottom: '5px', borderRadius: '4px', borderColor: '#888' }}>
                    <legend style={{ fontSize: '12px' }}> Sample Message </legend>
                    <Box height="250px">
                      <CodeEditor code={props.messageType?.sampleMessage} />
                    </Box>
                  </fieldset>
                  <Box sx={{ border: "0.5px #888888 solid", borderRadius: 1, marginTop: 5}}>
                    <Typography style={{ padding: "8px", fontWeight: 1, fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif" }} variant="h6">
                      Requirements
                    </Typography>
                      <List>
                        {props.messageType.requirements ? props.messageType.requirements.map(req => <ListItem style={{ padding: 0, paddingLeft: 8, margin:0 }}> <ListItemText primary={req}/> </ListItem> ) : '<no requirements>'}
                      </List>
                  </Box>
                  {((props.currentUserRole === UserRole.admin || props.currentUserRole === UserRole.owner) && !props.messageType.system) &&
                    <CardActions disableSpacing style={{ flex: 'end', height: '50px' }}>
                      <Grid container justifyContent="flex-end">
                        <Tooltip title="Edit" aria-label="edit">
                          <IconButton onClick={() => { setEditing(true); }} aria-label="share">
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={() => { setOpen(true); }} aria-label="share">
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </CardActions>
                  }
                </div>
              }
            </CardContent>
          </div>
        </Card>
      }
    </div>
  );
};

export default MessageTypeDetails;
