import { IAtBy } from "interfaces/at-by";
import { IEchoFunction, EchoFunctionType } from "interfaces/echo-function";
import { GraphQLData } from "interfaces/graphql-data";
import { IMessageType } from "interfaces/message-type";
import { ITenant } from "interfaces/tenant";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { AtByModel } from "../at-by-model";
import { TenantModel } from "../tenant/tenant-model";

export class ProcessorFunctionModel implements IEchoFunction, GraphQLData {

  argumentMessageType: IMessageType = new MessageTypeModel();
  code: string = '';
  created: IAtBy = new AtByModel('', '');
  description: string = '';
  name: string = '';
  readme: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  requirements: string[] = [];
  system: boolean = false;
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  returnMessageType?: IMessageType | undefined;
  type: EchoFunctionType = EchoFunctionType.processor;
  lastEvaluatedKey: string = '';

  public static create(
    argumentMessageType: IMessageType,
    code: string,
    created: IAtBy,
    description: string,
    name: string,
    readme: string,
    lastModified: IAtBy,
    requirements: string[],
    system: boolean,
    tenant: ITenant,
    typeName: string,
    returnMessageType: IMessageType,
    type: EchoFunctionType) : ProcessorFunctionModel {
    const t = new ProcessorFunctionModel();
    t.argumentMessageType = argumentMessageType;
    t.code = code;
    t.created = created;
    t.description = description;
    t.name = name;
    t.readme = readme;
    t.lastModified = lastModified;
    t.requirements = requirements;
    t.system = system;
    t.tenant = tenant;
    t.typeName = typeName;
    t.returnMessageType = returnMessageType;
    t.type = type;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);
  }
}