import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { Arrow, ArrowType, ClickEventArgs, DefaultLabelStyle, EdgeEventArgs, Font, GraphComponent, GraphEditorInputMode, GraphItemTypes, GraphViewerInputMode, HandleInputMode, ICommand, IEdge, IGraph, IInputModeContext, IModelItem, INode, InputModeEventArgs, IPort, IPortCandidate, ItemClickedEventArgs, ItemEventArgs, LayoutExecutorAsync, License, MoveInputMode, NodeStylePortStyleAdapter, OrganicLayout, Point, PolylineEdgeStyle, PopulateItemContextMenuEventArgs, Rect, SelectionEventArgs, ShapeNodeStyle, Size, TimeSpan } from 'yfiles';
// @ts-ignore
import yFilesDevLicense from './license.development.json';
// @ts-ignore
import yFilesProdLicense from './license.production.json';
// @ts-ignore
import LayoutWorker from 'worker-loader!./LayoutWorker'; // eslint-disable-line import/no-webpack-loader-syntax
import { GetGraphLayout, SaveGraphLayout } from 'contexts/TenantUserFunctions';
import MessageTypePortCandidateProvider from './portcandidates/message-type-port-candidate-provider';
import EdgeReconnectProvider from './portcandidates/edge-reconnect-port-candidate-provider';
import { ReactComponentNodeStyle } from './ReactComponentNodeStyle';
import NodeTemplate from './NodeTemplate';
import ReactGraphOverviewComponent from './GraphOverviewComponent';
import DemoToolbar from './DemoToolbar';
import GraphSearch from './utils/GraphSearch';
import Tooltip from './Tooltip';
import GroupTemplate from './GroupTemplate';
import { ContextMenu, ContextMenuItem } from './ContextMenu';
import { NodeTypeName } from 'enumerations/nodetype-name';
import { IGraphData, IGraphEdgeData, IGraphElement, IGraphNodeData } from "./graph-interfaces";
import { ITenantUser } from 'interfaces/tenant-user';
import { IGraphLayout } from 'interfaces/graph-layout';
import { ITenant } from 'interfaces/tenant';
import './ReactGraphComponent.css';

interface GraphSaveNodeInfo {
  name: string,
  app: boolean,
  x: number,
  y: number,
  w: number,
  h: number
}

interface ReactGraphComponentProps {
  graphData: IGraphData
  onResetData(): void
  onEdgeCreated: (edge: IGraphEdgeData) => void
  onEdgeDeleted: (edge: IGraphEdgeData) => void
  onEdgeMoved: (edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => void
  onMoved: (edge: IGraphEdgeData, newSource: IGraphNodeData, newTarget: IGraphNodeData) => void
  onInspection: (item: IGraphElement) => void
  onListChanges: (item: IGraphElement) => void
  onListLogEvents: (item: IGraphElement) => void
  onDeletion: (item: IGraphElement) => void
  onPurgeEdge: (item: IGraphElement) => void
  onRoute: (item: IGraphElement) => void
  onSelection: (item: IGraphElement | null) => void
  menuWidth: any
  tenant: ITenant,
  refresh: number,
  tenantUser: ITenantUser,
  showSystemNodesAndEdges: boolean,
  startNode: (name: string | undefined, typeName: string | undefined) => void,
  stopNode: (name: string | undefined, typeName: string | undefined) => void,
  isRefreshing: boolean,
  setIsRefreshing: React.Dispatch<React.SetStateAction<boolean>>,
  saveStatus: string,
  setSaveStatus: React.Dispatch<React.SetStateAction<string>>
}

interface ReactGraphComponentState {
  contextMenu: {
    show: boolean
    x: number
    y: number
    items: ContextMenuItem[]
  }
}

const layoutWorker = new LayoutWorker();

export default class EchoGraph extends Component<ReactGraphComponentProps, ReactGraphComponentState> {

  private readonly div: React.RefObject<HTMLDivElement>;
  private readonly graphComponent: GraphComponent;
  private updating: boolean;
  private isDirty: boolean;
  private ports = new Array<IPort>();
  private graphSearch: NodeTagSearch | null | undefined;
  private $query: string;
  private loadedLayout: Array<GraphSaveNodeInfo> | null | undefined;
  private refreshCounter: number = -100;
  private nodeSize = new Size(200, 60);
  private timer;

  nodeTypes = [ 
    NodeTypeName.ManagedNode,
    NodeTypeName.BitmapRouterNode,
    NodeTypeName.ProcessorNode,
    NodeTypeName.TimerNode,
    NodeTypeName.AppChangeReceiverNode,
    NodeTypeName.AppChangeRouterNode,
    NodeTypeName.ChangeEmitterNode,
    NodeTypeName.AlertEmitterNode,
    NodeTypeName.AuditEmitterNode,
    NodeTypeName.LogEmitterNode,
    NodeTypeName.CrossTenantReceivingNode,
    NodeTypeName.CrossTenantSendingNode,
    NodeTypeName.ExternalNode,
    NodeTypeName.FilesDotComWebhookNode,
    NodeTypeName.WebhookNode,
    NodeTypeName.WebSubHubNode,
    NodeTypeName.WebSubSubscriptionNode,
    NodeTypeName.LoadBalancerNode
  ]

  static defaultProps = {
    graphData: {
      nodesSource: [],
      edgesSource: []
    }
  }

  constructor(props: ReactGraphComponentProps) {
    super(props)
    this.state = {
      contextMenu: {
        show: false,
        x: 0,
        y: 0,
        items: []
      }
    }

    this.updating = false;
    this.isDirty = false;
    this.$query = ''
    this.div = React.createRef<HTMLDivElement>();

    // Newly created elements are animated during which the graph data should not be modified

    // include the yFiles License
    // License.value = yFilesDevLicense
    // const isProd = process.env.NODE_ENV === 'production';
    // License.value = isProd ? yFilesProdLicense : yFilesDevLicense;
    // console.log("Environment", process.env.NODE_ENV, isProd)
    License.value = yFilesProdLicense

    // initialize the GraphComponent
    this.graphComponent = new GraphComponent();

    this.configureInteraction();
    // specify default styles for newly created nodes and edges
    this.initializeDefaultStyles();
    this.initializeContextMenu();
    this.initializeGraphSearch();
    //this.initializeTooltips();
    this.loadGraph(true);

    this.timer = setInterval(async () => {
      if (this.hasLayoutChanged() === true) {
        console.log("layout will save")
        await this.saveGraph();
        await this.loadGraph(false);
      }
    }, 5000);
  }

  configureInteraction = () => {
    const editor = new GraphEditorInputMode({
      allowGroupingOperations: true,
      allowCreateNode: false,
      allowReverseEdge: false,
      allowCreateBend: true,
    });
    editor.movableItems = GraphItemTypes.ALL

    const moveInputMode = editor.moveInputMode;

    editor.moveInputMode.addDragFinishedListener((sender: MoveInputMode, evt: InputModeEventArgs) => {
      console.log("dragging")
        //@ts-ignore
      for (const item of moveInputMode.affectedItems) {
        this.saveGraph()
      }
    })

    editor.createEdgeInputMode.useHitItemsCandidatesOnly = true;

    editor.addCanvasClickedListener((sender: GraphEditorInputMode, evt: ClickEventArgs) => {
      this.props.onSelection(null);
    })

    editor.createEdgeInputMode.edgeCreator = (
      context: IInputModeContext,
      graph: IGraph,
      sourceCandidate: IPortCandidate,
      targetCandidate: IPortCandidate | null,
      dummyEdge: IEdge
    ) => {
      // the targetCandidate might be null if the edge creation ended prematurely
      if (targetCandidate === null || dummyEdge === null || sourceCandidate === null) {
        // no target - no edge
        return null
      }

      // only allow to create edges between something which has ports
      if (sourceCandidate.port === null || targetCandidate.port === null) {
        return null;
      }

      // get the source and target ports from the candidates
      const sourcePort = sourceCandidate.port; //|| sourceCandidate.createPort(context)
      const targetPort = targetCandidate.port;// || targetCandidate.createPort(context)

      // create the edge between the source and target port
      const edge = graph.createEdge(sourcePort, targetPort, dummyEdge.style);

      // create a label
      graph.addLabel(edge, '');
      
      // return the created edge
      return edge;
    }

    editor.addItemClickedListener((sender: GraphEditorInputMode, evt: ItemClickedEventArgs<IModelItem>) => {
      const item = evt.item;
      if (item.tag.typeName === "Node" || item.tag.typeName === "TimerNode" || this.nodeTypes.includes(item.tag.typeName)) {        // const nd = item as INode;
        const ee = this.props.graphData.nodesSource.find(o => o.name === item.tag.name);
        if (ee) {
          this.props.onSelection(ee);
        }
      } else if (item.tag.typeName === "Edge") {
        const nd = item as IEdge;
        if (nd && nd.tag && nd.tag.edgeUniqueKey) {
          const ee = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === nd.tag.edgeUniqueKey);
          if (ee) {
            this.props.onSelection(ee);
          }
        } 
      } else if(item.tag.isApp) {
        const selectedApp = item;
        const app = this.props.graphData.apps.find(o => o.typeName === selectedApp.tag.typeName && o.name === selectedApp.tag.name);
        if (app) {
          this.props.onSelection(app);
        }
      }
    });

    editor.addDeletingSelectionListener((sender: GraphEditorInputMode, evt: SelectionEventArgs<IModelItem>) => {
      console.log('before delete selection listener', "evt.selection", evt.selection);
      const item = evt.selection.first();
      if (item.tag.typeName === "Node" || item.tag.typeName === "TimerNode" || this.nodeTypes.includes(item.tag.typeName)) {
        const nd = item as INode;
        const ee = this.props.graphData.nodesSource.find(o => o.name === nd.tag.name);
        if (ee && !ee.system) {
          this.props.onDeletion(ee);
        }
      } else if (item.tag.typeName === "Edge") {
        const ed = item as IEdge;
        const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === ed.tag.edgeUniqueKey);
        if (edge) {
          this.props.onDeletion(edge);
        }
      } else if (item.tag.isApp) {
        const app = this.props.graphData.apps.find(o => o.name === item.tag.name);
        if (app) {
          this.props.onDeletion(app);
        }
      } else {
        //if you don't want to delete anything                       
        evt.selection.clear()
      }
    });

    editor.addDeletedItemListener((sender: GraphEditorInputMode, evt: ItemEventArgs<IModelItem>) => {
      console.log('after delete');
    });

    editor.addEdgePortsChangedListener((sender: GraphEditorInputMode, evt: EdgeEventArgs) => {
      const item = evt.item
      if(item instanceof IEdge) {
        const ie = item as IEdge;
        //The Edge's NEW source and target Node Names
        const from = this.props.graphData.nodesSource.find(f => f.name === item.sourceNode?.tag.name);
        const to = this.props.graphData.nodesSource.find(t => t.name === item.targetNode?.tag.name);
        const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === ie.tag.edgeUniqueKey);
        //pass edge and ie to onMoved
        if(edge && from && to){
          this.props.onEdgeMoved(edge, from, to);
          console.log(`edge to be moved: ${edge}`);
        } 
      }
    });

    this.graphComponent.inputMode = editor;
    this.graphComponent.graph.nodeDefaults.ports.autoCleanUp = false
    this.graphComponent.graph.nodeDefaults.ports.style = new NodeStylePortStyleAdapter(
      new ShapeNodeStyle({
        shape: 'ellipse'
      })
    )
    this.registerPortCandidateProvider(this.graphComponent.graph);
    this.registerEdgePortCandidateProvider(this.graphComponent.graph);
    this.graphComponent.updateContentRect();
  }

  private initializeContextMenu(): void {
    const inputMode = this.graphComponent.inputMode as GraphViewerInputMode;
    ContextMenu.addOpeningEventListeners(this.graphComponent, location => {
      const worldLocation = this.graphComponent.toWorldFromPage(location);
      const showMenu = inputMode.contextMenuInputMode.shouldOpenMenu(worldLocation);
      if (showMenu) {
        this.openMenu(location)
      }
    })

    inputMode.addPopulateItemContextMenuListener((sender, args) => {      
      // select the item
      this.graphComponent.selection.clear();
      if (args.item) {
        this.graphComponent.selection.setSelected(args.item, true)
      }
      // populate the menu
      this.populateContextMenu(args);
    })
    inputMode.contextMenuInputMode.addCloseMenuListener(this.hideMenu.bind(this));
  }

  openMenu(location: { x: number; y: number }): void {
    this.updateContextMenuState('x', location.x)
    this.updateContextMenuState('y', location.y)
    this.updateContextMenuState('show', true)
  }

  onContextMenuInspect(item: IModelItem) {
    if (item.tag.typeName === "Node" || item.tag.typeName === "TimerNode" || this.nodeTypes.includes(item.tag.typeName)) {
      const element = item as INode;
      const node = this.props.graphData.nodesSource.find(o => o.name === element.tag.name);
      if (node) {
        this.props.onInspection(node);
      } 
    } else if (item.tag.typeName === "Edge") {
      const element = item as IEdge;
      const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === element.tag.edgeUniqueKey);
      if (edge) {
        this.props.onInspection(edge);
      }
    } else if (item.tag.isApp) {
      const element = item;
      const app = this.props.graphData.apps.find(o => o.name === element.tag.name);
      if (app) {
        this.props.onInspection(app);
      }
    }
  }

  onContextMenuDelete(item: IModelItem) {
    if (item.tag.typeName === "Node" || item.tag.typeName === "TimerNode" || this.nodeTypes.includes(item.tag.typeName)) {
      const element = item as INode;
      const node = this.props.graphData.nodesSource.find(o => o.name === element.tag.name);
      if (node) {
        this.props.onDeletion(node);
      } 
    } else if (item.tag.typeName === "Edge") {
      const element = item as IEdge;
      const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === element.tag.edgeUniqueKey);
      if (edge) {
        this.props.onDeletion(edge);
      }
    } else if (item.tag.isApp) {
      const element = item;
      const app = this.props.graphData.apps.find(o => o.name === element.tag.name);
      if (app) {
        this.props.onDeletion(app);
      }
    }
  }

  onContextMenuRoute(item: IModelItem) {
    if (item instanceof IEdge) {
      const nd = item as IEdge;
      const ee = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === nd.tag.edgeUniqueKey);
      if (ee) {
        this.props.onRoute(ee);
      }
    }
  }

  onContextMenuListChanges(item: IModelItem) {
    if (item.tag.typeName === "Node" || this.nodeTypes.includes(item.tag.typeName)) {
      const element = item as INode;
      const node = this.props.graphData.nodesSource.find(o => o.name === element.tag.name);
      if (node) {
        this.props.onListChanges(node);
      } 
    } else if (item.tag.typeName === "Edge") {
      const element = item as IEdge;
      const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === element.tag.edgeUniqueKey);
      if (edge) {
        this.props.onListChanges(edge);
      }
    } else if (item.tag.isApp) {
      const element = item;
      const app = this.props.graphData.apps.find(o => o.name === element.tag.name);
      if (app) {
        this.props.onListChanges(app);
      }
    }
  }

  onContextMenuListLogEvents(item: IModelItem) {
    if (item instanceof INode) {
      const nd = item as INode;
      const ee = this.props.graphData.nodesSource.find(o => o.name === nd.tag.name);
      if (ee) {
        this.props.onListLogEvents(ee);
      }
    }
  }

  onContextMenuPurge(item: IModelItem) {
    console.log("context menu purge");
    if (item instanceof IEdge) {
      const element = item as IEdge;
      const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === element.tag.edgeUniqueKey);
      if (edge){
        this.props.onPurgeEdge(edge);
      }
    }
  }

  populateContextMenu(args: PopulateItemContextMenuEventArgs<IModelItem>): void {
    let contextMenuItems: ContextMenuItem[]= [];
    const item = args.item;
    let isNode = false;
    let isTimerNode = false;
    let isEdge = false;
    let isApp = false;
    console.log(item);
    //determine element type
    if (item?.tag.typeName === "Node" || this.nodeTypes.includes(item?.tag.typeName)) {
      isNode = true;
    } else if (item?.tag.typeName === "Edge") {
      isEdge = true;
    } else if (item?.tag.isApp) {
      isApp = true;
    } else if (item?.tag.typeName === "TimerNode" || this.nodeTypes.includes(item?.tag.typeName)) {
      isTimerNode = true;
    }

    if (item && isNode) {
      const element = item as INode;
      const node = this.props.graphData.nodesSource.find(o => o.name === element.tag.name && !o.system); 
      if (element && node) {
        contextMenuItems.push({
          title: 'Inspect',
          action: () => {
            this.onContextMenuInspect(item)
          }
        });
        contextMenuItems.push({
          title: 'Delete',
          action: () => {
            this.onContextMenuDelete(item)
          }
        });
        contextMenuItems.push({
          title: 'List Changes',
          action: () => {
            this.onContextMenuListChanges(item)
          }
        });
        if (element.tag.typeName === NodeTypeName.BitmapRouterNode || 
            NodeTypeName.CrossTenantReceivingNode || 
            NodeTypeName.CrossTenantSendingNode || 
            NodeTypeName.ExternalNode || 
            NodeTypeName.FilesDotComWebhookNode || 
            NodeTypeName.LoadBalancerNode || 
            NodeTypeName.ManagedNode || 
            NodeTypeName.ProcessorNode || 
            NodeTypeName.TimerNode || 
            NodeTypeName.WebhookNode ||
            NodeTypeName.WebSubHubNode) {
          if (node.stopped) {
            contextMenuItems.push({
              title: 'Start Node',
              action: () => {
                this.props.startNode(element.tag.name, element.tag.typeName);
              }
            })
          } else if (!node.stopped) {
            contextMenuItems.push({
              title: 'Stop Node',
              action: () => {
                this.props.stopNode(element.tag.name, element.tag.typeName);
              }
            })
          }
        }
        
        if (element.tag.typeName === NodeTypeName.BitmapRouterNode ||
          element.tag.typeName === NodeTypeName.ProcessorNode ||
          element.tag.typeName === NodeTypeName.ManagedNode ||
          element.tag.typeName === NodeTypeName.CrossTenantSendingNode ||
          element.tag.typeName === NodeTypeName.WebhookNode ||
          element.tag.typeName === NodeTypeName.WebSubHubNode){
            contextMenuItems.push({
              title: 'List Log Events',
              action: () => {
                this.onContextMenuListLogEvents(item)
              }
            });
        };
      }; 
    }

    if (item && isTimerNode) {
      const element = item as INode;
      const node = this.props.graphData.nodesSource.find(o => o.name === element.tag.name && !o.system); 
      if (element && node) {
        contextMenuItems.push({
          title: 'Inspect',
          action: () => {
            this.onContextMenuInspect(item)
          }
        });
        contextMenuItems.push({
          title: 'Delete',
          action: () => {
            this.onContextMenuDelete(item)
          }
        });
        if (node.stopped) {
          contextMenuItems.push({
            title: 'Start Node',
            action: () => {
              this.props.startNode(element.tag.name, element.tag.typeName);
            }
          });
        } else if (!node.stopped) {
          contextMenuItems.push({
            title: 'Stop Node',
            action: () => {
              this.props.stopNode(element.tag.name, element.tag.typeName);
            }
          });
        }
      }
    }
    
    if (item && isEdge) {
      const element = item as IEdge;
      const edge = this.props.graphData.edgesSource.find(o => o.edgeUniqueKey === element.tag.edgeUniqueKey);
      if (edge) {
        const fromNode = this.props.graphData.nodesSource.find(o => o.name === edge.fromNodeName.name);
        contextMenuItems.push({
          title: 'Inspect',
          action: () => {
            this.onContextMenuInspect(item)
          }
        });
        contextMenuItems.push({
          title: 'Delete',
          action: () => {
            this.onContextMenuDelete(item)
          }
        });
        if (fromNode && fromNode.typeName === NodeTypeName.BitmapRouterNode) {
          contextMenuItems.push({
            title: 'Route',
            action: () => {
              this.onContextMenuRoute(item)
            }
          });
        }
        contextMenuItems.push({
          title: 'Purge',
          action: () => {
            this.onContextMenuPurge(item)
          }
        });
      }
    }

    if (item && isApp) {
      const element = item;
      const app = this.props.graphData.apps.find(o => o.name === element.tag.name);
      if (app) {
        contextMenuItems.push({
          title: 'Inspect',
          action: () => {
            this.onContextMenuInspect(item)
          }
        });
        contextMenuItems.push({
          title: 'Delete',
          action: () => {
            this.onContextMenuDelete(item)
          }
        });
        contextMenuItems.push({
          title: 'List Changes',
          action: () => {
            this.onContextMenuListChanges(item)
          }
        });
      }
    }

    //show menu
    this.updateContextMenuState('items', contextMenuItems);
      if (contextMenuItems.length > 0) {
        args.showMenu = true;
    }
  }

  async componentDidMount(): Promise<void> {
    // Append the GraphComponent to the DOM
    this.div.current!.appendChild(this.graphComponent.div);
    // this.graphComponent.graph.addNodeLayoutChangedListener((sender:IGraph, node:INode, oldLayout: Rect) => {
    //   this.saveGraph()
    // })
    this.graphComponent.graph.addEdgeRemovedListener((sender: IGraph, args: ItemEventArgs<IEdge>) => {
      // since the edge is not passed in when it's deleted I have to found out which one is missing
      if (this.updating) {
        return;
      }

      console.log('edge deleted');
      let deletedEdge = null;
      this.props.graphData.edgesSource.forEach(o => {
        const found = sender.edges.find(k => k.tag.edgeUniqueKey === o.edgeUniqueKey);
        if (!found) {
          deletedEdge = o;
          return;
        }
      });

      if (deletedEdge) {
        const edge = deletedEdge as IGraphEdgeData;
        console.log(`${edge.edgeUniqueKey} was deleted`);
        this.props.onEdgeDeleted(edge);
      } else {
        toast.error('Cant find deleted edge');
      }
    });

    this.graphComponent.graph.addEdgeCreatedListener((sender: IGraph, args: ItemEventArgs<IEdge>) => {
      if (this.updating) {
        return;
      }

      const edge = {
        fromNode: 0,
        fromNodeName: args.item.sourceNode?.tag,
        toNode: 0,
        toNodeName: args.item.targetNode?.tag,
        maxReceiveCount: 0
      } as IGraphEdgeData
      this.props.onEdgeCreated(edge);
    });
    this.updateGraph().catch(e => alert(e));
  }

  // runs when elements are added or removed from the graph
  componentDidUpdate(prevProps: ReactGraphComponentProps): void {
    if (this.props.graphData.apps?.length !== prevProps.graphData.apps?.length ||
      this.props.graphData.nodesSource?.length !== prevProps.graphData.nodesSource?.length ||
      this.props.graphData.edgesSource?.length !== prevProps.graphData.edgesSource?.length || 
      prevProps.refresh !== this.props.refresh ||
      this.props.showSystemNodesAndEdges !== prevProps.showSystemNodesAndEdges) {
      this.refreshCounter = this.props.refresh;
      console.log('refresh because props changed');
      this.updateGraph().catch(e => alert(e));
    }
  }

  async updateGraph(): Promise<void> {
    this.isDirty = true;
    if (this.updating) {
      return;
    }
    this.updating = true;
    const graph = this.graphComponent.graph;

    // remove all old nodes
    if (graph.nodes && graph.nodes.size > 0) {
      const nodesToRemove = graph.nodes.toList();
      nodesToRemove.forEach(node => graph.remove(node));
    }

    let doAutomaticLayout = true;
    const edges = this.props.graphData.edgesSource;
    this.ports = new Array<IPort>();
    let notLayedoutAutomatically = 1;

    if (!this.loadedLayout) {
      await this.loadGraph(false);
    }

    // filter out any nodes with a parent and system nodes based on boolean value
    if (this.props.showSystemNodesAndEdges) {
      this.props.graphData.nodesSource.filter(o => !o.parent).forEach(item => {

        let foundLayout = null;
        if (this.loadedLayout) {
          foundLayout = this.loadedLayout.find(o => o.name === `${item.name}~${item.typeName}` && !o.app);
        }
        let node = null;
        if (foundLayout) {
          doAutomaticLayout = false;
          node = graph.createNode(new Rect(foundLayout.x, foundLayout.y, foundLayout.w, foundLayout.h));
        } else {
          const rect = new Rect(0 + (notLayedoutAutomatically * 20), 0 + (notLayedoutAutomatically * 20), this.nodeSize.width, this.nodeSize.height);
          notLayedoutAutomatically += 1; // used to offset the layout a little bit so nodes which are not save with a layout is not stacked on top of eachother
          node = graph.createNode(rect);
        }
        node.tag = { name: item.name, typeName: item.typeName, receiveMessageType: item.receiveMessageType, sendMessageType: item.sendMessageType, system: item.system, stopped: item.stopped};
        if (item.receiveMessageType) {
          this.ports.push(graph.addPortAt(node, node.layout.topLeft.add(new Point(0, 10)), null, `receive~${item.name}`));
        }
        if (item.sendMessageType) {
          this.ports.push(graph.addPortAt(node, node.layout.topRight.add(new Point(0, 10)), null, `send~${item.name}`));
        }
      });
    } else {
      this.props.graphData.nodesSource.filter(o => !o.parent && !o.system).forEach(item => {

        let foundLayout = null;
        if (this.loadedLayout) {
          foundLayout = this.loadedLayout.find(o => o.name === `${item.name}~${item.typeName}` && !o.app);
        }
        let node = null;
        if (foundLayout) {
          doAutomaticLayout = false;
          node = graph.createNode(new Rect(foundLayout.x, foundLayout.y, foundLayout.w, foundLayout.h));
        } else {
          const rect = new Rect(0 + (notLayedoutAutomatically * 20), 0 + (notLayedoutAutomatically * 20), this.nodeSize.width, this.nodeSize.height);
          notLayedoutAutomatically += 1; // used to offset the layout a little bit so nodes which are not save with a layout is not stacked on top of eachother
          node = graph.createNode(rect);
        }
        node.tag = { name: item.name, typeName: item.typeName, receiveMessageType: item.receiveMessageType, sendMessageType: item.sendMessageType, system: item.system, stopped: item.stopped};
        if (item.receiveMessageType) {
          this.ports.push(graph.addPortAt(node, node.layout.topLeft.add(new Point(0, 10)), null, `receive~${item.name}`));
        }
        if (item.sendMessageType) {
          this.ports.push(graph.addPortAt(node, node.layout.topRight.add(new Point(0, 10)), null, `send~${item.name}`));
        }
      });
    }

    notLayedoutAutomatically = 0;

    if (this.props.graphData.apps) {
      this.props.graphData.apps.forEach(item => {
        let groupLayout: GraphSaveNodeInfo | null | undefined = null;
        let group: INode | null | undefined = null;

        // find any children
        const children = this.props.graphData.nodesSource.filter(o => o.parent && o.parent.name === item.name);

        if (this.loadedLayout) {
          groupLayout = this.loadedLayout.find(o => o.name === `${item.name}~${item.typeName}` && o.app);

        }

        if (groupLayout) {
          group = graph.createGroupNode({
            layout: new Rect(groupLayout.x, groupLayout.y, groupLayout.w, ((groupLayout.h > ((children.length * 100) + 100)) ? groupLayout.h : ((children.length * 100) + 100))),
            tag: { name: item.name, typeName: item.typeName, isApp: true }
          })
        } else {
          group = graph.createGroupNode({
            layout: new Rect(25, 45, 250, 300),
            tag: { name: item.name, typeName: item.typeName, isApp: true }
          });
        }
        
        children.forEach(child => {
          // find the layout
          let foundLayout = null;
          let node = null;

          if (this.loadedLayout) {
            foundLayout = this.loadedLayout.find(o => o.name === `${child.name}~${child.typeName}` && !o.app);
          }

          if (foundLayout) {
            doAutomaticLayout = false;
            node = graph.createNode(group, new Rect(foundLayout.x, foundLayout.y, foundLayout.w, foundLayout.h));
          } else {
            const rect = new Rect(((groupLayout?.x || 0) + 35), ((groupLayout?.y || 0) + (children.length === 1 ? 95 : (((children.length - 1) * 100) + 35))), this.nodeSize.width, this.nodeSize.height);
            notLayedoutAutomatically += 1; // used to offset the layout a little bit so nodes which are not save with a layout is not stacked on top of eachother
            node = graph.createNode(group, rect);
          }

          node.tag = { name: child.name, typeName: child.typeName, receiveMessageType: child.receiveMessageType, sendMessageType: child.sendMessageType, system: child.system, stopped: child.stopped };
          
          if (child.receiveMessageType) {
            this.ports.push(graph.addPortAt(node, node.layout.topLeft.add(new Point(0, 10)), null, `receive~${child.name}`));
          }

          if (child.sendMessageType) {
            this.ports.push(graph.addPortAt(node, node.layout.topRight.add(new Point(0, 10)), null, `send~${child.name}`));
          }

          if (group) {
            // doesn't work right; shrinks the group to too small of a size
            //graph.groupingSupport.getPathToRoot(group).forEach(node => {
            //  graph.adjustGroupNodeLayout(node)
            //});
          }
        })
        //graph.adjustGroupNodeLayout(group);
      });
    }

    edges.forEach(o => {
      // find the source port
      const sourcePort = this.ports.find(k => k.tag === `send~${o.fromNodeName.name}`);

      if (sourcePort) {
        const targetPort = this.ports.find(k => k.tag === `receive~${o.toNodeName.name}`);
        if (sourcePort && targetPort) {
          const edge = graph.createEdge(sourcePort, targetPort, null, { edgeUniqueKey: o.edgeUniqueKey, typeName: 'Edge' });
          const fromNode = this.props.graphData.nodesSource.find(k => k.name === o.fromNodeName.name);
          if (fromNode && fromNode.typeName === NodeTypeName.BitmapRouterNode) {
            // get the route table...
            let routes = "";
            fromNode.routeTable.forEach(t => {
              const per = t.toNodes.find(i => i === o.toNodeName.name);
              if (per) {
                const hexNumber = t.routeNumber;
                routes = hexNumber;
              }
            });
            let label = 'No route';
            if (routes !== "") {
              // routes = routes.substr(0, routes.length - 1);
              label = `${routes.toUpperCase()}`;
            }
            graph.addLabel(edge, label);
          }
        }
      }
    });
    // ... and make sure it is centered in the view (this is the initial state of the layout animation)
    this.graphComponent.fitGraphBounds();
    // Layout the graph with the hierarchic layout style

    if (doAutomaticLayout) {
      const organicLayout = new OrganicLayout();
      organicLayout.minimumNodeDistance = 100;
      organicLayout.preferredEdgeLength = 100;
      organicLayout.automaticGroupNodeCompaction = false;
      organicLayout.groupNodeCompactness = 0;
      organicLayout.deterministic = true;
      organicLayout.nodeOverlapsAllowed = false;
      await this.graphComponent.morphLayout(organicLayout, '1s');
    }

    this.isDirty = false;

    // create an asynchronous layout executor that calculates a layout on the worker
    const executor = new LayoutExecutorAsync({
      messageHandler: webWorkerMessageHandler,
      graphComponent: this.graphComponent,
      duration: '1s',
      animateViewport: true,
      easedAnimation: true
    })

    //await executor.start()
    console.log('after refresh');
    // add edges
    this.updating = false;
    //await executor.start()

    // helper function that performs the actual message passing to the web worker
    function webWorkerMessageHandler(data: unknown): Promise<any> {
      return new Promise(resolve => {
        layoutWorker.onmessage = (e: any) => resolve(e.data);
        layoutWorker.postMessage(data);
      })
    }
  }

  /**
   * Sets default styles for the graph.
   */
  initializeDefaultStyles(): void {
    this.graphComponent.graph.nodeDefaults.size = this.nodeSize;
    this.graphComponent.graph.nodeDefaults.style = new ReactComponentNodeStyle<{ name?: string, typeName: string, receiveMessageType: string, sendMessageType: string, system: boolean, stopped: boolean}>(
      NodeTemplate
    );

    this.graphComponent.graph.nodeDefaults.labels.style = new DefaultLabelStyle({
      textFill: '#fff',
      font: new Font('Robot, sans-serif', 10)
    });

    this.graphComponent.graph.groupNodeDefaults.style = new ReactComponentNodeStyle<{ name?: string, typeName: string }>(
      GroupTemplate
    );


    this.graphComponent.graph.edgeDefaults.style = new PolylineEdgeStyle({
      smoothingLength: 5,
      stroke: '3px #242265',
      sourceArrow: new Arrow({
        fill: '#242265',
        scale: 1.5,
        type: ArrowType.CIRCLE
      }),
      targetArrow: new Arrow({
        fill: '#242265',
        scale: 1.5,
        type: ArrowType.TRIANGLE
      })
    });

    this.graphComponent.graph.edgeDefaults.labels.style = new DefaultLabelStyle({
      textFill: '#fff',
      font: new Font('Robot, sans-serif', 10)
    });
  }

  registerPortCandidateProvider(graph: IGraph): void {
    console.log('register port candidates');
    graph.decorator.nodeDecorator.portCandidateProviderDecorator.setFactory(node => {
      return new MessageTypePortCandidateProvider(node);
    })    
  }

  registerEdgePortCandidateProvider(graph: IGraph): void {
    graph.decorator.edgeDecorator.edgeReconnectionPortCandidateProviderDecorator.setFactory(edge => {
      return new EdgeReconnectProvider(edge);
    })
  }

  /**
 * Initializes the node search input.
 */
  initializeGraphSearch() {
    this.graphSearch = new NodeTagSearch(this.graphComponent);
    this.graphComponent.graph.addNodeCreatedListener(this.updateSearch.bind(this));
    this.graphComponent.graph.addNodeRemovedListener(this.updateSearch.bind(this));
    this.graphComponent.graph.addLabelAddedListener(this.updateSearch.bind(this));
    this.graphComponent.graph.addLabelRemovedListener(this.updateSearch.bind(this));
    this.graphComponent.graph.addLabelTextChangedListener(this.updateSearch.bind(this));
    this.graphComponent.graph.addEdgePortsChangedListener(this.updateSearch.bind(this));
  }

  /**
   * The tooltip may either be a plain string or it can also be a rich HTML element. In this case, we
   * show the latter by using a dynamically compiled Vue component.
   * @param {IModelItem} item
   * @returns {HTMLElement}
   */
  private createTooltipContent(item: IModelItem) {
    const title = item instanceof INode ? 'Node Data' : 'Edge Data';
    const content = JSON.stringify(item.tag);
    const props = {
      title,
      content
    }
    const tooltipContainer = document.createElement('div');
    const element = React.createElement(Tooltip, props);
    ReactDOM.render(element, tooltipContainer);

    return tooltipContainer;
  }

  /**
   * Dynamic tooltips are implemented by adding a tooltip provider as an event handler for
   * the {@link MouseHoverInputMode#addQueryToolTipListener QueryToolTip} event of the
   * {@link GraphViewerInputMode} using the
   * {@link ToolTipQueryEventArgs} parameter.
   * The {@link ToolTipQueryEventArgs} parameter provides three relevant properties:
   * Handled, QueryLocation, and ToolTip. The Handled property is a flag which indicates
   * whether the tooltip was already set by one of possibly several tooltip providers. The
   * QueryLocation property contains the mouse position for the query in world coordinates.
   * The tooltip is set by setting the ToolTip property.
   */
  private initializeTooltips() {
    const inputMode = this.graphComponent.inputMode as GraphViewerInputMode;

    // show tooltips only for nodes and edges
    inputMode.toolTipItems = GraphItemTypes.NODE | GraphItemTypes.EDGE;

    // Customize the tooltip's behavior to our liking.
    const mouseHoverInputMode = inputMode.mouseHoverInputMode;
    mouseHoverInputMode.toolTipLocationOffset = new Point(15, 15);
    mouseHoverInputMode.delay = TimeSpan.fromMilliseconds(500);
    mouseHoverInputMode.duration = TimeSpan.fromSeconds(5);

    // Register a listener for when a tooltip should be shown.
    inputMode.addQueryItemToolTipListener((src, eventArgs) => {
      if (eventArgs.handled) {
        // Tooltip content has already been assigned -> nothing to do.
        return;
      }

      // Use a rich HTML element as tooltip content. Alternatively, a plain string would do as well.
      eventArgs.toolTip = this.createTooltipContent(eventArgs.item!);

      // Indicate that the tooltip content has been set.
      eventArgs.handled = true;
    })
  }

  /**
   * Updates the search highlights.
   */
  updateSearch() {
    if (this.graphSearch) {
      this.graphSearch.updateSearch(this.$query);
    }
  }

  /**
   * Called when the search query has changed.
   */
  onSearchQueryChanged(query: string) {
    this.$query = query;
    this.updateSearch();
  }

  private updateContextMenuState(key: string, value: any): void {
    const contextMenuState = { ...this.state.contextMenu };
      (contextMenuState as any)[key] = value;
    this.setState({ contextMenu: contextMenuState });
  }

  hideMenu(): void {
    this.updateContextMenuState('show', false);
  }

  //compares frontend layout with backend layout to detect changes
  hasLayoutChanged() {
    console.log("hasLayoutChanged was called");
    if (this.loadedLayout === null || this.loadedLayout?.length === 0) {
      console.log("No layout");
      return true;
    }

    let hasChanged = false;
    this.graphComponent.graph.nodes.forEach(o => {
      const foundLayout = this.loadedLayout?.find(layout => layout.name === `${o.tag.name}~${o.tag.typeName}`);
      if (foundLayout) {
        // compare
        if (foundLayout.x !== o.layout.x || foundLayout.y !== o.layout.y || foundLayout.h !== o.layout.height || foundLayout.w !== o.layout.width) {
          console.log("Changes found", foundLayout.name);
          hasChanged = true;
        }
      }
    })
    return hasChanged
  }

  async saveGraph() {
    try {
      this.props.setIsRefreshing(true);
      this.props.setSaveStatus("Saving...");
      console.log("saveGraph was called");
      const dr: any = {};
      this.graphComponent.graph.nodes.forEach(o => {
        dr[`${o.tag.name}~${o.tag.typeName}`] = {
          r: [o.layout.x, o.layout.y, o.layout.width, o.layout.height]
        };

        if (this.graphComponent.graph.isGroupNode(o)) {
          dr[`${o.tag.name}~${o.tag.typeName}`] = {
            r: [o.layout.x, o.layout.y, o.layout.width, o.layout.height],
            app: true,
          }
        }
      })
        // just allowing for 1 layout at the time
        await SaveGraphLayout(this.props.tenant, this.props.tenantUser.email, JSON.stringify(dr), 'first-layout');
    } catch (err) {
      console.log(err);
    } finally {
      this.props.setIsRefreshing(false);
      this.props.setSaveStatus("Saved");
    }
  }

  async loadGraph(update: boolean) {
    console.log("loadGraph was called");
    if (!this.props.tenant || !this.props.tenantUser || !this.props.tenantUser.email) {
      return;
    }

    const layouts = await GetGraphLayout(this.props.tenant, this.props.tenantUser.email);
    this.loadedLayout = null;

    if (layouts) {
      // just allowing for 1 layout at the time
      const layout = layouts.find(o => o.name === 'first-layout');
      if (layout) { 
        const lt = JSON.parse(layout.layout) as IGraphLayout;
        const nodeLayout = Object.keys(lt).map<GraphSaveNodeInfo>(o => {
          //@ts-ignore
          const nt = lt[o];
          return {
            name: o,
            app: nt['app'],
            x: nt.r[0],
            y: nt.r[1],
            w: nt.r[2],
            h: nt.r[3]
          } as GraphSaveNodeInfo;
        })
        this.loadedLayout = nodeLayout;
        if (update) {
          this.updateGraph().catch(e => alert(e));
        }
      }
    }
  }

  //saves layout if changes are detected
  async componentWillUnmount(): Promise<void> {
    clearInterval(this.timer);
    if (this.hasLayoutChanged() || !this.loadedLayout) {
      this.saveGraph();
    }
  }

  render(): JSX.Element {
    return (
      <div>
        <div className="toolbar" style={{ position: 'absolute', left: '200px', top: '65px', right: '0px', bottom: '0px' }}>
          <DemoToolbar
            resetData={this.props.onResetData}
            zoomIn={(): void => ICommand.INCREASE_ZOOM.execute(null, this.graphComponent)}
            zoomOut={(): void => ICommand.DECREASE_ZOOM.execute(null, this.graphComponent)}
            resetZoom={(): void => ICommand.ZOOM.execute(1.0, this.graphComponent)}
            fitContent={(): void => ICommand.FIT_GRAPH_BOUNDS.execute(null, this.graphComponent)}
            searchChange={evt => this.onSearchQueryChanged(evt.target.value)}
            saveGraph={() => this.saveGraph()}
            loadGraph={() => this.loadGraph(true)}
            isRefreshing={this.props.isRefreshing}
            setIsRefreshing={this.props.setIsRefreshing}
            saveStatus={this.props.saveStatus}
            setSaveStatus={this.props.setSaveStatus} />
        </div>
        <div className="graph-component-container" style={{ position: 'absolute', left: this.props.menuWidth, top: '60px', right: '0px', bottom: '0px' }} ref={this.div} />
        <ContextMenu
          x={this.state.contextMenu.x}
          y={this.state.contextMenu.y}
          show={this.state.contextMenu.show}
          items={this.state.contextMenu.items}
          hideMenu={() => this.hideMenu()}
        />
        <div style={{ position: 'absolute', left: this.props.menuWidth, top: '100px', margin: '20px' }}>
          <ReactGraphOverviewComponent graphComponent={this.graphComponent} />
        </div>
      </div>
    )
  }
}

class NodeTagSearch extends GraphSearch {
  matches(node: INode, text: string): boolean {
    if (node.tag) {
      const data = node.tag
      return data.name.toLowerCase().indexOf(text.toLowerCase()) !== -1 || data.typeName.toLowerCase().indexOf(text.toLowerCase()) !== -1;
    }
    return false
  }
}