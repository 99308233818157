import { GraphQLData } from "interfaces/graphql-data";
import { IMoveEdge } from "interfaces/move-edge";
import { ITenant } from "interfaces/tenant";

export class MoveEdgeModel implements IMoveEdge, GraphQLData {
    lastEvaluatedKey: string = '';
    moved: boolean = false;

    public static create(
        moved: boolean) : MoveEdgeModel {
            const t = new MoveEdgeModel();
            t.moved = moved;
            return t;
        }

    async hydrate(data: any, tenant: ITenant): Promise<void> {
        console.log(`MoveEdgeModel hydrate data: ${data}`);
         console.log(`data to be moved: ${data.data.GetEdge.Move}`);
        if (data && data.data && data.data.GetEdge && data.data.GetEdge.Move) {
            this.moved = data.data.GetEdge.Move;
        } else {
            throw new Error('Data structured passed in does not contain data, data.data, data.data.GetEdge or data.data.GetEdge.Move');
        }
    }
}