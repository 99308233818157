import React, { useState } from 'react';
import { Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import { CardHeader } from '@material-ui/core';
import { Avatar } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import { CardActions } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { IEchoEdge } from 'interfaces/echo-edge';
import { DeleteDialogType } from 'components/tenants/DeleteDialogs';
import PurgeEdge from 'components/PurgeEdge';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  avatar: {
    backgroundColor: grey[500],
  },
  icon: {
    backgroundColor: grey[700],
  },
  card: {
    height: '150px',
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    }
  }
}));

interface Props {
  edge: IEchoEdge | undefined,
  tenant: ITenant
  onUpdateGraph: () => void,
  onDeleteClicked: (dialog: DeleteDialogType) => void
}

const EdgeDetail: React.FC<Props> = (props) => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [errorDeleting, setErrorDeleting] = useState('');
  const [infoDeleting, setInfoDeleting] = useState('');
  const [edgeChanged] = useState(false);
  const [successDeleting, setSuccessDeleting] = useState('');
  const [openPurgeDialog, setOpenPurgeDialog] = useState(false);
  const classes = useStyles();

  const clearStateSaveDialog = (hideDialog: boolean) => {
    setErrorDeleting('');
    setInfoDeleting('');
    setSuccessDeleting('');
    setOpenSaveDialog(!hideDialog);
  }

  const clearStatePurgeDialog = (hideDialog: boolean) => {
    setErrorDeleting('');
    setInfoDeleting('');
    setSuccessDeleting('');
    setOpenPurgeDialog(!hideDialog);
  }

  const onUpdateEdge = async () => {

  }

  return (
    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
      <EchoDialog
        open={openSaveDialog}
        title={`Save edge '${props.edge?.description}' changes?`}
        errorMessage={errorDeleting}
        infoMessage={infoDeleting}
        successMessage={successDeleting}
        confirmDialog={true}
        onCancel={() => clearStateSaveDialog(true)}
        onOk={() => clearStateSaveDialog(true)}
        onSave={() => onUpdateEdge()}
        contentText=""
        isValid={true}
        spinner={false}
      />
      <PurgeEdge 
        edge={props.edge}
        tenant={props.tenant}
        onCancel={() => { clearStatePurgeDialog(true); }}
        onCloseDialog={() => { clearStatePurgeDialog(true); }}
        isPurging={openPurgeDialog}
      />
      <Card style={{ display: 'flex', flexDirection: 'column' }} elevation={3}>
        <CardHeader 
          style={{ flex: 'none', height: '50px' }}
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              T
            </Avatar>
          }
          title={props.edge?.description}
          titleTypographyProps={{ variant: 'h5'}}
          subheader={props.edge?.queue}
        />
        <CardContent style={{ flex: 'auto' }}>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>From: </b>{props.edge?.source}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>To: </b>{props.edge?.target}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>Delivery attempts before dead letter: </b>{props.edge?.maxReceiveCount ? props.edge?.maxReceiveCount : "Infinite"}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>Number of messages: </b>{props.edge?.messageCounts.approximateNumberOfMessages ? props.edge.messageCounts.approximateNumberOfMessages : 0}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>Number of messages delayed: </b>{props.edge?.messageCounts.approximateNumberOfMessagesDelayed ? props.edge.messageCounts.approximateNumberOfMessagesDelayed : 0}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b>Number of mesages not visible: </b>{props.edge?.messageCounts.approximateNumberOfMessagesNotVisible ? props.edge.messageCounts.approximateNumberOfMessagesNotVisible : 0}</Typography>
          <Typography style={{ color: "#ffffff", fontSize: '20px' }}> <b></b>{}</Typography>
        </CardContent>
        <CardActions disableSpacing style={{ flex: 'none', height: '50px' }}>
          <Button
            variant="contained"
            color='primary'
            onClick={() => setOpenPurgeDialog(true) }
          >
            Purge
          </Button>
          <Grid container justifyContent="flex-end">
            <IconButton disabled={!edgeChanged} onClick={() => { setOpenSaveDialog(true); }} aria-label="Save">
              <SaveIcon />
            </IconButton>
            <IconButton onClick={() => { props.onDeleteClicked(DeleteDialogType.Edge); }} aria-label="share">
              <DeleteIcon />
            </IconButton>
          </Grid>
        </CardActions>
      </Card>
    </div>
  )
}

export default EdgeDetail;