import React, { useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import EchoDialog from 'components/EchoDialog';
import { ITenant } from 'interfaces/tenant';
import { MutationsStatic } from 'graphql/mutations-static';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IEchoNode } from 'interfaces/echo-node';
import { DeleteNodeModel } from 'models/node/delete-node-model';
import { NodeTypeName } from 'enumerations/nodetype-name';

interface Props {
  node: IEchoNode | undefined,
  tenant: ITenant,
  onDeleted: () => void,
  onCancel: () => void,
  onCloseDialog: () => void
}

const NodeDelete: React.FC<Props> = (props) => {

  const [errorDeleting, setErrorDeleting] = useState('');
  const [infoDeleting, setInfoDeleting] = useState('');
  const [successDeleting, setSuccessDeleting] = useState('');
  const [drain, setDrain] = useState(true);
  const [isLoading, setIsLoading] = useState(false);


  const clearState = () => {
    setErrorDeleting('');
    setInfoDeleting('');
    setSuccessDeleting('');
  }

  const onDeleteNode = async () => {
    try {
      setIsLoading(true);
      clearState();
      const params = {
        name: props.node?.name,
        tenant: props.tenant.name,
      }
      if (props.node?.typeName === NodeTypeName.WebSubHubNode) {
        const query = MutationsStatic.deleteWebSubNode();
        const p = await GraphQLHelper.execute<DeleteNodeModel>(query, params, DeleteNodeModel);
        if (!p.error) {
          const deletedNode = p.result as DeleteNodeModel;
          console.log(deletedNode);
          props.onDeleted();
          setSuccessDeleting(`Node deleted.`);
        } else {
          setErrorDeleting(p.errorMessage);
        }
      } else {
        const query = MutationsStatic.deleteNode(drain);
        const p = await GraphQLHelper.execute<DeleteNodeModel>(query, params, DeleteNodeModel);
        if (!p.error) {
          const deletedNode = p.result as DeleteNodeModel;
          console.log(deletedNode);
          props.onDeleted();
          setSuccessDeleting(`Node deleted.`);
        } else {
          setErrorDeleting(p.errorMessage);
        }
      }
      
    } catch (err) {
      console.log(err);
      setErrorDeleting(JSON.stringify(err));
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <EchoDialog
      open={true}
      title={`Delete node: ${props.node?.name}?`}
      errorMessage={errorDeleting}
      infoMessage={infoDeleting}
      successMessage={successDeleting}
      confirmDialog={true}
      onCancel={props.onCancel}
      onOk={props.onCloseDialog}
      onSave={() => onDeleteNode()}
      contentText=""
      isValid={true}
      spinner={isLoading}
    >
      {props.node?.typeName !== NodeTypeName.WebSubHubNode &&
      <FormControlLabel
        control={
          <Checkbox
            checked={drain}
            onChange={(): void => { setDrain(!drain); }}
            name="checkedB"
            color="primary"
          />
        }
        label="Drain Edges"
        disabled={((successDeleting && successDeleting.length > 0) ? true : false) || isLoading}
      />}
    </EchoDialog>
  )
}

export default NodeDelete;