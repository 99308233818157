
export enum CodeType {
    bitMapper = 'bitmapper',
    processor = 'processor',
    auditor = 'auditor',
    requirements = 'requirements',
    description = 'description',
    readme = 'readme',
    sampleMessage = 'sampleMessage'
  }

  