import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { IHealthCheck } from "interfaces/healthcheck";
import { IMessageType } from "interfaces/message-type";
import { IEchoNode } from "interfaces/echo-node";
import { ITenant } from "interfaces/tenant";
import { AtByModel } from "models/at-by-model";
import { MessageTypeModel } from "models/message-type/messagetype-model";
import { TenantModel } from "models/tenant/tenant-model";
import { IEchoEdge } from "interfaces/echo-edge";
import { IApp } from "interfaces/app";
import { CrossTenantSendingAppModel } from "models/app/cross-tenant-sending-app";
import { CrossTenantReceivingAppModel } from "models/app/cross-tenant-receiving-app";

/*
type ManagedNodeType @aws_cognito_user_pools {
  healthcheck: Healthcheck
  hostMountRequirements: [HostMountRequirement!]
  portMappingRequirements: [PortMappingRequirement!]
  readme: String
}*/
export class CrossTenantReceivingNodeModel implements IEchoNode, GraphQLData {
  created: IAtBy = new AtByModel('', '');
  deleted: boolean = false;
  description: string = '';
  lastEvaluatedKey: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  name: string = '';
  parent: IApp = new CrossTenantReceivingAppModel()
  scannedCount: number = 0;
  receiveMessageType: IMessageType = new MessageTypeModel();
  receiveEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  sendMessageType: IMessageType = new MessageTypeModel();
  sendEdges: Array<IEchoEdge> = new Array<IEchoEdge>();
  tenant: ITenant = new TenantModel();
  typeName: string = '';
  stopped: boolean = false;

  public static create(
    name: string,
    typeName: string,
    parent: IApp,
    sendMessageType: IMessageType,
    sendEdges: Array<IEchoEdge>,
    stopped: boolean): CrossTenantReceivingNodeModel {
    const t = new CrossTenantReceivingNodeModel();
    t.name = name;
    t.parent = parent;
    t.sendMessageType = sendMessageType;
    t.sendEdges = sendEdges;
    t.typeName = typeName;
    t.stopped = stopped
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    //..
    // data.data.GetTenant.Deleted (true)
    console.log(data);
    /*if (data && data.data && data.data.GetTenant && data.data.GetTenant.Delete) {
      this.deleted = data.data.GetTenant.Delete;
    } else {
      throw new Error('data structured passed in does not contain data, data.data, data.data.GetTenant or data.data.GetTenant.Delete');
    }*/
  }
}