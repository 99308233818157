import React, { useState } from 'react';
import { Button, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core';
import { Mount } from 'models/managed/mount';
import { MountRequirement } from 'models/managed/mount-requirement';
import MountReqForm from './MountReqForm';
import MountReq from './MountReq';

interface Props {
    mounts: Array<Mount> | undefined,
    addMountRequirements: (mounts: Array<MountRequirement>) => void,
    isAddingManagedNode?: boolean,
    mountRequirements: Array<MountRequirement> | undefined
}

const AddMNTMounts: React.FC<Props> = (props) => {

  const [addingMount, setAddingMount] = useState(false);
  const [addedMountValue, setAddedMountValue] = useState(new MountRequirement('', '', ''));
  const [isEditing, setIsEditing] = useState(false);
  const [editedValue, setEditedValue] = useState(new MountRequirement('', '', ''));
  const [mountsToAdd, setMountsToAdd] = useState<Array<MountRequirement>>(new Array<MountRequirement>());
  const [selectedMountReq, setSelectedMountReq] = useState(new MountRequirement('', '', ''))

  const editMount = (mountReq: MountRequirement) => {
    setIsEditing(true);
    setEditedValue({ ...mountReq })
  }

  const cancelEdit = () => {
    setEditedValue(new MountRequirement('', '', ''));
    setIsEditing(false);
  }

  const updateMountToAdd = (mountReq: MountRequirement) => {
    // delete the edited mount
    const mounts = mountsToAdd?.filter(o => o !== selectedMountReq);
    // add the new one
    const tt = mounts.concat([mountReq]);
    setMountsToAdd(tt);
    cancelAddMount();
    setIsEditing(false);
    props.addMountRequirements(tt);
  }

  const cancelAddMount = () => {
    setAddedMountValue(new MountRequirement('', '', ''));
    setAddingMount(false);
  }

  const addMount = (newMountReq: MountRequirement) => {
    const newReq = mountsToAdd.concat(newMountReq);
    setMountsToAdd(newReq);
    setAddingMount(false);
    props.addMountRequirements(newReq);
    cancelAddMount();
  }


  const deleteMount = (mountReq: MountRequirement) => {
    const mnts = mountsToAdd.filter(o => o !== mountReq);
    setMountsToAdd(mnts);
    props.addMountRequirements(mnts);
  }

  return(
    <>
    <div style={{ overflow: 'auto', marginTop: '10px' }}>
      <fieldset style={{ borderRadius: '4px', borderColor: '#888', float: 'left', width: '800px' }}>
        <legend style={{ fontSize: '12px' }}> Mount Requirements </legend>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">Target</TableCell>
                  <TableCell align="left">Description</TableCell>
                  <TableCell align="left">Source</TableCell>
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  {addingMount && 
                    <MountReqForm 
                      mountReq={addedMountValue}
                      cancelEdit={cancelEdit}
                      updateMountReq={updateMountToAdd}
                      toggleEdit={setIsEditing}
                      setEdits={setEditedValue}
                      addMountReq={addMount}
                    />
                  }
                </TableRow>
                {mountsToAdd.map((o, index) => {
                  if (!isEditing) {
                    return (
                    <TableRow>
                      <MountReq 
                        key={index}
                        mountReq={o} 
                        updateMountReq={updateMountToAdd} 
                        deleteMountReq={deleteMount} 
                        cancelEdit={cancelEdit} 
                        setEdits={setEditedValue} 
                        addMountReq={addMount}
                        setSelectedMountReq={setSelectedMountReq}                        
                      />
                    </TableRow>)
                  }
                })}
                <div style={{ marginTop: '5px' }}>
                  <Button variant="contained" color="primary" onClick={() => { setAddingMount(true); }}>
                    Add Mount Requirements
                  </Button>
                </div>
              </TableBody>
            </Table>
          </TableContainer>
        </fieldset>
      </div>
    </>
  );
}
export default AddMNTMounts;