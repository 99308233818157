import React, { useEffect, useState } from "react";
import { Chip, makeStyles, Tab, Tabs, TextField, withStyles } from "@material-ui/core";
import EditorTemplate from "./EditorTemplate";
import { ITenant } from "interfaces/tenant";
import { UserRole } from "enumerations/user-role";
import { IMessageType } from "interfaces/message-type";
import { CodeType } from "interfaces/code-type";
import { Autocomplete } from "@mui/material";

interface Props {
  messageType: IMessageType,
  currentUserRole: UserRole | undefined,
  tenant: ITenant,
  onMessageTypeChange: (code: string, copeType: CodeType, requirements: Array<string>) => void
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const CustomTabs = withStyles({
  indicator: {
    backgroundColor: "#0EBCB7",
  },
})(Tabs);

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ height: '100%' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ height: '100%' }}>
          {children}
        </div>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: "#fff",
  },
  paper: {
    padding: theme.spacing(2),
    marginLeft: "5px",
    marginRight: "5px",
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const EditMessageType: React.FC<Props> = (props) => {

  const [value, setValue] = useState(0);
  const [requirements, setRequirements] = useState<Array<string>>([])
  const [description, setDescription] = useState('')

  const classes = useStyles();

  useEffect(() => {
    setRequirements(props.messageType.requirements);
    setDescription(props.messageType.description);
  }, [props.messageType])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    props.onMessageTypeChange('', CodeType.requirements, requirements);
  }, [requirements])

  const onRequirementsChange = (event: any, value: any) => {
    const trimmedReqs = value.map((req: any) => req.trim())
    setRequirements((state) => trimmedReqs);
  }

  const onDescriptionChange = (event: any) => {
    setDescription(event.target.value);
    props.onMessageTypeChange(event.target.value, CodeType.description, []);
  }

  return (
    <div style={{ height: '100%' }}>
      <Tabs value={value} onChange={handleChange}>
        <Tab label="Description"  {...a11yProps(0)} />
        <Tab label="ReadMe"  {...a11yProps(1)} />
        <Tab label="Requirements"  {...a11yProps(2)} />
        <Tab label="Bitmapper"  {...a11yProps(3)} />
        <Tab label="Processor"  {...a11yProps(4)} />
        <Tab label="Auditor"  {...a11yProps(5)} />
        <Tab label="Sample Message"  {...a11yProps(6)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <TextField
          variant="outlined"
          value={description}
          margin="dense"
          multiline
          minRows={5}
          id="description"
          label="Description"
          onChange={onDescriptionChange}
          fullWidth
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EditorTemplate 
          codeType={CodeType.readme} 
          onChange={props.onMessageTypeChange} 
          tenant={props.tenant} 
          code={props.messageType?.readme} 
          title="ReadMe" 
          functionTester={true}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Autocomplete
          multiple
          id="tags-filled"
          options={[]}
          defaultValue={requirements ? requirements : []}
          freeSolo
          onChange={onRequirementsChange}
          renderTags={(
            value: any[],
            getTagProps: (arg0: { index: any }) => JSX.IntrinsicAttributes
          ) =>
          <div style={{ display: "flex", flexDirection:"column" }}>
            {value.map((option: any, index: any) => {
              return (
                <Chip
                  key={index}
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              );}
            )
          }
          </div>}
          renderInput={(params: any) => (
            <div>
              <TextField
                {...params}
                fullWidth
                label="Requirements"
                placeholder="Add a requirement by pressing enter"
            />
            </div>
          )}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <EditorTemplate 
          codeType={CodeType.bitMapper} 
          onChange={props.onMessageTypeChange} 
          tenant={props.tenant} 
          code={props.messageType?.bitmapperTemplate} 
          title="Bitmapper Template" 
          functionTester={true}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <EditorTemplate 
          codeType={CodeType.processor} 
          onChange={props.onMessageTypeChange} 
          tenant={props.tenant} 
          code={props.messageType?.processorTemplate} 
          title="Processor Template" 
         functionTester={true} 
        />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <EditorTemplate 
          codeType={CodeType.auditor} 
          onChange={props.onMessageTypeChange} 
          tenant={props.tenant} 
          code={props.messageType?.auditor} 
          title="Auditor" 
          functionTester={true}
        />
      </TabPanel>
      <TabPanel value={value} index={6}>
        <EditorTemplate 
          codeType={CodeType.sampleMessage} 
          onChange={props.onMessageTypeChange} 
          tenant={props.tenant} 
          code={props.messageType?.sampleMessage} 
          title="Sample Message" 
          functionTester={true}
        />
      </TabPanel>
    </div>
  );
};

export default EditMessageType;
