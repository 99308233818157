import { IEchoFunction } from 'interfaces/echo-function';
import { ITenant } from 'interfaces/tenant';
import { QueriesStatic } from 'graphql/queries-static';
import { FunctionListModel } from 'models/function/function-list-model';
import { GraphQLHelper } from 'utilities/graphql-helper';
import { IDictionary } from 'interfaces/dictionary';

let instance: FunctionCache | null = null;

export class FunctionCache {
  private functions: IDictionary<Array<IEchoFunction>> = {};
  
  constructor() {
    if (!instance) {
      instance = this;
    }

    return instance;
  }

  public clearCache(tenant: ITenant) {
    this.functions[tenant.name] = new Array<IEchoFunction>();
  }

  public async getFunctions(tenant: ITenant) : Promise<Array<IEchoFunction>> {
    if (!this.functions[tenant.name] || this.functions[tenant.name].length === 0) {
      let ret = [] as Array<IEchoFunction>;
      let lastKey = {};
      let iterations = 0;
      while(true && iterations < 10) {
        const params = {
          tenant: tenant.name,
          exclusiveStartKey: JSON.stringify(lastKey)
        }
        const p = await GraphQLHelper.execute<FunctionListModel>(QueriesStatic.listFunctions, params, FunctionListModel, tenant);
        if (p.error) {
          console.log(p.errorMessage);
          break;
        } else {
          const fns = p.result as FunctionListModel;
          ret.push(...fns.functions);
          if (fns.lastEvaluatedKey) {
            // run the query again and aggergate result
            lastKey = JSON.parse(fns.lastEvaluatedKey);
          } else {
            break;
          }
        }
      }
      this.functions[tenant.name] = new Array<IEchoFunction>();
      this.functions[tenant.name].push(...ret);
    }

    return this.functions[tenant.name]
  }
}

