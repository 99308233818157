import { IAtBy } from "interfaces/at-by";
import { GraphQLData } from "interfaces/graphql-data";
import { ITenant } from "interfaces/tenant";
import { ITenantUser } from "interfaces/tenant-user";
import { AtByModel } from "./at-by-model";

export class LoginUserModel implements GraphQLData {
  lastEvaluatedKey: string = '';
  activeAt: string = '';
  created: IAtBy = new AtByModel('', '');
  email: string = '';
  firstName: string = '';
  lastModified: IAtBy = new AtByModel('', '');
  lastName: string = '';
  tenantUsers: Array<ITenantUser> = [];


  public static create(
      activeAt: string,
      created: IAtBy,
      email: string,
      firstName: string,
      lastModified: IAtBy,
      lastName: string,
      tenantUsers: Array<ITenantUser>
    
    ) : LoginUserModel {
    const t = new LoginUserModel();
    t.activeAt = activeAt;
    t.created = created;
    t.email = email;
    t.firstName = firstName;
    t.lastModified = lastModified;
    t.lastName = lastName
    t.tenantUsers = tenantUsers;
    return t;
  }

  async hydrate(data: any, tenant: ITenant): Promise<void> {
    console.log(data);

    if (data && data.data && data.data.GetUser) {
      const d = data.data.GetUser;
      Object.assign(this, d);
    }

  }
}